import yup from '../../utils/yup';

export type BoreholeTemperatureFormData = {
  id: number;
  depth: number | undefined;
  temperature: number | undefined;
};

export type BoreholeTemperatureSubmitFormData = {
  id: number;
  depth: number;
  temperature: number;
};

export const schema = yup.object().shape({
  depth: yup.number().min(0).max(1000000).required(),
  temperature: yup.number().min(-999).max(100).required()
});

export const prepareDataToSubmit = (formData: BoreholeTemperatureFormData): BoreholeTemperatureSubmitFormData => {
  return formData as unknown as BoreholeTemperatureSubmitFormData;
};
