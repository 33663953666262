import React, { FC, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { HStack } from 'native-base';

import { appModeStore } from '../../stores';

import { HeaderLeft } from './header-left';
import { HeaderRight } from './header-right';
import { Breadcrumbs } from '../breadcrumbs';

export const Header: FC = observer((): ReactElement => {
  const { intermediateStage } = appModeStore;
  const pointerEvents = intermediateStage === 'download' || intermediateStage === 'upload' ? 'none' : undefined;
  return (
    <HStack justifyContent="space-between" width="100%" height="80px" alignItems="center" pointerEvents={pointerEvents}>
      <HStack>
        <HeaderLeft />
        <Breadcrumbs />
      </HStack>
      <HeaderRight />
    </HStack>
  );
});
