import { yupResolver } from '@hookform/resolvers/yup';
import { Button, HStack, View } from 'native-base';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CancelableProps } from '../../../components/cancelable';
import Icon from '../../../components/icon';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';
import { User } from '../../../models/User';
import { BoreholeShifts } from '../borehole-shifts/borehole-shifts';
import { BoreholeFormData, schema } from './borehole-form.model';
import { BoreholeFormRendererProps } from './components/borehole-form-renderer.model';

type UserOption = Pick<User, 'name' | 'id'>;

export interface BoreholeFormProps extends CancelableProps {
  readonly creationList: UserOption[];
  readonly Renderer: React.ComponentType<BoreholeFormRendererProps>;
  readonly defaultValue: Partial<BoreholeFormData> | undefined;
  readonly onSubmit: (data: BoreholeFormData) => void;
  readonly boreholeId?: number;
}

export const BoreholeForm: React.FC<BoreholeFormProps> = ({
  boreholeId,
  defaultValue,
  creationList,
  Renderer,
  onSubmit,
  onCancel
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<BoreholeFormData>({
    defaultValues: defaultValue,
    resolver: yupResolver<any>(schema),
    mode: 'onSubmit'
  });

  useNavigationEffect(() => {
    reset(defaultValue);
  }, []);

  console.log('BoreholeForm', errors);

  return (
    <View mt={2}>
      {React.createElement(Renderer, {
        creationList,
        control,
        name: defaultValue?.name
      })}
      {boreholeId && <BoreholeShifts boreholeId={boreholeId} isEdit />}
      <HStack mx={10} space={6} mb={10}>
        <Button onPress={handleSubmit(onSubmit)} startIcon={<Icon name="save" />}>
          {t('form.buttons.save')}
        </Button>
        <Button onPress={onCancel} _text={{ color: 'primary.default' }} variant="link">
          {t('form.buttons.cancel')}
        </Button>
      </HStack>
    </View>
  );
};
