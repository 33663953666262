import React from 'react';
import { Center, Text, Button } from 'native-base';
import { appName } from '@geo/commons/constants';
import { goBack } from '@geo/commons/navigation';

export const Restore: React.FC = () => {
  return (
    <Center flex={1}>
      <Text style={{ textTransform: 'uppercase' }} mb="70px">
        {appName}
      </Text>
      <Text mb="40px">Восстановление пароля</Text>
      <Text width="480px" mb="40px">
        Для восстановления пароля свяжитесь с ответственным сотрудником офиса по номеру телефона +XXXXXXXXXXX
      </Text>
      <Button width="320px" ml={1} onPress={goBack}>
        Назад
      </Button>
    </Center>
  );
};
