import React from 'react';
import { Button, Text, Pressable, Box } from 'native-base';
import Icon, { IconName } from '../icon';

export interface PageViewProps {
  current: number;
  page?: string | number;
  icon?: IconName;
  onPress: () => void;
}

export const PageView = ({ current, page, icon, onPress }: PageViewProps) => {
  const selected = current === page;

  return (
    <Pressable onPress={selected ? undefined : onPress} key={page}>
      {({ isHovered }) => {
        const hasBorder = !isHovered && !selected;
        return (
          <Box
            minWidth="40px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            borderRadius="8px"
            alignItems="center"
            height="40px"
            padding={0}
            {...(hasBorder ? { borderWidth: 1, borderStyle: 'solid', borderColor: '#dbdeea' } : {})}
            backgroundColor={selected ? 'dark.dark-2' : isHovered ? 'secondary.default' : 'light.light-0'}
          >
            <Text fontSize={14} color={isHovered || selected ? 'light.light-0' : 'dark.dark-2'}>
              {page && `${page}`}
            </Text>
            {icon && <Icon fill={isHovered ? 'white' : undefined} name={icon} />}
          </Box>
        );
      }}
    </Pressable>
  );
};
