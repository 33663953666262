import { observable, computed, action, runInAction } from 'mobx';
import { Comment } from '@geo/api/generated/swagger.json/components/schemas/Comment';
import { getApiClient } from '../api/api-client';
import { CollectionStore, CollectionFetched } from './collection';

export class CommentsStore extends CollectionStore<Comment> {
  @observable facilityId?: number;

  @computed get count(): number {
    return this.items.length;
  }

  @action setFacilityId(facilityId: number | undefined): void {
    this.facilityId = facilityId;
  }

  @action async fetchItems(): Promise<CollectionFetched<Comment>> {
    if (this.facilityId === undefined) {
      throw new Error('Facility identifier is not specified');
    }
    const { result: items, size: total } = await getApiClient().commentController.listOfCommentsByFacilityId(
      this.facilityId,
      { query: { page: undefined, size: 10000, sortBy: 'createdAt', order: 'DESC' } }
    );
    return { items, total };
  }

  @action async create(facilityId: number, parameters: { text: string }): Promise<void> {
    const comment = await getApiClient().commentController.createComment(facilityId, {
      body: parameters
    });
    runInAction(() => {
      this.items.unshift(comment);
    });
  }

  @action async delete(facilityId: number, commentId: number): Promise<void> {
    await getApiClient().commentController.deleteComment(facilityId, commentId);
    const filteredData = this.items.filter((item) => item.id !== commentId);
    this.setItems(filteredData, filteredData.length);
  }

  @action async update(facilityId: number, commentId: number, parameters: { text: string }): Promise<void> {
    const comment = await getApiClient().commentController.updateComment(facilityId, commentId, {
      body: parameters
    });
    const updated = this.items.map((item) => (item.id === comment.id ? comment : item));
    this.setItems(updated, updated.length);
  }
}
