import React, { useState } from 'react';
import { Box, Button } from 'native-base';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { PrintButton } from 'components/print-button/print-button';

import Icon from '@geo/commons/components/icon';
import { useNavigationEffect } from '@geo/commons/hooks/useNavigationEffect';
import { ConsolidatedStatementRow } from '@geo/api/generated/swagger.json/components/schemas/ConsolidatedStatementRow';
import { BoreholeLayersPrintComponent } from '@geo/commons/utils/components/types';
import { facilityStore, appStore } from '@geo/commons/stores';

import { getApiClient } from '@geo/commons/api/api-client';
import { BoreholeLayersPrint } from './borehole-layers-print';

export const BoreholeLayersPrintContainer: BoreholeLayersPrintComponent = observer(({ facilityId }) => {
  const { t } = useTranslation();
  const { item: currentFacility } = facilityStore;
  const { currentUser } = appStore;
  const [rows, setRows] = useState<ConsolidatedStatementRow[]>([]);

  useNavigationEffect(() => {
    facilityStore.loadItem(facilityId).catch((e) => {
      console.error(e);
    });
  }, [facilityId]);

  const beforePrint = async () => {
    const data = await getApiClient().consolidatedStatementController.ConsolidatedStatement(facilityId, {
      query: {
        deliveredByUserId: undefined,
        sampleIds: undefined
      }
    });
    setRows(data.rows);
  };

  if (!currentFacility || !currentUser) {
    return <></>;
  }

  return (
    <Box>
      <PrintButton
        beforePrint={beforePrint}
        buttonTrigger={
          <Button startIcon={<Icon name="print" fill="white" />} variant="solid">
            {t('borehole.sample.print.button')}
          </Button>
        }
      >
        <BoreholeLayersPrint user={currentUser} facility={currentFacility} rows={rows} />
      </PrintButton>
    </Box>
  );
});

BoreholeLayersPrintContainer.displayName = 'BoreholeLayersPrintContainer';
