import { action, makeObservable, observable } from 'mobx';

import { getApiClient } from '../api/api-client';
import { Facility, FacilitySortColumn } from '../models/Facility';

import { BaseTableStore, FetchListFn } from './base-table';
import { CollectionFetched } from './collection';

export interface FacilityTableEntity extends Facility {
  markToOffline?: boolean;
}

export type FacilityTableGetFetchList = FetchListFn<FacilityTableEntity, FacilitySortColumn>;

export class FacilityTableStore extends BaseTableStore<FacilityTableEntity, FacilitySortColumn> {
  @observable markedAllToOffline = false;

  @observable markedToOffline: number[] = [];

  constructor(storageKey: string) {
    super(storageKey);
    makeObservable(this);
    this.sortBy = 'lastUpdatedAt';
    this.order = 'DESC';
  }

  readonly getFetchList = (): FacilityTableGetFetchList => {
    return getApiClient().facilityController.listOfFacilities;
  };

  @action async fetchItems(): Promise<CollectionFetched<Facility>> {
    const { result: items, size: total } = await this.getFetchList()({ query: this.storedQuery });
    return { items, total };
  }

  async loadItems(): Promise<void> {
    await super.loadItems(({ items }) => {
      if (this.markedAllToOffline) {
        const ids = items.map((item) => item.id);
        this.multiMarkToOffline(ids);
      }
    });
  }

  @action.bound markToOffline(id: number): void {
    const index = this.markedToOffline.findIndex((markedId) => markedId === id);
    if (index >= 0) {
      this.markedToOffline.splice(index, 1);
    } else {
      this.markedToOffline.push(id);
    }
    this.markedAllToOffline = false;
  }

  @action.bound multiMarkToOffline(ids: number[]): void {
    const markedToOffline = [...this.markedToOffline, ...ids];
    this.markedToOffline = markedToOffline.filter((item, index) => markedToOffline.indexOf(item) === index);
  }

  @action.bound markAllToOffline(): void {
    const ids = [...this.items.map((item) => item.id)];
    this.markedAllToOffline = !this.markedAllToOffline;
    if (this.markedAllToOffline) {
      this.multiMarkToOffline(ids);
    } else {
      this.markedToOffline = [];
    }
  }

  @action.bound resetMarked(): void {
    this.markedToOffline = [];
    this.markedAllToOffline = false;
  }
}
