import React, { ReactElement } from 'react';
import { Flex, FlatList } from 'native-base';
import { ListRenderItem } from 'react-native';

import { Pagination } from '../pagination/pagination';

import { TableRow, TableRowProps } from './table-row';
import { TableHeader } from './table-header';
import {
  TableIdentifiedItem,
  ColumnDefinition,
  CustomHeaderRenderers,
  CustomRenderers,
  Sort,
  Actions
} from './table.model';

export interface TableProps<T extends TableIdentifiedItem, S extends string = never> {
  items: T[];
  headerHeight?: number;
  rowHeight?: number;
  rowAlignment?: 'flex-start' | 'flex-end' | 'center';
  sort?: Sort<S>;
  borderWidth?: number;
  selectedId?: T['id'];
  onRowClick?: (id: T['id']) => void;
  footer?: ReactElement;
  customHeaderRenderers?: CustomHeaderRenderers<T>;
  customRowRenderer?: (rowProps: TableRowProps<T, S>) => ListRenderItem<T>;
  customRenderers?: CustomRenderers<T>;
  actions?: Actions<T>;
  actionsWidth?: number;
  hidePagination?: boolean;
}

export interface TableWithPaginationProps<T extends TableIdentifiedItem, S extends string = never>
  extends TableProps<T, S> {
  total: number;
  page: number;
  perPage: number;
  onChangePage: (page: number) => void;
  onChangeSort: (sort: Sort<S>) => void;
}

export const Table = <T extends TableIdentifiedItem, S extends string = never>(
  props: TableWithPaginationProps<T, S> & { columns: Array<ColumnDefinition<T, S>> }
): ReactElement => {
  const {
    items,
    columns,
    headerHeight,
    rowHeight,
    rowAlignment,
    sort,
    selectedId,
    onRowClick,
    footer,
    customHeaderRenderers,
    customRowRenderer,
    customRenderers,
    actions,
    actionsWidth,
    borderWidth = 1,
    hidePagination,
    total,
    page,
    perPage,
    onChangePage,
    onChangeSort
  } = props;

  return (
    <>
      <Flex borderWidth={borderWidth} borderColor="#dbdeea" bg="white" rounded="lg">
        <FlatList
          keyExtractor={({ id }) => `row-${id}`}
          data={items}
          keyboardShouldPersistTaps="handled"
          ListHeaderComponent={
            <TableHeader
              customHeaderRenderers={customHeaderRenderers}
              onChangeSort={onChangeSort}
              columns={columns}
              sort={sort}
              actionsWidth={actionsWidth}
              height={headerHeight}
            />
          }
          renderItem={(info: { item: T; index: number; separators: any }) => {
            const { item, index: rowIndex } = info;
            const rowProps: TableRowProps<T, S> = {
              item,
              columns,
              index: rowIndex,
              height: rowHeight,
              alignment: rowAlignment,
              isSelected: item.id === selectedId,
              onClick: onRowClick,
              customRenderers,
              actions,
              actionsWidth
            };
            return customRowRenderer ? (
              customRowRenderer(rowProps)(info)
            ) : (
              <TableRow key={`table-row-${item.id}`} {...rowProps} />
            );
          }}
        />
      </Flex>
      {footer}
      {!hidePagination && <Pagination current={page} total={total} perPage={perPage} onChange={onChangePage} />}
    </>
  );
};
