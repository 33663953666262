import { string, number, literal, union, array, undefined, type } from 'io-ts';

export type FacilityUpdateRequest = {
	name: string;
	cypher: string;
	responsibleUserId: number;
	status: 'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED' | 'ARCHIVED';
	geologistsUsersIds: Array<number> | undefined;
};
export const FacilityUpdateRequestIO = type(
	{
		name: string,
		cypher: string,
		responsibleUserId: number,
		status: union([
			literal('NEW'),
			literal('IN_PROGRESS'),
			literal('PROBLEM'),
			literal('CLOSED'),
			literal('ARCHIVED'),
		]),
		geologistsUsersIds: union([array(number), undefined]),
	},
	'FacilityUpdateRequest',
);
