import { MonadThrow1 } from 'fp-ts/MonadThrow';

export const URI = 'Promise' as const;
export type URI = typeof URI;

declare module 'fp-ts/HKT' {
  interface URItoKind<A> {
    readonly [URI]: Promise<A>;
  }
}

export const monadPromise: MonadThrow1<URI> = {
  URI,
  of: (data) => Promise.resolve(data),
  throwError: (error) => Promise.reject(error),
  map: (fa, f) => fa.then((a) => Promise.resolve(f(a))),
  ap: (fab, fa) => fab.then((fn) => fa.then((a) => fn(a))),
  chain: (fa, f) => fa.then(f)
};
