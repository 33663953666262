import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react';
import { Ability } from '@casl/ability';

import { appStore } from '../../stores';

import { defineAbilitiesFor } from './index';

export const PermissionContext = React.createContext(defineAbilitiesFor());

export const PermissionProvider: FC = observer((props) => {
  const { children } = props;
  const { permission } = appStore;
  return <PermissionContext.Provider value={permission}>{children}</PermissionContext.Provider>;
});

export const usePermission = (): Ability => {
  return useContext<Ability>(PermissionContext);
};
