import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { StackActions } from '@react-navigation/native';

import { Cancelable } from '../../../components/cancelable';
import { Loadable } from '../../../components/loadable';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { Borehole } from '../../../models/Borehole';
import { screens } from '../../../navigation/config';
import { appStore, boreholeStore, workerListStore } from '../../../stores';

import {
  centimetersToMeters,
  metersToCentimeters,
  metersToCentimetersOptional,
  pipeCaseCentimetersToMeters,
  pipeCaseMetersToCentimeters
} from '../../../utils/units';

import { BoreholeForm } from '../borehole-form/borehole-form';
import { BoreholeFormData } from '../borehole-form/borehole-form.model';
import { BoreholeFormRendererGeologist } from '../borehole-form/components/borehole-form-renderer-geologist';
import { BoreholeFormRendererAdmin } from '../borehole-form/components/borehole-form-renderer-admin';

function boreholeToFormData(borehole: Borehole | null): Partial<BoreholeFormData> | undefined {
  if (!borehole) {
    return undefined;
  }

  const {
    name,
    creator,
    status,
    coordinate,
    isMetal,
    isPlastic,
    depthTarget,
    depthActual,
    pipeCase,
    technologicalExpansion
  } = borehole;

  return {
    name,
    isMetal,
    isPlastic,
    creatorId: creator.id,
    depthTarget: centimetersToMeters(depthTarget),
    status,
    latitude: coordinate.latitude,
    longitude: coordinate.longitude,
    ...pipeCaseCentimetersToMeters(pipeCase),
    technologicalExpansion:
      typeof technologicalExpansion === 'number' ? centimetersToMeters(technologicalExpansion) : undefined
  };
}

type BoreholeUpdateProps = {
  boreholeId: number;
};

export const BoreholeUpdate: FC<BoreholeUpdateProps> = observer((props) => {
  const { boreholeId } = props;
  const { role } = appStore;
  const { item: borehole } = boreholeStore;
  const { t } = useTranslation();
  const navigation = useAppNavigation();

  const ready = useComponentReady(() => {
    boreholeStore.loadItem(boreholeId).catch((e) => console.error(e));
  }, [boreholeId]);

  useEffect(() => {
    workerListStore.loadItems().catch((e) => console.error(e));
  }, []);

  const onSubmit = useCallback(
    (data: BoreholeFormData) => {
      const {
        name,
        status,
        latitude,
        longitude,
        depthTarget,
        depthActual,
        pipeCase1,
        pipeCase2,
        pipeCase3,
        isMetal,
        isPlastic,
        technologicalExpansion
      } = data;

      const params = {
        name,
        isMetal,
        isPlastic,
        depthTarget: metersToCentimeters(depthTarget),
        depthActual: metersToCentimetersOptional(depthActual),
        status: status as 'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED',
        coordinate: { latitude, longitude },
        pipeCase: pipeCaseMetersToCentimeters({ pipeCase1, pipeCase2, pipeCase3 }),
        technologicalExpansion:
          typeof technologicalExpansion === 'number' ? metersToCentimeters(technologicalExpansion) : undefined
      };

      console.log(params);

      boreholeStore.update(boreholeId, params).catch((e) => console.error(e));
    },
    [boreholeId]
  );

  if (!ready) {
    return null;
  }

  const onCancel = () => {
    if (borehole) {
      const { key: target, routes } = navigation.getState();
      if (routes[routes.length - 2]?.name === screens.Boreholes) {
        navigation.goBack();
      } else {
        navigation.dispatch({
          ...StackActions.replace(screens.Boreholes, { facilityId: borehole.facility.id }),
          target
        });
      }
    }
  };

  const creationList = borehole?.creator ? [{ id: borehole.creator.id, name: borehole.creator.name }] : [];

  return (
    <Loadable
      isLoading={boreholeStore.loading('get') || boreholeStore.loading('update')}
      error={!!boreholeStore.error('get') || !!boreholeStore.error('update')}
    >
      <Cancelable pending={!borehole} cancelConfirmationText={t('borehole.form.update.cancel.confirmation')}>
        <BoreholeForm
          boreholeId={boreholeId}
          creationList={creationList}
          Renderer={role === 'ROLE_GEOLOGIST' ? BoreholeFormRendererGeologist : BoreholeFormRendererAdmin}
          defaultValue={boreholeToFormData(borehole)}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Cancelable>
    </Loadable>
  );
});
