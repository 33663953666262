import { GeoDescription, GeoDescriptionIO } from '../../components/schemas/GeoDescription';
import { number, string, literal, union, undefined, type } from 'io-ts';

export type BoreholeLayerSample = {
	id: number;
	boreholeId: number;
	boreholeName: string;
	boreholeLayerId: number;
	type: 'MONOLITH' | 'WATER' | 'DISTURBED';
	depthFrom: number;
	depthTo: number;
	geoDescription: GeoDescription | undefined;
	accepted: 'UNDEFINED' | 'ACCEPTED' | 'REJECTED';
	comment: string | undefined;
};
export const BoreholeLayerSampleIO = type(
	{
		id: number,
		boreholeId: number,
		boreholeName: string,
		boreholeLayerId: number,
		type: union([literal('MONOLITH'), literal('WATER'), literal('DISTURBED')]),
		depthFrom: number,
		depthTo: number,
		geoDescription: union([GeoDescriptionIO, undefined]),
		accepted: union([literal('UNDEFINED'), literal('ACCEPTED'), literal('REJECTED')]),
		comment: union([string, undefined]),
	},
	'BoreholeLayerSample',
);
