import { NotificationParams, NotificationParamsIO } from '../../components/schemas/NotificationParams';
import { number, undefined, union, string, boolean, type, intersection } from 'io-ts';

export type FacilityNotificationParams = NotificationParams & {
	id: number | undefined;
	name: string | undefined;
	markToDelete: boolean | undefined;
	archived: boolean | undefined;
};
export const FacilityNotificationParamsIO = intersection([
	NotificationParamsIO,
	type({
		id: union([number, undefined]),
		name: union([string, undefined]),
		markToDelete: union([boolean, undefined]),
		archived: union([boolean, undefined]),
	}),
]);
