import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Cancelable } from '../../components/cancelable';
import { useNavigationEffect } from '../../hooks/useNavigationEffect';
import { FilesUploadForm } from './files-upload-form/files-upload-form';
import { FileStore, FileType } from '../../stores/file';

import {
  photoMaxSize,
  documentMaxSize,
  photoExtensions,
  documentExtensions
} from '../../components/uploader/constants';
import { useComponentReady } from '../../hooks/useComponentReady';

interface PhotosUploadContainerProps {
  facilityId: number;
  fileId?: number;
  fileType: FileType;
  store: FileStore;
  mode: 'edit' | 'upload';
  navigate?: (...args: any[]) => void;
  onSuccess?: (() => Promise<void>) | (() => void);
}

export const FilesUploadContainer = observer(
  ({ facilityId, navigate, fileId, mode, fileType, store, onSuccess }: PhotosUploadContainerProps) => {
    const { t } = useTranslation();
    const { uploadValue, setUploadValue, item: file } = store;
    const isLoading = store.loading('get');
    const ready = useComponentReady(() => {
      store.setFacilityId(facilityId);
    }, [facilityId]);

    const [validate, setValidate] = useState(false);

    const onSubmit = useCallback(async () => {
      const result =
        mode === 'edit' && file
          ? await store.patch(facilityId, file.id, fileType)
          : await store.upload(facilityId, fileType);

      if (result) {
        if (onSuccess) {
          await onSuccess();
        }
        navigate?.();
        store.setItem(null);
        store.setUploadValue(null);
      } else {
        console.error(result);
      }
    }, [uploadValue, facilityId]);

    const CANCEL_TEXT = {
      edit: t('uploader.form.cancel.confirmation', { name: file?.description }),
      upload: t('facility.form.create.cancel.confirmation')
    };
    const cancelText = CANCEL_TEXT[mode];

    const onCancel = useCallback(() => {
      store.setUploadValue(null);
      store.setItem(null);
      navigate?.();
    }, [uploadValue, facilityId, fileType, file, navigate]);

    useNavigationEffect(() => {
      if (fileId) {
        store.loadItem(fileId).catch();
      }

      return () => {
        store.setItem(null);
      };
    }, [facilityId, fileId]);

    useNavigationEffect(() => {
      if (file) {
        store.setUploadValue({
          description: file?.description || '',
          file: null,
          contentType: file?.contentType || ''
        });
        setValidate(true);
      }
      return () => {
        store.setUploadValue(null);
      };
    }, [file]);

    if (!ready) {
      return null;
    }

    return (
      <>
        <Cancelable cancelConfirmationText={cancelText}>
          <FilesUploadForm
            file={file}
            fileType={fileType}
            extensions={fileType === 'photo' ? photoExtensions : documentExtensions}
            maxSize={fileType === 'photo' ? photoMaxSize : documentMaxSize}
            validate={validate}
            setValidate={setValidate}
            uploadValue={uploadValue}
            setUploadValue={setUploadValue}
            isLoading={isLoading}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Cancelable>
      </>
    );
  }
);
