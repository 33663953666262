import { action, makeObservable, observable } from 'mobx';
import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';
import { http } from '@geo/api/http-client';
import { EntityStore } from './entity';
import { getApiClient } from '../api/api-client';

export type FileRequestType = 'get' | 'download' | 'upload' | 'patch' | 'remove';

interface NavigationInfo {
  prevId?: number;
  nextId?: number;
  currentIndex?: number;
}

export interface UploadValue {
  description: string;
  file: File | null;
  contentType: string;
}

export type FileType = 'photo' | 'document' | 'codificator';

export class FileStore extends EntityStore<FacilityFile, number, FileRequestType> {
  @observable facilityId?: number = undefined;

  @observable.ref uploadValue: UploadValue | null = null;

  @observable.ref navigationInfo: NavigationInfo = {
    prevId: undefined,
    nextId: undefined,
    currentIndex: undefined
  };

  constructor() {
    super();
    makeObservable(this);
  }

  protected async fetchItem(id: number): Promise<FacilityFile> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }
    return getApiClient().documentController.getDocument(this.facilityId, id);
  }

  @action.bound setFacilityId(facilityId: number) {
    this.facilityId = facilityId;
  }

  @action.bound
  setUploadValue(value: UploadValue | null) {
    this.uploadValue = value;
  }

  @action.bound updateNavigationInfo(info: NavigationInfo) {
    this.navigationInfo = info;
  }

  @action.bound
  upload(facilityId: number, type: FileType = 'document'): Promise<FacilityFile> | null {
    const upload = this.uploadValue;
    const uploadedFile = upload?.file;
    if (upload && uploadedFile) {
      return this.request<FacilityFile>(
        'upload',
        () => {
          const { description } = upload;
          const form = new FormData();
          form.append('file', uploadedFile);

          return http
            .post(`/api/v1/facility/${this.facilityId}/${type}`, form, { params: { description } })
            .then((uploaded) => uploaded.data as FacilityFile);
        },
        (file) => this.setItem(file)
      );
    }
    return null;
  }

  @action.bound
  patch(
    facilityId: number,
    fileId: number,
    fileType: FileType = 'document',
    uploaded?: UploadValue
  ): Promise<FacilityFile> | null {
    const upload = uploaded || this.uploadValue;
    const current = this.item;
    if (upload && current) {
      return this.request(
        'patch',
        () => {
          const uploadedFile = upload?.file;
          const { description } = upload;

          const form = new FormData();
          if (uploadedFile) {
            form.append('file', uploadedFile);
          }
          console.log(current, description, facilityId, fileType);
          return http
            .patch(`/api/v1/facility/${facilityId}/${fileType}/${fileId}`, { description })
            .then(({ data }) => data as FacilityFile);
        },
        (file) => this.setItem(file)
      );
    }
    return null;
  }
}
