import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import {
	CollectionDtoFacilityFile,
	CollectionDtoFacilityFileIO,
} from '../components/schemas/CollectionDtoFacilityFile';
import { FacilityFile, FacilityFileIO } from '../components/schemas/FacilityFile';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType, BinaryFromStringIO, Binary } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, undefined, union, literal, type, string, void as tvoid, unknown } from 'io-ts';

export interface DocumentController<F> {
	readonly listDocuments: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'creator' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => HKT<F, CollectionDtoFacilityFile>;

	readonly createDocument: (
		facilityId: number,
		parameters: { query: { description: string }; body: { file: Binary | undefined } },
	) => HKT<F, FacilityFile>;

	readonly createDocument_2: (
		facilityId: number,
		fileId: number,
		parameters: { query: { description: string | undefined }; body: { file: Binary | undefined } | undefined },
	) => HKT<F, FacilityFile>;

	readonly getDocument: (facilityId: number, documentId: number) => HKT<F, FacilityFile>;

	readonly deleteDocument: (facilityId: number, documentId: number) => HKT<F, void>;

	readonly getDocumentContent: (facilityId: number, documentId: number) => HKT<F, unknown>;
}

export interface DocumentController1<F extends URIS> {
	readonly listDocuments: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'creator' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind<F, CollectionDtoFacilityFile>;

	readonly createDocument: (
		facilityId: number,
		parameters: { query: { description: string }; body: { file: Binary | undefined } },
	) => Kind<F, FacilityFile>;

	readonly createDocument_2: (
		facilityId: number,
		fileId: number,
		parameters: { query: { description: string | undefined }; body: { file: Binary | undefined } | undefined },
	) => Kind<F, FacilityFile>;

	readonly getDocument: (facilityId: number, documentId: number) => Kind<F, FacilityFile>;

	readonly deleteDocument: (facilityId: number, documentId: number) => Kind<F, void>;

	readonly getDocumentContent: (facilityId: number, documentId: number) => Kind<F, unknown>;
}

export interface DocumentController2<F extends URIS2> {
	readonly listDocuments: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'creator' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind2<F, Error, CollectionDtoFacilityFile>;

	readonly createDocument: (
		facilityId: number,
		parameters: { query: { description: string }; body: { file: Binary | undefined } },
	) => Kind2<F, Error, FacilityFile>;

	readonly createDocument_2: (
		facilityId: number,
		fileId: number,
		parameters: { query: { description: string | undefined }; body: { file: Binary | undefined } | undefined },
	) => Kind2<F, Error, FacilityFile>;

	readonly getDocument: (facilityId: number, documentId: number) => Kind2<F, Error, FacilityFile>;

	readonly deleteDocument: (facilityId: number, documentId: number) => Kind2<F, Error, void>;

	readonly getDocumentContent: (facilityId: number, documentId: number) => Kind2<F, Error, unknown>;
}

export function documentController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): DocumentController2<F>;
export function documentController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): DocumentController1<F>;
export function documentController<F>(e: { httpClient: HTTPClient<F> }): DocumentController<F>;
export function documentController<F>(e: { httpClient: HTTPClient<F> }): DocumentController<F> {
	return {
		listDocuments: (facilityId, parameters) => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('name'),
							literal('creator'),
							literal('createdAt'),
							literal('lastUpdatedAt'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(number.encode(facilityId).toString())}/document`,
					controller: 'document',
					operation: 'listDocuments',
					method: 'GET',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoFacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createDocument: (facilityId, parameters) => {
			const body = type({ file: union([BinaryFromStringIO, undefined]) }).encode(parameters.body);
			const query = compact([
				pipe(string.encode(parameters.query['description']), value =>
					fromEither(serializePrimitiveParameter('form', 'description', value)),
				),
			]).join('&');

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'multipart/form-data',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(number.encode(facilityId).toString())}/document`,
					controller: 'document',
					operation: 'createDocument',
					method: 'POST',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,
					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createDocument_2: (facilityId, fileId, parameters) => {
			const body = union([type({ file: union([BinaryFromStringIO, undefined]) }), undefined]).encode(
				parameters.body,
			);
			const query = compact([
				pipe(
					union([string, undefined]).encode(parameters.query['description']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'description', value))),
				),
			]).join('&');

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'multipart/form-data',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/document/${encodeURIComponent(number.encode(fileId).toString())}`,
					controller: 'document',
					operation: 'createDocument_2',
					method: 'PATCH',
					responseType,
					pathParameters: [facilityId, fileId],
					parameters,
					query,
					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		getDocument: (facilityId, documentId) => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/document/${encodeURIComponent(number.encode(documentId).toString())}`,
					controller: 'document',
					operation: 'getDocument',
					method: 'GET',
					responseType,
					pathParameters: [facilityId, documentId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		deleteDocument: (facilityId, documentId) => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/document/${encodeURIComponent(number.encode(documentId).toString())}`,
					controller: 'document',
					operation: 'deleteDocument',
					method: 'DELETE',
					responseType,
					pathParameters: [facilityId, documentId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						tvoid.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		getDocumentContent: (facilityId, documentId) => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/document/${encodeURIComponent(number.encode(documentId).toString())}/content`,
					controller: 'document',
					operation: 'getDocumentContent',
					method: 'GET',
					responseType,
					pathParameters: [facilityId, documentId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						unknown.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
