import * as yup from 'yup';
import { Maybe, AnyObject } from 'yup/lib/types';

declare module 'yup' {
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    fractionalDigits(digits: number, errorMessage?: string): NumberSchema<TType, TContext>;
  }
}

yup.addMethod<yup.NumberSchema>(
  yup.number,
  'fractionalDigits',
  function fractionalDigits(digits: number, errorMessage = `value must have ${digits} digits after decimal or less`) {
    if (digits < 1) {
      throw new TypeError('Fractional digits must be equal to or more than 1');
    }
    const regExp = new RegExp(`^\\d+(\\.\\d{1,${digits}})?$`);
    return this.test('fractional-digits', errorMessage, function fractionalDigitsTest(value?: number) {
      return regExp.test(`${Math.abs(value || 0)}`);
    });
  }
);

export default yup;
