import { string, number, array, undefined, union, type } from 'io-ts';

export type FacilityCreateRequest = {
	name: string;
	cypher: string;
	responsibleUserId: number;
	geologistsUsersIds: Array<number> | undefined;
};
export const FacilityCreateRequestIO = type(
	{ name: string, cypher: string, responsibleUserId: number, geologistsUsersIds: union([array(number), undefined]) },
	'FacilityCreateRequest',
);
