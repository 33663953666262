import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { Borehole, BoreholeIO } from '../components/schemas/Borehole';
import { BoreholeCreateRequest, BoreholeCreateRequestIO } from '../components/schemas/BoreholeCreateRequest';
import { BoreholeUpdateRequest, BoreholeUpdateRequestIO } from '../components/schemas/BoreholeUpdateRequest';
import { CollectionDtoBorehole, CollectionDtoBoreholeIO } from '../components/schemas/CollectionDtoBorehole';
import {
	CollectionDtoBoreholeWithLayers,
	CollectionDtoBoreholeWithLayersIO,
} from '../components/schemas/CollectionDtoBoreholeWithLayers';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, unknown, undefined, union, literal, type } from 'io-ts';

export interface BoreholeController<F> {
	readonly getBorehole: (id: number) => HKT<F, Borehole>;

	readonly updateBorehole: (id: number, parameters: { body: BoreholeUpdateRequest }) => HKT<F, Borehole>;

	readonly deleteBorehole: (id: number) => HKT<F, unknown>;

	readonly createBorehole: (parameters: { body: BoreholeCreateRequest }) => HKT<F, Borehole>;

	readonly unsign: (id: number) => HKT<F, Borehole>;

	readonly sign: (id: number) => HKT<F, Borehole>;

	readonly boreholeList: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'status') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => HKT<F, CollectionDtoBorehole>;

	readonly listOfBoreholesByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'status') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => HKT<F, CollectionDtoBorehole>;

	readonly boreholeListAll: (facilityId: number) => HKT<F, CollectionDtoBoreholeWithLayers>;
}

export interface BoreholeController1<F extends URIS> {
	readonly getBorehole: (id: number) => Kind<F, Borehole>;

	readonly updateBorehole: (id: number, parameters: { body: BoreholeUpdateRequest }) => Kind<F, Borehole>;

	readonly deleteBorehole: (id: number) => Kind<F, unknown>;

	readonly createBorehole: (parameters: { body: BoreholeCreateRequest }) => Kind<F, Borehole>;

	readonly unsign: (id: number) => Kind<F, Borehole>;

	readonly sign: (id: number) => Kind<F, Borehole>;

	readonly boreholeList: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'status') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind<F, CollectionDtoBorehole>;

	readonly listOfBoreholesByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'status') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind<F, CollectionDtoBorehole>;

	readonly boreholeListAll: (facilityId: number) => Kind<F, CollectionDtoBoreholeWithLayers>;
}

export interface BoreholeController2<F extends URIS2> {
	readonly getBorehole: (id: number) => Kind2<F, Error, Borehole>;

	readonly updateBorehole: (id: number, parameters: { body: BoreholeUpdateRequest }) => Kind2<F, Error, Borehole>;

	readonly deleteBorehole: (id: number) => Kind2<F, Error, unknown>;

	readonly createBorehole: (parameters: { body: BoreholeCreateRequest }) => Kind2<F, Error, Borehole>;

	readonly unsign: (id: number) => Kind2<F, Error, Borehole>;

	readonly sign: (id: number) => Kind2<F, Error, Borehole>;

	readonly boreholeList: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'status') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind2<F, Error, CollectionDtoBorehole>;

	readonly listOfBoreholesByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'status') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind2<F, Error, CollectionDtoBorehole>;

	readonly boreholeListAll: (facilityId: number) => Kind2<F, Error, CollectionDtoBoreholeWithLayers>;
}

export function boreholeController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): BoreholeController2<F>;
export function boreholeController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): BoreholeController1<F>;
export function boreholeController<F>(e: { httpClient: HTTPClient<F> }): BoreholeController<F>;
export function boreholeController<F>(e: { httpClient: HTTPClient<F> }): BoreholeController<F> {
	return {
		getBorehole: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'borehole',
					operation: 'getBorehole',
					method: 'GET',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		updateBorehole: (id, parameters) => {
			const body = BoreholeUpdateRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'borehole',
					operation: 'updateBorehole',
					method: 'PUT',
					responseType,
					pathParameters: [id],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		deleteBorehole: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'borehole',
					operation: 'deleteBorehole',
					method: 'DELETE',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						unknown.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createBorehole: parameters => {
			const body = BoreholeCreateRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/create`,
					controller: 'borehole',
					operation: 'createBorehole',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		unsign: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/${encodeURIComponent(number.encode(id).toString())}/unsign`,
					controller: 'borehole',
					operation: 'unsign',
					method: 'PATCH',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		sign: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/${encodeURIComponent(number.encode(id).toString())}/sign`,
					controller: 'borehole',
					operation: 'sign',
					method: 'PATCH',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		boreholeList: parameters => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('createdAt'),
							literal('lastUpdatedAt'),
							literal('name'),
							literal('status'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole`,
					controller: 'borehole',
					operation: 'boreholeList',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoBoreholeIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listOfBoreholesByFacilityId: (facilityId, parameters) => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('createdAt'),
							literal('lastUpdatedAt'),
							literal('name'),
							literal('status'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'borehole',
					operation: 'listOfBoreholesByFacilityId',
					method: 'GET',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoBoreholeIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		boreholeListAll: facilityId => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/facility/${encodeURIComponent(number.encode(facilityId).toString())}/all`,
					controller: 'borehole',
					operation: 'boreholeListAll',
					method: 'GET',
					responseType,
					pathParameters: [facilityId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoBoreholeWithLayersIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
