import React, { ReactElement } from 'react';
import { IconButton, Square } from 'native-base';
import Icon from './icon';
import { Action } from './table/table.model';

type ActionViewProps<T> = Action<T> & {
  item: T;
};

type ActionFunction = <T>(props: ActionViewProps<T>) => ReactElement | null;

export const ActionView: ActionFunction = (props) => {
  const {
    item,
    iconName = 'delete',
    iconVariant = 'outline',
    iconBorderColor,
    iconBackgroundColor,
    isVisible,
    actionCallback,
    customRenderer
  } = props;

  if (isVisible && !isVisible(item)) {
    return null;
  }

  const handlePress = () => {
    actionCallback?.(item);
  };

  if (customRenderer) {
    return customRenderer(item);
  }

  return (
    <IconButton
      variant={iconVariant}
      px={0}
      py={0}
      _hover={{
        borderColor: iconBorderColor,
        backgroundColor: iconBackgroundColor
      }}
      _pressed={{
        borderColor: iconBorderColor,
        backgroundColor: iconBackgroundColor
      }}
      icon={
        <Square size={10}>
          <Icon name={iconName} />
        </Square>
      }
      onPress={handlePress}
    />
  );
};
