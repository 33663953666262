import React, { PropsWithChildren } from 'react';
import { Box } from 'native-base';
import { Header } from '../../header';
import { useComponent } from '../../../hooks/useComponent';

export const MainLayout = ({ children }: PropsWithChildren<any>) => {
  const { AppInfoComponent } = useComponent();
  return (
    <Box backgroundColor="#f7f7fa" width="100%" flex={1}>
      <Header />
      {children}
      <AppInfoComponent />
    </Box>
  );
};
