import React, { FC, useCallback, useEffect, useState } from 'react';
import { HStack } from 'native-base';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';
import { ActionView } from '../../../components/action';
import { PageProps } from '../../types';
import { screens } from '../../config';
import { PhotoCard } from '../../../features/photo/photo-card.component';
import { QuickViewPage } from '../../../components/quick-view-page';
import { getApiClient } from '../../../api/api-client';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { PermissionGuard } from '../../../utils/permissions/guard';
import { ConfirmationPopup } from '../../../components/confirmation-popup';
import { facilityStore, photosStore, photoStore } from '../../../stores';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';

type PhotoCardPageProps = PageProps<'PhotoCard'>;

export const PhotoCardPage: FC<PhotoCardPageProps> = observer(({ route, navigation }) => {
  const { params } = route;
  const facilityId = params?.facilityId;
  const photoId = params?.photoId;
  const { t } = useTranslation();

  const { item, navigationInfo, setItem, download } = photoStore;
  const [isOpen, setOpen] = useState(false);

  useNavigationEffect(() => {
    if (!facilityStore.item || facilityStore.item.id !== facilityId) {
      facilityStore.loadItem(facilityId).catch((e) => console.error(e));
    }
    photoStore.setFacilityId(facilityId);
    photoStore.loadItem(photoId).catch();
    return () => {
      setItem(null);
    };
  }, [photoId, facilityId]);

  const onClose = useCallback(() => {
    navigation.navigate(screens.Photos, { facilityId });
  }, [navigation, facilityId]);

  const onDelete = useCallback(async () => {
    await getApiClient().photoController.deletePhoto(facilityId, photoId);
    navigation.navigate(screens.Photos, { facilityId });
  }, [photoId, facilityId]);

  const onDownloadPress = useCallback(
    (item: FacilityFile) => {
      download(item).catch();
    },
    [photoId]
  );

  const onDeleteConfirm = async () => {
    await onDelete();
    setOpen(false);
  };

  const onDeletePress = () => {
    setOpen(true);
  };

  const handleNavigate = useCallback((id: number) => {
    navigation.navigate(screens.PhotoCard, {
      facilityId,
      photoId: id
    });
  }, []);

  if (!facilityId || !photoId) {
    return null;
  }

  const deletable = facilityId === facilityStore.item?.id && !facilityStore.isArchived && !facilityStore.isDeleted;

  const appendHeader = item ? (
    <PermissionGuard action={ACTION.DELETE} subject={SUBJECT.PHOTO}>
      <HStack space={3}>
        {Platform.OS === 'web' && (
          <ActionView id="download" item={item} actionCallback={onDownloadPress} iconName="download" />
        )}
        {deletable && <ActionView id="delete" item={item} actionCallback={onDeletePress} iconName="delete" />}
      </HStack>
    </PermissionGuard>
  ) : (
    <HStack />
  );

  return (
    <QuickViewPage
      navigationInfo={navigationInfo}
      id={photoId}
      total={photosStore.total}
      onNavigate={handleNavigate}
      appendHeader={appendHeader}
      onClose={onClose}
    >
      <PhotoCard contentType={item?.contentType} facilityId={facilityId} photoId={photoId} title={item?.description} />
      <ConfirmationPopup
        isOpen={isOpen}
        handleConfirmButton={onDeleteConfirm}
        handleCloseButton={() => {
          setOpen(false);
        }}
      >
        {t('photo.confirm-delete')}
      </ConfirmationPopup>
    </QuickViewPage>
  );
});

PhotoCardPage.displayName = PhotoCardPage.name;
