import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'native-base';
import { subject } from '@casl/ability';
import { useTranslation } from 'react-i18next';

import { DeleteButton } from '../../../components/delete-button';
import { Loadable } from '../../../components/loadable';
import { Actions, Sort } from '../../../components/table/table.model';
import { Facility, FacilitySortColumn } from '../../../models/Facility';
import { screens } from '../../../navigation/config';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { facilityStore, facilityDeletedTableStore } from '../../../stores';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { usePermission } from '../../../utils/permissions/context';
import { PermissionGuard } from '../../../utils/permissions/guard';

import { FacilityDeletedTable } from './facility-deleted-table';

export const FacilityDeletedTableContainer: FC = observer(() => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const permission = usePermission();
  const { sortTableProp, isLoading, selectedId, page, items, perPage, total, updateSelection } =
    facilityDeletedTableStore;

  const ready = useComponentReady(() => {
    facilityDeletedTableStore.loadItems().catch((e) => console.error(e));
  }, []);

  if (!ready) {
    return null;
  }

  const onChangePage = (p: number) => {
    facilityDeletedTableStore.updatePage(p - 1);
  };

  const onChangeSort = (sort: Sort<FacilitySortColumn>) => {
    facilityDeletedTableStore.updateSort(sort?.column, sort?.sortOrder);
  };

  const handleApproveDeleteButton = (id: number) => {
    facilityStore.remove(id).catch((e) => console.error(e));
  };

  const handleCancelDeleteButton = (id: number) => {
    facilityStore.setMarkToDelete(id, false).catch((e) => console.error(e));
  };

  const actions: Actions<Facility> = [
    {
      id: 'delete',
      customRenderer: (item) => {
        return (
          <PermissionGuard action={ACTION.DELETE} subject={SUBJECT.FACILITY}>
            <DeleteButton
              item={item}
              onMark={() => ''}
              subject={SUBJECT.FACILITY}
              onDelete={handleApproveDeleteButton}
              markToDeleteConfirmationText=""
              buttonText={t('confirmation.buttons.confirm')}
              deleteConfirmationText={t('deleteConfirmation.text')}
            />
          </PermissionGuard>
        );
      }
    },
    {
      id: 'cancel',
      customRenderer: (item) => {
        return (
          <PermissionGuard action={ACTION.CANCEL_MARK_TO_DELETE} subject={SUBJECT.FACILITY}>
            <Button variant="outline" onPress={() => handleCancelDeleteButton(item.id)}>
              Отменить
            </Button>
          </PermissionGuard>
        );
      }
    },
    {
      id: 'edit',
      iconName: 'edit',
      actionCallback: (item) => {
        navigation.navigate(screens.FacilityUpdate, { facilityId: item.id });
      },
      isVisible: (item) => {
        const { status, markToDelete = true } = item || {};
        return permission.can(ACTION.EDIT, subject(SUBJECT.FACILITY, { status, markToDelete }));
      }
    }
  ];

  return (
    <Loadable isLoading={isLoading || facilityStore.anyLoading}>
      <FacilityDeletedTable
        selectedId={selectedId}
        sort={sortTableProp}
        page={page}
        total={total}
        perPage={perPage}
        onRowClick={updateSelection}
        actions={actions}
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        items={items}
        actionsWidth={356}
      />
    </Loadable>
  );
});
