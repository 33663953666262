import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { CollectionDtoFacility, CollectionDtoFacilityIO } from '../components/schemas/CollectionDtoFacility';
import { Facility, FacilityIO } from '../components/schemas/Facility';
import { FacilityCreateRequest, FacilityCreateRequestIO } from '../components/schemas/FacilityCreateRequest';
import { FacilityStatusRequest, FacilityStatusRequestIO } from '../components/schemas/FacilityStatusRequest';
import { FacilityUpdateRequest, FacilityUpdateRequestIO } from '../components/schemas/FacilityUpdateRequest';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, unknown, boolean, undefined, union, literal, type, array } from 'io-ts';

export interface FacilityController<F> {
	readonly getFacility: (id: number) => HKT<F, Facility>;

	readonly updateFacility: (id: number, parameters: { body: FacilityUpdateRequest }) => HKT<F, Facility>;

	readonly deleteFacility: (id: number) => HKT<F, unknown>;

	readonly setStatus: (id: number, parameters: { body: FacilityStatusRequest }) => HKT<F, Facility>;

	readonly createFacility: (parameters: { body: FacilityCreateRequest }) => HKT<F, Facility>;

	readonly setMarkToDelete: (id: number, markToDelete: boolean) => HKT<F, Facility>;

	readonly listOfFacilities: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => HKT<F, CollectionDtoFacility>;

	readonly status: () => HKT<F, Array<'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED' | 'ARCHIVED'>>;

	readonly listMarkToDelete: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => HKT<F, CollectionDtoFacility>;

	readonly listArchived: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => HKT<F, CollectionDtoFacility>;
}

export interface FacilityController1<F extends URIS> {
	readonly getFacility: (id: number) => Kind<F, Facility>;

	readonly updateFacility: (id: number, parameters: { body: FacilityUpdateRequest }) => Kind<F, Facility>;

	readonly deleteFacility: (id: number) => Kind<F, unknown>;

	readonly setStatus: (id: number, parameters: { body: FacilityStatusRequest }) => Kind<F, Facility>;

	readonly createFacility: (parameters: { body: FacilityCreateRequest }) => Kind<F, Facility>;

	readonly setMarkToDelete: (id: number, markToDelete: boolean) => Kind<F, Facility>;

	readonly listOfFacilities: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind<F, CollectionDtoFacility>;

	readonly status: () => Kind<F, Array<'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED' | 'ARCHIVED'>>;

	readonly listMarkToDelete: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind<F, CollectionDtoFacility>;

	readonly listArchived: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind<F, CollectionDtoFacility>;
}

export interface FacilityController2<F extends URIS2> {
	readonly getFacility: (id: number) => Kind2<F, Error, Facility>;

	readonly updateFacility: (id: number, parameters: { body: FacilityUpdateRequest }) => Kind2<F, Error, Facility>;

	readonly deleteFacility: (id: number) => Kind2<F, Error, unknown>;

	readonly setStatus: (id: number, parameters: { body: FacilityStatusRequest }) => Kind2<F, Error, Facility>;

	readonly createFacility: (parameters: { body: FacilityCreateRequest }) => Kind2<F, Error, Facility>;

	readonly setMarkToDelete: (id: number, markToDelete: boolean) => Kind2<F, Error, Facility>;

	readonly listOfFacilities: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind2<F, Error, CollectionDtoFacility>;

	readonly status: () => Kind2<F, Error, Array<'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED' | 'ARCHIVED'>>;

	readonly listMarkToDelete: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind2<F, Error, CollectionDtoFacility>;

	readonly listArchived: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'name' | 'cypher' | 'status' | 'lastUpdatedAt' | 'responsible') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind2<F, Error, CollectionDtoFacility>;
}

export function facilityController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): FacilityController2<F>;
export function facilityController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): FacilityController1<F>;
export function facilityController<F>(e: { httpClient: HTTPClient<F> }): FacilityController<F>;
export function facilityController<F>(e: { httpClient: HTTPClient<F> }): FacilityController<F> {
	return {
		getFacility: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'facility',
					operation: 'getFacility',
					method: 'GET',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		updateFacility: (id, parameters) => {
			const body = FacilityUpdateRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'facility',
					operation: 'updateFacility',
					method: 'PUT',
					responseType,
					pathParameters: [id],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		deleteFacility: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'facility',
					operation: 'deleteFacility',
					method: 'DELETE',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						unknown.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		setStatus: (id, parameters) => {
			const body = FacilityStatusRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'facility',
					operation: 'setStatus',
					method: 'PATCH',
					responseType,
					pathParameters: [id],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createFacility: parameters => {
			const body = FacilityCreateRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/create`,
					controller: 'facility',
					operation: 'createFacility',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		setMarkToDelete: (id, markToDelete) => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/mark/${encodeURIComponent(
						number.encode(id).toString(),
					)}/${encodeURIComponent(boolean.encode(markToDelete).toString())}`,
					controller: 'facility',
					operation: 'setMarkToDelete',
					method: 'PATCH',
					responseType,
					pathParameters: [id, markToDelete],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listOfFacilities: parameters => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('name'),
							literal('cypher'),
							literal('status'),
							literal('lastUpdatedAt'),
							literal('responsible'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility`,
					controller: 'facility',
					operation: 'listOfFacilities',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoFacilityIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		status: () => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/status`,
					controller: 'facility',
					operation: 'status',
					method: 'GET',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						array(
							union([
								literal('NEW'),
								literal('IN_PROGRESS'),
								literal('PROBLEM'),
								literal('CLOSED'),
								literal('ARCHIVED'),
							]),
						).decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listMarkToDelete: parameters => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('name'),
							literal('cypher'),
							literal('status'),
							literal('lastUpdatedAt'),
							literal('responsible'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/marked_to_delete`,
					controller: 'facility',
					operation: 'listMarkToDelete',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoFacilityIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listArchived: parameters => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('name'),
							literal('cypher'),
							literal('status'),
							literal('lastUpdatedAt'),
							literal('responsible'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/archived`,
					controller: 'facility',
					operation: 'listArchived',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoFacilityIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
