import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { FacilityArchivedTableContainer } from '../../../features/facility/facility-archived/facility-archived-table.container';
import { PageProps } from '../../types';

type FacilityArchivedListPageProps = PageProps<'FacilitiesArchived'>;

export const FacilityArchivedListPage: FC<FacilityArchivedListPageProps> = () => {
  return (
    <Page>
      <FacilityArchivedTableContainer />
    </Page>
  );
};
