import React, { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Pressable, Text } from 'native-base';

import Icon from '../../components/icon';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { Map } from '../../models/Map';
import { useGridItemSize } from '../../hooks/useGridItemSize';
import { GridItems } from '../../components/grid-items';

const MAP_ROWS = 3;
const MAP_COLUMNS = 7;
const MAP_ROW_GAP = 10;
const MAP_COLUMN_GAP = 10;
const MAP_TITLE_HEIGHT = 50;

type MapListComponentProps = {
  maps: Map[];
  facilityId: number;
};

export const MapListComponent: FC<MapListComponentProps> = ({ maps, facilityId }) => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();

  const { width, height, onLayout } = useGridItemSize({
    rows: MAP_ROWS,
    columns: MAP_COLUMNS,
    rowGap: MAP_ROW_GAP,
    columnGap: MAP_COLUMN_GAP,
    verticalExtra: MAP_TITLE_HEIGHT
  });

  const openGeneralMap = useCallback(() => {
    navigation.navigate('MapGeneralView', { facilityId });
  }, [facilityId]);

  const openMap = useCallback(
    (map: Map) => {
      navigation.navigate('MapView', { facilityId, mapId: map.id });
    },
    [facilityId]
  );

  const renderedMaps = useMemo(() => {
    return [
      { id: 0 } as Map,
      ...Array.from({ length: MAP_COLUMNS - 1 }).map(() => {
        return { id: -1 } as Map;
      }),
      ...maps.slice(0, (MAP_ROWS - 1) * MAP_COLUMNS)
    ];
  }, [maps]);

  let content;
  if (width > 0 && height > 0) {
    content = (
      <GridItems
        items={renderedMaps.map((map) => {
          const { id, name } = map;
          const key = `${id}-${name}`;
          let onPress: (() => void) | null = null;
          let itemContent = <></>;
          if (id === 0) {
            onPress = openGeneralMap;
            itemContent = (
              <>
                <View flex={1}>
                  <Icon name="allBoreholesMapIcon" stretch />
                </View>
                <View justifyContent="center" alignItems="center" maxWidth={width} height={`${MAP_TITLE_HEIGHT}px`}>
                  <Text fontSize={14} lineHeight={18} noOfLines={2} isTruncated textAlign="center">
                    {t('map.common')}
                  </Text>
                </View>
              </>
            );
          } else if (id > 0) {
            onPress = () => openMap(map);
            itemContent = (
              <>
                <View flex={1}>
                  <Icon name="boreholeMapIcon" stretch />
                </View>
                <View justifyContent="center" alignItems="center" maxWidth={width} height={`${MAP_TITLE_HEIGHT}px`}>
                  <Text fontSize={14} lineHeight={18} noOfLines={2} isTruncated textAlign="center">
                    {name}
                  </Text>
                </View>
              </>
            );
          }

          const wrappedItemContent = (
            <View width={`${width}px`} height={`${height + MAP_TITLE_HEIGHT}px`}>
              {itemContent}
            </View>
          );

          if (onPress) {
            return (
              <Pressable key={key} onPress={onPress}>
                {wrappedItemContent}
              </Pressable>
            );
          }

          return <Fragment key={key}>{wrappedItemContent}</Fragment>;
        })}
        columns={MAP_COLUMNS}
        rowGap={MAP_ROW_GAP}
        columnGap={MAP_COLUMN_GAP}
      />
    );
  }

  return (
    <View flex={1} onLayout={onLayout}>
      <View position="absolute" top={0} bottom={0} left={0} right={0} overflow="hidden">
        {content}
      </View>
    </View>
  );
  //   <>
  //     {content.length > 0 && (
  //       <>
  //         <HStack>
  //           <Button variant="ghost" onPress={openGeneralMap}>
  //             <Center w="120">
  //               <Icon name="allBoreholesMapIcon" />
  //               <Text textAlign="center">{t('map.common')}</Text>
  //             </Center>
  //           </Button>
  //         </HStack>
  //       </>
  //     )}
  //   </>
  // );
};
