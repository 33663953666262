import React, { FC, useState, useCallback, useLayoutEffect } from 'react';
import { Box, Flex, Center, HStack, Text } from 'native-base';
import { PageView } from './page-view';
import { BreakView } from './break-view';

type PaginationProps = {
  perPage: number;
  current: number;
  total: number;
  pageRangeDisplayed?: number;
  marginPagesDisplayed?: number;
  onChange: (page: number) => void;
};

export const Pagination: FC<PaginationProps> = ({
  current,
  total,
  pageRangeDisplayed = 3,
  marginPagesDisplayed = 1,
  perPage,
  onChange
}) => {
  const pageCount = Math.ceil(total / perPage);
  const selected = current + 1;
  const handleNextPage = useCallback(() => onChange(selected - 1), [selected, onChange]);
  const handlePreviousPage = useCallback(() => onChange(selected + 1), [selected, onChange]);

  const items = [];
  if (pageCount <= pageRangeDisplayed) {
    for (let index = 1; index <= pageCount; index++) {
      items.push(<PageView key={index} current={selected} page={index} onPress={() => onChange(index)} />);
    }
  } else {
    let leftSide = pageRangeDisplayed / 2;
    let rightSide = pageRangeDisplayed - leftSide;

    if (selected > pageCount - pageRangeDisplayed / 2) {
      rightSide = pageCount - selected;
      leftSide = pageRangeDisplayed - rightSide;
    } else if (selected < pageRangeDisplayed / 2) {
      leftSide = selected;
      rightSide = pageRangeDisplayed - leftSide;
    }
    let breakView;
    let createPageView = (index: number) => (
      <PageView key={index} current={selected} page={index} onPress={() => onChange(index)} />
    );
    for (let index = 1; index <= pageCount; index++) {
      const page = index + 1;
      // Left side
      if (page <= marginPagesDisplayed) {
        items.push(createPageView(index));
        continue;
      }
      // Right side
      if (page > pageCount - marginPagesDisplayed) {
        items.push(createPageView(index));
        continue;
      }

      // Center
      if (index >= selected - leftSide && index <= selected + rightSide) {
        items.push(createPageView(index));
        continue;
      }

      if (items[items.length - 1] !== breakView) {
        breakView = <BreakView key={'breakView'} current={selected} page={index} onPress={() => onChange(index)} />;
        items.push(breakView);
      }
    }
  }

  const from = perPage * (selected - 1);
  let to = perPage * selected;
  if (to > total) {
    to = total;
  }

  return (
    <HStack mt={5} mb={5} alignItems={'center'} width={'100%'}>
      <Flex direction={'row'} flex={1}>
        <Text fontSize={14} fontWeight={500}>
          {to !== 0 ? from + 1 : from}-{to}
        </Text>
        <Text fontSize={14} opacity={0.4} ml={1} mr={1} color={'dark.dark-0'}>
          из
        </Text>
        <Text fontSize={14} fontWeight={500}>
          {total}
        </Text>
      </Flex>
      <HStack flex={1} space={1} justifyContent={'center'} alignItems="center">
        {selected > 1 && <PageView current={selected} icon={'btnSmPagLeft'} onPress={handleNextPage} />}
        {items}
        {selected < pageCount && <PageView current={selected} icon={'btnSmPagRight'} onPress={handlePreviousPage} />}
      </HStack>
      <HStack flex={1} />
    </HStack>
  );
};
