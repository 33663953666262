import React, { FC, memo } from 'react';
import { HStack } from 'native-base';
import Icon, { IconName } from '../../../components/icon';

export interface StatusActionsProps {
  iconName?: IconName;
  title?: string;
}

export const StatusActions: FC<StatusActionsProps> = memo(({ iconName, title, children }) => (
  <HStack
    py={3}
    px={6}
    mb={5}
    mt={2}
    justifyContent={'space-between'}
    alignItems={'center'}
    borderWidth={2}
    borderRadius={16}
    borderColor={'dark.dark-2'}
  >
    <HStack space={6} flex={1} alignItems="center">
      {iconName && <Icon name={iconName} />}
      {title}
    </HStack>
    {children}
  </HStack>
));
