import React, { memo } from 'react';
import { FormControl, ITextProps, Text } from 'native-base';

export interface LabeledFormFieldProps extends ITextProps {
  label: React.ReactNode;
  width?: number | string;
  labelWidth?: string | number;
  children: React.ReactNode | React.ReactNode[];
}

export const LabeledFormField = memo<LabeledFormFieldProps>(({ label, width, labelWidth, children, ...textProps }) => (
  <FormControl width={width} height={10}>
    <FormControl.Label width={labelWidth} marginY={0}>
      <Text color="dark.dark-2" opacity={0.56} fontWeight={500} fontSize={14} {...textProps}>
        {label}
      </Text>
    </FormControl.Label>
    {children}
  </FormControl>
));
