import React, { PropsWithChildren } from 'react';
import logo from './logo.png';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.css';

export const PrintTemplate = React.forwardRef<HTMLDivElement, PropsWithChildren<unknown>>((props, ref) => {
  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.header}>
        <img src={logo} alt="Логотип" className={styles.logo} />
        <div className={styles.address}>
          г. Москва,Электрозаводская д.29
          <br />
          тел. +7 (495) 763-32-53, тел./факс +7 (495) 963-61-02
          <br />
          geosphere@mail.ru
        </div>
      </div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
});
