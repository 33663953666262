import { ShiftUpdateWorkerRequest } from '@geo/api/generated/swagger.json/components/schemas/ShiftUpdateWorkerRequest';
import { action, computed, makeObservable, observable } from 'mobx';
import { ShiftOpenRequest } from '@geo/api/generated/swagger.json/components/schemas/ShiftOpenRequest';
import { ShiftCloseRequest } from '@geo/api/generated/swagger.json/components/schemas/ShiftCloseRequest';
import { OptionalResponseShift } from '@geo/api/generated/swagger.json/components/schemas/OptionalResponseShift';
import { appStore, boreholeStore } from './index';
import { getApiClient } from '../api/api-client';
import { EntityStore } from './entity';
import { GlobalShift, Shift } from '../models/Shift';

export type ShiftRequestType = 'open' | 'close' | 'start' | 'updateShiftWorker';

const isShift = (response: OptionalResponseShift, value: undefined | Shift): value is Shift => {
  const { present = false } = response;
  return present;
};

const TAG = '[Mobx] [ShiftStore]';

export class ShiftStore extends EntityStore<Shift, number, ShiftRequestType> {
  @observable globalShift: GlobalShift | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  protected async fetchItem(boreholeId?: number): Promise<Shift> {
    const response = await getApiClient().shiftController.findActiveShift({
      query: {
        boreholeId
      }
    });

    if (isShift(response, response.value)) {
      return response.value;
    }

    const value = null as unknown;

    return value as Shift;
  }

  @action async updateItem(boreholeId?: number): Promise<Shift> {
    return this.loadItem(boreholeId);
  }

  @action create(params: ShiftOpenRequest): Promise<Shift> {
    // eslint-disable-next-line no-console
    console.log(TAG, 'create shift', params);
    return this.request(
      'open',
      () =>
        getApiClient().shiftController.openShift({
          body: params
        }),
      (shift) => {
        this.setItem(shift);
        this.setGlobalShift(shift);
      }
    );
  }

  @action updateShiftWorker(id: number, params: ShiftUpdateWorkerRequest): Promise<Shift> {
    return this.request('updateShiftWorker', () =>
      getApiClient().shiftController.updateShiftWorker(id, {
        body: params
      })
    );
  }

  @action close(params: ShiftCloseRequest): Promise<Shift> {
    return this.request(
      'close',
      () => getApiClient().shiftController.closeShift({ body: params }),
      () => {
        this.setItem(null);
        this.globalShift = null;
      }
    );
  }

  @computed
  get isStarted(): boolean {
    const isStarted = this.globalShift !== null;
    // eslint-disable-next-line no-console
    console.log(TAG, 'check is global started', this.globalShift, isStarted);
    return isStarted;
  }

  @computed
  get isCurrentStarted(): boolean {
    let isCurrentStarted = false;
    if (this.item) {
      const boreholeIdEqual = boreholeStore.item?.id === this.item.boreholeId;
      const geologistEqual = appStore.currentUser?.id === this.item.geologist.id;
      isCurrentStarted = boreholeIdEqual && geologistEqual;
    }
    // eslint-disable-next-line no-console
    console.log(TAG, 'check is current started', isCurrentStarted);
    return isCurrentStarted;
  }

  @action async setGlobalShift(shift?: Shift): Promise<void> {
    const globalShift = shift || (await this.fetchItem());
    // eslint-disable-next-line no-console
    console.log(TAG, 'set global shift', globalShift);
    if (globalShift == null) {
      this.globalShift = null
      return
    }
    let borehole = this.globalShift?.borehole;
    if (!borehole || globalShift.boreholeId !== this.globalShift?.boreholeId) {
      borehole = await getApiClient().boreholeController.getBorehole(globalShift.boreholeId);
    }

    this.globalShift = {
      ...globalShift,
      borehole,
      facilityId: borehole?.facility?.id
    };
  }
}
