import { ColumnDefinition } from '../../../components/table/table.model';
import { i18n } from '../../../i18n/i18next';
import { Borehole, BoreholeSortColumn } from '../../../models/Borehole';
import { centimetersToMeters, stringifyFractional } from '../../../utils/units';

export const columns: ColumnDefinition<Borehole, BoreholeSortColumn>[] = [
  {
    key: 'name',
    header: i18n.t('borehole.table.name'),
    sortBy: 'name'
  },
  {
    key: 'depthTarget',
    header: i18n.t('borehole.table.depthTarget'),
    width: 100,
    transformValue: (value: number) => stringifyFractional(centimetersToMeters(value), 2)
  },
  {
    key: 'depthActual',
    header: i18n.t('borehole.table.depthActual'),
    width: 100,
    transformValue: (value: number) => stringifyFractional(centimetersToMeters(value), 2)
  },
  {
    key: 'coordinate',
    header: i18n.t('borehole.table.coordinate'),
    width: 200
  },
  {
    key: 'status',
    header: i18n.t('borehole.table.status'),
    width: 110,
    sortBy: 'status'
  },
  {
    key: 'lastUpdatedAt',
    header: i18n.t('borehole.table.lastUpdatedAt'),
    width: 70,
    sortBy: 'lastUpdatedAt'
  }
];
