import yup from '../../../utils/yup';
import { FacilityStatus } from '../../../models/Facility';

export interface FacilityFormData {
  name: string;
  cypher: string;
  creationId?: number;
  responsibleUserId: number;
  status: FacilityStatus;
  geologistsUsersIds?: number[];
}

export const schema = yup.object().shape({
  name: yup.string().max(200).trim().required(),
  cypher: yup.string().max(20).trim().default('')
});
