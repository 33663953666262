import React, { FC, ReactElement, useMemo } from 'react';
import { HStack, Text } from 'native-base';
import { observer } from 'mobx-react';
import { boreholeStore } from '../../../stores';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { Page } from '../../../components/page';
import { Tabs } from '../../../components/tabs';
import { createTabs } from './borehole.tabs';
import { Borehole } from '../../../models/Borehole';

type BoreholeLoaderProps = {
  boreholeId: number;
  facilityId: number;
  headerBuilder?: (borehole: Borehole) => ReactElement;
};

export const BoreholeLoader: FC<BoreholeLoaderProps> = observer((props) => {
  const { boreholeId, facilityId, children, headerBuilder } = props;
  const { item: borehole } = boreholeStore;

  const ready = useComponentReady(() => {
    boreholeStore.loadItem(boreholeId).catch((e) => console.error(e));
  }, [boreholeId]);

  const status = borehole?.status;

  const isClosed = useMemo(() => {
    return status ? status === 'CLOSED' : true;
  }, [status]);

  const tabs = useMemo(() => createTabs(boreholeId, facilityId, isClosed), [borehole, facilityId, isClosed]);

  if (!ready) {
    return null;
  }

  return (
    <Page
      header={
        <HStack justifyContent="space-between" alignItems="center">
          <Tabs tabs={tabs} />
          {borehole && headerBuilder?.(borehole)}
        </HStack>
      }
    >
      {borehole && children}
    </Page>
  );
});

BoreholeLoader.displayName = BoreholeLoader.name;
