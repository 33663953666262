import React from 'react';
import { Box, Image as NativeBaseImage, IImageProps } from 'native-base';

import { useFacilityImage } from '../hooks/useFacilityImage';

import { Loadable } from './loadable';

interface FacilityImageProps extends Omit<IImageProps, 'source' | 'fallbackSource' | 'defaultSource' | 'alt'> {
  facilityId: number;
  isThumbnail?: boolean;
  contentType: string;
  photoId: number;
  width: string | number;
  height: string | number;
  alt?: string;
}

export const FacilityImage: React.FC<FacilityImageProps> = ({
  facilityId,
  isThumbnail,
  photoId,
  width,
  height,
  contentType,
  alt = 'alt title',
  ...nativeBaseProps
}) => {
  const { source, isLoading } = useFacilityImage(facilityId, photoId, contentType, isThumbnail);
  return (
    <Box height={height} width={width}>
      <Loadable isLoading={isLoading}>
        <NativeBaseImage {...nativeBaseProps} source={{ uri: source }} width={width} height={height} alt={alt} />
      </Loadable>
    </Box>
  );
};
