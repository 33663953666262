import React, { FC } from 'react';
import { Box, Button, FlatList } from 'native-base';
import { useTranslation } from 'react-i18next';

import { BoreholeLayerSampleItem } from '../../../models/BoreholeLayerSample';
import { Form } from '../../../utils/form';
import Icon from '../../icon';
import { SampleListEditableItem } from './sample-list-editable-item';
import { SampleListItem } from './sample-list-item';

type SampleListProps<T extends string = 'samples'> = {
  name: T;
  samples?: Array<BoreholeLayerSampleItem>;
  form?: Form<{ [key in T]: Array<BoreholeLayerSampleItem> }>;
  onAdd?: () => void;
  onRemove?: (index: number) => void;
};

export const SampleList: FC<SampleListProps> = (props) => {
  const { name, samples = [], form, onAdd, onRemove } = props;
  const { control, state } = form || {};
  const error = state && state.errors[name];
  const { t } = useTranslation();
  return (
    <>
      <FlatList
        data={samples}
        keyExtractor={(item: BoreholeLayerSampleItem, index: number) => `${item.type}-${index}`}
        renderItem={(item) => {
          const { index } = item;
          return (
            <Box justifyContent="center" height={10}>
              {control && onRemove ? (
                <SampleListEditableItem
                  {...item}
                  name={name}
                  control={control}
                  index={index}
                  value={samples[index]}
                  onRemove={onRemove}
                />
              ) : (
                <SampleListItem {...item} />
              )}
            </Box>
          );
        }}
      />
      {!!control && onAdd && (
        <Button
          size="sm"
          width={295}
          variant="outline"
          borderColor={error ? 'error' : undefined}
          mt={samples.length > 0 ? 2 : 0}
          startIcon={<Icon name="addplus" fill="#5B8DEF" />}
          onPress={onAdd}
          fontSize="lg"
          _text={{ color: 'black' }}
        >
          {t('borehole-layer-sample.add')}
        </Button>
      )}
    </>
  );
};

SampleList.displayName = SampleList.name;
