import { number, undefined, union, string, type } from 'io-ts';

export type FacilityFile = {
	id: number;
	createdAt: number;
	lastUpdatedAt: number | undefined;
	filename: string;
	contentType: string;
	contentUrl: string;
	previewContentUrl: string | undefined;
	description: string | undefined;
	creatorId: number;
	creatorName: string;
	facilityId: number;
};
export const FacilityFileIO = type(
	{
		id: number,
		createdAt: number,
		lastUpdatedAt: union([number, undefined]),
		filename: string,
		contentType: string,
		contentUrl: string,
		previewContentUrl: union([string, undefined]),
		description: union([string, undefined]),
		creatorId: number,
		creatorName: string,
		facilityId: number,
	},
	'FacilityFile',
);
