type LogFunctions = {
  logInfo: (...data: any[]) => void;
  logError: (...data: any[]) => void;
};

export function createLogFunctions(prefix: string): LogFunctions {
  return {
    logInfo: (item) => {
      console.log(prefix, item);
    },
    logError: (item) => {
      console.error(prefix, item instanceof Error ? item.toString() : item);
    }
  }
}

type AllSettledFulfilledItem = { state: 'fulfilled', value: any };
type AllSettledRejectedItem = { state: 'rejected', reason: any };
type AllSettledItem = AllSettledFulfilledItem | AllSettledRejectedItem;

export function allSettled(promises: Promise<any>[]): Promise<AllSettledItem[]> {
  return Promise.all(promises.map((promise) => promise
    .then((value: any): AllSettledFulfilledItem => ({ state: 'fulfilled', value }))
    .catch((reason: any): AllSettledRejectedItem => ({ state: 'rejected', reason }))
  ));
}

