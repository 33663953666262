import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { record } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { v4 as uuid } from 'uuid';

import { HTTPClient1, Request } from './generated/swagger.json/client/client';
import { controllers } from './generated/swagger.json/paths/paths';
import { URI, monadPromise } from './monad';
import { createLogFunctions } from './utils';

export const http = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  withCredentials: true
});

export const httpClient = (baseURL: string): HTTPClient1<URI> => {
  const request = (req: Request) => {
    const { body, headers, method, query, url, controller, operation, parameters } = req;
    const { logInfo, logError } = createLogFunctions(`[ HTTP :: ${uuid().slice(0, 6)} ]`);
    logInfo(`Request: ${url} (${controller} / ${operation})`);
    logInfo('Parameters:');
    logInfo(Object.assign({}, parameters?.query, body));
    let responseType: ResponseType | undefined = undefined;
    if (['getPhotoPreviewContent', 'getPhotoContent', 'getDocumentContent'].includes(operation)) {
      responseType = 'arraybuffer';
    } else if (['getAuditReport'].includes(operation)) {
      responseType = 'blob';
    }

    return http
      .request({
        responseType,
        headers:
          headers &&
          pipe(
            headers,
            record.map((val) => {
              if (Array.isArray(val)) {
                return val.join(',');
              } else {
                return String(val);
              }
            })
          ),
        baseURL,
        url: query ? `${url}?${query}` : url,
        data: body,
        method
      })
      .then(({ data }) => {
        logInfo('Response:');
        logInfo(data);
        return data === '' ? undefined : data;
      });
  };

  return {
    ...monadPromise,
    request
  };
};

type HttpApiOptions = {
  baseUrl: string;
  requestInterceptor?: {
    onFulfilled?: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
    onRejected?: (error: any) => any;
  };
  responseInterceptor?: {
    onFulfilled?: (value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>;
    onRejected?: (error: any) => any;
  };
};

export const httpApi = (options: HttpApiOptions) => {
  const { baseUrl, requestInterceptor, responseInterceptor } = options;
  if (requestInterceptor) {
    http.interceptors.request.use(requestInterceptor?.onFulfilled, requestInterceptor?.onRejected);
  }
  if (responseInterceptor) {
    http.interceptors.response.use(responseInterceptor?.onFulfilled, responseInterceptor?.onRejected);
  }
  return controllers({ httpClient: httpClient(baseUrl) });
};
