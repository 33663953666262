import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { FacilityCreateContainer } from '../../../features/facility/facility-create/facility-create.container';
import { PageProps } from '../../types';

type FacilityCreateProps = PageProps<'FacilityCreate'>;

export const FacilityCreatePage: FC<FacilityCreateProps> = () => {
  return (
    <Page>
      <FacilityCreateContainer />
    </Page>
  );
};
