import { useState, useCallback, useMemo } from 'react';
import { LayoutChangeEvent } from 'react-native';

type GridItemSize = {
  width: number;
  height: number;
};

type GridItemSizeOptions = {
  rows: number;
  columns: number;
  rowGap?: number;
  columnGap?: number;
  horizontalExtra?: number;
  verticalExtra?: number;
};

type GridItemSizeResult = GridItemSize & {
  onLayout: (event: LayoutChangeEvent) => void;
};

const defaultRowGap = 10;
const defaultColumnGap = 10;
const defaultHorizontalExtra = 0;
const defaultVerticalExtra = 0;

export function useGridItemSize(options: GridItemSizeOptions): GridItemSizeResult {
  const {
    rows,
    columns,
    rowGap = defaultRowGap,
    columnGap = defaultColumnGap,
    horizontalExtra = defaultHorizontalExtra,
    verticalExtra = defaultVerticalExtra
  } = options;

  const [wrapperSize, setWrapperSize] = useState<GridItemSize | null>(null);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout;
    setWrapperSize({ width, height });
  }, []);

  const { width: itemWidth, height: itemHeight } = useMemo<GridItemSize>(() => {
    if (!wrapperSize) {
      return { width: 0, height: 0 };
    }
    const width = Math.floor((wrapperSize.width - columnGap * (columns - 1) - horizontalExtra * columns) / columns);
    const height = Math.floor((wrapperSize.height - rowGap * (rows - 1) - verticalExtra * rows) / rows);
    return { width, height };
  }, [wrapperSize?.width, wrapperSize?.height, rows, columns, rowGap, columnGap, horizontalExtra, verticalExtra]);

  return { width: itemWidth, height: itemHeight, onLayout };
}
