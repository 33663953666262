import { makeObservable, observable, action, configure, runInAction, computed } from 'mobx';
import { defaultPerPage } from '../constants';
import { BaseStore } from './base';

interface Collection<Entity> {
  page: number;
  perPage: number;
  total: number;
  error: string | null;
  isLoading: boolean;
  items: Array<Entity>;
  setPage: (page: number) => void;
}

export type CollectionFetched<Entity> = {
  items: Array<Entity>;
  total: number;
  update?: () => void;
};

export type CollectionFetchedCallback<Entity> =
  | ((fetched: CollectionFetched<Entity>) => void)
  | ((fetched: CollectionFetched<Entity>) => Promise<void>);

configure({ enforceActions: 'observed' });

export abstract class CollectionStore<Entity> extends BaseStore implements Collection<Entity> {
  @observable page = 0;

  @observable perPage = defaultPerPage;

  @observable error: string | null = null;

  @observable total = 0;

  @observable isLoading = false;

  @observable.shallow items: Entity[] = [];

  constructor() {
    super();
    makeObservable(this);
  }

  @action setPage(page: number): void {
    this.page = page;
  }

  @action setPerPage(perPage: number): void {
    this.perPage = perPage;
  }

  @action async paginate(page: number): Promise<void> {
    this.setPage(page);
    await this.loadItems();
  }

  @action async prevPage(): Promise<void> {
    const prevPage = this.page === 0 ? this.lastPage : this.page - 1;
    await this.paginate(prevPage);
  }

  @action async nextPage(): Promise<void> {
    const nextPage = this.page === this.lastPage ? 0 : this.page + 1;
    await this.paginate(nextPage);
  }

  @computed
  get lastPage(): number {
    const pageCount = Math.ceil(this.total / this.perPage);
    const lastPage = pageCount - 1;
    return lastPage < 0 ? 0 : lastPage;
  }

  @action setItems(items: Entity[], total: number): void {
    this.items = items;
    this.total = total;
  }

  @action setError(error: any): void {
    if (error === null) {
      this.error = null;
    } else if (typeof error === 'string') {
      this.error = error;
    } else if (error instanceof Error) {
      this.error = error.toString();
    } else {
      this.error = 'unknown';
    }
  }

  @action async loadItems(onSuccess?: CollectionFetchedCallback<Entity>): Promise<void> {
    try {
      this.isLoading = true;
      const fetched = await this.fetchItems();
      this.setItems(fetched.items, fetched.total);
      if (typeof fetched.update === 'function') {
        runInAction(fetched.update);
      }
      this.setError(null);
      if (typeof onSuccess === 'function') {
        await onSuccess(fetched);
      }
    } catch (e) {
      console.error(e);
      this.setItems([], 0);
      this.setError(e);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  abstract fetchItems(): Promise<CollectionFetched<Entity>>;
}
