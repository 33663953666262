import React, { FC, memo } from 'react';
import { HStack, ITextProps, Text as BaseText } from 'native-base';

import { stringifyFractional, centimetersToMeters } from '../../../utils/units';
import { BoreholeLayerSampleItem } from '../../../models/BoreholeLayerSample';
import { SampleType } from '../sample-type';

export interface SampleListItemProps {
  index: number;
  item: BoreholeLayerSampleItem;
}

export const Text: FC<ITextProps> = (props) => <BaseText {...props} color="dark.dark-0" />;

export const SampleListItem: FC<SampleListItemProps> = memo(({ index, item }) => {
  const { type, depthFrom, depthTo } = item;
  return (
    <HStack space={3} alignItems="center" height={8}>
      <Text>{index + 1}.</Text>
      <SampleType type={type} isVisibleLabel={false} />
      <Text>{stringifyFractional(centimetersToMeters(depthFrom), 2)}</Text>
      <Text>—</Text>
      <Text>{stringifyFractional(centimetersToMeters(depthTo), 2)}</Text>
    </HStack>
  );
});

SampleListItem.displayName = SampleListItem.name;
