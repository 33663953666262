import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Stores, stores, InitStoreOptions, initStores } from '../../stores';
import { Loadable } from '../../components/loadable';

type Disposer = () => void;
type Initializer = (stores: Stores) => Disposer;
type AppLoaderContainerProps = {
  initializers?: Initializer[];
  initStoreOptions?: InitStoreOptions;
};

export const AppLoaderContainer: React.FC<AppLoaderContainerProps> = observer((props) => {
  const { initializers, initStoreOptions, children } = props;
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const disposers: Disposer[] = [];
    const promise = initStores(initStoreOptions).then((disposeStores) => {
      disposers.push(disposeStores);
      if (Array.isArray(initializers)) {
        initializers.forEach((initialize) => disposers.push(initialize(stores)));
      }
      setInitialized(true);
    });
    return () => {
      promise.then(() => {
        disposers.forEach((dispose) => dispose());
      });
    };
  }, []);

  return <Loadable isLoading={!initialized}>{children}</Loadable>;
});
