import React from 'react';
import { HStack, Input, Checkbox } from 'native-base';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledFormField } from '../../../../components/labeled-form-field';
import { BoreholeFormRendererProps } from './borehole-form-renderer.model';
import { stringifyNumber } from '../../../../utils/units';

type BoreholeFormCommonPartProps = Pick<BoreholeFormRendererProps, 'control'>;

export const BoreholeFormCommonPart: React.FC<BoreholeFormCommonPartProps> = ({ control }) => {
  const { t } = useTranslation();
  return (
    <>
      <LabeledFormField label="Обсадка:" labelWidth="68px">
        <HStack flex={1} justifyContent="space-between">
          <HStack space={6}>
            <LabeledFormField label={t(`borehole.card.pipeCaseType_1`)}>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                  <Input
                    size="sm"
                    isInvalid={invalid}
                    value={stringifyNumber(value)}
                    width={20}
                    onChangeText={onChange}
                    placeholder=""
                  />
                )}
                name="pipeCase1"
              />
            </LabeledFormField>
            <LabeledFormField label={t(`borehole.card.pipeCaseType_2`)}>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                  <Input
                    size="sm"
                    isInvalid={invalid}
                    value={stringifyNumber(value)}
                    width={20}
                    onChangeText={onChange}
                    placeholder=""
                  />
                )}
                name="pipeCase2"
              />
            </LabeledFormField>
            <LabeledFormField label={t(`borehole.card.pipeCaseType_3`)}>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                  <Input
                    size="sm"
                    isInvalid={invalid}
                    value={stringifyNumber(value)}
                    width={20}
                    onChangeText={onChange}
                    placeholder=""
                  />
                )}
                name="pipeCase3"
              />
            </LabeledFormField>
          </HStack>
          <LabeledFormField width="370px" label="Технологич. расширение">
            <Controller
              control={control}
              render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                <Input
                  size="sm"
                  isInvalid={invalid}
                  value={stringifyNumber(value)}
                  width={20}
                  onChangeText={onChange}
                  placeholder=""
                />
              )}
              name="technologicalExpansion"
            />
          </LabeledFormField>
        </HStack>
      </LabeledFormField>
      <HStack space={10}>
        <LabeledFormField label={t('borehole.card.isMetal')} labelWidth="68px">
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
              <Checkbox value="isMetal" isInvalid={invalid} isChecked={value} onChange={onChange} />
            )}
            name="isMetal"
          />
        </LabeledFormField>
        <LabeledFormField label={t('borehole.card.isPlastic')} labelWidth="68px">
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => {
              return <Checkbox value="isPlastic" isInvalid={invalid} isChecked={value} onChange={onChange} />;
            }}
            name="isPlastic"
          />
        </LabeledFormField>
      </HStack>
    </>
  );
};
