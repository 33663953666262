import React, { ReactElement } from 'react';
import i18n from 'i18next';
import { VStack, View, Text, Divider } from 'native-base';

import {
  isBoreholeNotification,
  isBoreholeSetTemperatureNotification,
  isCommentNotification,
  isDocumentNotification,
  isFacilityNotification,
  isPhotoNotification,
  Notification
} from '../../models/Notification';
import { stringifyRole } from '../../utils/stringifyRole';
import { screens } from '../../navigation/config';
import { navigate } from '../../navigation';

export type NotificationDisplay = {
  title: string;
  time: number;
  userName: string;
  userRoles: Array<string>;
  content: ReactElement;
};

function getNotificationParams(notification: Notification): Record<string, unknown> {
  return notification.params as Record<string, unknown>;
}

function getNotificationTitle(notification: Notification): string {
  if (
    isBoreholeNotification(notification) ||
    isCommentNotification(notification) ||
    isPhotoNotification(notification) ||
    isDocumentNotification(notification)
  ) {
    const { params } = notification;
    return params.facility?.name || '';
  }
  const params = getNotificationParams(notification);
  return typeof params.name === 'string' ? params.name : '';
}

function getUserRoles(user: Notification['principal']): Array<string> {
  const { roles = [] } = user;
  return roles.map((role) => stringifyRole(role));
}

export function navigateTo(notification: Notification): void {
  if (isFacilityNotification(notification)) {
    const { params } = notification;
    if (params?.id) {
      navigate(screens.FacilityView, { facilityId: params?.id });
    }
    return;
  }

  if (isBoreholeNotification(notification)) {
    const { params } = notification;
    if (params.facility?.id) {
      navigate(screens.BoreholeView, { boreholeId: params.id, facilityId: params.facility.id });
    }
    return;
  }

  if (isCommentNotification(notification)) {
    const { params } = notification;
    if (params.facility?.id) {
      navigate(screens.Comments, { facilityId: params.facility.id });
    }
    return;
  }

  if (isPhotoNotification(notification)) {
    const { params } = notification;
    if (params.facility?.id) {
      navigate(screens.PhotoCard, { facilityId: params.facility.id, photoId: params.id });
    }
    return;
  }

  if (isDocumentNotification(notification)) {
    const { params } = notification;
    if (params.facility?.id) {
      navigate(screens.Documents, { facilityId: params.facility.id });
    }
  }
}

function getNotificationContent(notification: Notification): ReactElement {
  const { entityType, actionType } = notification;

  const textStyle = { fontSize: 14, color: 'dark.dark-0' };
  let generalInfo: ReactElement;

  if (isBoreholeSetTemperatureNotification(notification)) {
    generalInfo = <Text style={textStyle}>{i18n.t(`notification.actionTypes.${actionType}`)}</Text>;
  } else {
    generalInfo = (
      <Text style={textStyle}>
        {i18n.t(`notification.actionTypes.${actionType}`)}{' '}
        <Text fontWeight={500} color="blue.700">
          {i18n.t(`notification.entityTypes.${entityType}`)}
        </Text>
      </Text>
    );
  }

  let content: ReactElement | string = '';

  if (isCommentNotification(notification)) {
    const { params } = notification;
    content = params.text || '';
  }

  if (content && typeof content === 'string') {
    content = <Text style={textStyle}>{content}</Text>;
  }

  return content ? (
    <VStack space={3}>
      {generalInfo}
      {content && <Divider thickness={1} />}
      {content && <View>{content}</View>}
    </VStack>
  ) : (
    generalInfo
  );
}

export function getNotificationDisplay(notification: Notification): NotificationDisplay {
  const { eventTs: time, principal: user } = notification;
  return {
    title: getNotificationTitle(notification),
    time,
    userName: user.name,
    userRoles: getUserRoles(user),
    content: getNotificationContent(notification)
  };
}
