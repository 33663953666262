import { FacilityFile, FacilityFileIO } from '../../components/schemas/FacilityFile';
import { Geologist, GeologistIO } from '../../components/schemas/Geologist';
import { number, undefined, union, string, literal, boolean, array, type } from 'io-ts';

export type Facility = {
	id: number;
	createdAt: number;
	lastUpdatedAt: number | undefined;
	creatorId: number;
	creatorName: string;
	name: string;
	cypher: string;
	boreholeDepthTarget: number | undefined;
	boreholeDepthActual: number | undefined;
	boreholesTarget: number | undefined;
	boreholesActual: number | undefined;
	status: 'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED' | 'ARCHIVED';
	responsibleUserId: number;
	responsibleUserName: string;
	markToDelete: boolean | undefined;
	photos: Array<FacilityFile> | undefined;
	geologists: Array<Geologist> | undefined;
};
export const FacilityIO = type(
	{
		id: number,
		createdAt: number,
		lastUpdatedAt: union([number, undefined]),
		creatorId: number,
		creatorName: string,
		name: string,
		cypher: string,
		boreholeDepthTarget: union([number, undefined]),
		boreholeDepthActual: union([number, undefined]),
		boreholesTarget: union([number, undefined]),
		boreholesActual: union([number, undefined]),
		status: union([
			literal('NEW'),
			literal('IN_PROGRESS'),
			literal('PROBLEM'),
			literal('CLOSED'),
			literal('ARCHIVED'),
		]),
		responsibleUserId: number,
		responsibleUserName: string,
		markToDelete: union([boolean, undefined]),
		photos: union([array(FacilityFileIO), undefined]),
		geologists: union([array(GeologistIO), undefined]),
	},
	'Facility',
);
