import { literal, union, number, type } from 'io-ts';

export type BoreholeLayerSampleSaveRequest = {
	type: 'MONOLITH' | 'WATER' | 'DISTURBED';
	depthFrom: number;
	depthTo: number;
};
export const BoreholeLayerSampleSaveRequestIO = type(
	{ type: union([literal('MONOLITH'), literal('WATER'), literal('DISTURBED')]), depthFrom: number, depthTo: number },
	'BoreholeLayerSampleSaveRequest',
);
