import React from 'react';
import { NotificationsContainer } from '../../../features/notifications/container/notifications';
import { Page } from '../../../components/page';

export const NotificationsPage = () => {
  return (
    <Page>
      <NotificationsContainer />
    </Page>
  );
};

NotificationsPage.displayName = 'MapsPage';
