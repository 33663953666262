import { Map } from '../../models/Map';
import { MapPoint, MapBoundingBox } from './types';

export function getGeneralMapId(facilityId: number): string {
  return `general-${facilityId}`;
}

export function getMapId(mapId: number): string {
  return `map-${mapId}`;
}

export function getMapBoundingBox(maps: Map[]): MapBoundingBox {
  let minLatitude: number | undefined;
  let maxLatitude: number | undefined;
  let minLongitude: number | undefined;
  let maxLongitude: number | undefined;

  maps.forEach((map) => {
    const { coordinate } = map;
    const { latitude, longitude } = coordinate;
    if (minLatitude === undefined || latitude < minLatitude) {
      minLatitude = latitude;
    }
    if (maxLatitude === undefined || latitude > maxLatitude) {
      maxLatitude = latitude;
    }
    if (minLongitude === undefined || longitude < minLongitude) {
      minLongitude = longitude;
    }
    if (maxLongitude === undefined || longitude > maxLongitude) {
      maxLongitude = longitude;
    }
  });

  if (
    minLatitude === undefined ||
    maxLatitude === undefined ||
    minLongitude === undefined ||
    maxLongitude === undefined
  ) {
    throw new TypeError('No maps are provided');
  }

  return { minLatitude, maxLatitude, minLongitude, maxLongitude };
}

export function getMapBoundingBoxCenterPoint(boundingBox: MapBoundingBox): MapPoint {
  const { minLatitude, maxLatitude, minLongitude, maxLongitude } = boundingBox;
  return {
    latitude: (minLatitude + maxLatitude) / 2,
    longitude: (minLongitude + maxLongitude) / 2
  };
}

export function getMapCenterPoint(maps: Map[]): MapPoint {
  return getMapBoundingBoxCenterPoint(getMapBoundingBox(maps));
}

// Source: http://makinacorpus.github.io/Leaflet.GeometryUtil/global.html#destination
// http://www.movable-type.co.uk/scripts/latlong.html
export function getMapDestinationPoint(point: MapPoint, heading: number, distance: number): MapPoint {
  const { latitude, longitude } = point;
  const normalizedHeading = (heading + 360) % 360;
  const rad = Math.PI / 180;
  const radInv = 180 / Math.PI;
  const R = 6378137; // approximation of Earth's radius
  const lon1 = longitude * rad;
  const lat1 = latitude * rad;
  const rheading = normalizedHeading * rad;
  const sinLat1 = Math.sin(lat1);
  const cosLat1 = Math.cos(lat1);
  const cosDistR = Math.cos(distance / R);
  const sinDistR = Math.sin(distance / R);
  const lat2 = Math.asin(sinLat1 * cosDistR + cosLat1 * sinDistR * Math.cos(rheading));
  let lon2 = lon1 + Math.atan2(Math.sin(rheading) * sinDistR * cosLat1, cosDistR - sinLat1 * Math.sin(lat2));
  lon2 *= radInv;
  if (lon2 > 180) {
    lon2 -= 360;
  } else if (lon2 < -180) {
    lon2 += 360;
  }
  return { latitude: lat2 * radInv, longitude: lon2 };
}

export function extendMapBoundingBox(
  boundingBox: MapBoundingBox,
  latitudeDeltaMeters: number,
  longitudeDeltaMeters: number
): MapBoundingBox {
  const centerPoint = getMapBoundingBoxCenterPoint(boundingBox);

  const north: MapPoint = { latitude: boundingBox.maxLatitude, longitude: centerPoint.longitude };
  const east: MapPoint = { latitude: centerPoint.latitude, longitude: boundingBox.maxLongitude };
  const south: MapPoint = { latitude: boundingBox.minLatitude, longitude: centerPoint.longitude };
  const west: MapPoint = { latitude: centerPoint.latitude, longitude: boundingBox.minLongitude };

  const extendedNorth = getMapDestinationPoint(north, 0, latitudeDeltaMeters);
  const extendedEast = getMapDestinationPoint(east, 90, longitudeDeltaMeters);
  const extendedSouth = getMapDestinationPoint(south, 180, latitudeDeltaMeters);
  const extendedWest = getMapDestinationPoint(west, 270, longitudeDeltaMeters);

  return {
    minLatitude: extendedSouth.latitude,
    maxLatitude: extendedNorth.latitude,
    minLongitude: extendedWest.longitude,
    maxLongitude: extendedEast.longitude
  };
}
