import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { OptionalResponseShift, OptionalResponseShiftIO } from '../components/schemas/OptionalResponseShift';
import { Shift, ShiftIO } from '../components/schemas/Shift';
import { ShiftCloseRequest, ShiftCloseRequestIO } from '../components/schemas/ShiftCloseRequest';
import { ShiftOpenRequest, ShiftOpenRequestIO } from '../components/schemas/ShiftOpenRequest';
import { ShiftUpdateWorkerRequest, ShiftUpdateWorkerRequestIO } from '../components/schemas/ShiftUpdateWorkerRequest';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, array, literal, union, undefined, type } from 'io-ts';

export interface ShiftController<F> {
	readonly updateShiftWorker: (id: number, parameters: { body: ShiftUpdateWorkerRequest }) => HKT<F, Shift>;

	readonly openShift: (parameters: { body: ShiftOpenRequest }) => HKT<F, Shift>;

	readonly closeShift: (parameters: { body: ShiftCloseRequest }) => HKT<F, Shift>;

	readonly listShiftsByBorehole: (
		boreholeId: number,
		parameters: {
			query: {
				sortBy: ('id' | 'depthTo' | 'startedAt' | 'finishedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => HKT<F, Array<Shift>>;

	readonly findActiveShift: (parameters: {
		query: { boreholeId: number | undefined };
	}) => HKT<F, OptionalResponseShift>;
}

export interface ShiftController1<F extends URIS> {
	readonly updateShiftWorker: (id: number, parameters: { body: ShiftUpdateWorkerRequest }) => Kind<F, Shift>;

	readonly openShift: (parameters: { body: ShiftOpenRequest }) => Kind<F, Shift>;

	readonly closeShift: (parameters: { body: ShiftCloseRequest }) => Kind<F, Shift>;

	readonly listShiftsByBorehole: (
		boreholeId: number,
		parameters: {
			query: {
				sortBy: ('id' | 'depthTo' | 'startedAt' | 'finishedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind<F, Array<Shift>>;

	readonly findActiveShift: (parameters: {
		query: { boreholeId: number | undefined };
	}) => Kind<F, OptionalResponseShift>;
}

export interface ShiftController2<F extends URIS2> {
	readonly updateShiftWorker: (id: number, parameters: { body: ShiftUpdateWorkerRequest }) => Kind2<F, Error, Shift>;

	readonly openShift: (parameters: { body: ShiftOpenRequest }) => Kind2<F, Error, Shift>;

	readonly closeShift: (parameters: { body: ShiftCloseRequest }) => Kind2<F, Error, Shift>;

	readonly listShiftsByBorehole: (
		boreholeId: number,
		parameters: {
			query: {
				sortBy: ('id' | 'depthTo' | 'startedAt' | 'finishedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind2<F, Error, Array<Shift>>;

	readonly findActiveShift: (parameters: {
		query: { boreholeId: number | undefined };
	}) => Kind2<F, Error, OptionalResponseShift>;
}

export function shiftController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): ShiftController2<F>;
export function shiftController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): ShiftController1<F>;
export function shiftController<F>(e: { httpClient: HTTPClient<F> }): ShiftController<F>;
export function shiftController<F>(e: { httpClient: HTTPClient<F> }): ShiftController<F> {
	return {
		updateShiftWorker: (id, parameters) => {
			const body = ShiftUpdateWorkerRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/shift/${encodeURIComponent(number.encode(id).toString())}/worker`,
					controller: 'shift',
					operation: 'updateShiftWorker',
					method: 'PUT',
					responseType,
					pathParameters: [id],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						ShiftIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		openShift: parameters => {
			const body = ShiftOpenRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/shift/open`,
					controller: 'shift',
					operation: 'openShift',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						ShiftIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		closeShift: parameters => {
			const body = ShiftCloseRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/shift/close`,
					controller: 'shift',
					operation: 'closeShift',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						ShiftIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listShiftsByBorehole: (boreholeId, parameters) => {
			const query = compact([
				pipe(
					union([
						union([literal('id'), literal('depthTo'), literal('startedAt'), literal('finishedAt')]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/shift/list/borehole/${encodeURIComponent(number.encode(boreholeId).toString())}`,
					controller: 'shift',
					operation: 'listShiftsByBorehole',
					method: 'GET',
					responseType,
					pathParameters: [boreholeId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						array(ShiftIO).decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		findActiveShift: parameters => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['boreholeId']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'boreholeId', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/shift/active`,
					controller: 'shift',
					operation: 'findActiveShift',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						OptionalResponseShiftIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
