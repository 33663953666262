import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { subject } from '@casl/ability';

import { DeleteButton } from '../../../components/delete-button';
import { Loadable } from '../../../components/loadable';
import { Actions, ColumnDefinition, Sort } from '../../../components/table/table.model';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useComponent } from '../../../hooks/useComponent';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { Facility, FacilitySortColumn } from '../../../models/Facility';
import { screens } from '../../../navigation/config';
import { appModeStore, facilityStore, facilityTableStore } from '../../../stores';
import { FacilityTableEntity } from '../../../stores/facility-table';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { usePermission } from '../../../utils/permissions/context';

import { FacilityTable } from './facility-table';
import { columns } from './facility-table.columns';

export const FacilityTableContainer = observer(() => {
  const {
    isLoading,
    markedAllToOffline,
    markAllToOffline,
    markToOffline,
    markedToOffline,
    sortTableProp,
    updateSort,
    updateSelection,
    updatePage,
    selectedId,
    items,
    page,
    perPage,
    total
  } = facilityTableStore;

  const navigation = useAppNavigation();
  const permission = usePermission();
  const { t } = useTranslation();

  const ready = useComponentReady(() => {
    facilityTableStore.loadItems().catch((e) => console.error(e));
  });

  const { AppModeLoading } = useComponent();
  if (
    appModeStore.intermediateStage === 'download' ||
    appModeStore.intermediateStage === 'upload' ||
    appModeStore.error
  ) {
    return <AppModeLoading />;
  }

  if (!ready) {
    return null;
  }

  const onMark = (id: number) => {
    facilityStore.setMarkToDelete(id, true).catch((e) => console.error(e));
  };

  const onDelete = (id: number) => {
    facilityStore.remove(id).catch((e) => console.error(e));
  };

  const onChangePage = (p: number) => {
    const newPage = p - 1;
    updatePage(newPage);
  };

  const onChangeSort = (sort: Sort<FacilitySortColumn>) => {
    updateSort(sort?.column, sort?.sortOrder);
  };

  const actionsList: Actions<Facility> = [];

  if (permission.can(ACTION.MARK_TO_ARCHIVE, SUBJECT.FACILITY)) {
    actionsList.push({
      id: 'archive',
      iconName: 'archive',
      actionCallback: ({ id }) => {
        facilityStore.archive(id, true).catch((e) => console.error(e));
      }
    });
  }

  actionsList.push({
    id: 'edit',
    iconName: 'edit',
    actionCallback: ({ id }) => {
      navigation.navigate(screens.FacilityUpdate, { facilityId: id });
    },
    isVisible: (item) => {
      const { status, markToDelete } = item || {};
      return permission.can(ACTION.EDIT, subject(SUBJECT.FACILITY, { status, markToDelete }));
    }
  });

  const canDelete = permission.can(ACTION.DELETE, SUBJECT.FACILITY);
  const canMarkToDelete = permission.can(ACTION.MARK_TO_DELETE, SUBJECT.FACILITY);
  if (canDelete || canMarkToDelete) {
    actionsList.push({
      id: 'delete',
      customRenderer: (item: Facility) => (
        <DeleteButton
          item={item}
          subject={SUBJECT.FACILITY}
          onMark={onMark}
          onDelete={onDelete}
          markToDeleteConfirmationText="Объект будет отмечен «под удаление», но сохранён в системе. Полное удаление или отмену удаления сможет сделать
        только Руководитель. При подтверждении действия вы не сможете редактировать этот Объект. Вы уверены?"
          deleteConfirmationText={t('deleteConfirmation.text')}
        />
      )
    });
  }

  const visibleColumns: Array<ColumnDefinition<FacilityTableEntity, FacilitySortColumn>> =
    appModeStore.intermediateStage === 'selection'
      ? [
          ...columns,
          {
            key: 'markToOffline',
            header: '',
            width: 0
          }
        ]
      : columns;

  return (
    <Loadable isLoading={isLoading || facilityStore.anyLoading}>
      <FacilityTable
        selectedId={selectedId}
        sort={sortTableProp}
        page={page}
        customColumn={visibleColumns}
        total={total}
        perPage={perPage}
        onRowClick={updateSelection}
        actions={actionsList}
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        actionsWidth={appModeStore.intermediateStage === 'selection' ? 0 : undefined}
        onMarkAllToOffline={markAllToOffline}
        markedAllToOffline={markedAllToOffline}
        markedToOffline={markedToOffline.slice()}
        onMarkToOffline={markToOffline}
        items={items}
      />
    </Loadable>
  );
});
