import React, { memo, ReactElement } from 'react';
import { Fab } from 'native-base';
import Icon from '@geo/commons/components/icon';
import { screens } from '@geo/commons/navigation/config';
import { useAppNavigation } from '@geo/commons/hooks/useAppNavigation';
import { FabImagePickerComponent } from '@geo/commons/utils/components/types';

export const FabImagePicker: FabImagePickerComponent = memo(({ facilityId }): ReactElement => {
  const navigation = useAppNavigation();
  const handleClick = () => {
    navigation.navigate(screens.PhotosUpload, { facilityId });
  };

  return (
    <Fab
      position="absolute"
      onPress={handleClick}
      size="sm"
      icon={<Icon name="addplus32" />}
      _web={{ position: 'fixed' }}
      renderInPortal={false}
    />
  );
});
