import { reaction } from 'mobx';

import { navigate, reset } from '../../navigation';
import { screens } from '../../navigation/config';
import { ACTION, SUBJECT } from '../../utils/permissions';
import { Stores } from '../index';
import { getApiClient } from '../../api/api-client';
import { i18n } from '../../i18n/i18next';

export function initUserReactions(stores: Stores): () => void {
  const {
    appStore,
    facilityTableStore,
    facilityArchivedTableStore,
    facilityDeletedTableStore,
    notificationStore,
    shiftStore,
    codificatorStore
  } = stores;

  const loadTranslations = async () => {
    const translations = await getApiClient().codificatorController.getTranslations();
    const grouped = Object.entries(translations).reduce((acc: Record<string, string>, [key, value]) => {
      acc[`codificator.${key}`] = value;
      return acc;
    }, {});
    codificatorStore.setKeyTranslations(translations);
    i18n.addResources('ru', 'translation', grouped);
  };

  if (appStore.currentUser) {
    notificationStore.loadItems().catch((e) => console.error(e));
    loadTranslations().catch((e) => console.log(e));
    codificatorStore.load().catch((e) => console.error(e));
    if (appStore.permission.can(ACTION.ENABLE, SUBJECT.SHIFT)) {
      shiftStore.setGlobalShift().catch((e) => console.error(e));
    }
  }

  const disposeCurrentUser = reaction(
    () => appStore.currentUser,
    (currentUser) => {
      if (currentUser) {
        // User is logged in
        setImmediate(() => {
          reset({ index: 0, routes: [{ name: 'Facilities' }] });
          notificationStore.loadItems().catch((e) => console.error(e));
          loadTranslations().catch((e) => console.log(e));
          codificatorStore.load().catch((e) => console.error(e));
        });
      } else {
        // User is logged out
        setImmediate(() => {
          navigate(screens.Login);
          facilityTableStore.setPage(0);
          facilityArchivedTableStore.setPage(0);
          facilityDeletedTableStore.setPage(0);
        });
      }
    }
  );

  return () => {
    disposeCurrentUser();
  };
}
