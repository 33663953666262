import { User, UserIO } from '../../components/schemas/User';
import { number, undefined, union, string, boolean, type } from 'io-ts';

export type Comment = {
	id: number;
	createdAt: number;
	lastUpdatedAt: number | undefined;
	text: string;
	facilityId: number;
	creator: User;
	markToDelete: boolean | undefined;
};
export const CommentIO = type(
	{
		id: number,
		createdAt: number,
		lastUpdatedAt: union([number, undefined]),
		text: string,
		facilityId: number,
		creator: UserIO,
		markToDelete: union([boolean, undefined]),
	},
	'Comment',
);
