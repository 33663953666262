import React, { FC } from 'react';
import { Fab } from 'native-base';

import Icon from '../../../components/icon';
import { Page } from '../../../components/page';
import { FacilityTableContainer } from '../../../features/facility/facility-table/facility-table.container';
import { PageProps } from '../../types';
import { screens } from '../../config';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { PermissionGuard } from '../../../utils/permissions/guard';

type FacilityListPageProps = PageProps<'Facilities'>;

export const FacilityListPage: FC<FacilityListPageProps> = ({ navigation }) => {
  const onAddClick = () => {
    navigation.navigate(screens.FacilityCreate);
  };

  return (
    <Page
      siblings={
        <PermissionGuard action={ACTION.CREATE} subject={SUBJECT.FACILITY}>
          <Fab
            position="absolute"
            onPress={onAddClick}
            size="sm"
            icon={<Icon name="addplus32" />}
            _web={{ position: 'fixed' }}
            renderInPortal={false}
          />
        </PermissionGuard>
      }
    >
      <FacilityTableContainer />
    </Page>
  );
};
