import { Borehole, BoreholeIO } from '../../components/schemas/Borehole';
import { BoreholeLayerSample, BoreholeLayerSampleIO } from '../../components/schemas/BoreholeLayerSample';
import { GeoDescription, GeoDescriptionIO } from '../../components/schemas/GeoDescription';
import { number, undefined, union, boolean, array, type } from 'io-ts';

export type BoreholeLayer = {
	id: number;
	createdAt: number;
	lastUpdatedAt: number | undefined;
	borehole: Borehole;
	depthFrom: number;
	depthTo: number;
	gwlEmerged: number | undefined;
	gwlSteady: number | undefined;
	geoDescription: GeoDescription;
	canEdit: boolean;
	samples: Array<BoreholeLayerSample> | undefined;
};
export const BoreholeLayerIO = type(
	{
		id: number,
		createdAt: number,
		lastUpdatedAt: union([number, undefined]),
		borehole: BoreholeIO,
		depthFrom: number,
		depthTo: number,
		gwlEmerged: union([number, undefined]),
		gwlSteady: union([number, undefined]),
		geoDescription: GeoDescriptionIO,
		canEdit: boolean,
		samples: union([array(BoreholeLayerSampleIO), undefined]),
	},
	'BoreholeLayer',
);
