import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { AuditContainer } from '../../../features/audit/audit.container';

type AuditPageProps = PageProps<'Audit'>;

export const AuditPage: FC<AuditPageProps> = () => {
  return (
    <Page>
      <AuditContainer />
    </Page>
  );
};
