// We need these defaults in order to prevent TS from crying:
// https://github.com/reduxjs/react-redux/issues/1565#issuecomment-867143221
import React, { FC } from 'react';
import { Text } from 'native-base';
import { Components } from './types';

const noop: FC = () => <Text>Данный компонент недоступен или ещё нереализован для этой версии приложения</Text>;
const empty: FC = () => <></>;

export const defaultComponents: Components = {
  AppInfoComponent: noop,
  AppModeSwitcher: noop,
  AppModeLoading: noop,
  ShiftSwitcher: empty,
  MapView: noop,
  FabImagePicker: noop,
  BoreholePrint: empty,
  BoreholesPrint: empty,
  BoreholeLayersPrint: empty
};
