import { BoreholeLayer, BoreholeLayerIO } from '../../components/schemas/BoreholeLayer';
import { array, number, undefined, union, type } from 'io-ts';

export type BoreholeLayerCollectionDto = {
	result: Array<BoreholeLayer>;
	size: number;
	countByBorehole: number | undefined;
};
export const BoreholeLayerCollectionDtoIO = type(
	{ result: array(BoreholeLayerIO), size: number, countByBorehole: union([number, undefined]) },
	'BoreholeLayerCollectionDto',
);
