import { BoreholeLayerSample, BoreholeLayerSampleIO } from '../../components/schemas/BoreholeLayerSample';
import { array, number, record, string, undefined, union, type } from 'io-ts';

export type BoreholeLayerSampleCollectionDto = {
	result: Array<BoreholeLayerSample>;
	size: number;
	countByTypeMap: { [key: string]: number } | undefined;
};
export const BoreholeLayerSampleCollectionDtoIO = type(
	{ result: array(BoreholeLayerSampleIO), size: number, countByTypeMap: union([record(string, number), undefined]) },
	'BoreholeLayerSampleCollectionDto',
);
