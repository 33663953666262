import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';
import { facilityStore, photoStore } from '../../../stores';
import { FacilityImage } from '../../../components/facility-image';
import { Loadable } from '../../../components/loadable';
import { FileEditForm } from '../../../components/file-edit-form/file-edit-form';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { getApiClient } from '../../../api/api-client';
import { screens } from '../../config';
import { FileEditFormData } from '../../../components/file-edit-form/file-edit-form.model';

type PhotoEditPageProps = PageProps<'PhotoEdit'>;

export const PhotoEditPage: FC<PhotoEditPageProps> = observer(({ route }) => {
  const { params } = route;
  const facilityId = params?.facilityId;
  const photoId = params?.photoId;
  const navigation = useAppNavigation();
  const { item, setItem, anyError, anyLoading } = photoStore;

  useNavigationEffect(() => {
    if (!facilityStore.item || facilityStore.item.id !== facilityId) {
      facilityStore.loadItem(facilityId).catch((e) => console.error(e));
    }
    photoStore.setFacilityId(facilityId);
    photoStore.loadItem(photoId).catch();
    return () => {
      setItem(null);
    };
  }, [photoId, facilityId]);

  const preview = useMemo(() => {
    return (
      <FacilityImage
        resizeMode="contain"
        contentType="images/jpeg"
        facilityId={facilityId}
        isThumbnail={false}
        photoId={photoId}
        width={120}
        height={140}
        alt={item?.description}
      />
    );
  }, [facilityId, photoId, item?.description]);

  if (!facilityId || !photoId) {
    return null;
  }

  const handleSubmit = useCallback(
    async (value: FileEditFormData) => {
      if (item) {
        const result = await photoStore.updateDescription(facilityId, item.id, value.description);
        if (result) {
          photoStore.setItem(null);
          navigation.navigate(screens.Photos, { facilityId });
        } else {
          console.error(result);
        }
      }
    },
    [item, item?.id, facilityId]
  );

  const handleCancel = useCallback(async () => {
    await getApiClient().photoController.deletePhoto(facilityId, photoId);
    navigation.navigate(screens.Photos, { facilityId });
  }, [photoId, facilityId]);

  return (
    <Page>
      <Loadable isLoading={anyLoading} error={anyError}>
        {item && (
          <FileEditForm
            defaultValues={{ description: item?.description || '' }}
            preview={preview}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        )}
      </Loadable>
    </Page>
  );
});

PhotoEditPage.displayName = 'PhotosUploadPage';
