import { makeObservable, observable, action } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { CollectionFetched, CollectionStore } from './collection';
import { Map } from '../models/Map';

import { getApiClient } from '../api/api-client';
import { defaultMapsPerPage } from '../constants';

export class MapListStore extends CollectionStore<Map> {
  @observable facilityId?: number;

  perPage = defaultMapsPerPage;

  constructor() {
    super();
    makeObservable(this);
  }

  async init(): Promise<void> {
    await super.init();
    await makePersistable(this, {
      name: 'Maps',
      properties: ['page', 'facilityId'],
      storage: AsyncStorage
    });
  }

  @action setFacilityId(facilityId?: number): void {
    this.facilityId = facilityId;
  }

  @action async fetchItems(): Promise<CollectionFetched<Map>> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }
    const { result: items, size: total } = await getApiClient().mapController.listOfMapsByFacilityId(this.facilityId, {
      query: {
        page: this.page,
        size: this.perPage,
        sortBy: undefined,
        order: undefined
      }
    });
    return { items, total };
  }
}
