import i18n from 'i18next';
import { ROLE } from './permissions';

export const stringifyRole = (role: string): string => {
  switch (role) {
    case ROLE.Assistant:
      return i18n.t('role.assistant');
    case ROLE.Manager:
      return i18n.t('role.manager');
    case ROLE.Geologist:
      return i18n.t('role.geologist');
    case ROLE.OfficeWorker:
      return i18n.t('role.officeWorker');
    default:
      return role;
  }
};
