import { Platform } from 'react-native';

export const appName = 'КАБИНЕТ ГЕОЛОГА';

// eslint-disable-next-line prefer-destructuring,dot-notation
const API_BASE_URL = process.env['API_BASE_URL'];
const defaultApiUrl = Platform.OS === 'web' ? '/' : 'https://dev.geodome.pro/';
export const apiUrl = API_BASE_URL || defaultApiUrl;

export const defaultPerPage = 10;
export const defaultMapsPerPage = 14;
export const defaultDocumentsPerPage = 21;
export const defaultPhotosPerPage = 21;
export const defaultNotificationsPerPage = 21;
