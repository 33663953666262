import { i18n } from '../../../i18n/i18next';
import { ColumnDefinition } from '../../../components/table/table.model';
import { BoreholeLayerSample, BoreholeLayerSampleSortColumn } from '../../../models/BoreholeLayerSample';
import { centimetersToMeters, stringifyFractional } from '../../../utils/units';

export const columns: ColumnDefinition<BoreholeLayerSample, BoreholeLayerSampleSortColumn>[] = [
  {
    key: 'id',
    header: i18n.t('borehole.sample.id'),
    width: 60
  },
  {
    key: 'boreholeName',
    header: i18n.t('borehole.sample.boreholeName'),
    sortBy: 'layerBoreholeId',
    width: 115
  },
  {
    key: 'depthFrom',
    header: i18n.t('borehole.sample.depthFrom'),
    width: 75,
    sortBy: 'depthFrom',
    transformValue: (value: number) => stringifyFractional(centimetersToMeters(value), 2)
  },
  {
    key: 'depthTo',
    header: i18n.t('borehole.sample.depthTo'),
    width: 75,
    sortBy: 'depthTo',
    transformValue: (value: number) => stringifyFractional(centimetersToMeters(value), 2)
  },
  {
    key: 'type',
    header: i18n.t('borehole.sample.type'),
    sortBy: 'type',
    width: 120
  }
];

export const assistantColumns: ColumnDefinition<BoreholeLayerSample, BoreholeLayerSampleSortColumn>[] = [
  {
    key: 'geoDescription',
    header: i18n.t('borehole.sample.geoDescription'),
    width: 200
  },
  {
    key: 'accepted',
    header: i18n.t('borehole.sample.accepted'),
    width: 88
  },
  {
    key: 'comment',
    header: i18n.t('borehole.sample.comment')}
];
