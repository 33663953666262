import {
	BoreholeLayerSampleSaveRequest,
	BoreholeLayerSampleSaveRequestIO,
} from '../../components/schemas/BoreholeLayerSampleSaveRequest';
import { GeoDescription, GeoDescriptionIO } from '../../components/schemas/GeoDescription';
import { number, undefined, union, array, type } from 'io-ts';

export type BoreholeLayerCreateRequest = {
	boreholeId: number;
	depthFrom: number;
	depthTo: number;
	gwlEmerged: number | undefined;
	gwlSteady: number | undefined;
	geoDescription: GeoDescription;
	samples: Array<BoreholeLayerSampleSaveRequest> | undefined;
};
export const BoreholeLayerCreateRequestIO = type(
	{
		boreholeId: number,
		depthFrom: number,
		depthTo: number,
		gwlEmerged: union([number, undefined]),
		gwlSteady: union([number, undefined]),
		geoDescription: GeoDescriptionIO,
		samples: union([array(BoreholeLayerSampleSaveRequestIO), undefined]),
	},
	'BoreholeLayerCreateRequest',
);
