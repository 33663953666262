type ConsoleMethod = typeof console.log;

export const patchConsoleMethod = (method: ConsoleMethod, ignoreList: Array<RegExp>): ConsoleMethod => {
  return (message?: any, ...optionalParams: any[]): void => {
    for (let i = 0; i < ignoreList.length; i += 1) {
      if (ignoreList[i].test(message)) {
        return;
      }
    }
    method(message, ...optionalParams);
  };
};
