import React, { FC } from 'react';
import { Button, Modal } from 'native-base';
import { useTranslation } from 'react-i18next';
import Icon from './icon';

interface ConfirmationPopupProps {
  isOpen?: boolean;
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  handleConfirmButton: () => void;
  handleCloseButton: () => void;
}

export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  isOpen = false,
  children,
  confirmButtonTitle,
  cancelButtonTitle,
  handleConfirmButton,
  handleCloseButton
}) => {
  const { t } = useTranslation();
  const onConfirmButtonClick = () => {
    if (handleConfirmButton) {
      handleConfirmButton();
    }
  };
  const onCancelButtonClick = () => {
    handleCloseButton();
  };

  return (
    <Modal isOpen={isOpen} onClose={onCancelButtonClick}>
      <Modal.Content style={{ width: 480 }}>
        <Modal.CloseButton />
        <Modal.Header>{t('confirmation.title')}</Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer justifyContent="flex-start">
          <Button.Group space={2}>
            <Button startIcon={<Icon name="done" />} onPress={onConfirmButtonClick}>
              {confirmButtonTitle || t('confirmation.buttons.confirm')}
            </Button>
            <Button variant="ghost" onPress={onCancelButtonClick}>
              {cancelButtonTitle || t('confirmation.buttons.cancel')}
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

ConfirmationPopup.displayName = ConfirmationPopup.name;
