import React, { FC } from 'react';
import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { FilesUploadContainer } from '../../../features/files-upload/files-upload.container';
import { screens } from '../../config';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { fileStore } from '../../../stores';
import { documentsStore } from '../../../stores';

type DocumentUploadPageProps = PageProps<'DocumentUpload'>;

export const DocumentUploadPage: FC<DocumentUploadPageProps> = ({ route }) => {
  const navigation = useAppNavigation();
  const { params } = route;
  const facilityId = params?.facilityId;

  if (!facilityId) {
    return null;
  }

  const navigate = () => {
    navigation.navigate(screens.Documents, {
      facilityId
    });
  };

  const onSuccessHandler = () => {
    documentsStore.setPage(0);
  };

  return (
    <Page>
      <FilesUploadContainer
        mode="upload"
        store={fileStore}
        navigate={navigate}
        fileType="document"
        facilityId={facilityId}
        onSuccess={onSuccessHandler}
      />
    </Page>
  );
};

DocumentUploadPage.displayName = 'DocumentUploadPage';
