import yup from '../../../utils/yup';
import { FacilityStatus } from '../../../models/Facility';

// All length values are in meters
export interface BoreholeFormData {
  name: string;
  creatorId: number;
  status: FacilityStatus;
  latitude: number;
  longitude: number;
  depthTarget: number;
  isPlastic: boolean;
  isMetal: boolean;
  depthActual?: number;
  pipeCase1?: number;
  pipeCase2?: number;
  pipeCase3?: number;
  technologicalExpansion?: number;
}

export const nameMaxLength = 100;
export const maxDepth = 10000;

const transformNumber = (value: number, originalValue: string): number | undefined => {
  return originalValue === '' ? undefined : value;
};

const pipeCaseSchema = yup.number().min(0).max(maxDepth).fractionalDigits(2).transform(transformNumber);

export const schema = yup.object().shape({
  name: yup.string().max(nameMaxLength).trim().required(),
  creatorId: yup.number().required(),
  status: yup.string().required(),
  latitude: yup.number().min(-90).max(90).fractionalDigits(8).required(),
  longitude: yup.number().min(-180).max(180).fractionalDigits(8).required(),
  depthTarget: yup.number().min(0).max(maxDepth).fractionalDigits(2).required(),
  depthActual: yup.number().min(0).max(maxDepth).fractionalDigits(2).transform(transformNumber),
  pipeCase1: pipeCaseSchema,
  pipeCase2: pipeCaseSchema,
  pipeCase3: pipeCaseSchema,
  technologicalExpansion: yup.number().min(0).max(maxDepth).fractionalDigits(2).transform(transformNumber)
});
