import { action, observable } from 'mobx';

import { getApiClient } from '../api/api-client';
import { BoreholeTemperature } from '../models/BoreholeTemperatures';

import { BaseTableStore } from './base-table';
import { BoreholeSortColumn } from '../models/Borehole';
import { CollectionFetched } from './collection';
import { dateFormat, timeFormat } from '../utils/date';

export class BoreholeTemperaturesTableStore extends BaseTableStore<BoreholeTemperature, BoreholeSortColumn> {
  @observable boreholeId?: number;

  @action setBoreholeId(boreholeId: number | undefined): void {
    this.boreholeId = boreholeId;
  }

  @action async fetchItems(): Promise<CollectionFetched<BoreholeTemperature>> {
    if (!this.boreholeId) {
      throw new Error('Borehole identifier is not set');
    }
    const items = await getApiClient().boreholeTemperatureController.boreholeTemperatureList(this.boreholeId);
    return {
      items: items.map((item) => ({
        ...item,
        time: timeFormat(item.measuredAt),
        measuredAt: dateFormat(item.measuredAt),
        geologistName: item.geologist.name
      })),
      total: items.length
    };
  }
}
