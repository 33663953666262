import { extendTheme, Theme } from 'native-base';

const buttonVariantTab = {
  borderWidth: 1,
  borderColor: 'light.light-4',
  backgroundColor: 'light.light-0',
  _text: {
    color: 'dark.dark-0'
  },
  _web: {
    _hover: {
      borderColor: 'dark.dark-2'
    }
  },
  _pressed: {
    borderColor: 'dark.dark-2',
    backgroundColor: 'light.light-1'
  },
  _disabled: {
    backgroundColor: 'light.light-3',
    _text: {
      fontWeight: 400
    }
  }
};

const buttonVariantTabActive = {
  ...buttonVariantTab,
  backgroundColor: 'dark.dark-2',
  _text: { color: 'light.light-0', fontWeight: 400 }
};

export const theme: Theme = extendTheme({
  colors: {
    black: '#000000',
    white: '#FFFFFF',
    primary: {
      default: '#004FC4'
    },
    secondary: {
      default: '#5B8DEF'
    },
    blue: {
      default: '#73A2FF'
    },
    green: {
      default: '#06C270'
    },
    red: {
      default: '#EF3B3B'
    },
    dark: {
      'dark-0': '#1C1C28',
      'dark-1': '#28293D',
      'dark-2': '#555770',
      'dark-3': '#303148',
      'dark-4': '#8587B7',
      placeholder: 'rgba(28,28,40,0.48)'
    },
    light: {
      'light-0': '#FFFFFF',
      'light-1': '#F7F7FA',
      'light-2': '#EFF2FA',
      'light-3': '#DBDEEA',
      'light-4': '#CBCECA',
      'light-5': '#929298'
    },
    error: '#FF5C5C',
    icons: '#B4B6C9'
  },
  components: {
    Menu: {
      baseStyle: {
        borderWidth: 1,
        borderColor: '#dbdeea',
        backgroundColor: '#fff',
        py: 1,
        shadow: null
      }
    },
    MenuItem: {
      baseStyle: {
        py: 2,
        _focus: {
          backgroundColor: 'transparent'
        }
      }
    },
    FormControl: {
      baseStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 'auto'
      }
    },
    FormControlLabel: {
      baseStyle: {
        marginBottom: 0,
        _text: {
          color: 'dark.dark-2',
          fontSize: '14px',
          opacity: 0.56,
          fontWeight: 500
        }
      }
    },
    Text: {
      baseStyle: {
        fontSize: 'sm'
      }
    },
    Input: {
      baseStyle: {
        borderRadius: '8px',
        color: 'dark.dark-0',
        height: 12,
        placeholderTextColor: 'dark.placeholder',
        borderColor: 'light.light-5',
        backgroundColor: 'light.light-1',
        _invalid: {
          borderColor: 'error',
          backgroundColor: 'light.light-0'
        },
        _disabled: {
          backgroundColor: 'light.light-3',
          borderColor: 'light.light-3'
        },
        _hover: {
          borderColor: 'light.light-4',
          backgroundColor: 'light.light-0'
        },
        _focus: {
          borderColor: 'dark.dark-2',
          backgroundColor: 'light.light-0'
        }
      },
      sizes: {
        md: {
          fontSize: 16,
          px: 4
        },
        sm: {
          fontSize: 14,
          px: 3,
          py: 0,
          height: 10
        },
        xs: {
          fontSize: 12,
          px: 2,
          py: 0,
          height: 8
        }
      }
    },
    ModalHeader: {
      baseStyle: {
        backgroundColor: 'white',
        borderBottomWidth: 0
      }
    },
    ModalContent: {
      baseStyle: {
        backgroundColor: 'white'
      }
    },
    ModalFooter: {
      baseStyle: {
        backgroundColor: 'white'
      }
    },
    ModalOverlay: {
      baseStyle: {
        position: 'fixed'
      }
    },
    Button: {
      baseStyle: {
        borderRadius: 8
      },
      sizes: {
        lg: {
          py: 2
        },
        md: {
          py: 2
        },
        sm: {
          py: 1
        },
        xs: {
          py: 0
        }
      },
      variants: {
        solid: {
          backgroundColor: 'primary.default',
          _text: {
            color: 'light.light-0'
          },
          _web: {
            _hover: {
              backgroundColor: 'secondary.default',
              borderColor: 'secondary.default'
            },
            _pressed: {
              backgroundColor: 'blue.default',
              borderColor: 'blue.default'
            }
          }
        },
        outline: {
          borderColor: 'primary.default',
          _text: {
            color: 'primary.default'
          },
          _web: {
            _hover: {
              backgroundColor: 'secondary.default',
              borderColor: 'secondary.default',
              _text: {
                color: 'light.light-0'
              }
            },
            _pressed: {
              backgroundColor: 'blue.default',
              borderColor: 'blue.default'
            }
          }
        },
        ghost: {
          _text: {
            color: 'primary.default',
            fontWeight: 'bold'
          },
          _web: {
            _hover: {
              backgroundColor: 'transparent',
              _text: {
                color: 'secondary.default'
              }
            },
            _pressed: {
              backgroundColor: 'transparent',
              _text: {
                color: 'blue.default'
              }
            }
          }
        },
        tab: buttonVariantTab,
        tabActive: buttonVariantTabActive
      }
    },
    Select: {
      baseStyle: {
        borderRadius: '8px',
        backgroundColor: 'light.light-0'
      }
    },
    IconButton: {
      baseStyle: {
        borderRadius: '8px'
      },
      variants: {
        outline: {
          borderWidth: 1,
          borderStyle: 'solid',
          backgroundColor: 'dark',
          borderColor: 'light.light-3',
          _web: {
            _hover: {
              borderColor: 'light.light-2',
              backgroundColor: 'secondary.default'
            },
            _pressed: {
              borderColor: 'light.light-2',
              backgroundColor: 'secondary.default'
            }
          }
        },
        solid: {
          backgroundColor: 'primary.default'
        }
      }
    }
  },
  sizes: {
    '14': 56,
    '17': 68,
    '25': 100,
    '50': 200
  }
});
