import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';
import { Stores } from '../index';
import { createEntityLoadingReaction } from './utils';
import { PhotoStore } from '../photo';
import { FileRequestType } from '../file';
import { defaultPhotosPerPage as perPage } from '../../constants';

const getNavigationInfo = (page: number, idsOfPage: number[], ids: number[], id: number) => {
  const indexOfPage = idsOfPage.indexOf(id);
  const index = ids.indexOf(id);
  const prevId = ids[index - 1] ?? ids[ids.length - 1];
  const nextId = ids[index + 1] ?? ids[0];

  return {
    prevId,
    nextId,
    currentIndex: page * perPage + (indexOfPage + 1)
  };
};
export function initPhotoReactions(stores: Stores): () => void {
  const { photoStore, photosStore } = stores;

  const disposePhotoLoad = createEntityLoadingReaction<FacilityFile, FileRequestType, PhotoStore>(
    photoStore,
    'get',
    (state) => {
      (async function () {
        const { item: photo } = state;
        if (photo) {
          const { id } = photo;
          if (photosStore.items.length === 0) {
            photosStore.setFacilityId(photoStore.facilityId);
            await photosStore.loadItems();
          }

          const ids = [...photosStore.prevPageIds, ...photosStore.fileIds, ...photosStore.nextPageIds];
          console.log(
            'initPhotoReactions stored',
            'prevPageIds',
            photosStore.prevPageIds,
            'fileIds',
            photosStore.fileIds,
            'nextPageIds',
            photosStore.nextPageIds
          );
          if (photosStore.nextPageIds.includes(id)) {
            await photosStore.nextPage().catch();
          }
          if (photosStore.prevPageIds.includes(id)) {
            await photosStore.prevPage().catch();
            console.log('initPhotoReactions load prev page');
          }
          const info = getNavigationInfo(photosStore.page, photosStore.fileIds, ids, id);
          console.log('initPhotoReactions', photosStore.page, photosStore.fileIds, ids);
          photoStore.updateNavigationInfo(info);
        }
      })();
    }
  );

  return () => {
    disposePhotoLoad();
  };
}
