import { action, override, makeObservable } from 'mobx';

import { BoreholeLayerCreateRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeLayerCreateRequest';
import { BoreholeLayerUpdateRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeLayerUpdateRequest';

import { EntityStore } from './entity';

import { getApiClient } from '../api/api-client';
import { BoreholeLayer } from '../models/BoreholeLayer';
import { BoreholeLayerSample } from '../models/BoreholeLayerSample';

export type BoreholeLayerRequestType = 'create' | 'update' | 'remove';

export class BoreholeLayerStore extends EntityStore<BoreholeLayer, number, BoreholeLayerRequestType> {
  constructor(storageKey?: string) {
    super(storageKey);
    makeObservable(this);
  }

  protected async fetchItem(id: number): Promise<BoreholeLayer> {
    return getApiClient().boreholeLayerController.getBoreholeLayer(id);
  }

  @override setItem(item: BoreholeLayer | null): void {
    if (!item) {
      super.setItem(null);
    } else {
      super.setItem(item, item.id);
    }
  }

  @action async create(params: BoreholeLayerCreateRequest): Promise<BoreholeLayer> {
    return this.request(
      'create',
      () => getApiClient().boreholeLayerController.createBoreholeLayer({ body: params }),
      (boreholeLayer) => this.setItem(boreholeLayer)
    );
  }

  @action async update(id: number, params: BoreholeLayerUpdateRequest): Promise<BoreholeLayer> {
    return this.request(
      'update',
      () => getApiClient().boreholeLayerController.updateBoreholeLayer(id, { body: params }),
      (boreholeLayer) => this.setItem(boreholeLayer)
    );
  }

  @action async remove(id: number): Promise<void> {
    await this.request(
      'remove',
      () => getApiClient().boreholeLayerController.deleteBoreholeLayer(id),
      () => this.setItem(null)
    );
  }

  @action setSamples(samples: Array<BoreholeLayerSample>): void {
    if (!this.item) {
      throw new TypeError('Borehole layer is not set');
    }
    this.item.samples = samples;
  }
}
