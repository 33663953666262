import React, { ComponentProps, ReactElement, useMemo } from 'react';
import { StyleProp, StyleSheet, ViewStyle, TouchableOpacity } from 'react-native';
import { View } from 'native-base';
import svgs from './svgs';

export type IconName = keyof typeof svgs;

export interface RawIconProps {
  fill?: string;
  stretch?: boolean;
}

export interface IconProps extends ComponentProps<typeof View>, RawIconProps {
  name: IconName;
  onPress?: () => void;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  touchable: {
    alignItems: 'center'
  },
  iconContainer: {
    maxHeight: '100%',
    justifyContent: 'center'
  },
  disabled: {
    opacity: 0.4
  }
});

const Icon: React.FC<IconProps> = (props): ReactElement => {
  const { name, onPress, disabled, style, fill, stretch = false, ...viewProps } = props;
  const rawIcon: ReactElement | ((props: RawIconProps) => ReactElement) = svgs[name];
  const icon: ReactElement = typeof rawIcon === 'function' ? rawIcon({ fill, stretch }) : rawIcon;

  const renderedIcon = useMemo(
    () => (
      <View {...viewProps} style={[styles.iconContainer, style].filter(Boolean)}>
        {icon}
      </View>
    ),
    [icon, style]
  );

  if (onPress) {
    return (
      <TouchableOpacity
        disabled={disabled}
        onPress={onPress}
        style={[styles.touchable, disabled ? styles.disabled : {}]}
      >
        {renderedIcon}
      </TouchableOpacity>
    );
  }

  return renderedIcon;
};

Icon.displayName = Icon.name;

export default Icon;
