import { IBoxProps, IButtonProps, Pressable, Box, Text } from 'native-base';
import React, { PropsWithChildren } from 'react';
import Icon, { IconName } from '../icon';

interface NavButtonProps extends PropsWithChildren<Omit<IBoxProps, 'children'>> {
  isActive: boolean;
  onPress: IButtonProps['onPress'];
  iconName?: IconName;
}

export const NavButton = ({ isActive, iconName, onPress, children, ...boxProps }: NavButtonProps) => {
  return (
    <Pressable onPress={!isActive ? onPress : undefined} _web={{ cursor: !isActive ? 'pointer' : 'default' }}>
      {({ isHovered }) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center" {...boxProps}>
            {iconName && <Icon mr="7px" name={iconName} fill={isActive ? '#555770' : '#B4B6C9'} />}
            <Text
              color={isActive ? 'dark.dark-0' : 'primary.default'}
              fontWeight={400}
              fontSize="md"
              textDecorationLine={isHovered && !isActive ? 'underline' : 'none'}
            >
              {children}
            </Text>
          </Box>
        );
      }}
    </Pressable>
  );
};
