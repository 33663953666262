import React, { FC, useCallback, useMemo } from 'react';
import memoize from 'fast-memoize';
import { Text } from 'native-base';
import { BoreholeTemperature } from '../../models/BoreholeTemperatures';
import { Table, TableProps } from '../../components/table/table';

import { columns } from './borehole-temperatures.column';
import { Actions, CustomRenderers } from '../../components/table/table.model';
import { TableRow, TableRowProps } from '../../components/table/table-row';
import { getFormRenderers } from './form-renderer';
import { Form } from '../../utils/form';
import { BoreholeTemperatureFormData } from './borehole-temperature.model';

export interface BoreholeTemperaturesProps extends TableProps<BoreholeTemperature> {
  form: Form<BoreholeTemperatureFormData>;
  editableBoreholeTemperatureId?: number;
  actionsEditable?: Actions<BoreholeTemperature>;
}

export const BoreholeTemperatures: FC<BoreholeTemperaturesProps> = ({
  items,
  form,
  onRowClick,
  selectedId,
  actionsEditable,
  editableBoreholeTemperatureId,
  actions
}) => {
  const perPage = 100;
  const page = 0;
  const nullFnc = useCallback(() => null, []);

  const isEditableBoreholeTemperature = useMemo(() => {
    return memoize((boreholeTemperature: BoreholeTemperature): boolean => {
      return (
        typeof editableBoreholeTemperatureId === 'number' && boreholeTemperature.id === editableBoreholeTemperatureId
      );
    });
  }, [editableBoreholeTemperatureId]);

  const customRenderers: CustomRenderers<BoreholeTemperature> = {
    depth: ({ depth }) => <Text>{depth === 0 ? '—' : depth}</Text>,
    temperature: ({ temperature }) => <Text>{temperature === 0 ? '—' : temperature}</Text>
  };

  const renderItem =
    (rowProps: TableRowProps<BoreholeTemperature>) =>
    ({ item }: { item: BoreholeTemperature; index: number }) => {
      const isEditable = isEditableBoreholeTemperature(item);
      return (
        <TableRow
          {...rowProps}
          customRenderers={isEditable ? getFormRenderers(form) : customRenderers}
          key={`table-row-${item.id}`}
          actions={isEditable ? actionsEditable : actions}
        />
      );
    };

  return (
    <Table
      items={items}
      columns={columns}
      customRowRenderer={renderItem}
      onRowClick={onRowClick}
      headerHeight={14}
      selectedId={selectedId}
      hidePagination
      actions={actions}
      total={0}
      page={page}
      perPage={perPage}
      onChangePage={nullFnc}
      onChangeSort={nullFnc}
    />
  );
};
