import React from 'react';
import { VStack, HStack, Input } from 'native-base';
import { Controller } from 'react-hook-form';
import { Panel } from '../../../../components/panel/panel';
import { SelectBox } from '../../../../components/select-box';
import { statusList, StatusSelect } from '../../../../components/status/status-select';
import { LabeledFormField } from '../../../../components/labeled-form-field';
import { User } from '../../../../models/User';
import { BoreholeFormRendererProps } from './borehole-form-renderer.model';
import { BoreholeFormCommonPart } from './borehole-form-common-part';
import { nameMaxLength } from '../borehole-form.model';
import { stringifyNumber } from '../../../../utils/units';

type UserOption = Pick<User, 'name' | 'id'>;

const userToOption = (user: UserOption): { label: string; value: string } => ({
  label: user.name,
  value: user.id.toString()
});

export const BoreholeFormRendererAdmin: React.FC<BoreholeFormRendererProps> = ({ creationList, control }) => {
  const availableStatuses = statusList.filter((status) => status !== 'CLOSED');

  const headerLeft = (
    <Controller
      control={control}
      render={({ field: { onChange, value }, fieldState: { invalid } }) => {
        const onChangeText = (newValue: string) => onChange(newValue.substring(0, nameMaxLength));
        return (
          <HStack flex={1}>
            <Input
              size="sm"
              isInvalid={invalid}
              value={value}
              onChangeText={onChangeText}
              placeholder="Название скважины"
              isFullWidth
            />
          </HStack>
        );
      }}
      name="name"
      defaultValue=""
    />
  );

  return (
    <Panel headerIcon="info" headerLeft={headerLeft}>
      <VStack space={4} py={5}>
        <HStack justifyContent="space-between">
          <LabeledFormField label="Статус" labelWidth="56px">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <StatusSelect
                  statuses={availableStatuses}
                  placeholder="Выберите статус"
                  value={value}
                  onValueChange={onChange}
                />
              )}
              name="status"
            />
          </LabeledFormField>
          <LabeledFormField width="370px" label="Создатель" labelWidth="120px">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectBox
                  isDisabled
                  onValueChange={onChange}
                  value={stringifyNumber(value)}
                  options={creationList.map(userToOption)}
                />
              )}
              name="creatorId"
            />
          </LabeledFormField>
        </HStack>
        <LabeledFormField label="Координаты:" labelWidth="160px">
          <HStack space={6}>
            <LabeledFormField labelWidth="56px" label="N">
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                  <Input
                    size="sm"
                    isInvalid={invalid}
                    value={stringifyNumber(value)}
                    width={120}
                    onChangeText={onChange}
                    placeholder=""
                  />
                )}
                name="latitude"
              />
            </LabeledFormField>
            <LabeledFormField labelWidth="56px" label="E">
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                  <Input
                    size="sm"
                    isInvalid={invalid}
                    value={stringifyNumber(value)}
                    width={120}
                    onChangeText={onChange}
                    placeholder=""
                  />
                )}
                name="longitude"
              />
            </LabeledFormField>
          </HStack>
        </LabeledFormField>
        <HStack space={6}>
          <LabeledFormField label="План" labelWidth="56px">
            <Controller
              control={control}
              render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                <Input
                  size="sm"
                  isInvalid={invalid}
                  value={stringifyNumber(value)}
                  width={120}
                  onChangeText={onChange}
                  placeholder=""
                />
              )}
              name="depthTarget"
            />
          </LabeledFormField>
          <LabeledFormField label="Факт" labelWidth="56px">
            <Controller
              control={control}
              render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                <Input
                  size="sm"
                  isInvalid={invalid}
                  value={stringifyNumber(value)}
                  width={120}
                  onChangeText={onChange}
                  placeholder=""
                  isReadOnly={true}
                />
              )}
              name="depthActual"
            />
          </LabeledFormField>
        </HStack>
        <BoreholeFormCommonPart control={control} />
      </VStack>
    </Panel>
  );
};
