import React, { FC } from 'react';
import { Fab } from 'native-base';
import { observer } from 'mobx-react';
import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { BoreholeTableContainer } from '../../../features/borehole/borehole-table/borehole-table.container';

import { ACTION, SUBJECT } from '../../../utils/permissions';
import { PermissionGuard } from '../../../utils/permissions/guard';

import Icon from '../../../components/icon';
import { screens } from '../../config';
import { Tabs } from '../../../components/tabs';
import { facilityStore } from '../../../stores';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';

type BoreholesProps = PageProps<'Boreholes'>;

export const BoreholesPage: FC<BoreholesProps> = observer(({ route, navigation }) => {
  const { params } = route;
  const facilityId = params?.facilityId;

  useNavigationEffect(() => {
    if (facilityId) {
      facilityStore.loadItem(facilityId).catch((e) => console.error(e));
    }
  }, [facilityId]);

  if (!facilityId) {
    return null;
  }

  const { isArchived, isDeleted } = facilityStore;
  const showAddButton = !facilityStore.loading('get') && !facilityStore.error('get') && !isArchived && !isDeleted;

  const onAddClick = () => {
    navigation.navigate(screens.BoreholeCreate, { facilityId });
  };

  return (
    <Page
      header={<Tabs facilityId={facilityId} />}
      siblings={
        showAddButton && (
          <PermissionGuard action={ACTION.CREATE} subject={SUBJECT.BOREHOLE}>
            <Fab
              position="absolute"
              onPress={onAddClick}
              size="sm"
              icon={<Icon name="addplus32" />}
              _web={{ position: 'fixed' }}
              renderInPortal={false}
            />
          </PermissionGuard>
        )
      }
    >
      <BoreholeTableContainer facilityId={facilityId} />
    </Page>
  );
});

BoreholesPage.displayName = BoreholesPage.name;
