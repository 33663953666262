import React, { FC, memo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { HStack, IconButton, Input, Square } from 'native-base';

import { BoreholeLayerSampleItem } from '../../../models/BoreholeLayerSample';
import { stringifyNumber } from '../../../utils/units';
import Icon from '../../icon';
import { SampleSelect } from '../sample-select';
import { SampleListItemProps, Text } from './sample-list-item';

export interface SampleListEditableItemProps<T extends string = 'samples'> extends SampleListItemProps {
  name: T;
  control: Control<{ [key in T]: Array<BoreholeLayerSampleItem> }>;
  value?: BoreholeLayerSampleItem;
  index: number;
  onRemove: (id: number) => void;
}

export const SampleListEditableItem: FC<SampleListEditableItemProps> = memo((props) => {
  const { name, control, index, onRemove } = props;
  return (
    <HStack space={2} alignItems="center">
      <Text>{index + 1}.</Text>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => <SampleSelect value={value} onValueChange={onChange} />}
        name={`${name}.${index}.type`}
      />
      <Controller
        control={control}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <Input
            size="xs"
            width={16}
            type="number"
            isInvalid={invalid}
            keyboardType="numeric"
            value={stringifyNumber(value)}
            onChangeText={onChange}
          />
        )}
        name={`${name}.${index}.depthFrom`}
      />
      <Text>—</Text>
      <Controller
        control={control}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <Input
            size="xs"
            width={16}
            isInvalid={invalid}
            type="number"
            keyboardType="numeric"
            value={stringifyNumber(value)}
            onChangeText={onChange}
          />
        )}
        name={`${name}.${index}.depthTo`}
      />
      <IconButton
        variant="outline"
        px={0}
        py={0}
        onPress={() => onRemove(index)}
        icon={
          <Square size={8}>
            <Icon name="del" />
          </Square>
        }
      />
    </HStack>
  );
});

SampleListEditableItem.displayName = SampleListEditableItem.name;
