import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { BlankPage } from '../../components/blank';
import { LoadingIndicator } from '../../components/loadable';
import { useComponent } from '../../hooks/useComponent';
import { useComponentReady } from '../../hooks/useComponentReady';
import { mapGeneralListStore } from '../../stores';
import { getGeneralMapId } from './utils';

type MapGeneralViewContainerProps = {
  facilityId: number;
};

export const MapGeneralViewContainer: FC<MapGeneralViewContainerProps> = observer((props) => {
  const { facilityId } = props;
  const { items: maps, isLoading } = mapGeneralListStore;

  const { MapView } = useComponent();

  const ready = useComponentReady(() => {
    mapGeneralListStore.setFacilityId(facilityId);
    mapGeneralListStore.loadItems().catch();
  }, [facilityId]);

  if (!ready) {
    return null;
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (maps.length === 0) {
    return <BlankPage mode="warning" />;
  }

  return <MapView id={getGeneralMapId(facilityId)} maps={maps} />;
});
