import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { CollectionDtoMap, CollectionDtoMapIO } from '../components/schemas/CollectionDtoMap';
import { Map, MapIO } from '../components/schemas/Map';
import { MapCreateRequest, MapCreateRequestIO } from '../components/schemas/MapCreateRequest';
import { MapUpdateRequest, MapUpdateRequestIO } from '../components/schemas/MapUpdateRequest';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, unknown, undefined, union, literal, type } from 'io-ts';

export interface MapController<F> {
	readonly getMap: (id: number) => HKT<F, Map>;

	readonly updateMap: (id: number, parameters: { body: MapUpdateRequest }) => HKT<F, Map>;

	readonly deleteMap: (id: number) => HKT<F, unknown>;

	readonly createMap: (parameters: { body: MapCreateRequest }) => HKT<F, Map>;

	readonly listOfMapsByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => HKT<F, CollectionDtoMap>;
}

export interface MapController1<F extends URIS> {
	readonly getMap: (id: number) => Kind<F, Map>;

	readonly updateMap: (id: number, parameters: { body: MapUpdateRequest }) => Kind<F, Map>;

	readonly deleteMap: (id: number) => Kind<F, unknown>;

	readonly createMap: (parameters: { body: MapCreateRequest }) => Kind<F, Map>;

	readonly listOfMapsByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind<F, CollectionDtoMap>;
}

export interface MapController2<F extends URIS2> {
	readonly getMap: (id: number) => Kind2<F, Error, Map>;

	readonly updateMap: (id: number, parameters: { body: MapUpdateRequest }) => Kind2<F, Error, Map>;

	readonly deleteMap: (id: number) => Kind2<F, Error, unknown>;

	readonly createMap: (parameters: { body: MapCreateRequest }) => Kind2<F, Error, Map>;

	readonly listOfMapsByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind2<F, Error, CollectionDtoMap>;
}

export function mapController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): MapController2<F>;
export function mapController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): MapController1<F>;
export function mapController<F>(e: { httpClient: HTTPClient<F> }): MapController<F>;
export function mapController<F>(e: { httpClient: HTTPClient<F> }): MapController<F> {
	return {
		getMap: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/map/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'map',
					operation: 'getMap',
					method: 'GET',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						MapIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		updateMap: (id, parameters) => {
			const body = MapUpdateRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/map/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'map',
					operation: 'updateMap',
					method: 'PUT',
					responseType,
					pathParameters: [id],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						MapIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		deleteMap: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/map/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'map',
					operation: 'deleteMap',
					method: 'DELETE',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						unknown.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createMap: parameters => {
			const body = MapCreateRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/map/create`,
					controller: 'map',
					operation: 'createMap',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						MapIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listOfMapsByFacilityId: (facilityId, parameters) => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([union([literal('id'), literal('name'), literal('lastUpdatedAt')]), undefined]).encode(
						parameters.query['sortBy'],
					),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/map/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'map',
					operation: 'listOfMapsByFacilityId',
					method: 'GET',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoMapIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
