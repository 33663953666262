import { ColumnDefinition } from '../../../components/table/table.model';
import { i18n } from '../../../i18n/i18next';
import { FacilitySortColumn } from '../../../models/Facility';
import { FacilityTableEntity } from '../../../stores/facility-table';

export const general: ColumnDefinition<FacilityTableEntity, FacilitySortColumn>[] = [
  {
    key: 'id',
    header: '№',
    width: 60,
    sortBy: 'id'
  },
  {
    key: 'name',
    header: i18n.t('facility.table.name'),
    sortBy: 'name'
  },
  {
    key: 'cypher',
    header: i18n.t('facility.table.cypher'),
    width: 120
  }
];

export const boreholes: ColumnDefinition<FacilityTableEntity, FacilitySortColumn>[] = [
  {
    key: 'boreholesTarget',
    header: i18n.t('facility.table.boreholesTarget'),
    width: 100
  },
  {
    key: 'boreholesActual',
    header: i18n.t('facility.table.boreholesActual'),
    width: 100
  }
];

export const additional: ColumnDefinition<FacilityTableEntity, FacilitySortColumn>[] = [
  {
    key: 'status',
    header: i18n.t('facility.table.status'),
    width: 110,
    sortBy: 'status'
  },
  {
    key: 'responsibleUserName',
    header: i18n.t('facility.table.responsibleUserName'),
    width: 200,
    sortBy: 'responsible'
  },
  {
    key: 'lastUpdatedAt',
    header: i18n.t('facility.table.lastUpdatedAt'),
    width: 70,
    sortBy: 'lastUpdatedAt'
  }
];

export const columns: ColumnDefinition<FacilityTableEntity, FacilitySortColumn>[] = [
  ...general,
  ...boreholes,
  ...additional
];
