import { DefaultTheme } from '@react-navigation/native';

import { Route } from './types';

export const defaultRoute: Route = {
  name: 'Login'
};

export const defaultAuthedRoute: Route = {
  name: 'Facilities'
};

export const navigatorTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: '#fff'
  }
};
