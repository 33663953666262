import React, { ReactElement, useState, cloneElement } from 'react';
import { HStack, Pressable, Text, Box } from 'native-base';

import { ActionView } from '../action';
import { TableIdentifiedItem, ColumnDefinition, CustomRenderers, Actions } from './table.model';

export type TableRowProps<T extends TableIdentifiedItem, S extends string = never> = {
  item: T;
  columns: Array<ColumnDefinition<T, S>>;
  index: number;
  height?: number;
  alignment?: 'flex-start' | 'flex-end' | 'center';
  isSelected?: boolean;
  onClick?: (id: T['id']) => void;
  customRenderers?: CustomRenderers<T>;
  actions?: Actions<T>;
  actionsWidth?: number;
};

export const TableRow = <T extends TableIdentifiedItem, S extends string = never>(
  props: TableRowProps<T, S>
): ReactElement => {
  const {
    item,
    columns,
    index,
    height = 14,
    alignment = 'center',
    isSelected = false,
    onClick,
    customRenderers,
    actions,
    actionsWidth = 0
  } = props;

  const [hover, setHover] = useState(false);

  const handlePress = () => onClick?.(item.id);
  const handleHoverIn = () => setHover(true);
  const handleHoverOut = () => setHover(false);

  const filteredActions = actions
    ? actions.filter((action) => {
        const { isVisible } = action;
        return typeof isVisible === 'function' ? isVisible(item) : true;
      })
    : [];

  return (
    <Pressable onPress={handlePress} onHoverIn={handleHoverIn} onHoverOut={handleHoverOut}>
      <HStack
        space={4}
        py={2}
        minHeight={height}
        backgroundColor={isSelected || hover ? '#e9eeff' : undefined}
        borderBottomWidth={1}
        borderBottomColor="#dbdeea"
        alignItems={alignment}
      >
        {columns.map((column, i) => {
          const key = `${column.key}-${index}-${i}`;
          const paddingLeft = i === 0 ? 4 : 0;
          const paddingRight = i - 1 === columns.length ? 4 : 0;
          const { width, height: cellHeight, transformValue } = column;

          const itemProps = {
            key,
            paddingLeft,
            paddingRight,
            flex: width ? undefined : 1,
            width,
            height: cellHeight,
            justifyContent: 'center'
          };

          const customRenderer = customRenderers?.[column.key];
          if (customRenderer) {
            return <Box {...itemProps}>{cloneElement(customRenderer(item, index) as React.ReactElement)}</Box>;
          }

          let value: ReactElement | string = '';
          const rawValue = item[column.key];
          const transformedValue = transformValue ? transformValue(rawValue) : rawValue;
          if (typeof transformedValue === 'string' || typeof transformedValue === 'number') {
            value = <Text>{transformedValue}</Text>;
          }

          return <Box {...itemProps}>{value}</Box>;
        })}
        <HStack space={2} paddingRight={4} width={actionsWidth} flex={actionsWidth ? undefined : 1} alignItems="center">
          {filteredActions.map((action, i) => {
            const key = `${action.id}-${index}-${i}`;
            return <ActionView key={key} item={item} {...action} />;
          })}
        </HStack>
      </HStack>
    </Pressable>
  );
};
