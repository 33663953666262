import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { View } from 'native-base';

import { BlankPage } from '../../components/blank';
import { Loadable } from '../../components/loadable';
import { Pagination } from '../../components/pagination/pagination';
import { useComponentReady } from '../../hooks/useComponentReady';
import { mapListStore } from '../../stores';

import { MapListComponent } from './map-list.component';

type MapListContainerProps = {
  facilityId: number;
};

export const MapListContainer: React.FC<MapListContainerProps> = observer((props) => {
  const { facilityId } = props;
  const { items: maps, isLoading, error, page, total, perPage } = mapListStore;

  const ready = useComponentReady(() => {
    mapListStore.setFacilityId(facilityId);
    mapListStore.loadItems().catch();
  }, [facilityId]);

  const { t } = useTranslation();

  if (!ready) {
    return null;
  }

  const onChangePage = (p: number) => {
    const newPage = p - 1;
    mapListStore.paginate(newPage).catch((e) => console.error(e));
  };

  return (
    <Loadable isLoading={isLoading} error={!!error}>
      {maps.length === 0 ? (
        <BlankPage mode="warning" text={t('map.no-items')} />
      ) : (
        <>
          <View flex={1}>
            <MapListComponent maps={maps} facilityId={facilityId} />
          </View>
          <Pagination current={page} total={total} perPage={perPage} onChange={onChangePage} />
        </>
      )}
    </Loadable>
  );
});
