import React, { FC, useState } from 'react';
import { Pressable, HStack, Popover, List, ChevronDownIcon, ChevronUpIcon } from 'native-base';
import { BoreholeSampleAcceptType } from './borehole-sample-accept-type';
import { AcceptedStatus, AcceptedType } from './types';

const items: AcceptedStatus[] = [AcceptedStatus.Undefined, AcceptedStatus.Accepted, AcceptedStatus.Rejected];

type AcceptSelectProps = {
  value: AcceptedType;
  onValueChange: (value: AcceptedStatus) => void;
};

export const BoreholeSampleAcceptSelect: FC<AcceptSelectProps> = ({ value, onValueChange }) => {
  const [isOpen, updateOpen] = useState<boolean>(false);

  const onChange = (newValue: AcceptedStatus) => {
    onValueChange(newValue);
    handleClose();
  };

  const handleOpen = () => {
    updateOpen(true);
  };

  const handleClose = () => {
    updateOpen(false);
  };

  const Anchor = (
    <HStack
      px={3}
      alignItems="center"
      justifyContent="center"
      borderWidth={1}
      borderColor="light.light-5"
      rounded="lg"
      space={3}
      height={8}
    >
      <BoreholeSampleAcceptType type={value} isVisibleLabel={false} />
      {isOpen ? <ChevronUpIcon size={5} /> : <ChevronDownIcon color="#B4B6C9" size={5} />}
    </HStack>
  );

  const size = items.length;
  const lastIndex = size - 1;

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="bottom left"
      trigger={(triggerProps) => <Pressable {...triggerProps}>{Anchor}</Pressable>}
    >
      <Popover.Content borderColor="#dbdeea" backgroundColor="#fff" style={{ width: 200 }}>
        <Popover.Body m={0} p={0} borderTopWidth={0}>
          <List borderWidth={0} p={0} m={0}>
            {items.map((item, index) => (
              <List.Item
                borderWidth={0}
                px={1}
                height={12}
                onPress={() => onChange(item)}
                borderBottomWidth={index === lastIndex ? undefined : 1}
                _hover={{ backgroundColor: '#e9eeff' }}
                borderBottomColor="#dbdeea"
                key={`sample-${item}`}
              >
                <BoreholeSampleAcceptType type={item} />
              </List.Item>
            ))}
          </List>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

BoreholeSampleAcceptSelect.displayName = 'AcceptSelect';
