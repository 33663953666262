import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { BlankPage } from '../../../components/blank';
import { ConfirmationPopup } from '../../../components/confirmation-popup';
import { Loadable } from '../../../components/loadable';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { FacilityStatus } from '../../../models/Facility';
import { screens } from '../../../navigation/config';
import { appStore, facilityStore } from '../../../stores';

import { FacilityForm } from '../facility-form/facility-form';
import { FacilityFormData } from '../facility-form/facility-form.model';

export const FacilityCreateContainer = observer(() => {
  const { users: responsibleList, currentUser } = appStore;
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const [isOpen, setOpen] = useState<boolean>(false);

  if (responsibleList.length === 0) {
    return <BlankPage mode="warning" />;
  }

  const onSubmit = (formData: FacilityFormData) => {
    const { name, cypher, responsibleUserId, geologistsUsersIds } = formData;
    facilityStore.create({ name, cypher, responsibleUserId, geologistsUsersIds }).catch((e) => console.error(e));
  };

  const onCancel = () => {
    setOpen(true);
  };

  const onCancelConfirm = () => {
    setOpen(false);
    navigation.navigate(screens.Facilities, { page: undefined });
  };

  const formValue = {
    responsibleUserId: responsibleList[0]?.id,
    name: '',
    cypher: '',
    status: 'NEW' as FacilityStatus,
    creationId: currentUser?.id || 0
  };

  const creationList = currentUser
    ? [
        {
          id: currentUser.id,
          name: currentUser.name
        }
      ]
    : [];

  return (
    <Loadable isLoading={facilityStore.loading('create')}>
      <FacilityForm
        onCancel={onCancel}
        isCreation
        responsibleList={responsibleList}
        creationList={creationList}
        value={formValue}
        onSubmit={onSubmit}
        geologistList={responsibleList}
      />
      <ConfirmationPopup
        isOpen={isOpen}
        handleConfirmButton={onCancelConfirm}
        handleCloseButton={() => {
          setOpen(false);
        }}
      >
        {t('facility.form.create.cancel.confirmation')}
      </ConfirmationPopup>
    </Loadable>
  );
});
