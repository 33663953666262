import React, { useRef, useMemo } from 'react';
import { MapViewComponent } from '@geo/commons/utils/components/types';
import { Map } from '@geo/commons/models/Map';

// https://github.com/Leaflet/Leaflet/issues/4968
import L, { Map as LeafletMap, LatLngBounds } from 'leaflet';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { MapContainer, Tooltip, Marker, Popup, Pane, Rectangle, TileLayer } from 'react-leaflet';

import {
  tileOnlineUrlTemplate,
  defaultMaxZoomLevel,
  mapLatitudePaddingMeters,
  mapLongitudePaddingMeters
} from '@geo/commons/features/map/constants';

import { MapPoint } from '@geo/commons/features/map/types';
import { getMapCenterPoint, getMapBoundingBox, extendMapBoundingBox } from '@geo/commons/features/map/utils';

import './map-view.css';
import { getBounds } from './utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl; // eslint-disable-line no-underscore-dangle
L.Icon.Default.mergeOptions({ iconUrl, iconRetinaUrl, shadowUrl });

const debugBounds = false;

const boundsRectangleStyle = { zIndex: 500 };

const getClassNameByStatus = (status: Map['status']) => {
  let postfixClassName = '';
  switch (status) {
    case 'CLOSED':
      postfixClassName = 'closed';
      break;
    default:
      break;
  }
  return postfixClassName;
};

const createMarker = (status: Map['status']) => {
  const classNameByStatus = getClassNameByStatus(status);
  return L.divIcon({
    className: `circle-marker-icon ${classNameByStatus} `,
    iconSize: [16, 16],
    iconAnchor: [8, 8],
    popupAnchor: [0, -9]
  });
};

export const MapView: MapViewComponent = (props) => {
  const { maps, caption } = props;

  const mapRef = useRef<LeafletMap>();
  const centerPoint = useMemo<MapPoint>(() => getMapCenterPoint(maps), [maps]);
  const bounds = useMemo<LatLngBounds>(() => {
    const boundingBox = getMapBoundingBox(maps);
    const extendedBoundingBox = extendMapBoundingBox(boundingBox, mapLatitudePaddingMeters, mapLongitudePaddingMeters);
    return getBounds(extendedBoundingBox);
  }, [maps]);

  const className = caption ? 'map-view map-view map-view-with-caption' : 'map-view';

  return (
    <>
      <div className={className}>
        <MapContainer
          center={[centerPoint.latitude, centerPoint.longitude]}
          bounds={bounds}
          boundsOptions={{ padding: [0, 0] }}
          maxZoom={defaultMaxZoomLevel}
          scrollWheelZoom
          whenCreated={(map: LeafletMap) => {
            mapRef.current = map;
          }}
        >
          <TileLayer url={tileOnlineUrlTemplate} />
          {debugBounds && (
            <Pane name="bounds" style={boundsRectangleStyle}>
              <Rectangle bounds={bounds} pathOptions={{ color: 'yellow', fillOpacity: 0.1, weight: 2 }} />
            </Pane>
          )}
          {maps.map((map) => {
            const { id, name, status, coordinate } = map;
            const { latitude, longitude } = coordinate;
            const description =
              `${latitude.toFixed(4)}° ${latitude >= 0 ? 'N' : 'S'}, ` +
              `${longitude.toFixed(4)}° ${longitude >= 0 ? 'E' : 'W'}`;

            const markerIcon = createMarker(status);
            const classNameByStatus = getClassNameByStatus(status);
            return (
              <Marker key={id} position={[latitude, longitude]} icon={markerIcon}>
                <Tooltip
                  className={`${classNameByStatus} marker-tooltip`}
                  direction="top"
                  offset={[0, -5]}
                  opacity={1}
                  permanent
                >
                  {name}
                </Tooltip>
                <Popup>
                  <b>{name}</b>
                  <br />
                  {description}
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      </div>
      {caption && <div className="map-view-caption">{caption}</div>}
    </>
  );
};
