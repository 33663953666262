import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { BoreholeForm } from '../borehole-form/borehole-form';
import { BoreholeFormData } from '../borehole-form/borehole-form.model';
import { BoreholeFormRendererAdmin } from '../borehole-form/components/borehole-form-renderer-admin';

import { ConfirmationPopup } from '../../../components/confirmation-popup';
import { Loadable } from '../../../components/loadable';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { FacilityStatus } from '../../../models/Facility';
import { screens } from '../../../navigation/config';
import { appStore, boreholeStore } from '../../../stores';
import { metersToCentimeters, metersToCentimetersOptional, pipeCaseMetersToCentimeters } from '../../../utils/units';

interface BoreholeCreateProps {
  facilityId: number;
}

export const BoreholeCreate: FC<BoreholeCreateProps> = observer((props) => {
  const { facilityId } = props;
  const { currentUser } = appStore;
  if (!currentUser) {
    return null;
  }

  const { t } = useTranslation();
  const navigation = useAppNavigation();

  const [isOpen, setOpen] = useState<boolean>(false);

  const onSubmit = useCallback(
    (data: BoreholeFormData) => {
      const {
        name,
        status,
        latitude,
        longitude,
        depthTarget,
        depthActual,
        pipeCase1,
        pipeCase2,
        pipeCase3,
        technologicalExpansion
      } = data;

      const params = {
        facilityId,
        name,
        isMetal: false,
        isPlastic: false,
        depthTarget: metersToCentimeters(depthTarget),
        depthActual: metersToCentimetersOptional(depthActual),
        status: status as 'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED',
        coordinate: { latitude, longitude },
        pipeCase: pipeCaseMetersToCentimeters({ pipeCase1, pipeCase2, pipeCase3 }),
        technologicalExpansion:
          typeof technologicalExpansion === 'number' ? metersToCentimeters(technologicalExpansion) : undefined
      };

      boreholeStore.create(params).catch((e) => console.error(e));
    },
    [facilityId]
  );

  const onCancel = () => {
    setOpen(true);
  };

  const onCancelConfirm = () => {
    setOpen(false);
    navigation.navigate(screens.Boreholes, { facilityId });
  };

  const onCancelCancel = () => {
    setOpen(false);
  };

  const defaultValue: Partial<BoreholeFormData> = {
    creatorId: currentUser.id,
    status: 'NEW' as FacilityStatus
  };

  const creationList = currentUser ? [{ id: currentUser.id, name: currentUser.name }] : [];

  return (
    <Loadable isLoading={boreholeStore.loading('create')} error={!!boreholeStore.error('create')}>
      <BoreholeForm
        Renderer={BoreholeFormRendererAdmin}
        creationList={creationList}
        defaultValue={defaultValue}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <ConfirmationPopup isOpen={isOpen} handleConfirmButton={onCancelConfirm} handleCloseButton={onCancelCancel}>
        {t('borehole.form.create.cancel.confirmation')}
      </ConfirmationPopup>
    </Loadable>
  );
});
