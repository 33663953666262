import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { Tabs } from '../../../components/tabs';
import { CommentsContainer } from '../../../features/comments/container/comments';
import { PageProps } from '../../types';

type CommentsPageProps = PageProps<'Comments'>;

export const CommentsPage: FC<CommentsPageProps> = ({ route }) => {
  const { params } = route;
  const facilityId = params?.facilityId;

  if (!facilityId) {
    return null;
  }

  return (
    <Page header={<Tabs facilityId={facilityId} />} scrollable={false}>
      <CommentsContainer facilityId={facilityId} />
    </Page>
  );
};

CommentsPage.displayName = CommentsPage.name;
