export function stringifyNumber(value?: number, defaultResult = ''): string {
  return value === undefined || Number.isNaN(value) ? defaultResult : `${value}`;
}

export function stringifyFractional(value?: number, fractionDigits?: number, defaultResult = ''): string {
  if (value === undefined || Number.isNaN(value)) {
    return defaultResult;
  }
  if (fractionDigits === undefined) {
    return `${value}`;
  }
  return value.toFixed(fractionDigits).replace(/\.?0*$/, '');
}

export function centimetersToMeters(cm: number): number {
  return cm / 100;
}

export function centimetersToMetersOptional(cm?: number): number | undefined {
  return cm ? cm / 100 : undefined;
}

export function metersToCentimeters(m: number): number {
  return Math.round(m * 100);
}

export function metersToCentimetersOptional(m?: number): number | undefined{
  return m ? Math.round(m * 100) : undefined;
}

type PipeCaseCentimeters = Partial<Record<'TYPE_1' | 'TYPE_2' | 'TYPE_3', number>>;
type PipeCaseMeters = Partial<Record<'pipeCase1' | 'pipeCase2' | 'pipeCase3', number>>;

export function pipeCaseCentimetersToMeters(pipeCaseCentimeters?: PipeCaseCentimeters): PipeCaseMeters | undefined {
  const { TYPE_1: pipeCase1, TYPE_2: pipeCase2, TYPE_3: pipeCase3 } = pipeCaseCentimeters || {};
  const pipeCaseMeters: PipeCaseMeters = {};
  if (typeof pipeCase1 === 'number') {
    pipeCaseMeters.pipeCase1 = centimetersToMeters(pipeCase1);
  }
  if (typeof pipeCase2 === 'number') {
    pipeCaseMeters.pipeCase2 = centimetersToMeters(pipeCase2);
  }
  if (typeof pipeCase3 === 'number') {
    pipeCaseMeters.pipeCase3 = centimetersToMeters(pipeCase3);
  }
  return Object.keys(pipeCaseMeters).length > 0 ? pipeCaseMeters : undefined;
}

export function pipeCaseMetersToCentimeters(pipeCaseMeters?: PipeCaseMeters): PipeCaseCentimeters | undefined {
  const { pipeCase1, pipeCase2, pipeCase3 } = pipeCaseMeters || {};
  const pipeCaseCentimeters: PipeCaseCentimeters = {};
  if (typeof pipeCase1 === 'number') {
    pipeCaseCentimeters.TYPE_1 = metersToCentimeters(pipeCase1);
  }
  if (typeof pipeCase2 === 'number') {
    pipeCaseCentimeters.TYPE_2 = metersToCentimeters(pipeCase2);
  }
  if (typeof pipeCase3 === 'number') {
    pipeCaseCentimeters.TYPE_3 = metersToCentimeters(pipeCase3);
  }
  return Object.keys(pipeCaseCentimeters).length > 0 ? pipeCaseCentimeters : undefined;
}
