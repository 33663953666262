import React from 'react';
import { Controller } from 'react-hook-form';
import { HStack, Text, Input } from 'native-base';

import { CustomRenderers } from '../../../components/table/table.model';
import { BoreholeLayer } from '../../../models/BoreholeLayer';
import { Form } from '../../../utils/form';
import { stringifyNumber } from '../../../utils/units';

import { BoreholeLayerFormData } from './borehole-layer.model';
import { BoreholeLayerTableGeoDescriptionCell } from './borehole-layer-table-geo-description-cell';
import { BoreholeLayerTableSampleCell } from './borehole-layer-table-sample-cell';

export const getFormRenderers = (
  form: Form<BoreholeLayerFormData>,
  perPage: number,
  page: number
): CustomRenderers<BoreholeLayer> => {
  const { control } = form;
  return {
    id: (item, index = 0) => {
      const order = index + 1 + perPage * page;
      return <Text>{order}</Text>;
    },
    depthFrom: ({ canEdit }) => (
      <HStack space={2} alignItems="center">
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <Input
              size="xs"
              width={16}
              type="number"
              keyboardType="numeric"
              isInvalid={invalid}
              onChangeText={onChange}
              isDisabled={!canEdit}
              value={stringifyNumber(value)}
            />
          )}
          name="depthFrom"
        />
        <Text>—</Text>
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <Input
              size="xs"
              width={16}
              type="number"
              keyboardType="numeric"
              isInvalid={invalid}
              isDisabled={!canEdit}
              onChangeText={onChange}
              value={stringifyNumber(value)}
            />
          )}
          name="depthTo"
        />
      </HStack>
    ),
    gwlEmerged: () => (
      <Controller
        control={control}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <Input
            size="xs"
            width={16}
            type="number"
            keyboardType="numeric"
            isInvalid={invalid}
            onChangeText={onChange}
            value={stringifyNumber(value)}
          />
        )}
        name="gwlEmerged"
      />
    ),
    gwlSteady: () => (
      <Controller
        control={control}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <Input
            size="xs"
            width={16}
            type="number"
            keyboardType="numeric"
            isInvalid={invalid}
            onChangeText={onChange}
            value={stringifyNumber(value)}
          />
        )}
        name="gwlSteady"
      />
    ),
    geoDescription: () => <BoreholeLayerTableGeoDescriptionCell form={form} />,
    samples: () => <BoreholeLayerTableSampleCell form={form} />
  };
};
