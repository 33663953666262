import { action, computed, override, runInAction } from 'mobx';

import { FacilityCreateRequest } from '@geo/api/generated/swagger.json/components/schemas/FacilityCreateRequest';
import { FacilityUpdateRequest } from '@geo/api/generated/swagger.json/components/schemas/FacilityUpdateRequest';

import { getApiClient } from '../api/api-client';
import { Facility } from '../models/Facility';

import { EntityStore } from './entity';

export type FacilityRequestType = 'create' | 'update' | 'archive' | 'remove' | 'markToDelete';

export class FacilityStore extends EntityStore<Facility, number, FacilityRequestType> {
  protected async fetchItem(id: number): Promise<Facility> {
    return getApiClient().facilityController.getFacility(id);
  }

  @override setItem(item: Facility | null): void {
    if (!item) {
      super.setItem(null);
    } else {
      super.setItem(item, item.id);
    }
  }

  @action create(params: FacilityCreateRequest): Promise<Facility> {
    return this.request(
      'create',
      () => getApiClient().facilityController.createFacility({ body: params }),
      (facility) => this.setItem(facility)
    );
  }

  @action update(id: number, params: FacilityUpdateRequest): Promise<Facility> {
    return this.request(
      'update',
      () => getApiClient().facilityController.updateFacility(id, { body: params }),
      (facility) => this.setItem(facility)
    );
  }

  @action archive(id: number, archive: boolean): Promise<Facility> {
    return this.request(
      'archive',
      () => {
        return getApiClient().facilityController.setStatus(id, {
          body: { status: archive ? 'ARCHIVED' : 'CLOSED' }
        });
      },
      (facility) => this.setItem(facility)
    );
  }

  @action async remove(id: number): Promise<void> {
    await this.request(
      'remove',
      () => getApiClient().facilityController.deleteFacility(id),
      () => this.setItem(null)
    );
  }

  @action setMarkToDelete(id: number, markToDelete: boolean): Promise<Facility> {
    return this.request(
      'markToDelete',
      () => getApiClient().facilityController.setMarkToDelete(id, markToDelete),
      (facility) => this.setItem(facility)
    );
  }

  @computed
  get isArchived(): boolean {
    return this.item?.status === 'ARCHIVED';
  }

  @computed
  get isDeleted(): boolean {
    return this.item?.markToDelete || false;
  }
}
