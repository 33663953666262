import { makeObservable, computed, configure, observable, action, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CollectionStore } from './collection';
import { defaultPerPage } from '../constants';

configure({ enforceActions: 'observed' });

export type SortDirection = 'ASC' | 'DESC';

interface BaseTable<Entity = unknown, SortColumn = string> extends CollectionStore<Entity> {
  isLoading: boolean;
  total: number;
  selectedId?: number;
  order?: SortDirection;
  sortBy?: SortColumn;
}

type CollectionDto<T> = { result: Array<T>; size: number };

export interface Query<SortColumn> {
  page: number | undefined;
  size: number | undefined;
  sortBy: SortColumn | undefined;
  order: SortDirection | undefined;
}

export interface FetchListParameter<SortColumn> {
  query: Query<SortColumn>;
}

export type FetchListFn<Entity, SortColumn> = (
  parameters: FetchListParameter<SortColumn>
) => Promise<CollectionDto<Entity>>;

export type FetchListWithIdFn<Entity, SortColumn> = (
  id: number,
  parameters: FetchListParameter<SortColumn>
) => Promise<CollectionDto<Entity>>;

export abstract class BaseTableStore<Entity, SortColumn>
  extends CollectionStore<Entity>
  implements BaseTable<Entity, SortColumn>
{
  storageKey: string;

  @observable sortBy?: SortColumn = undefined;

  @observable order: SortDirection = 'DESC';

  @observable selectedId?: number = undefined;

  constructor(storageKey: string) {
    super();
    makeObservable(this);
    this.storageKey = storageKey;
  }

  async init(): Promise<void> {
    await super.init();
    await makePersistable(this, {
      name: `${this.storageKey}Table`,
      properties: ['page', 'sortBy', 'order', 'selectedId'],
      storage: AsyncStorage
    });
  }

  @action setSort(sortBy: SortColumn, order: SortDirection): void {
    this.sortBy = sortBy;
    this.order = order;
  }

  @computed
  get storedQuery(): Query<SortColumn> {
    return {
      page: this.page,
      sortBy: this.sortBy,
      order: this.order,
      size: this.perPage
    };
  }

  @computed
  get sortTableProp() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      column: this.sortBy as any,
      sortOrder: this.order
    };
  }

  @action public updateSort = (sortBy: SortColumn, order: SortDirection) => {
    runInAction(() => {
      this.sortBy = sortBy;
      this.order = order;
    });
    this.loadItems();
  };

  @action public updatePage = (page: number) => {
    this.page = page;
    this.loadItems();
  };

  @action reset(): void {
    this.page = 0;
    this.perPage = defaultPerPage;
    this.total = 0;
    this.isLoading = false;
    this.items = [];
    this.sortBy = undefined;
    this.order = 'DESC';
  }

  @action updateSelection = (id: number | undefined): void => {
    this.selectedId = id;
  };

  @action async refresh(): Promise<void> {
    await this.loadItems();
  }
}
