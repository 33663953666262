import { View, Input, Box, HStack, Image, Text, VStack, Button } from 'native-base';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { NativeSyntheticEvent, Platform, TextInputChangeEventData } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Panel } from '../panel/panel';

import { ConfirmationPopup } from '../confirmation-popup';
import { FileType, UploadValue } from '../../stores/file';
import Icon from '../icon/icon';
import { IconName } from '../icon';

interface UploaderEditProps {
  fileType: FileType;
  value: UploadValue;
  previewUrl?: string;
  setValue: (value: UploadValue | null) => void;
}

export const UploaderEdit: FC<UploaderEditProps> = ({ fileType, value, setValue, previewUrl }) => {
  const [errors] = useState<Partial<Record<keyof UploadValue, string>>>({});

  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const requiredErrorText = t('form.error.required');

  const onCancelConfirm = () => {
    setValue(null);
  };

  const onReplace = () => {
    setOpen(true);
  };

  const changeDescription = useCallback(
    (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
      const newValues = { ...value };
      // @ts-ignore Почему-то считает, что typeof target === number
      const inputValue: string = e.target.value;
      if (inputValue) {
        newValues.description = inputValue;
        errors.description = undefined;
      } else {
        errors.description = requiredErrorText;
      }
      setValue(newValues);
    },
    [value, requiredErrorText]
  );

  const previewUri = useMemo(() => {
    if (Platform.OS === 'web') {
      return previewUrl || (value.file ? URL.createObjectURL(value.file) : '');
    }
    return '';
  }, [value.file, previewUrl]);

  if (Platform.OS === 'android') return null;
  let headerIcon: IconName = 'drawing';
  if (fileType === 'photo') {
    headerIcon = 'image';
  }
  return (
    <View mt={2}>
      <Panel
        headerIcon={headerIcon}
        headerLeft={
          <HStack flex={1}>
            <Input
              size="sm"
              isInvalid={!!errors.description}
              value={value.description}
              onChange={changeDescription}
              placeholder={t('facility.form.fields.name.placeholder')}
              isFullWidth
            />
          </HStack>
        }
      >
        <HStack pt={6} pb={6}>
          <VStack space={3} flex={1}>
            <HStack>
              <Box display="flex" alignItems="center" width={300}>
                {fileType === 'document' || fileType === 'codificator' ? (
                  <Box height={120} width={100} justifyContent="center" alignItems="center" bgColor="light.light-3">
                    <Icon name="documentIcon" />
                  </Box>
                ) : (
                  <Image alt={value.description} height={120} width={100} source={{ uri: previewUri }} />
                )}
                <Text>{value.description}</Text>
              </Box>
              <Box justifyContent="center">
                <Button onPress={onReplace} variant="outline">
                  {t('uploader.form.replace-button')}
                </Button>
              </Box>
            </HStack>
          </VStack>
        </HStack>
      </Panel>

      <ConfirmationPopup
        isOpen={isOpen}
        handleConfirmButton={onCancelConfirm}
        handleCloseButton={() => {
          setOpen(false);
        }}
      >
        {t('uploader.confirm-replace', 'Вы уверены, что хотите заменить файл? Все данные будут удалены')}
      </ConfirmationPopup>
    </View>
  );
};
