import React, { FC, useMemo } from 'react';
import { VStack, Box, HStack, Text, IconButton } from 'native-base';

import { Panel } from '../../components/panel/panel';
import Icon from '../../components/icon';
import { Notification } from '../../models/Notification';
import { dateTimeFormatLocale } from '../../utils/date';
import { getNotificationDisplay, navigateTo, NotificationDisplay } from './utils';

export interface NotificationItemProps {
  notification: Notification;
}

export const NotificationItem: FC<NotificationItemProps> = (props) => {
  const { notification } = props;
  const { viewed } = notification;

  const { title, time, userName, userRoles, content } = useMemo<NotificationDisplay>(
    (): NotificationDisplay => getNotificationDisplay(notification),
    [notification]
  );

  const panelProp = !viewed ? { borderColor: '#06c270', borderWidth: 3 } : { borderWidth: 1 };
  const handlePress = () => {
    navigateTo(notification);
  };

  return (
    <Panel
      headerIcon="info"
      headerLeft={
        <Text fontWeight={500} fontSize="md" color="primary.default">
          {title}
        </Text>
      }
      {...panelProp}
      headerRight={<IconButton onPress={handlePress} icon={<Icon name="forward" />} />}
    >
      <VStack pt={3} pb={6}>
        <HStack space={11} height={14} alignItems="center">
          <HStack alignItems="center" space={4}>
            <Text color="dark.dark-0" fontWeight={500} bg="#e9eeff" borderRadius="lg" px={2} py={1}>
              {userName}
            </Text>
            {userRoles.map((role) => (
              <Text key={`role-${role}`} color="dark.dark-2" fontSize={13}>
                {role}
              </Text>
            ))}
          </HStack>
          <HStack flexShrink={1} width="100%" alignItems="center" justifyContent="flex-end">
            <HStack space={6} alignItems="center">
              <Text fontSize={12} opacity={0.56} fontWeight={500}>
                {dateTimeFormatLocale(time)}
              </Text>
            </HStack>
          </HStack>
        </HStack>
        <Box>{content}</Box>
      </VStack>
    </Panel>
  );
};

NotificationItem.displayName = NotificationItem.name;
