import React, { useState } from 'react';
import { Box, IconButton } from 'native-base';
import { observer } from 'mobx-react';
import { BoreholesPrintComponent } from '@geo/commons/utils/components/types';
import { PrintButton } from 'components/print-button/print-button';
import Icon from '@geo/commons/components/icon';
import { BoreholePrint, BoreholePrintItem } from 'features/borehole-print/borehole-print';
import { getApiClient } from '@geo/commons/api/api-client';

export const BoreholesPrintContainer = observer<BoreholesPrintComponent>(({ facilityId }) => {
  const [templateData, setTemplateData] = useState<BoreholePrintItem[]>([]);
  const beforePrint = async () => {
    const { result } = await getApiClient().boreholeController.boreholeListAll(facilityId);
    setTemplateData(result);
  };

  return (
    <Box>
      <PrintButton
        beforePrint={beforePrint}
        buttonTrigger={<IconButton variant="outline" w={10} h={10} icon={<Icon name="print" />} />}
      >
        <BoreholePrint items={templateData} />
      </PrintButton>
    </Box>
  );
});

BoreholesPrintContainer.displayName = 'BoreholesPrintComponent';
