import { DependencyList, useState } from 'react';
import { useNavigationEffect, NavigationEffectCallback } from './useNavigationEffect';

export const useComponentReady = <T extends NavigationEffectCallback>(
  callback: T,
  deps: DependencyList = []
): boolean => {
  const [ready, setReady] = useState(false);
  useNavigationEffect((): any => {
    const result = callback(); // eslint-disable-line @typescript-eslint/no-unsafe-assignment
    setReady(true);
    return result;
  }, deps);
  return ready;
};
