import React from 'react';
import { VStack } from 'native-base';
import { Comment } from './comment';

interface CommentsProps {
  comments: Comment[];
  currentUserId?: number;
  onEdit: (comment: Comment) => void;
  onDelete: (commentId: number) => void;
}

export const Comments: React.FC<CommentsProps> = ({ comments, currentUserId, onEdit, onDelete }) => {
  return (
    <VStack space={6} bg="#fff" borderWidth={1} borderRadius="lg" borderColor="#dbdeea" px={6} pt={3} pb={6}>
      {comments.map((comment) => (
        <Comment
          {...comment}
          key={`comment${comment.id}`}
          currentUserId={currentUserId}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </VStack>
  );
};
