import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { observer } from 'mobx-react';
import { useLinkTo } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Center, Input, Text, VStack, Button, Heading } from 'native-base';

import { appName } from '@geo/commons/constants';

import Icon from '../../components/icon';
import { PasswordInput } from '../../components/password-input';
import { appStore } from '../../stores';
import { LoginFormData, schema, maxLength } from './login-form.model';

type LoginProps = {
  onLogin: (loginData: { email: string; password: string }) => void;
  isLoggingIn: boolean;
  wrongCredentials: boolean;
};

const Login: React.FC<LoginProps> = ({ onLogin, isLoggingIn, wrongCredentials }) => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<LoginFormData>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      email: '',
      password: ''
    },
    mode: 'all'
  });

  const onSubmit = (data: LoginFormData) => {
    onLogin(data);
  };

  const linkTo = useLinkTo();
  const requiredErrorText = t('form.error.required');
  const handleChangeText = (onChange: (value: string) => void) => (newValue: string) =>
    onChange(newValue.substring(0, maxLength));

  return (
    <Center flex={1}>
      <Center flex={1}>
        <Heading fontSize={32} mb={70}>
          {appName}
        </Heading>
        <VStack space={4} width={320}>
          <Center>
            <Text color="error">{wrongCredentials ? t('login.global-error') : '  '}</Text>
          </Center>
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
              <Input
                size="md"
                isFullWidth
                value={value}
                isInvalid={invalid || wrongCredentials}
                InputLeftElement={
                  <Icon
                    style={{
                      marginLeft: 4
                    }}
                    name="user"
                  />
                }
                onChangeText={handleChangeText(onChange)}
                placeholder={t('login.fields.name')}
              />
            )}
            name="email"
            rules={{ required: requiredErrorText }}
          />
          <Controller
            control={control}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
              <PasswordInput
                isInvalid={invalid || wrongCredentials}
                value={value}
                onValueChange={handleChangeText(onChange)}
                placeholder={t('login.fields.password')}
              />
            )}
            name="password"
            rules={{ required: requiredErrorText }}
          />
          <Button
            isLoading={isLoggingIn}
            height={14}
            marginTop={4}
            marginBottom={4}
            _text={{ fontSize: 16, fontWeight: 500 }}
            onPress={handleSubmit(onSubmit)}
          >
            {t('login.buttons.submit')}
          </Button>
          <Button size="lg" variant="ghost" onPress={() => linkTo('/auth/restore')}>
            {t('login.buttons.forgot')}
          </Button>
        </VStack>
      </Center>
    </Center>
  );
};

export const LoginContainer = observer(() => {
  const { isLoggingIn, loginWrongCredentials } = appStore;
  const onLogin = (data: LoginFormData) => {
    const { email, password } = data;
    appStore.login(email, password).catch((e) => console.error(e));
  };
  return <Login onLogin={onLogin} isLoggingIn={isLoggingIn} wrongCredentials={loginWrongCredentials} />;
});
