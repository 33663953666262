import React, { PropsWithChildren, useState } from 'react';
import { LoadingIndicator } from './loadable';
import { ConfirmationPopup } from './confirmation-popup';

export interface CancelableProps {
  readonly onCancel: () => void;
}

interface CancelableComponentProps<P extends CancelableProps> {
  children: React.ReactElement<P>;
  cancelConfirmationText?: string;
  pending?: boolean;
}

export const Cancelable = <P extends CancelableProps>(props: PropsWithChildren<CancelableComponentProps<P>>) => {
  const { children, pending, cancelConfirmationText } = props;
  const [isOpen, setOpen] = useState<boolean>(false);
  const { onCancel: originOnCancel } = children.props;

  const onCancel = () => {
    setOpen(true);
  };

  const onCancelConfirm = () => {
    setOpen(false);
    originOnCancel();
  };

  const onCancelCancel = () => {
    setOpen(false);
  };

  if (pending) {
    return <LoadingIndicator />;
  }

  return cancelConfirmationText ? (
    <>
      {React.cloneElement(children, {
        ...children.props,
        onCancel
      })}
      <ConfirmationPopup isOpen={isOpen} handleConfirmButton={onCancelConfirm} handleCloseButton={onCancelCancel}>
        {cancelConfirmationText}
      </ConfirmationPopup>
    </>
  ) : (
    children
  );
};
