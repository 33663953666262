import React from 'react';
import { observer } from 'mobx-react';
import { View } from 'native-base';

import { BlankPage } from '../../components/blank';
import { Loadable } from '../../components/loadable';
import { Pagination } from '../../components/pagination/pagination';
import { useComponentReady } from '../../hooks/useComponentReady';
import { photosStore } from '../../stores';
import { PhotoList } from './photo-list';

interface PhotoListContainerProps {
  facilityId: number;
}

export const PhotoListContainer: React.FC<PhotoListContainerProps> = observer((props) => {
  const { facilityId } = props;
  const { isLoading, facilityId: persistFacilityId, error, items, page, perPage, total } = photosStore;
  const isBlank = items.length === 0;

  const ready = useComponentReady(() => {
    if (persistFacilityId !== facilityId) {
      photosStore.setFacilityId(facilityId);
      photosStore.setPage(0);
    }
    photosStore.loadItems().catch((e) => console.error(e));
  }, [facilityId]);

  if (!ready) {
    return null;
  }

  const onChangePage = (p: number) => {
    const newPage = p - 1;
    photosStore.paginate(newPage).catch((e) => console.error(e));
  };

  return (
    <Loadable isLoading={isLoading} error={!!error}>
      {isBlank ? (
        <BlankPage mode="warning" text="Нет загруженных фотографий" />
      ) : (
        <>
          <View flex={1} flexDirection="column">
            <PhotoList facilityId={facilityId} data={items} />
          </View>
          <Pagination current={page} total={total} perPage={perPage} onChange={onChangePage} />
        </>
      )}
    </Loadable>
  );
});
