import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { Tabs } from '../../../components/tabs';
import { MapViewContainer } from '../../../features/map/map-view.container';
import { PageProps } from '../../types';

type MapViewPageProps = PageProps<'MapView'>;

export const MapViewPage: FC<MapViewPageProps> = ({ route }) => {
  const { params } = route;
  const { facilityId, mapId } = params;
  if (!mapId) {
    return null;
  }
  return (
    <Page header={<Tabs facilityId={facilityId} />} scrollable={false}>
      <MapViewContainer mapId={mapId} />
    </Page>
  );
};
