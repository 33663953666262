import { BoreholeTemperature, BoreholeTemperatureIO } from '../../components/schemas/BoreholeTemperature';
import { Coordinate, CoordinateIO } from '../../components/schemas/Coordinate';
import { Facility, FacilityIO } from '../../components/schemas/Facility';
import { User, UserIO } from '../../components/schemas/User';
import { number, undefined, union, string, literal, array, record, boolean, type } from 'io-ts';

export type Borehole = {
	id: number;
	createdAt: number;
	lastUpdatedAt: number | undefined;
	facility: Facility;
	name: string;
	status: 'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED';
	coordinate: Coordinate;
	depthTarget: number;
	depthActual: number | undefined;
	creator: User;
	technologicalExpansion: number | undefined;
	temperatures: Array<BoreholeTemperature> | undefined;
	pipeCase: { [key: string]: number } | undefined;
	sign: boolean | undefined;
	signerId: number | undefined;
	signAt: number | undefined;
	isMetal: boolean;
	isPlastic: boolean;
};
export const BoreholeIO = type(
	{
		id: number,
		createdAt: number,
		lastUpdatedAt: union([number, undefined]),
		facility: FacilityIO,
		name: string,
		status: union([literal('NEW'), literal('IN_PROGRESS'), literal('PROBLEM'), literal('CLOSED')]),
		coordinate: CoordinateIO,
		depthTarget: number,
		depthActual: union([number, undefined]),
		creator: UserIO,
		technologicalExpansion: union([number, undefined]),
		temperatures: union([array(BoreholeTemperatureIO), undefined]),
		pipeCase: union([record(string, number), undefined]),
		sign: union([boolean, undefined]),
		signerId: union([number, undefined]),
		signAt: union([number, undefined]),
		isMetal: boolean,
		isPlastic: boolean,
	},
	'Borehole',
);
