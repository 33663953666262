import { Tab } from '../../../components/tabs';
import { screens } from '../../config';
import { i18n } from '../../../i18n/i18next';

export const createTabs = (boreholeId: number, facilityId: number, isClosed: boolean): Tab[] => [
  {
    id: 'general',
    icon: 'description',
    title: i18n.t('borehole.tabs.general'),
    route: screens.BoreholeView,
    routeParams: {
      boreholeId,
      facilityId
    }
  },
  {
    id: 'boreholeLayers',
    icon: 'layers',
    title: i18n.t('borehole.tabs.journal'),
    route: screens.BoreholeLayers,
    routeParams: {
      boreholeId,
      facilityId
    }
  },
  {
    id: 'boreholeTemperatures',
    icon: 'layers',
    disabled: !isClosed,
    title: i18n.t('borehole.tabs.temperature'),
    route: screens.BoreholeTemperatures,
    routeParams: {
      boreholeId,
      facilityId
    }
  }
];
