import { Button, Text } from 'native-base';
import React, { useState } from 'react';
import { ACTION } from '../utils/permissions';
import { usePermission } from '../utils/permissions/context';
import { ActionView } from './action';
import { ConfirmationPopup } from './confirmation-popup';
import Icon from './icon/icon';

interface EntityLike {
  id: number;
}

interface DeleteButtonProps<P> {
  item: P;
  subject: string;
  deleteConfirmationText: string;
  buttonText?: string;
  markToDeleteConfirmationText: string;
  onMark: (id: number) => void;
  onDelete: (id: number) => void;
}

export const DeleteButton = <P extends EntityLike>(props: DeleteButtonProps<P>) => {
  const [isOpen, updateOpen] = useState<boolean>(false);
  const { item, subject, onMark, onDelete, deleteConfirmationText, markToDeleteConfirmationText, buttonText } = props;
  const permission = usePermission();
  // TODO: remove permission check from button
  const canDelete = permission.can(ACTION.DELETE, subject);
  const canMarkToDelete = permission.can(ACTION.MARK_TO_DELETE, subject);
  if (!canDelete && !canMarkToDelete) {
    return null;
  }

  const handleOpen = () => {
    updateOpen(true);
  };

  const handleClose = () => {
    updateOpen(false);
  };

  const handleConfirmButton = () => {
    if (canDelete) {
      onDelete(item.id);
    } else {
      onMark(item.id);
    }

    handleClose();
  };

  const confirmationText = canDelete ? deleteConfirmationText : markToDeleteConfirmationText;

  return (
    <>
      {buttonText ? (
        <Button
          bgColor="error"
          variant="unstyled"
          onPress={handleOpen}
          startIcon={<Icon name="deleted" fill="white" />}
          color="white"
        >
          <Text color="white" fontWeight={600} fontSize={16}>
            {buttonText}
          </Text>
        </Button>
      ) : (
        <ActionView id="delete" actionCallback={handleOpen} item={item} iconName="delete" />
      )}
      <ConfirmationPopup isOpen={isOpen} handleConfirmButton={handleConfirmButton} handleCloseButton={handleClose}>
        <Text>{confirmationText}</Text>
      </ConfirmationPopup>
    </>
  );
};
