import React, { FC } from 'react';
import { View, Link as NativeBaseLink } from 'native-base';

import { navigate } from '../navigation';
import { RootStackParamList } from '../navigation/types';

type NavigationLinkProps = {
  title: string;
  route: keyof RootStackParamList;
  routeParams?: RootStackParamList[keyof RootStackParamList];
};

export const NavigationLink: FC<NavigationLinkProps> = (props) => {
  const { title, route, routeParams } = props;

  const onPress = () => {
    navigate(route, routeParams);
  };

  const textStyle = { color: 'blue.700' };
  const textHoverStyle = { _text: { textDecorationLine: 'underline' } };

  return (
    <View flexDirection="row">
      <NativeBaseLink
        onPress={onPress}
        flexShrink={1}
        overflow="hidden"
        _text={textStyle}
        _hover={textHoverStyle}
        isUnderlined={false}
      >
        {title}
      </NativeBaseLink>
    </View>
  );
};
