import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { subject } from '@casl/ability';

import { BlankPage } from '../../../components/blank';
import { ConfirmationPopup } from '../../../components/confirmation-popup';
import { Loadable } from '../../../components/loadable';
import { Actions, Sort } from '../../../components/table/table.model';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useComponent } from '../../../hooks/useComponent';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { Borehole, BoreholeSortColumn } from '../../../models/Borehole';
import { screens } from '../../../navigation/config';
import { appStore, boreholeStore, boreholeTableStore } from '../../../stores';
import { ACTION, ROLE, SUBJECT } from '../../../utils/permissions';
import { usePermission } from '../../../utils/permissions/context';

import { BoreholeTable } from './borehole-table';

const signText =
  'Вы уверены, что хотите подписать эту Скважину, и она точно готова? Отметить действие смогут только в Кабинете Офиса';
const unsignText = 'Вы уверены, что хотите снять подпись со Скважины и отправить её в работу?';

type BoreholeTableContainerProps = {
  facilityId: number;
};

export const BoreholeTableContainer = observer((props: BoreholeTableContainerProps) => {
  const { facilityId } = props;
  const { isLoading, sortTableProp, total, items, page, perPage } = boreholeTableStore;

  const { currentUser } = appStore;

  const navigation = useAppNavigation();
  const permission = usePermission();

  const [isOpenForSign, updateOpenForSign] = useState<boolean>(false);
  const [isOpen, updateOpen] = useState<boolean>(false);
  const [currentBoreholeId, setBoreholeId] = useState<number | null>(null);
  const popUpText = currentUser?.roles.includes(ROLE.Geologist) ? signText : unsignText;
  const { BoreholePrint } = useComponent();

  const ready = useComponentReady(() => {
    boreholeTableStore.setFacilityId(facilityId);
    boreholeTableStore.loadItems().catch();
  }, [facilityId]);

  if (!ready) {
    return null;
  }

  const handleOpen = () => {
    updateOpen(true);
  };
  const handleClose = () => {
    updateOpen(false);
  };
  const handleRemoveButton = () => {
    if (currentBoreholeId) {
      boreholeStore.remove(currentBoreholeId).catch((e) => console.error(e));
    }
    handleClose();
  };

  const onRowClick = (id: number) => {
    boreholeTableStore.updateSelection(id);
  };

  const onChangePage = (p: number) => {
    const newPage = p - 1;
    boreholeTableStore.updatePage(newPage);
  };

  const onChangeSort = (sort: Sort<BoreholeSortColumn>) => {
    boreholeTableStore.updateSort(sort?.column, sort?.sortOrder);
  };

  const handleCloseForSign = () => {
    updateOpenForSign(false);
  };

  const handleSignBotton = () => {
    if (currentBoreholeId) {
      if (currentUser?.roles.includes(ROLE.Geologist)) {
        boreholeStore.sign(currentBoreholeId).catch((e) => console.error(e));
      } else {
        boreholeStore.unsign(currentBoreholeId).catch((e) => console.error(e));
      }
      handleCloseForSign();
    }
  };

  const actionsList: Actions<Borehole> = [
    {
      id: 'print',
      customRenderer: ({ id }) => <BoreholePrint boreholeId={id} />,
      isVisible: () => permission.can(ACTION.PRINT, SUBJECT.BOREHOLE)
    },
    {
      id: 'edit',
      iconName: 'edit',
      actionCallback: (item) => {
        navigation.navigate(screens.BoreholeUpdate, { boreholeId: item.id, facilityId });
      },
      isVisible: (item) =>
        permission.can(
          ACTION.EDIT,
          subject(SUBJECT.BOREHOLE, {
            status: item?.status,
            markToDelete: item?.facility.markToDelete || false,
            facilityStatus: item?.facility?.status
          })
        )
    },
    {
      id: 'delete',
      iconName: 'delete',
      actionCallback: (item) => {
        setBoreholeId(item.id);
        handleOpen();
      },
      isVisible: (item) =>
        permission.can(
          ACTION.DELETE,
          subject(SUBJECT.BOREHOLE, {
            status: item?.status,
            markToDelete: item?.facility.markToDelete || false,
            facilityStatus: item?.facility?.status
          })
        )
    },
    {
      id: 'unsign',
      iconName: 'removeSign',
      actionCallback: (item) => {
        updateOpenForSign(true);
        setBoreholeId(item?.id);
      },
      isVisible: (item) =>
        permission.can(
          ACTION.REMOVE_SIGN,
          subject(SUBJECT.BOREHOLE, {
            status: item?.sign,
            markToDelete: item?.facility.markToDelete || false,
            facilityStatus: item?.facility?.status
          })
        )
    },
    {
      id: 'sign',
      iconName: 'addSign',
      actionCallback: (item) => {
        updateOpenForSign(true);
        setBoreholeId(item?.id);
      },
      isVisible: (item) => permission.can(ACTION.ADD_SIGN, subject(SUBJECT.BOREHOLE, { status: item?.sign }))
    }
  ];

  if (!isLoading && items.length === 0) {
    return <BlankPage mode="warning" />;
  }

  return (
    <>
      <ConfirmationPopup isOpen={isOpen} handleConfirmButton={handleRemoveButton} handleCloseButton={handleClose}>
        Вы уверены, что хотите удалить Скважину без возможности восстановления?
      </ConfirmationPopup>
      <ConfirmationPopup
        isOpen={isOpenForSign}
        handleConfirmButton={handleSignBotton}
        handleCloseButton={handleCloseForSign}
      >
        {popUpText}
      </ConfirmationPopup>
      <Loadable isLoading={isLoading || boreholeStore.anyLoading}>
        <BoreholeTable
          items={items}
          total={total}
          page={page}
          perPage={perPage}
          sort={sortTableProp}
          onRowClick={onRowClick}
          onChangePage={onChangePage}
          onChangeSort={onChangeSort}
          actions={actionsList}
        />
      </Loadable>
    </>
  );
});
