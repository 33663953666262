import { observable, makeObservable, action, computed } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { BaseStore } from './base';

export type AppMode = 'online' | 'offline';
export type AppModeIntermetiateStage = 'selection' | 'download' | 'upload' | null;
export type AppModeOfflineLoadingStage = 'db' | 'tile' | null;
export type AppModeError = 'token' | 'db-download' | 'db-upload' | 'db-connection' | 'tile-download' | null;

export class AppModeStore extends BaseStore {
  @observable mode: AppMode = 'online';

  @observable intermediateStage: AppModeIntermetiateStage = null;

  @observable offlineLoadingStage: AppModeOfflineLoadingStage = null;

  @observable progress = 0;

  @observable error: AppModeError = null;

  @observable interrupted = false;

  @observable.ref abortController: AbortController | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  async init(): Promise<void> {
    await super.init();
    await makePersistable(this, {
      name: 'AppMode',
      properties: ['mode'],
      storage: AsyncStorage
    });
  }

  @action
  setMode(mode: AppMode): void {
    this.mode = mode;
    this.intermediateStage = null;
    this.offlineLoadingStage = null;
    this.progress = 0;
    this.error = null;
    this.interrupted = false;
    this.abortController = null;
  }

  @action
  setIntermediateStage(intermediateStage: AppModeIntermetiateStage): void {
    this.intermediateStage = intermediateStage;
    this.offlineLoadingStage = null;
    this.progress = 0;
  }

  @action
  setOfflineLoadingStage(offlineLoadingStage: AppModeOfflineLoadingStage, canInterrupt = false): void {
    this.offlineLoadingStage = offlineLoadingStage;
    this.progress = 0;
    this.error = null;
    this.interrupted = false;
    this.abortController = canInterrupt ? new AbortController() : null;
  }

  @action
  setProgress(progress: number): void {
    if (progress > 1) {
      this.progress = 1;
    } else if (progress < 0) {
      this.progress = 0;
    } else {
      this.progress = progress;
    }
  }

  @action
  interrupt(): void {
    this.interrupted = true;
    this.abortController?.abort();
    this.abortController = null;
  }

  @action
  setError(error: AppModeError): void {
    this.error = error;
    this.intermediateStage = null;
    this.progress = 0;
  }

  @computed
  get isOffline(): boolean {
    return this.mode === 'offline' && this.intermediateStage === null;
  }

  @computed
  get isOnline(): boolean {
    return this.mode === 'online' && this.intermediateStage === null;
  }

  @computed
  get canInterrupt(): boolean {
    return !!this.abortController && this.progress < 1;
  }
}
