import React, { FC, cloneElement, ReactElement, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Box } from 'native-base';

import { pageStyle } from './common-page-style';

interface PrintButtonProps {
  children: ReactElement;
  buttonTrigger: ReactElement;
  beforePrint: () => Promise<void>;
}

export const PrintButton: FC<PrintButtonProps> = ({ beforePrint, children, buttonTrigger }) => {
  const componentRef = useRef(null);
  const divStyle = { display: 'none' };
  const invokePrint = useReactToPrint({
    content: () => componentRef?.current,
    pageStyle
  });

  const handlePrint = async () => {
    if (invokePrint) {
      await beforePrint();
      invokePrint();
    }
  };

  return (
    <Box>
      {cloneElement(buttonTrigger, { onPress: handlePrint })}
      <div style={divStyle}>{cloneElement(children, { ref: componentRef })}</div>
    </Box>
  );
};

PrintButton.displayName = 'PrintButton';
