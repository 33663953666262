import { facilityTableStore } from '../stores';

import {
  RootStackParamList,
  ScreenName,
  ScreenConfig,
  ScreenConfigItem,
  InvertedScreenConfig,
  BreadcrumbsConfig
} from './types';

export const screens = {
  Login: 'Login',
  Audit: 'Audit',
  Restore: 'Restore',
  Facilities: 'Facilities',
  FacilitiesArchived: 'FacilitiesArchived',
  FacilitiesDeleted: 'FacilitiesDeleted',
  FacilityView: 'FacilityView',
  FacilityCreate: 'FacilityCreate',
  FacilityUpdate: 'FacilityUpdate',
  Boreholes: 'Boreholes',
  BoreholeView: 'BoreholeView',
  BoreholeCreate: 'BoreholeCreate',
  BoreholeUpdate: 'BoreholeUpdate',
  BoreholeLayers: 'BoreholeLayers',
  BoreholeSamples: 'BoreholeSamples',
  BoreholeTemperatures: 'BoreholeTemperatures',
  MapList: 'MapList',
  MapGeneralView: 'MapGeneralView',
  MapView: 'MapView',
  Photos: 'Photos',
  PhotosUpload: 'PhotosUpload',
  PhotoEdit: 'PhotoEdit',
  PhotoCard: 'PhotoCard',
  Documents: 'Documents',
  Codificator: 'Codificator',
  CodificatorTranslationsUpload: 'CodificatorTranslationsUpload',
  CodificatorUpload: 'CodificatorUpload',
  DocumentUpload: 'DocumentUpload',
  DocumentEdit: 'DocumentEdit',
  Comments: 'Comments',
  Notification: 'Notification'
} as const;

export const screensConfig: ScreenConfig = {
  [screens.Audit]: '/audit',
  [screens.Login]: '/auth/login',
  [screens.Restore]: '/auth/restore',
  [screens.Facilities]: '/facilities',
  [screens.FacilitiesArchived]: '/facilities/archive',
  [screens.FacilitiesDeleted]: '/facilities/deleted',
  [screens.FacilityView]: {
    path: '/facilities/:facilityId',
    parse: { facilityId: Number }
  },
  [screens.FacilityCreate]: '/facilities/create',
  [screens.FacilityUpdate]: {
    path: '/facilities/:facilityId/update',
    parse: { facilityId: Number }
  },
  [screens.Boreholes]: '/facilities/:facilityId/boreholes',
  [screens.BoreholeView]: {
    path: '/facilities/:facilityId/boreholes/:boreholeId',
    parse: { facilityId: Number, boreholeId: Number }
  },
  [screens.BoreholeCreate]: {
    path: '/facilities/:facilityId/boreholes/create',
    parse: { facilityId: Number }
  },
  [screens.BoreholeUpdate]: {
    path: '/facilities/:facilityId/boreholes/:boreholeId/update',
    parse: { facilityId: Number, boreholeId: Number }
  },
  [screens.BoreholeLayers]: {
    path: '/facilities/:facilityId/boreholes/:boreholeId/geological-description',
    parse: { facilityId: Number, boreholeId: Number }
  },
  [screens.BoreholeSamples]: {
    path: '/facilities/:facilityId/borehole-samples',
    parse: { facilityId: Number }
  },
  [screens.BoreholeTemperatures]: {
    path: '/facilities/:facilityId/boreholes/:boreholeId/temperatures',
    parse: { facilityId: Number, boreholeId: Number }
  },
  [screens.MapList]: {
    path: '/facilities/:facilityId/maps',
    parse: { facilityId: Number }
  },
  [screens.MapGeneralView]: {
    path: '/facilities/:facilityId/maps/general',
    parse: { facilityId: Number }
  },
  [screens.MapView]: {
    path: '/facilities/:facilityId/maps/:mapId',
    parse: { facilityId: Number, mapId: Number }
  },
  [screens.Photos]: {
    path: '/facilities/:facilityId/photos',
    parse: { facilityId: Number }
  },
  [screens.PhotoEdit]: {
    path: '/facilities/:facilityId/photos/:photoId/edit',
    parse: { facilityId: Number, photoId: Number }
  },
  [screens.PhotosUpload]: {
    path: '/facilities/:facilityId/photos/upload',
    parse: { facilityId: Number }
  },
  [screens.PhotoCard]: {
    path: '/facilities/:facilityId/photos/:photoId',
    parse: { facilityId: Number, photoId: Number }
  },
  [screens.Documents]: {
    path: '/facilities/:facilityId/documents',
    parse: { facilityId: Number }
  },
  [screens.DocumentUpload]: {
    path: '/facilities/:facilityId/documents/upload',
    parse: { facilityId: Number }
  },
  [screens.DocumentEdit]: {
    path: '/facilities/:facilityId/documents/:documentId',
    parse: { facilityId: Number, documentId: Number }
  },
  [screens.Comments]: '/facilities/:facilityId/comments',
  [screens.Notification]: '/notifications',
  [screens.Codificator]: '/codificator',
  [screens.CodificatorTranslationsUpload]: '/codificator/translations-upload',
  [screens.CodificatorUpload]: '/codificator/upload'
};

const keys = Object.keys(screensConfig) as ScreenName[];
export const invertedScreensConfig = keys.reduce((acc: InvertedScreenConfig, key: ScreenName) => {
  const value: ScreenConfigItem = screensConfig[key];
  const path = typeof value === 'string' ? value : value.path;
  acc[path] = key;
  return acc;
}, {});

export const breadcrumbsConfig: BreadcrumbsConfig = {
  [screens.Audit]: {
    label: 'Аналитика'
  },
  [screens.Codificator]: 'Кодификатор',
  [screens.CodificatorUpload]: 'Обновление',
  [screens.CodificatorTranslationsUpload]: 'Обновление переводов',
  [screens.Facilities]: {
    label: 'Объекты',
    before: (): void => {
      facilityTableStore.setPage(0);
    }
  },
  [screens.FacilitiesArchived]: 'Архивированные объекты',
  [screens.FacilitiesDeleted]: 'Удаленные объекты',
  [screens.FacilityView]: ({ facilityId }: RootStackParamList[typeof screens.FacilityView]) => {
    return `Объект №${facilityId}`;
  },
  [screens.FacilityCreate]: 'Новый объект',
  [screens.FacilityUpdate]: ({ facilityId }: RootStackParamList[typeof screens.FacilityUpdate]) =>
    `Объект №${facilityId}`,
  [screens.Boreholes]: 'Скважины',
  [screens.BoreholeView]: ({ boreholeId }: RootStackParamList[typeof screens.BoreholeView]) =>
    `Скважина ID ${boreholeId}`,
  [screens.BoreholeCreate]: 'Новая скважина',
  [screens.BoreholeUpdate]: ({ boreholeId }: RootStackParamList[typeof screens.BoreholeUpdate]) =>
    `Скважина №${boreholeId}`,
  [screens.BoreholeSamples]: 'Список образцов',
  [screens.BoreholeLayers]: 'Геолог. описание',
  [screens.BoreholeTemperatures]: 'Температура',
  [screens.MapList]: 'Карты',
  [screens.MapGeneralView]: 'Общая карта',
  [screens.MapView]: 'Карта скважины',
  [screens.Photos]: 'Фотографии',
  [screens.PhotosUpload]: 'Добавление',
  [screens.PhotoCard]: ({ photoId }: RootStackParamList[typeof screens.PhotoCard]) => `Фото №${photoId}`,
  [screens.PhotoEdit]: 'Добавление',
  [screens.Documents]: 'Документы',
  [screens.DocumentUpload]: 'Добавление',
  [screens.DocumentEdit]: 'Редактирование',
  [screens.Comments]: 'Комментарии',
  [screens.Notification]: {
    label: 'Уведомления',
    prepend: screens.Facilities
  }
};
