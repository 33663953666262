import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'native-base';
import { subject } from '@casl/ability';

import { ConfirmationPopup } from '../../../components/confirmation-popup';
import { DeleteButton } from '../../../components/delete-button';
import { LoadingError, LoadingIndicator } from '../../../components/loadable';
import { Actions } from '../../../components/table/table.model';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { screens } from '../../../navigation/config';
import { facilityStore } from '../../../stores';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { usePermission } from '../../../utils/permissions/context';

import { FacilityCard, NarrowFacility } from './facility-card';
import { StatusActions } from './status-actions';
import { useComponent } from '../../../hooks/useComponent';

interface FacilityCardContainerProps {
  facilityId: number;
}

export const FacilityCardContainer = observer((props: FacilityCardContainerProps) => {
  const { facilityId } = props;
  const { item: facility } = facilityStore;
  const { status } = facility || {};

  const { BoreholesPrint } = useComponent();

  const isArchived = Boolean(status === 'ARCHIVED');
  const markToDelete = Boolean(facility?.markToDelete);
  const showArchivedStatus = isArchived && !markToDelete;

  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const permission = usePermission();
  const [isOpen, updateOpen] = useState<boolean>(false);
  const [currentFacilityId, setFacilityId] = useState<number | null>(null);
  const [isOpenMarkToDelete, updateOpenMarkToDelete] = useState<boolean>(false);

  const ready = useComponentReady(() => {
    facilityStore.loadItem(facilityId).catch((e) => console.error(e));
  }, [facilityId]);

  if (!ready) {
    return null;
  }

  const handleOpen = () => {
    updateOpen(true);
  };

  const handleClose = () => {
    updateOpen(false);
  };

  const handleCloseMarkToDeleteModal = () => {
    updateOpenMarkToDelete(false);
  };

  const handleRemoveButton = () => {
    if (currentFacilityId) {
      facilityStore.remove(currentFacilityId).catch((e) => console.error(e));
    }
    handleClose();
  };

  const handleRestoreButton = () => {
    facilityStore.archive(facilityId, false).catch((e) => console.error(e));
  };

  const handleApproveDeleteButton = () => {
    facilityStore.remove(facilityId).catch((e) => console.error(e));
  };

  const handleCancelDeleteButton = () => {
    facilityStore.setMarkToDelete(facilityId, false).catch((e) => console.error(e));
  };

  const handleMarkToDeleteButton = () => {
    if (currentFacilityId) {
      facilityStore.setMarkToDelete(currentFacilityId, true).catch((e) => console.error(e));
    }
    handleCloseMarkToDeleteModal();
  };

  const actionsList: Actions<NarrowFacility> = [];

  if (permission.can(ACTION.PRINT, SUBJECT.BOREHOLE)) {
    actionsList.push({
      id: 'print',
      iconName: 'print',
      customRenderer: ({ id }) => {
        return <BoreholesPrint facilityId={id} />;
      }
    });
  }

  if (permission.can(ACTION.EDIT, subject(SUBJECT.FACILITY, { status, markToDelete }))) {
    actionsList.push({
      id: 'edit',
      iconName: 'edit',
      actionCallback: (item) => {
        navigation.navigate(screens.FacilityUpdate, { facilityId: item.id });
      }
    });
  }

  if (!markToDelete && !isArchived && permission.can(ACTION.MARK_TO_ARCHIVE, SUBJECT.FACILITY)) {
    actionsList.push({
      id: 'archive',
      iconName: 'archive',
      actionCallback: (item) => {
        facilityStore.archive(item.id, true).catch((e) => console.error(e));
      }
    });
  }

  if (!markToDelete && permission.can(ACTION.MARK_TO_DELETE, SUBJECT.FACILITY)) {
    actionsList.push({
      id: 'mark-to-delete',
      iconName: 'delete',
      actionCallback: (item) => {
        setFacilityId(item.id);
        updateOpenMarkToDelete(true);
      }
    });
  }

  if (!markToDelete && permission.can(ACTION.DELETE, SUBJECT.FACILITY)) {
    actionsList.push({
      id: 'delete',
      iconName: 'delete',
      actionCallback: (item) => {
        setFacilityId(item.id);
        handleOpen();
      }
    });
  }

  if (facilityStore.loading('get')) {
    return <LoadingIndicator />;
  }

  if (facilityStore.error('get')) {
    return <LoadingError />;
  }

  if (!facility) {
    return null;
  }

  return (
    <>
      {showArchivedStatus && permission.can(ACTION.MARK_TO_ARCHIVE, SUBJECT.FACILITY) && (
        <StatusActions iconName="archive" title="Объект заархивирован">
          <Button variant="outline" onPress={handleRestoreButton}>
            Восстановить
          </Button>
        </StatusActions>
      )}
      <ConfirmationPopup isOpen={isOpen} handleConfirmButton={handleRemoveButton} handleCloseButton={handleClose}>
        {t('deleteConfirmation.text')}
      </ConfirmationPopup>
      <ConfirmationPopup
        isOpen={isOpenMarkToDelete}
        handleConfirmButton={handleMarkToDeleteButton}
        handleCloseButton={handleCloseMarkToDeleteModal}
      >
        Объект будет отмечен «под удаление», но сохранён в системе. Полное удаление или отмену удаления сможет сделать
        только Руководитель. При подтверждении действия вы не сможете редактировать этот Объект. Вы уверены?
      </ConfirmationPopup>
      {markToDelete && permission.can(ACTION.DELETE, SUBJECT.FACILITY) && (
        <StatusActions iconName="del" title="Объект помечен «под удаление»">
          <Button.Group space={4}>
            <DeleteButton
              item={facility}
              onMark={() => ''}
              subject={SUBJECT.FACILITY}
              onDelete={handleApproveDeleteButton}
              markToDeleteConfirmationText=""
              buttonText={t('confirmation.buttons.confirm')}
              deleteConfirmationText={t('deleteConfirmation.text')}
            />
            <Button variant="outline" onPress={handleCancelDeleteButton}>
              Отменить
            </Button>
          </Button.Group>
        </StatusActions>
      )}
      <FacilityCard id={facilityId} actions={actionsList} data={facility} />
    </>
  );
});
