import React, { FC, useCallback, useMemo } from 'react';
import { useRoute } from '@react-navigation/native';
import { observer } from 'mobx-react';

import { HStack, Text, Button } from 'native-base';

import Icon, { IconName } from './icon';

import { i18n } from '../i18n/i18next';
import { useAppNavigation } from '../hooks/useAppNavigation';
import { screens } from '../navigation/config';
import { RootStackParamList } from '../navigation/types';

import { boreholeTableStore, commentsStore } from '../stores';

export interface Tab {
  id: string;
  icon: IconName;
  before?: () => void;
  route?: keyof RootStackParamList;
  routeParams?: Record<string, unknown>;
  title: string;
  disabled?: boolean;
}

interface TabsProps {
  facilityId?: number;
  tabs?: Tab[];
}

const createTabs = (facilityId: number): Tab[] => [
  {
    id: 'general',
    icon: 'description',
    title: i18n.t('facility.tabs.general'),
    route: 'FacilityView',
    routeParams: {
      facilityId
    }
  },
  {
    id: 'boreholes',
    icon: 'journal',
    title: i18n.t('facility.tabs.boreholes'),
    before: () => {
      boreholeTableStore.setPage(0);
    },
    route: 'Boreholes',
    routeParams: {
      facilityId
    }
  },
  {
    id: 'maps',
    icon: 'map',
    title: i18n.t('facility.tabs.maps'),
    route: 'MapList',
    routeParams: {
      facilityId
    }
  },
  {
    id: 'photos',
    icon: 'photo',
    title: i18n.t('facility.tabs.photos'),
    route: screens.Photos,
    routeParams: {
      facilityId
    }
  },
  {
    id: 'docs',
    icon: 'drawing',
    title: i18n.t('facility.tabs.docs'),
    route: screens.Documents,
    routeParams: {
      facilityId
    }
  },
  {
    id: 'samples',
    icon: 'sample',
    route: 'BoreholeSamples',
    title: i18n.t('facility.tabs.samples'),
    routeParams: {
      facilityId
    }
  }
];

export const Tabs: FC<TabsProps> = observer(({ tabs: tabsPassed, facilityId }) => {
  const route = useRoute();
  const navigation = useAppNavigation();

  const tabs = useMemo(() => {
    return facilityId ? createTabs(facilityId) : tabsPassed ?? [];
  }, [tabsPassed, facilityId]);

  const handleClick = useCallback(
    (tab: Tab) => {
      return () => {
        if (tab.route) {
          if (tab.route === route.name && JSON.stringify(tab.routeParams) === JSON.stringify(route.params)) {
            // We stay on the same page, no need to navigate
            return;
          }
          if (tab.before) {
            tab.before();
          }
          navigation.navigate(tab.route, tab.routeParams);
        }
      };
    },
    [tabs]
  );

  return (
    <HStack my={4} alignItems="center">
      <Button.Group space={4}>
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            size="lg"
            isDisabled={tab.disabled}
            variant={route.name === tab.route ? 'tabActive' : 'tab'}
            onPress={handleClick(tab)}
            disabled={tab.disabled}
            startIcon={<Icon name={tab.icon} />}
          >
            {tab.title}
          </Button>
        ))}
      </Button.Group>
      {facilityId && (
        <Button.Group space={4} flex={1} justifyContent="flex-end">
          <Button
            size="lg"
            variant={route.name === 'Comments' ? 'tabActive' : 'tab'}
            isLoading={commentsStore.isLoading}
            onPress={handleClick({
              icon: 'photo',
              id: '',
              title: '',
              route: screens.Comments,
              routeParams: {
                facilityId
              }
            })}
            startIcon={<Icon name="comments" />}
            endIcon={<Text color="green.default">{commentsStore.count}</Text>}
          >
            Комментарии
          </Button>
        </Button.Group>
      )}
    </HStack>
  );
});

Tabs.displayName = Tabs.name;
