import { Box, Circle, HStack, Text, IBoxProps, Pressable, Center, IconButton } from 'native-base';
import React, { FC, useCallback } from 'react';
import { Breadcrumbs } from './breadcrumbs';
import Icon from './icon';

interface NavigationInfo {
  prevId?: number;
  nextId?: number;
  currentIndex?: number;
}

interface ModalPageProps {
  onClose: () => void;
  onNavigate?: (id: number) => void;
  appendHeader?: React.ReactElement;
  total?: number;
  navigationInfo: NavigationInfo;
  id: number;
}

const circleButtonStyle: IBoxProps['style'] = {
  height: 64,
  width: 64,
  backgroundColor: '#F7F7FA',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const QuickViewPage: FC<ModalPageProps> = ({
  children,
  navigationInfo,
  onNavigate,
  onClose,
  appendHeader,
  total
}) => {
  const { prevId, nextId, currentIndex } = navigationInfo;

  const handlePrevPress = useCallback(() => {
    if (onNavigate && prevId) onNavigate(prevId);
  }, [onNavigate, prevId]);

  const handleNextPress = useCallback(() => {
    if (onNavigate && nextId) onNavigate(nextId);
  }, [onNavigate, nextId]);

  return (
    <Box>
      <HStack width="100%" alignItems="center" justifyContent="space-between" p={5}>
        <HStack alignItems="center">
          <IconButton mr={16.25} size="lg" onPress={onClose} icon={<Icon name="cancel" />} />
          <Breadcrumbs />
        </HStack>
        <HStack alignItems="center">
          <Text mr={5} fontWeight={500} fontSize={20}>
            {currentIndex} / {total}
          </Text>
          {appendHeader}
        </HStack>
      </HStack>
      <Center width="100%" minHeight={700}>
        <HStack width="100%" alignItems="center" justifyContent="center">
          <Pressable disabled={!prevId} onPress={handlePrevPress}>
            <Circle mr={10} style={circleButtonStyle}>
              <Icon name="arrowLeft" />
            </Circle>
          </Pressable>
          {children}
          <Pressable disabled={!nextId} onPress={handleNextPress}>
            <Circle ml={10} style={circleButtonStyle}>
              <Icon name="arrowRight" />
            </Circle>
          </Pressable>
        </HStack>
      </Center>
    </Box>
  );
};
