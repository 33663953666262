import React, { FC, useState } from 'react';
import { Pressable, HStack, Popover, List, Text, ChevronDownIcon, ChevronUpIcon } from 'native-base';

import { BoreholeLayerSampleType } from '../../models/BoreholeLayerSample';
import { SampleType } from './sample-type';

const items: BoreholeLayerSampleType[] = ['MONOLITH', 'WATER', 'DISTURBED'];

type SampleSelectProps = {
  placeholder?: string;
  isDisabled?: boolean;
  value?: BoreholeLayerSampleType;
  onValueChange: (value: BoreholeLayerSampleType) => void;
};

export const SampleSelect: FC<SampleSelectProps> = ({ placeholder, value, onValueChange, isDisabled }) => {
  const [isOpen, updateOpen] = useState<boolean>(false);

  const onChange = (newValue: BoreholeLayerSampleType) => {
    onValueChange(newValue);
    handleClose();
  };

  const handleOpen = () => {
    updateOpen(true);
  };
  const handleClose = () => {
    updateOpen(false);
  };

  const Anchor = (
    <HStack
      px={3}
      alignItems="center"
      justifyContent="center"
      borderWidth={1}
      bg={isDisabled ? '#f7f7f7' : '#fff'}
      borderColor="light.light-5"
      rounded="lg"
      height={8}
    >
      {value ? <SampleType type={value} isVisibleLabel={false} /> : <Text opacity={0.6}>{placeholder}</Text>}
      {isOpen ? <ChevronUpIcon size={5} /> : <ChevronDownIcon color="#B4B6C9" size={5} />}
    </HStack>
  );

  const size = items.length;
  const lastIndex = size - 1;

  return isDisabled ? (
    Anchor
  ) : (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="bottom left"
      trigger={(triggerProps) => <Pressable {...triggerProps}>{Anchor}</Pressable>}
    >
      <Popover.Content borderColor="#dbdeea" backgroundColor="#fff" style={{ width: 240 }}>
        <Popover.Body m={0} p={0} borderTopWidth={0}>
          <List borderWidth={0} p={0} m={0}>
            {items.map((item, index) => (
              <List.Item
                borderWidth={0}
                px={1}
                height={12}
                onPress={() => onChange(item)}
                borderBottomWidth={index === lastIndex ? undefined : 1}
                _hover={{ backgroundColor: '#e9eeff' }}
                borderBottomColor="#dbdeea"
                key={item}
              >
                <SampleType type={item} />
              </List.Item>
            ))}
          </List>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

SampleSelect.displayName = 'SampleSelect';
