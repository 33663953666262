import { Button, ScrollView, HStack } from 'native-base';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../components/icon';
import { Summary } from '../../components/summary';
import { Notification } from '../../models/Notification';
import { NotificationItem } from './notification-item';

interface NotificationListProps {
  data: Notification[];
  removeAll: () => void;
  readAll: () => void;
  countOfNew: number;
}

export const NotificationList: FC<NotificationListProps> = (props) => {
  const { t } = useTranslation();
  const { data, removeAll, readAll, countOfNew } = props;
  const summary = [
    {
      label: t('notification.list.summary.countOfNew'),
      value: countOfNew
    },
    {
      label: t('notification.list.summary.total'),
      value: data.length
    }
  ];
  return (
    <>
      <HStack alignItems="center">
        <Summary items={summary} />
        <HStack space={2} flex={1} justifyContent="flex-end">
          <Button onPress={readAll} startIcon={<Icon name="done" fill="#5b8def" />} variant="unstyled">
            {t('notification.list.action.readAll')}
          </Button>
          <Button onPress={removeAll} startIcon={<Icon name="del" />} variant="unstyled">
            {t('notification.list.action.removeAll')}
          </Button>
        </HStack>
      </HStack>

      <ScrollView flex={1}>
        {data.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} />
        ))}
      </ScrollView>
    </>
  );
};

NotificationList.displayName = NotificationList.name;
