import { getApiClient } from '../api/api-client';
import { Worker } from '../models/Worker';
import { CollectionStore, CollectionFetched } from './collection';

export class WorkerListStore extends CollectionStore<Worker> {
  async fetchItems(): Promise<CollectionFetched<Worker>> {
    const { result: items, size: total } = await getApiClient().workerController.listOfWorkers({
      query: {
        page: undefined,
        size: undefined,
        sortBy: undefined,
        order: undefined
      }
    });
    return { items, total };
  }
}
