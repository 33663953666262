import React, { FC } from 'react';
import { MapListContainer } from '../../../features/map/map-list.container';
import { Page } from '../../../components/page';
import { Tabs } from '../../../components/tabs';
import { PageProps } from '../../types';

type MapListPageProps = PageProps<'MapList'>;

export const MapListPage: FC<MapListPageProps> = ({ route }) => {
  const { params } = route;
  const facilityId = params?.facilityId;
  if (!facilityId) {
    return null;
  }
  return (
    <Page header={<Tabs facilityId={facilityId} />}>
      <MapListContainer facilityId={facilityId} />
    </Page>
  );
};
