import React, { FC, useState, useCallback } from 'react';
import { Input, IInputProps } from 'native-base';
import { useTranslation } from 'react-i18next';
import Icon from './icon';

type PasswordInputMode = 'password' | 'text';

type PasswordInputProp = IInputProps & {
  defaultMode?: PasswordInputMode;
  value: string;
  onValueChange?: (newValue: string) => void;
};

export const PasswordInput: FC<PasswordInputProp> = ({
  value,
  defaultMode = 'password',
  onValueChange,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState<PasswordInputMode>(defaultMode);
  const handleClick = useCallback(() => {
    setMode(mode === 'text' ? 'password' : 'text');
  }, [mode]);
  return (
    <Input
      size="md"
      InputLeftElement={
        <Icon
          style={{
            marginLeft: 5
          }}
          name="lock"
        />
      }
      InputRightElement={
        <Icon
          onPress={handleClick}
          style={{
            marginRight: 5
          }}
          name={mode === 'password' ? 'visibility' : 'visibilityOff'}
        />
      }
      {...restProps}
      value={value}
      onChangeText={onValueChange}
      type={mode}
      placeholder={t('password-field.placeholder')}
    />
  );
};

PasswordInput.displayName = PasswordInput.name;
