import { httpApi } from '@geo/api/http-client';
import { AxiosRequestConfig, AxiosError } from 'axios';
import { appStore } from '../stores';
import { apiUrl } from '../constants';

export const onlineClient = httpApi({
  baseUrl: apiUrl,
  requestInterceptor: {
    onFulfilled: (config: AxiosRequestConfig) => {
      const { token } = appStore;
      if (token) {
        Object.assign(config, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      }
      return config;
    }
  },
  responseInterceptor: {
    onRejected: (error: AxiosError) => {
      const status = error?.response?.status;
      if (status === 401) {
        appStore.logout();
      }
      throw error;
    }
  }
});
