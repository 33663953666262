import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Button, Center, Flex, Text, HStack, VStack } from 'native-base';
import { DateRange } from '@geo/web/src/components/date-range';
import { TFunction, useTranslation } from 'react-i18next';
import { addDays, subWeeks, subMonths, subYears, startOfDay, endOfDay } from 'date-fns';
import { Range } from 'react-date-range';
import { LabeledFormField } from '../../components/labeled-form-field';
import { CustomSelect, SelectOption } from '../../components/custom-select/custom-select';
import { appStore, workerListStore } from '../../stores';
import { useComponentReady } from '../../hooks/useComponentReady';
import { User } from '../../models/User';
import { Worker } from '../../models/Worker';
import Icon from '../../components/icon';
import { AuditFormModel, schema } from './audit-form.model';
import { ReportStore } from '../../stores/report';
import { Loadable } from '../../components/loadable';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { screens } from '../../navigation/config';

const generateOptions = (subjects: Array<User | Worker>): SelectOption<string>[] =>
  subjects.map((subject) => {
    const { name } = subject;
    return {
      value: name,
      label: name,
      key: `${subject.id}_${subject.name} `
    };
  });

interface PredefineRange {
  startDate: Date;
  endDate: Date;
  label?: string;
}

const predefinedRanges = (t: TFunction, endDate: Date): PredefineRange[] => [
  {
    startDate: subWeeks(endDate, 1),
    endDate,
    label: t('audit.ranges.week')
  },
  {
    startDate: subMonths(endDate, 1),
    endDate,
    label: t('audit.ranges.month')
  }
];

export const AuditContainer = observer(() => {
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const reportStore = new ReportStore();
  const { items: workers } = workerListStore;
  const { users, role } = appStore;
  const { control, reset, setValue, formState, watch, handleSubmit } = useForm<AuditFormModel>({
    resolver: yupResolver<any>(schema),
    defaultValues: {
      worker: undefined,
      geologist: undefined,
      range: {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
        isDefault: true,
        key: 'selection'
      }
    },
    mode: 'all'
  });

  if (role !== 'ROLE_MANAGER') {
    navigation.navigate(screens.Facilities, { page: undefined });
  }

  const onSubmit = ({ range, geologist, worker }: AuditFormModel) => {
    if (range.startDate && range.endDate) {
      reportStore
        .download({
          startedFrom: range.startDate.toISOString(),
          startedTo: range.endDate.toISOString(),
          geologist,
          worker
        })
        .catch((e) => console.error(e));
    }
  };
  const [selectedTabRange, setSelectedTabRange] = useState<number | null | undefined>();
  const ready = useComponentReady(() => {
    workerListStore.loadItems().catch((e) => console.error(e));
  }, []);

  const workerOptions = useMemo(() => {
    return generateOptions(workers);
  }, [workers]);

  const userOptions = useMemo(() => {
    return generateOptions(users);
  }, [users]);

  const handleCancel = () => {
    setSelectedTabRange(undefined);
    reset();
  };

  if (!ready) {
    return null;
  }

  const handleRangeSelect = (range: Range) => {
    setValue('range', { ...range, isDefault: false });
    setSelectedTabRange(null);
  };

  const watchRange = watch('range');
  const isSelectedWorker = watch('worker', undefined) !== undefined;
  const isSelectedGeologist = watch('geologist', undefined) !== undefined;

  const ranges: Array<JSX.Element> = predefinedRanges(t, new Date()).map((predefineRange, index) => (
    <Controller
      control={control}
      render={({ field: { onChange, value } }) => (
        <Button
          key={predefineRange.label}
          onPress={() => {
            setSelectedTabRange(index);
            onChange({
              startDate: predefineRange.startDate,
              isDefault: false,
              endDate: predefineRange.endDate,
              key: 'selection'
            });
          }}
          minWidth={100}
          variant={selectedTabRange === index ? 'tabActive' : 'tab'}
        >
          {predefineRange.label}
        </Button>
      )}
      name="range"
    />
  ));
  ranges.push(
    <DateRange
      placeholder={t('audit.fields.dateRange.placeholder')}
      ranges={[watchRange]}
      manualRangeSelected={selectedTabRange === null}
      onChange={(item) => handleRangeSelect(item.selection)}
      minDate={subYears(new Date(), 100)}
      maxDate={addDays(new Date(), 900)}
    />
  );

  return (
    <Loadable isLoading={workerListStore.isLoading}>
      <VStack justifyContent="space-between" flex={1}>
        <HStack justifyContent="space-between">
          <LabeledFormField label={t('audit.fields.dateRange.label')} opacity={1} color="dark.dark-1">
            <Button.Group space={3}>{ranges}</Button.Group>
          </LabeledFormField>

          <HStack space={3}>
            {!!workerOptions.length && (
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                  <CustomSelect
                    isInvalid={invalid}
                    placeholder={t('audit.fields.worker.placeholder')}
                    onValueChange={onChange}
                    value={value}
                    isDisabled={isSelectedGeologist}
                    width={200}
                    bg="#fff"
                    options={workerOptions}
                  />
                )}
                name="worker"
              />
            )}
            {!!userOptions.length && (
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                  <CustomSelect
                    isInvalid={invalid}
                    placeholder={t('audit.fields.geologist.placeholder')}
                    onValueChange={onChange}
                    value={value}
                    width={200}
                    isDisabled={isSelectedWorker}
                    bg="#fff"
                    options={userOptions}
                  />
                )}
                name="geologist"
              />
            )}
          </HStack>
        </HStack>
        <Flex flex={1} py={10}>
          {watchRange.isDefault && (
            <Center flexGrow={1}>
              <Text fontSize={20}>{t('audit.fields.dateRange.error')}</Text>
            </Center>
          )}
        </Flex>
        <HStack justifyContent="end" space={6} mb={10}>
          <Button
            isDisabled={!formState.isValid}
            onPress={() => handleSubmit(onSubmit)()}
            startIcon={<Icon name="download" />}
          >
            {t('audit.buttons.generate')}
          </Button>
          <Button onPress={handleCancel} _text={{ color: 'primary.default' }} variant="link">
            {t('audit.buttons.cancel')}
          </Button>
        </HStack>
      </VStack>
    </Loadable>
  );
});
