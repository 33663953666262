import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { BoreholeTemperature, BoreholeTemperatureIO } from '../components/schemas/BoreholeTemperature';
import {
	BoreholeTemperatureRequest,
	BoreholeTemperatureRequestIO,
} from '../components/schemas/BoreholeTemperatureRequest';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either } from 'fp-ts';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, array } from 'io-ts';

export interface BoreholeTemperatureController<F> {
	readonly getBoreholeTemperature: (id: number) => HKT<F, BoreholeTemperature>;

	readonly updateBoreholeTemperature: (
		id: number,
		parameters: { body: BoreholeTemperatureRequest },
	) => HKT<F, BoreholeTemperature>;

	readonly boreholeTemperatureList: (boreholeId: number) => HKT<F, Array<BoreholeTemperature>>;
}

export interface BoreholeTemperatureController1<F extends URIS> {
	readonly getBoreholeTemperature: (id: number) => Kind<F, BoreholeTemperature>;

	readonly updateBoreholeTemperature: (
		id: number,
		parameters: { body: BoreholeTemperatureRequest },
	) => Kind<F, BoreholeTemperature>;

	readonly boreholeTemperatureList: (boreholeId: number) => Kind<F, Array<BoreholeTemperature>>;
}

export interface BoreholeTemperatureController2<F extends URIS2> {
	readonly getBoreholeTemperature: (id: number) => Kind2<F, Error, BoreholeTemperature>;

	readonly updateBoreholeTemperature: (
		id: number,
		parameters: { body: BoreholeTemperatureRequest },
	) => Kind2<F, Error, BoreholeTemperature>;

	readonly boreholeTemperatureList: (boreholeId: number) => Kind2<F, Error, Array<BoreholeTemperature>>;
}

export function boreholeTemperatureController<F extends URIS2>(e: {
	httpClient: HTTPClient2<F>;
}): BoreholeTemperatureController2<F>;
export function boreholeTemperatureController<F extends URIS>(e: {
	httpClient: HTTPClient1<F>;
}): BoreholeTemperatureController1<F>;
export function boreholeTemperatureController<F>(e: { httpClient: HTTPClient<F> }): BoreholeTemperatureController<F>;
export function boreholeTemperatureController<F>(e: { httpClient: HTTPClient<F> }): BoreholeTemperatureController<F> {
	return {
		getBoreholeTemperature: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/temperature/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'boreholeTemperature',
					operation: 'getBoreholeTemperature',
					method: 'GET',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeTemperatureIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		updateBoreholeTemperature: (id, parameters) => {
			const body = BoreholeTemperatureRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/temperature/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'boreholeTemperature',
					operation: 'updateBoreholeTemperature',
					method: 'PUT',
					responseType,
					pathParameters: [id],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeTemperatureIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		boreholeTemperatureList: boreholeId => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/temperature/borehole/${encodeURIComponent(
						number.encode(boreholeId).toString(),
					)}`,
					controller: 'boreholeTemperature',
					operation: 'boreholeTemperatureList',
					method: 'GET',
					responseType,
					pathParameters: [boreholeId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						array(BoreholeTemperatureIO).decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
