import { action, computed, runInAction } from 'mobx';

import { Notification } from '../models/Notification';
import { defaultNotificationsPerPage } from '../constants';
import { getApiClient } from '../api/api-client';
import { CollectionFetched, CollectionStore } from './collection';

export class NotificationStore extends CollectionStore<Notification> {
  perPage = defaultNotificationsPerPage;

  @action.bound
  async fetchItems(): Promise<CollectionFetched<Notification>> {
    const { result: items, size: total } = await getApiClient().notificationController.listOfNotifications({
      query: { page: undefined, size: undefined, order: undefined, sortBy: undefined }
    });
    return { items, total };
  }

  @action.bound
  async removeAll(): Promise<void> {
    await getApiClient().notificationController.deleteAllNotifications();
    runInAction(() => {
      this.items = [];
    });
  }

  @action.bound
  async readAll(): Promise<void> {
    const ids = this.items.map((value) => value.id);
    const result = await getApiClient().notificationController.setNotificationsViewed({
      body: { ids }
    });
    runInAction(() => {
      this.items = result;
    });
  }

  @computed
  get countOfNew(): number {
    return this.items.filter((i) => i.viewed === false).length;
  }

  @computed
  get hasUnreadableNotification(): boolean {
    return this.countOfNew > 0;
  }
}
