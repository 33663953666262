import React, { FC, ReactNode } from 'react';
import { HStack, Text, Box } from 'native-base';

// Set to true to debug level borders
const debug = false;

const labelStyle = {
  fontSize: 14,
  fontWeight: 400,
  color: 'dark.dark-2',
  opacity: 0.56,
  borderWidth: debug ? 1 : undefined,
  borderColor: debug ? '#090' : undefined
};

const valueStyle = {
  fontSize: 14,
  fontWeight: 400,
  color: 'dark.dark-0',
  opacity: 1,
  borderWidth: debug ? 1 : undefined,
  borderColor: debug ? '#b00' : undefined
};

const boxStyle = {
  borderWidth: debug ? 1 : undefined,
  borderColor: debug ? '#00b' : undefined
};

interface LevelGeometryProps {
  ratio?: number | null;
  width?: number;
  minHeight?: number;
  gap?: number;
  align?: 'top' | 'middle' | 'bottom';
}

interface LevelProps extends LevelGeometryProps {
  left?: ReactNode;
  right?: ReactNode;
}

interface LevelLabelProps extends LevelGeometryProps {
  label?: ReactNode;
}

export const Level: FC<LevelProps> = (props) => {
  const { left, right, children, ratio = null, width, minHeight = 10, gap = 2, align = 'middle' } = props;

  let alignItems = 'center';
  if (align === 'top') {
    alignItems = 'flex-start';
  } else if (align === 'bottom') {
    alignItems = 'flex-end';
  }

  const leftFlex = right && ratio !== Infinity && ratio !== null ? 1 : undefined;
  let rightFlex: number | undefined;
  if (left && ratio !== 0 && ratio !== null) {
    rightFlex = ratio !== Infinity ? ratio : 1;
  } else {
    rightFlex = undefined;
  }

  return (
    <HStack width={width} minHeight={minHeight} alignItems={alignItems}>
      {left && (
        <Box {...boxStyle} flex={leftFlex} {...(right ? { marginRight: gap / 2 } : {})}>
          {left}
        </Box>
      )}
      {children}
      {right && (
        <Box {...boxStyle} flex={rightFlex} {...(left ? { marginLeft: gap / 2 } : {})}>
          {right}
        </Box>
      )}
    </HStack>
  );
};

export const LevelItem: FC<LevelLabelProps> = (props) => {
  const { label, children, ...restProps } = props;

  const left =
    typeof label === 'string' || typeof label === 'number' || typeof label === 'boolean' ? (
      <Text {...labelStyle}>{label}</Text>
    ) : (
      label
    );

  const right =
    typeof children === 'string' || typeof children === 'number' || typeof children === 'boolean' ? (
      <Text {...valueStyle}>{children}</Text>
    ) : (
      children
    );

  return <Level {...restProps} left={left} right={right} />;
};
