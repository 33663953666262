import React, { FC } from 'react';
import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { BoreholeUpdate } from '../../../features/borehole/borehole-update/borehole-update';
import { BoreholeSamplesPage } from './borehole-samples';

type BoreholeUpdatePageProps = PageProps<'BoreholeUpdate'>;

export const BoreholeUpdatePage: FC<BoreholeUpdatePageProps> = ({ route }) => {
  const { params } = route;
  const currentId = params?.boreholeId;
  if (!currentId) {
    return null;
  }
  return (
    <Page>
      <BoreholeUpdate boreholeId={currentId} />
    </Page>
  );
};

BoreholeUpdatePage.displayName = BoreholeSamplesPage.name;
