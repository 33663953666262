import { checkUserRole } from '@geo/api/sql/utils/user';
import React, { FC } from 'react';
import { Input, Text, VStack } from 'native-base';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { BoreholeSampleAcceptSelect } from './borehole-sample-accept-select';
import { AcceptedStatus } from './types';
import { GeoDescriptionItem } from '../../../components/geo-description/geo-description-item';

import { SampleType } from '../../../components/sample/sample-type';
import { Table, TableWithPaginationProps } from '../../../components/table/table';

import { BoreholeLayerSample, BoreholeLayerSampleSortColumn } from '../../../models/BoreholeLayerSample';
import { screens } from '../../../navigation/config';
import { appStore, boreholeSampleTableStore } from '../../../stores';
import { ROLE } from '../../../utils/permissions';
import { geoDescriptionToSelection } from '../../borehole-layer/borehole-layer-table/utils';
import { BoreholeSampleCommentTotal } from './borehole-sample-commet';

import { assistantColumns, columns } from './borehole-sample-table.columns';
import { NavigationLink } from '../../../components/link';

type BoreholeSampleTableProps = TableWithPaginationProps<BoreholeLayerSample, BoreholeLayerSampleSortColumn> & {
  facilityId: number;
};

export const BoreholeSampleTable: FC<BoreholeSampleTableProps> = ({ facilityId, page, perPage, ...restProps }) => {
  const { currentUser } = appStore;

  const setAcceptedStatus = (id: number, accepted: AcceptedStatus) => {
    boreholeSampleTableStore.accept(id, { accepted }).catch();
  };

  const tableColumns = currentUser?.roles.includes(ROLE.Assistant) ? [...columns, ...assistantColumns] : columns;

  return (
    <Table
      {...restProps}
      perPage={perPage}
      page={page}
      rowHeight={60}
      columns={tableColumns}
      customRenderers={{
        id: (item, index = 0) => {
          const order = index + 1 + perPage * page;

          return <Text>{order}</Text>;
        },
        boreholeId: ({ boreholeId, boreholeLayerId, boreholeName }) => {
          return (
            <NavigationLink
              title={`${boreholeName}`}
              route={screens.BoreholeLayers}
              routeParams={{ facilityId, boreholeId, layerId: boreholeLayerId }}
            />
          );
        },
        type: ({ type }) => <SampleType type={type} />,
        geoDescription: ({ geoDescription }) =>
          geoDescription ? (
            <VStack space={2}>
              {geoDescriptionToSelection(geoDescription)
                .slice(2)
                .map((item, index) => {
                  const { levelId, key: itemKey } = item;
                  const key = `${levelId}-${itemKey}`;

                  return <GeoDescriptionItem key={key} index={index} levelId={levelId} itemKey={itemKey} />;
                })}
            </VStack>
          ) : null,
        accepted: ({ accepted, id }) => (
          <BoreholeSampleAcceptSelect value={accepted} onValueChange={(value) => setAcceptedStatus(id, value)} />
        ),
        comment: ({ accepted, comment, id }) => (
          <BoreholeSampleCommentTotal accepted={accepted} comment={comment} id={id} />
        )
      }}
    />
  );
};
