import React, { FC } from 'react';

import { Tabs } from '../../../components/tabs';
import { Page } from '../../../components/page';
import { FacilityCardContainer } from '../../../features/facility/facility-card/facility-card.container';
import { PageProps } from '../../types';

type FacilityProps = PageProps<'FacilityView'>;

export const FacilityCardPage: FC<FacilityProps> = ({ route }) => {
  const { params } = route;
  const facilityId = params?.facilityId;
  if (!facilityId) {
    return null;
  }
  return (
    <Page header={<Tabs facilityId={facilityId} />}>
      <FacilityCardContainer facilityId={facilityId} />
    </Page>
  );
};
