import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { CollectionDtoWorker, CollectionDtoWorkerIO } from '../components/schemas/CollectionDtoWorker';
import { Worker, WorkerIO } from '../components/schemas/Worker';
import { WorkerSaveRequest, WorkerSaveRequestIO } from '../components/schemas/WorkerSaveRequest';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, undefined, union, literal, type } from 'io-ts';

export interface WorkerController<F> {
	readonly listOfWorkers: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'phoneNumber') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => HKT<F, CollectionDtoWorker>;

	readonly createWorker: (parameters: { body: WorkerSaveRequest }) => HKT<F, Worker>;

	readonly getWorker: (id: number) => HKT<F, Worker>;
}

export interface WorkerController1<F extends URIS> {
	readonly listOfWorkers: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'phoneNumber') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind<F, CollectionDtoWorker>;

	readonly createWorker: (parameters: { body: WorkerSaveRequest }) => Kind<F, Worker>;

	readonly getWorker: (id: number) => Kind<F, Worker>;
}

export interface WorkerController2<F extends URIS2> {
	readonly listOfWorkers: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'createdAt' | 'lastUpdatedAt' | 'name' | 'phoneNumber') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind2<F, Error, CollectionDtoWorker>;

	readonly createWorker: (parameters: { body: WorkerSaveRequest }) => Kind2<F, Error, Worker>;

	readonly getWorker: (id: number) => Kind2<F, Error, Worker>;
}

export function workerController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): WorkerController2<F>;
export function workerController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): WorkerController1<F>;
export function workerController<F>(e: { httpClient: HTTPClient<F> }): WorkerController<F>;
export function workerController<F>(e: { httpClient: HTTPClient<F> }): WorkerController<F> {
	return {
		listOfWorkers: parameters => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('createdAt'),
							literal('lastUpdatedAt'),
							literal('name'),
							literal('phoneNumber'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/worker`,
					controller: 'worker',
					operation: 'listOfWorkers',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoWorkerIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createWorker: parameters => {
			const body = WorkerSaveRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/worker`,
					controller: 'worker',
					operation: 'createWorker',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						WorkerIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		getWorker: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/worker/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'worker',
					operation: 'getWorker',
					method: 'GET',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						WorkerIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
