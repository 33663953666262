import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HStack, IconButton, Input, Text, ZStack } from 'native-base';
import { Keyboard } from 'react-native';
import { yupResolver } from '@hookform/resolvers/yup';

import Icon from '../../components/icon';
import yup from '../../utils/yup';

import { Comment } from './comment';

export interface FormValue {
  text: string;
}

interface CommentCreateFormProps {
  comment?: Comment;
  onCancel: () => void;
  onSubmit: (value: FormValue) => void;
}

const maxLength = 1000;
const schema = yup.object().shape({
  text: yup.string().max(maxLength).required()
});

const defaultText = '';

export const CommentForm: React.FC<CommentCreateFormProps> = (props) => {
  const { comment, onCancel } = props;
  const initialText = comment?.text || defaultText;
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
    reset
  } = useForm<FormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver<any>(schema)
  });

  useEffect(() => reset({ text: initialText }), [initialText]);

  const onSubmit = (value: FormValue) => {
    reset({ text: defaultText });
    props.onSubmit(value);
    Keyboard.dismiss();
  };

  const showCancelButton = comment && initialText !== defaultText;
  const { length } = watch('text', initialText);
  return (
    <HStack py={4} bg="#fff" mx={-34} px={34} borderTopWidth={1} borderTopColor="#dbdeea">
      <ZStack flex={1} alignItems="flex-end">
        <Controller
          control={control}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => (
            <Input
              bg="#f7f7fa"
              isInvalid={invalid}
              pr={10}
              value={value}
              onChangeText={onChange}
              placeholder="Введите текст комментария"
              w="100%"
              height={10}
            />
          )}
          name="text"
        />
        {showCancelButton && (
          <IconButton zIndex={2} right={10} icon={<Icon name="cancel" />} onPress={onCancel} height={10} />
        )}
        <IconButton
          isDisabled={!isValid}
          zIndex={2}
          icon={<Icon fill={isValid ? undefined : '#b4b6c9'} name="send" />}
          onPress={handleSubmit(onSubmit)}
          height={10}
        />
      </ZStack>
      <HStack alignItems="center" justifyContent="flex-end" width={25}>
        <Text color={length > maxLength ? 'red.default' : 'dark.dark-2'}>{length} </Text>
        <Text color="dark.dark-2" opacity={0.56}>
          / {maxLength}
        </Text>
      </HStack>
    </HStack>
  );
};
