import React, { FC, ReactElement, useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { observer } from 'mobx-react';

import { GeoDescription } from '@geo/api/generated/swagger.json/components/schemas/GeoDescription';

import { GeoDescriptionList } from '../../../components/geo-description/geo-description-list';
import { useMemoizedValue } from '../../../hooks/memoizeObject';
import { codificatorStore } from '../../../stores';
import { CodificatorSelection, CodificatorSelectionItem } from '../../../stores/codificator';
import { Form } from '../../../utils/form';

import { BoreholeLayerFormData } from './borehole-layer.model';
import { geoDescriptionToSelection } from './utils';

type EditableGeoDescriptionCellProps = {
  form: Form<BoreholeLayerFormData>;
};

type BoreholeLayerTableGeoDescriptionCellProps = {
  form?: Form<BoreholeLayerFormData>;
  geoDescription?: GeoDescription;
};

const fieldName = 'geoDescription';

const EditableGeoDescriptionCell: FC<EditableGeoDescriptionCellProps> = observer((props) => {
  const { form } = props;
  const { control, validate, state } = form;
  const { fields: selection, append, remove } = useFieldArray({ control, name: fieldName });
  const { errors } = state;
  const error = !!errors[fieldName];

  const onRemove = (index: number) => {
    const indexes = Array.from(Array(selection.length - index).keys()).map((item, i) => i + index);
    remove(indexes);
    validate(fieldName).catch();
  };

  const onAdd = (selectionItem: CodificatorSelectionItem) => {
    const validSelection = codificatorStore.validSelection(selection);
    if (validSelection.length < selection.length) {
      onRemove(validSelection.length);
    }
    append(selectionItem);
    validate(fieldName).catch();
  };

  const memoizedSelection = useMemoizedValue(selection);
  return (
    <GeoDescriptionList selection={memoizedSelection} form={form} error={error} onAdd={onAdd} onRemove={onRemove} />
  );
});

export const BoreholeLayerTableGeoDescriptionCell: FC<BoreholeLayerTableGeoDescriptionCellProps> = (
  props
): ReactElement => {
  const { form, geoDescription } = props;
  const memoizedGeoDescription = useMemoizedValue(geoDescription);
  const memoizedSelection: CodificatorSelection = useMemo(() => {
    return memoizedGeoDescription ? geoDescriptionToSelection(memoizedGeoDescription) : [];
  }, [memoizedGeoDescription]);
  return form ? <EditableGeoDescriptionCell form={form} /> : <GeoDescriptionList selection={memoizedSelection} />;
};

BoreholeLayerTableGeoDescriptionCell.displayName = BoreholeLayerTableGeoDescriptionCell.name;
