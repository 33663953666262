import React, { FC } from 'react';
import { VStack, Text, HStack, Box } from 'native-base';
import { useTranslation } from 'react-i18next';

import { typeToLabel } from '../../../components/sample/sample-type';
import { BoreholeLayerSampleType } from '../../../models/BoreholeLayerSample';
import { useComponent } from '../../../hooks/useComponent';

type BoreholeSampleTableProps = {
  total: number;
  countByTypeMap?: Record<string, number>;
  facilityId: number;
};

export const BoreholeSampleTableTotal: FC<BoreholeSampleTableProps> = ({ total, facilityId, countByTypeMap }) => {
  const { t } = useTranslation();
  const { BoreholeLayersPrint } = useComponent();

  if (!countByTypeMap) {
    return null;
  }
  const keys = Object.keys(countByTypeMap) as BoreholeLayerSampleType[];
  if (keys.length === 0) {
    return null;
  }
  return (
    <VStack space={1} mt={6}>
      <HStack space={2} justifyContent="space-between">
        <Box>
          <Text>{t('borehole.sample.total.general')}:</Text>
          <Text fontWeight={600} color="dark.dark-0">
            {total} {t('borehole.sample.total.label')}
          </Text>
        </Box>
        <Box>
          <BoreholeLayersPrint facilityId={facilityId} />
        </Box>
      </HStack>
      <HStack space={4}>
        {keys.map((key) => (
          <HStack space={2} key={key}>
            <Text>{typeToLabel(t, key)}:</Text>
            <Text fontWeight={600} color="dark.dark-0">{`${countByTypeMap[key]}`}</Text>
          </HStack>
        ))}
      </HStack>
    </VStack>
  );
};
