import React, { FC } from 'react';
import { Select, CheckIcon, ISelectProps } from 'native-base';

type SelectBoxOption<T> = {
  value: T;
  label: string;
};

type SelectBoxProps<T extends string> = ISelectProps & {
  value: T;
  options: SelectBoxOption<T>[];
  onValueChange?: (newValue: T) => void;
  height?: number;
};

export const SelectBox: FC<SelectBoxProps<string>> = (props) => {
  const { value, options, onValueChange, height = 48, ...restProps } = props;

  const selectProps: ISelectProps = {
    ...restProps,
    style: { height },
    selectedValue: value,
    onValueChange,
    _selectedItem: {
      backgroundColor: 'cyan.600',
      endIcon: <CheckIcon size={4} />
    }
  };

  return (
    <Select {...selectProps} width={250}>
      {options.map(({ label, value }, index) => (
        <Select.Item key={`${index}${value}`} label={label} value={value} />
      ))}
    </Select>
  );
};

SelectBox.displayName = SelectBox.name;
