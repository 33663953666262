import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { CollectionDtoComment, CollectionDtoCommentIO } from '../components/schemas/CollectionDtoComment';
import { Comment, CommentIO } from '../components/schemas/Comment';
import { CommentRequest, CommentRequestIO } from '../components/schemas/CommentRequest';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, void as tvoid, undefined, union, literal, type } from 'io-ts';

export interface CommentController<F> {
	readonly getComment: (facilityId: number, id: number) => HKT<F, Comment>;

	readonly updateComment: (facilityId: number, id: number, parameters: { body: CommentRequest }) => HKT<F, Comment>;

	readonly deleteComment: (facilityId: number, id: number) => HKT<F, void>;

	readonly createComment: (facilityId: number, parameters: { body: CommentRequest }) => HKT<F, Comment>;

	readonly setMarkToDeleteComment: (facilityId: number, id: number) => HKT<F, Comment>;

	readonly listOfCommentsByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => HKT<F, CollectionDtoComment>;
}

export interface CommentController1<F extends URIS> {
	readonly getComment: (facilityId: number, id: number) => Kind<F, Comment>;

	readonly updateComment: (facilityId: number, id: number, parameters: { body: CommentRequest }) => Kind<F, Comment>;

	readonly deleteComment: (facilityId: number, id: number) => Kind<F, void>;

	readonly createComment: (facilityId: number, parameters: { body: CommentRequest }) => Kind<F, Comment>;

	readonly setMarkToDeleteComment: (facilityId: number, id: number) => Kind<F, Comment>;

	readonly listOfCommentsByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind<F, CollectionDtoComment>;
}

export interface CommentController2<F extends URIS2> {
	readonly getComment: (facilityId: number, id: number) => Kind2<F, Error, Comment>;

	readonly updateComment: (
		facilityId: number,
		id: number,
		parameters: { body: CommentRequest },
	) => Kind2<F, Error, Comment>;

	readonly deleteComment: (facilityId: number, id: number) => Kind2<F, Error, void>;

	readonly createComment: (facilityId: number, parameters: { body: CommentRequest }) => Kind2<F, Error, Comment>;

	readonly setMarkToDeleteComment: (facilityId: number, id: number) => Kind2<F, Error, Comment>;

	readonly listOfCommentsByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind2<F, Error, CollectionDtoComment>;
}

export function commentController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): CommentController2<F>;
export function commentController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): CommentController1<F>;
export function commentController<F>(e: { httpClient: HTTPClient<F> }): CommentController<F>;
export function commentController<F>(e: { httpClient: HTTPClient<F> }): CommentController<F> {
	return {
		getComment: (facilityId, id) => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/comment/${encodeURIComponent(
						number.encode(id).toString(),
					)}/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'comment',
					operation: 'getComment',
					method: 'GET',
					responseType,
					pathParameters: [facilityId, id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CommentIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		updateComment: (facilityId, id, parameters) => {
			const body = CommentRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/comment/${encodeURIComponent(
						number.encode(id).toString(),
					)}/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'comment',
					operation: 'updateComment',
					method: 'PUT',
					responseType,
					pathParameters: [facilityId, id],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CommentIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		deleteComment: (facilityId, id) => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/comment/${encodeURIComponent(
						number.encode(id).toString(),
					)}/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'comment',
					operation: 'deleteComment',
					method: 'DELETE',
					responseType,
					pathParameters: [facilityId, id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						tvoid.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createComment: (facilityId, parameters) => {
			const body = CommentRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/comment/create/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'comment',
					operation: 'createComment',
					method: 'POST',
					responseType,
					pathParameters: [facilityId],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CommentIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		setMarkToDeleteComment: (facilityId, id) => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/comment/mark/${encodeURIComponent(
						number.encode(id).toString(),
					)}/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'comment',
					operation: 'setMarkToDeleteComment',
					method: 'PATCH',
					responseType,
					pathParameters: [facilityId, id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CommentIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listOfCommentsByFacilityId: (facilityId, parameters) => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([union([literal('id'), literal('createdAt'), literal('lastUpdatedAt')]), undefined]).encode(
						parameters.query['sortBy'],
					),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/comment/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'comment',
					operation: 'listOfCommentsByFacilityId',
					method: 'GET',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoCommentIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
