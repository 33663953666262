import { View, Input, Box, HStack, Text, VStack, Button } from 'native-base';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Panel } from '../panel/panel';
import { FileEditFormData, schema } from './file-edit-form.model';
import Icon, { IconName } from '../icon';
import { Cancelable, CancelableProps } from '../cancelable';

interface FileEditFormProps extends CancelableProps {
  headerIcon?: IconName;
  defaultValues: FileEditFormData;
  preview: ReactNode;
  onSubmit: (value: FileEditFormData) => void;
  onReplace?: () => void;
}

const Form: FC<FileEditFormProps> = ({ headerIcon, onSubmit, defaultValues, onCancel, preview, onReplace }) => {
  const { t } = useTranslation();
  const { control, watch, handleSubmit } = useForm<FileEditFormData>({
    resolver: yupResolver<any>(schema),
    defaultValues,
    mode: 'all'
  });

  const description = watch('description');

  return (
    <>
      <View mt={2}>
        <Panel
          headerIcon={headerIcon}
          headerLeft={
            <HStack flex={1}>
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                  <Input
                    value={value}
                    size="sm"
                    isInvalid={invalid}
                    onChangeText={onChange}
                    placeholder={t('facility.form.fields.name.placeholder')}
                    isFullWidth
                  />
                )}
                name="description"
              />
            </HStack>
          }
        >
          <HStack pt={6} pb={6}>
            <VStack space={3} flex={1}>
              <HStack>
                <Box display="flex" justifyContent="flex-start" width={300}>
                  {preview}
                  <Text>{description}</Text>
                </Box>
                <Box justifyContent="center">
                  {onReplace && (
                    <Button onPress={onReplace} variant="outline">
                      {t('uploader.form.replace-button')}
                    </Button>
                  )}
                </Box>
              </HStack>
            </VStack>
          </HStack>
        </Panel>
      </View>
      <HStack mx={10} mt={6} space={6} mb={10}>
        <Button onPress={handleSubmit(onSubmit)} startIcon={<Icon name="save" />}>
          {t('form.buttons.save')}
        </Button>
        <Button onPress={onCancel} _text={{ color: 'primary.default' }} variant="link">
          {t('form.buttons.cancel')}
        </Button>
      </HStack>
    </>
  );
};

export const FileEditForm: FC<FileEditFormProps> = (props) => {
  const { t } = useTranslation();
  const { defaultValues } = props;
  return (
    <Cancelable cancelConfirmationText={t('uploader.form.cancel.confirmation', { name: defaultValues.description })}>
      <Form {...props} />
    </Cancelable>
  );
};
