import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { LoginRequest, LoginRequestIO } from '../components/schemas/LoginRequest';
import { LoginResponse, LoginResponseIO } from '../components/schemas/LoginResponse';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either } from 'fp-ts';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { pipe } from 'fp-ts/lib/pipeable';

export interface AuthController<F> {
	readonly signin: (parameters: { body: LoginRequest }) => HKT<F, LoginResponse>;
}

export interface AuthController1<F extends URIS> {
	readonly signin: (parameters: { body: LoginRequest }) => Kind<F, LoginResponse>;
}

export interface AuthController2<F extends URIS2> {
	readonly signin: (parameters: { body: LoginRequest }) => Kind2<F, Error, LoginResponse>;
}

export function authController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): AuthController2<F>;
export function authController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): AuthController1<F>;
export function authController<F>(e: { httpClient: HTTPClient<F> }): AuthController<F>;
export function authController<F>(e: { httpClient: HTTPClient<F> }): AuthController<F> {
	return {
		signin: parameters => {
			const body = LoginRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/auth/signin`,
					controller: 'auth',
					operation: 'signin',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						LoginResponseIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
