import React, { FC } from 'react';
import { BoreholeCardContainer } from '../../../features/borehole/borehole-card/borehole-card.container';
import { useComponent } from '../../../hooks/useComponent';
import { PageProps } from '../../types';
import { Borehole } from '../../../models/Borehole';
import { BoreholeLoader } from './borehole-loader';

type BoreholeCreatePageProps = PageProps<'BoreholeView'>;

export const BoreholeViewPage: FC<BoreholeCreatePageProps> = ({ route }) => {
  const { ShiftSwitcher } = useComponent();
  const headerBuilder = (borehole: Borehole) => <ShiftSwitcher borehole={borehole} />;
  return (
    <BoreholeLoader {...route.params} headerBuilder={headerBuilder}>
      <BoreholeCardContainer boreholeId={route.params.boreholeId} />
    </BoreholeLoader>
  );
};

BoreholeViewPage.displayName = BoreholeViewPage.name;
