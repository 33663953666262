import React from 'react';
import { Controller } from 'react-hook-form';
import { Text, Input } from 'native-base';

import { BoreholeTemperatureFormData } from './borehole-temperature.model';
import { BoreholeTemperature } from '../../models/BoreholeTemperatures';
import { Form } from '../../utils/form';
import { CustomRenderers } from '../../components/table/table.model';
import { stringifyNumber } from '../../utils/units';

export const getFormRenderers = (form: Form<BoreholeTemperatureFormData>): CustomRenderers<BoreholeTemperature> => {
  const { control } = form;
  return {
    depth: () => (
      <Controller
        control={control}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <Input
            size="xs"
            width={16}
            type="number"
            keyboardType="numeric"
            isInvalid={invalid}
            onChangeText={onChange}
            isDisabled={false}
            value={stringifyNumber(value)}
          />
        )}
        name="depth"
      />
    ),
    temperature: () => (
      <Controller
        control={control}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <Input
            size="xs"
            width={16}
            type="number"
            keyboardType="numeric"
            isInvalid={invalid}
            onChangeText={onChange}
            isDisabled={false}
            value={stringifyNumber(value)}
          />
        )}
        name="temperature"
      />
    )
  };
};
