import React from 'react';
import { HStack, Text } from 'native-base';

import { CustomRenderers } from '../../../components/table/table.model';
import { BoreholeLayer } from '../../../models/BoreholeLayer';

import { BoreholeLayerTableSampleCell } from './borehole-layer-table-sample-cell';

import { stringifyFractional, centimetersToMeters } from '../../../utils/units';
import { BoreholeLayerTableGeoDescriptionCell } from './borehole-layer-table-geo-description-cell';

export const getRenderers = (perPage: number, page: number): CustomRenderers<BoreholeLayer> => {
  return {
    id: (item, index = 0) => {
      const order = index + 1 + perPage * page;
      return <Text>{order}</Text>;
    },
    depthFrom: ({ depthFrom, depthTo }) => (
      <HStack space={3}>
        <Text>{stringifyFractional(centimetersToMeters(depthFrom), 2)}</Text>
        <Text>—</Text>
        <Text>{stringifyFractional(centimetersToMeters(depthTo), 2)}</Text>
      </HStack>
    ),
    gwlEmerged: ({ gwlEmerged }) => <Text>{gwlEmerged ? stringifyFractional(centimetersToMeters(gwlEmerged), 2):''}</Text>,
    gwlSteady: ({ gwlSteady }) => <Text>{gwlSteady ? stringifyFractional(centimetersToMeters(gwlSteady), 2):''}</Text>,
    geoDescription: ({ geoDescription }) => <BoreholeLayerTableGeoDescriptionCell geoDescription={geoDescription} />,
    samples: ({ samples }) => <BoreholeLayerTableSampleCell samples={samples} />
  };
};
