import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View, HStack } from 'native-base';
import { shiftsStore } from '../../../stores';

import { Panel } from '../../../components/panel/panel';
import { Table } from '../../../components/table/table';
import { columns } from './borehole-shifts-columns';
import { Loadable } from '../../../components/loadable';
import { BoreHoleShiftWorkerCell } from './borehole-shifts-worker-cell';

export interface BoreholeShiftsProps {
  boreholeId: number;
  isEdit?: boolean;
}

export const BoreholeShifts: React.FC<BoreholeShiftsProps> = observer(({ boreholeId, isEdit }) => {
  const { t } = useTranslation();
  const { isLoading, total, items, tableData } = shiftsStore;

  useEffect(() => {
    shiftsStore.setBoreholeId(boreholeId);
    shiftsStore.loadItems().catch();
  }, [boreholeId]);

  const tableBrigadeData = useMemo(() => {
    return tableData.map((item, index) => ({
      ...item,
      worker: items[index].worker
    }));
  }, [items]);

  return (
    <Loadable isLoading={isLoading}>
      <HStack space={6}>
        <View flex={1}>
          <Panel headerIcon="geologist" headerLeft={t('borehole.card.geologist')}>
            <ScrollView mr={-5} height={298}>
              <Table
                borderWidth={0}
                rowAlignment="flex-start"
                actionsWidth={1}
                page={0}
                rowHeight={5}
                hidePagination
                perPage={1000}
                columns={columns}
                items={tableData.slice()}
                onChangePage={() => ''}
                onChangeSort={() => ''}
                total={total}
                customRenderers={{
                  worker: ({ geologist }) => <>{geologist.name}</>
                }}
              />
            </ScrollView>
          </Panel>
        </View>
        <View flex={1}>
          <Panel headerIcon="brigade" headerLeft={t('borehole.card.brigade')}>
            <ScrollView height={298}>
              <Table
                borderWidth={0}
                rowAlignment="flex-start"
                actionsWidth={1}
                page={0}
                rowHeight={5}
                hidePagination
                perPage={1000}
                columns={columns}
                items={tableBrigadeData}
                onChangePage={() => ''}
                onChangeSort={() => ''}
                total={total}
                customRenderers={{
                  worker: ({ worker, id }) =>
                    isEdit ? <BoreHoleShiftWorkerCell id={id} worker={worker} /> : <>{worker.name}</>
                }}
              />
            </ScrollView>
          </Panel>
        </View>
      </HStack>
    </Loadable>
  );
});
