import { Worker } from '@geo/api/generated/swagger.json/components/schemas/Worker';
import { User } from '@geo/api/generated/swagger.json/components/schemas/User';
import { observable, computed, action } from 'mobx';
import { Shift } from '@geo/api/generated/swagger.json/components/schemas/Shift';
import format from 'date-fns/format';
import { getApiClient } from '../api/api-client';
import { CollectionStore, CollectionFetched } from './collection';
import { dateFormat } from '../utils/date';
import {centimetersToMeters} from "../utils/units";

export type BoreholeShiftTableEntity = {
  id: number;
  date: string;
  time: string;
  depth: string;
  worker: Worker;
  geologist: User;
};

export class ShiftsStore extends CollectionStore<Shift> {
  @observable boreholeId?: number;

  @computed get count(): number {
    return this.items.length;
  }

  @action setBoreholeId(boreholeId: number | undefined): void {
    this.boreholeId = boreholeId;
  }

  @action async fetchItems(): Promise<CollectionFetched<Shift>> {
    if (this.boreholeId === undefined) {
      throw new Error('Borehole identifier is not specified');
    }
    // @TODO replace order to depthTo after backend complete
    const items = await getApiClient().shiftController.listShiftsByBorehole(this.boreholeId, {
      query: { sortBy: 'startedAt', order: 'ASC' }
    });
    const total = items.length;
    return { items, total };
  }

  @computed
  get tableData(): BoreholeShiftTableEntity[] {
    var depthFrom = 0
    return this.items.map((item, index) => {
      var depthToPrevious = index === 0 ? depthFrom : this.items[index - 1].depthTo;
      depthFrom = depthToPrevious ? depthToPrevious : depthFrom;
      const timeRange = [item.startedAt];
      if (item.finishedAt) {
        timeRange.push(item.finishedAt);
      }

      const time = timeRange.map((t) => format(t, 'H:mm')).join('-');

      var depthFromFormated = centimetersToMeters(depthFrom);
      return {
        id: item.id,
        date: dateFormat(item.startedAt),
        time,
        depth: `${depthFromFormated} - ${item.depthTo ? centimetersToMeters(item.depthTo): depthFromFormated}`,
        worker: item.worker,
        geologist: item.geologist
      };
    });
  }
}
