import React, { useState } from 'react';
import { Box, IconButton } from 'native-base';

import Icon from '@geo/commons/components/icon';
import { BoreholePrintComponent } from '@geo/commons/utils/components/types';

import { PrintButton } from 'components/print-button/print-button';
import { observer } from 'mobx-react';
import { boreholeStore } from '@geo/commons/stores';
import { getApiClient } from '@geo/commons/api/api-client';
import { BoreholePrint, BoreholePrintItem } from './borehole-print';

const blankParams = {
  size: undefined,
  page: undefined,
  order: undefined,
  sortBy: undefined
};

export const BoreholePrintContainer = observer<BoreholePrintComponent>(({ boreholeId }) => {
  const [templateData, setTemplateData] = useState<BoreholePrintItem[]>([]);

  const beforePrint = async () => {
    if (boreholeId) {
      let borehole = boreholeStore.item;
      if (borehole?.id !== boreholeId) {
        borehole = await getApiClient().boreholeController.getBorehole(boreholeId);
      }

      const { result } = await getApiClient().boreholeLayerController.boreholeLayerList(boreholeId, {
        query: blankParams
      });

      setTemplateData([
        {
          borehole,
          layers: result
        }
      ]);
    }
  };

  return (
    <Box>
      <PrintButton
        beforePrint={beforePrint}
        buttonTrigger={<IconButton variant="outline" w={10} h={10} icon={<Icon name="print" />} />}
      >
        <BoreholePrint items={templateData} />
      </PrintButton>
    </Box>
  );
});

BoreholePrintContainer.displayName = 'BoreholePrintContainer';
