import React, { ReactElement } from 'react';
import { Path, Rect, Svg, Defs, ClipPath, Circle, G } from 'react-native-svg';

const svgs = {
  diameter: (
    <Svg viewBox="0 0 1000 1000">
      <Path d="M466.61 10.23c-3.73.39-15.33 1.77-25.54 2.95C299.8 29.29 167.77 112.6 86.82 236.97c-38.71 59.53-63.66 128.5-74.46 205.32-3.14 22.79-3.14 93.13 0 115.92 13.75 98.62 51.47 184.29 113.17 257.38l16.5 19.65-53.83 54.02c-48.33 48.73-53.83 54.82-53.83 60.52 0 8.64 7.46 16.11 16.11 16.11 5.7 0 11.79-5.5 60.51-53.84l54.03-53.83 19.65 16.5c73.09 61.69 158.75 99.42 257.38 113.17 23.18 3.14 93.13 3.14 116.9-.2 77.21-10.61 147.16-36.54 209.25-77.8 121.62-80.55 199.42-205.12 219.27-350.71 3.34-23.77 3.34-93.92.2-116.9-13.76-98.63-51.48-184.29-113.17-257.38L858 165.25l53.83-54.03c48.33-48.73 53.84-54.82 53.84-60.51 0-8.65-7.47-16.11-16.11-16.11-5.7 0-11.79 5.5-60.52 53.83l-54.03 53.83-19.65-16.5C743.25 64.66 657 26.54 560.92 12.98c-15.52-2.16-82.52-4.13-94.31-2.75zm100.2 35.17c88.22 12.97 172.7 52.07 239.31 110.42l8.25 7.46-325.75 325.76L162.86 814.8l-15.33-18.27c-43.62-52.07-75.64-115.13-92.54-181.15-10.61-42.04-13.56-67.2-13.56-115.13 0-30.85.98-48.73 3.54-65.82C77 221.83 241.84 64.65 455.81 42.65c24.95-2.75 84.28-1.18 111 2.75zm298.45 174.86c74.27 96.67 107.28 224.18 89.59 346.58-39.69 272.71-304.54 446.79-573.91 377.43-62.68-16.31-126.33-49.12-177.22-91.56l-18.27-15.33 325.76-325.74 325.76-325.76 7.47 8.25c3.93 4.72 13.35 16.31 20.82 26.13z" />
    </Svg>
  ),
  online: ({ fill = '#06C270' }) => (
    <Svg width={20} height={20} fill="none">
      <Path
        d="M9.99 0C4.47 0 0 4.48 0 10s4.47 10 9.99 10C15.52 20 20 15.52 20 10S15.52 0 9.99 0zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0116.92 6zM10 2.04c.83 1.2 1.48 2.53 1.91 3.96H8.09C8.52 4.57 9.17 3.24 10 2.04zM2.26 12C2.1 11.36 2 10.69 2 10s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H2.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 013.08 14zm2.95-8H3.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 006.03 6zM10 17.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM12.34 12H7.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM14.36 12c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
        fill={fill}
      />
    </Svg>
  ),
  offline: (
    <Svg width={24} height={24} fill="none">
      <Path
        d="M13.9 20.9l1.4-1.4-.1-.1c-.2.1-.3.1-.5.2.1-.2.1-.3.2-.5l-.9-.9c-.2-.2-.4-.4-.5-.7-.5.8-1 1.7-1.5 2.5-.8-1.2-1.5-2.5-1.9-4H13c0-.7.3-1.4.8-2H9.7c-.1-.7-.2-1.3-.2-2s.1-1.3.2-2h4.7c.1.7.2 1.3.2 2 0 .5 0 1-.1 1.5.6-.4 1.4-.5 2.1-.4v-1c0-.7-.1-1.3-.1-2h3.4c.2.6.3 1.3.3 2s-.1 1.4-.3 2h-1.5l1.3 1.3 1.4-1.4c.3-.3.7-.5 1-.7 0-.4.1-.8.1-1.2 0-5.5-4.5-10-10-10C6.5 2 2 6.5 2 12s4.5 10 10 10c.4 0 .8 0 1.2-.1.2-.4.4-.7.7-1zm5-12.9h-3c-.3-1.3-.8-2.5-1.4-3.6 1.9.7 3.5 1.9 4.4 3.6zM12 4c.8 1.2 1.5 2.5 1.9 4h-3.8c.4-1.4 1.1-2.8 1.9-4zM4.3 14c-.2-.6-.3-1.3-.3-2s.1-1.4.3-2h3.4c-.1.7-.1 1.3-.1 2s.1 1.3.1 2H4.3zm.8 2h3c.3 1.3.8 2.5 1.4 3.6-1.9-.7-3.5-1.9-4.4-3.6zM8 8H5c1-1.7 2.5-2.9 4.3-3.6C8.8 5.5 8.3 6.8 8 8z"
        fill="#B4B6C9"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.293 15.293a1 1 0 011.414 1.414L20.914 19.5l2.793 2.793a1 1 0 01-1.414 1.414L19.5 20.914l-2.793 2.793a1 1 0 01-1.414-1.414l2.793-2.793-2.793-2.793a1 1 0 011.414-1.414l2.793 2.793 2.793-2.793z"
        fill="#FF5C5C"
      />
    </Svg>
  ),
  monolith: (
    <Svg width={16} height={16} fill="none">
      <Rect x={1} y={1} width={14} height={14} rx={2} fill="#39D98A" />
    </Svg>
  ),
  water: (
    <Svg width={16} height={16} fill="none">
      <Circle cx={8} cy={8} r={8} fill="#5B8DEF" />
    </Svg>
  ),
  disturbed: (
    <Svg width={16} height={16} fill="none">
      <G clipPath="url(#prefix__clip0)">
        <Path
          d="M7.02 1.6c-.124.226-1.263 2.16-1.691 2.886l-.002.003L.18 13.28a1.194 1.194 0 00-.116 1.01.682.682 0 00.022.054c.014.043.031.084.05.124a1.117 1.117 0 00.993.53h13.815a1.056 1.056 0 00.925-.497.914.914 0 00.046-.11.976.976 0 00.086-.382 1.188 1.188 0 00-.168-.597c-.165-.279-5.227-8.924-5.227-8.924l-1.69-2.885a1.1 1.1 0 00-.4-.435A1.121 1.121 0 007.946 1c-.195.009-.383.07-.546.175a1.075 1.075 0 00-.38.425z"
          fill="#FF5C5C"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  ),
  done: ({ fill = '#fff' }) => (
    <Svg width={24} height={24} fill="none">
      <Path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" fill={fill} />
    </Svg>
  ),
  del: ({ fill = '#FF5C5C' }) => (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z"
        fill={fill}
      />
    </Svg>
  ),
  edit: ({ fill = '#5B8DEF' }) => (
    <Svg width={24} height={24} fill="none">
      <Path
        d="M3 15.25V21h5.75l9.06-9.06-5.75-5.75L3 15.25zM7.92 19H5v-2.92l7.06-7.06 2.92 2.92L7.92 19zM20.71 7.63l-4.34-4.34c-.2-.2-.45-.29-.71-.29-.26 0-.51.1-.7.29l-1.83 1.83 5.75 5.75 1.83-1.83a.996.996 0 000-1.41z"
        fill={fill}
      />
    </Svg>
  ),
  addplus32: (
    <Svg width={32} height={32} fill="none">
      <Path d="M27 17H17v10h-2V17H5v-2h10V5h2v10h10v2z" fill="#fff" />
    </Svg>
  ),
  addplus: ({ fill = '#fff' }) => {
    return (
      <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
        <Path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill={fill} />
      </Svg>
    );
  },
  sample: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M13 11.33L18 18H6l5-6.67V6h2v5.33zM15.96 4H8.04c-.42 0-.65.48-.39.81L9 6.5v4.17L3.2 18.4c-.49.66-.02 1.6.8 1.6h16c.82 0 1.29-.94.8-1.6L15 10.67V6.5l1.35-1.69c.26-.33.03-.81-.39-.81z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  comments: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17l-.59.59-.58.58V4h16v12zM6 12h9v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  drawing: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 2h-6v3h6V2zM2 3h13v2H4v10h11v2H2V3zm14 3h6v12.414l-3 3-3-3V6zm2 2v9.586l1 1 1-1V8h-2z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  photo: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M14.12 5l1.83 2H20v12H4V7h4.05l1.83-2h4.24zM15 3H9L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2h-3.17L15 3zm-3 7c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3zm0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  map: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  journal: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M9 16h5v2H9v-2zm0-4h8v2H9v-2zm0-4h8v2H9V8zm11-6h-5.18C14.4.84 13.3 0 12 0c-1.3 0-2.4.84-2.82 2H4c-.14 0-.27.01-.4.04a2.008 2.008 0 00-1.44 1.19c-.1.23-.16.49-.16.77v17c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM20 21H4V4h16v17z"
        fill="#B4B6C9"
      />
      <Path d="M6 4h1v17H6V4z" fill="#B4B6C9" />
    </Svg>
  ),
  layers: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M13.7957 2.35066L20.7946 5.54178C21.3168 5.77906 21.9988 6.28051 22.0004 7.13207C22.002 7.98468 21.3213 8.4877 20.8003 8.72559L18.9303 9.57846L20.7988 10.4162C21.3196 10.653 22.0052 11.1555 22.0031 12.011C22.0011 12.8644 21.3162 13.3646 20.794 13.6011L20.7922 13.6019L18.9953 14.4213L20.8098 15.2945C21.33 15.5336 22.0037 16.0344 22.0037 16.8818C22.0037 17.7352 21.3205 18.237 20.7989 18.4741L13.8624 21.6378C13.2992 21.8942 12.6234 21.9983 12.0042 21.9983C11.385 21.9983 10.7097 21.8944 10.1465 21.638L3.20959 18.4742C2.68805 18.237 2.00464 17.7348 2.00464 16.8818C2.00464 16.0284 2.68796 15.5265 3.20959 15.2894L3.21398 15.2874L5.07339 14.4531L3.20495 13.601C2.68338 13.3638 2 12.8618 2 12.0086C2 11.1552 2.68332 10.6533 3.20495 10.4162L3.2093 10.4143L5.07485 9.57701L3.20959 8.7263C2.68839 8.4892 2.00464 7.98725 2.00464 7.13414C2.00464 6.28101 2.68762 5.77942 3.20882 5.54232L10.2042 2.35066C10.7508 2.10101 11.4032 2 11.9999 2C12.5968 2 13.2491 2.10088 13.7957 2.35066ZM7.49749 10.6819L4.53728 12.0105L10.9711 14.9443C11.1995 15.0485 11.5715 15.125 11.9995 15.125C12.4275 15.125 12.7995 15.0485 13.0279 14.9444L19.4638 12.0095L16.5069 10.6837L13.8624 11.8898C13.2991 12.1466 12.6235 12.2507 12.0042 12.2507C11.3848 12.2507 10.7094 12.1467 10.1461 11.8899L7.49749 10.6819ZM7.49632 15.558L4.54192 16.8837L10.9753 19.8178C11.2038 19.9218 11.5762 19.9983 12.0042 19.9983C12.4322 19.9983 12.8041 19.9219 13.0327 19.818L19.4836 16.8758L16.6272 15.5012L13.8577 16.7641C13.2944 17.0209 12.6189 17.125 11.9995 17.125C11.3802 17.125 10.7047 17.021 10.1414 16.7642L7.49632 15.558ZM11.9999 4C11.5964 4 11.2479 4.07263 11.0357 4.16965L4.53826 7.1341L10.9757 10.0701C11.2041 10.1742 11.5762 10.2507 12.0042 10.2507C12.4321 10.2507 12.8041 10.1743 13.0325 10.0702L19.468 7.13503L12.9651 4.17004C12.7528 4.07302 12.4035 4 11.9999 4Z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  save: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M17 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6V6z"
        fill="#fff"
      />
    </Svg>
  ),
  logout: (
    <Svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <Path
        fill="#B4B6C9"
        d="M11 7L9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14z"
      />
    </Svg>
  ),
  geologist: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.22 0 .41.1.55.25.12.13.2.31.2.5 0 .41-.34.75-.75.75s-.75-.34-.75-.75c0-.19.08-.37.2-.5.14-.15.33-.25.55-.25zM19 19H5V5h14v14zM12 6c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm0 4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-6 6.47V18h12v-1.53c0-2.5-3.97-3.58-6-3.58s-6 1.07-6 3.58zM8.31 16c.69-.56 2.38-1.12 3.69-1.12s3.01.56 3.69 1.12H8.31z"
        fill="#5B8DEF"
      />
    </Svg>
  ),
  description: ({ fill = '#B4B6C9' }) => (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M7 16h10v2H7v-2zm0-4h10v2H7v-2zm8-10H5c-1.1 0-2 .9-2 2v17c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V8l-6-6zm4 19H5V4h9v5h5v12z"
        fill={fill}
      />
    </Svg>
  ),
  camera: () => (
    <Svg width={32} height={32} fill="none">
      <Path
        d="M28.5 7h-7.17L19.5 5h-6l-1.83 2H4.5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h24c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2Zm0 18h-24V9h8.05l1.83-2h4.24l1.83 2h8.05v16Zm-12-13c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5Zm0 8c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3Z"
        fill="#fff"
      />
    </Svg>
  ),
  notifications: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M10.01 21.01c0 1.1.89 1.99 1.99 1.99s1.99-.89 1.99-1.99h-3.98zM12 6c2.76 0 5 2.24 5 5v7H7v-7c0-2.76 2.24-5 5-5zm0-4.5c-.83 0-1.5.67-1.5 1.5v1.17C7.36 4.85 5 7.65 5 11v6l-2 2v1h18v-1l-2-2v-6c0-3.35-2.36-6.15-5.5-6.83V3c0-.83-.67-1.5-1.5-1.5z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  refresh: ({ fill = '#B4B6C9' }) => (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.837 3.258A10 10 0 004.944 4.92L2 7.688V4H0v6a1 1 0 001 1h6V9H3.524l2.8-2.631a8 8 0 11-1.872 8.299l-1.885.664a10 10 0 1014.27-12.074z"
        fill={fill}
      />
    </Svg>
  ),
  brigade: (
    <Svg width={24} height={24} fill="none">
      <Path
        d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z"
        fill="#5B8DEF"
      />
    </Svg>
  ),
  visibilityOff: (
    <Svg width={24} height={24} fill="none">
      <Path
        d="M12 6a9.77 9.77 0 018.82 5.5 9.647 9.647 0 01-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68A11.738 11.738 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5l2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 01-2.5-2.5c0-.05.01-.08.01-.13zm-3.4-3.4l1.75 1.75a4.6 4.6 0 00-.36 1.78 4.507 4.507 0 006.27 4.14l.98.98c-.88.24-1.8.38-2.75.38a9.77 9.77 0 01-8.82-5.5c.7-1.43 1.72-2.61 2.93-3.53z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  visibility: (
    <Svg width={24} height={24} fill="none">
      <Path
        d="M12 6a9.77 9.77 0 018.82 5.5A9.77 9.77 0 0112 17a9.77 9.77 0 01-8.82-5.5A9.77 9.77 0 0112 6zm0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5a2.5 2.5 0 010 5 2.5 2.5 0 010-5zm0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  lock: (
    <Svg width={24} height={24} fill="none">
      <Path
        d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  send: ({ fill = '#5B8DEF' }) => (
    <Svg width={24} height={24} fill="none">
      <Path
        d="M4.01 6.03l7.51 3.22-7.52-1 .01-2.22zm7.5 8.72L4 17.97v-2.22l7.51-1zM2.01 3L2 10l15 2-15 2 .01 7L23 12 2.01 3z"
        fill={fill}
      />
    </Svg>
  ),
  user: (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 9c2.7 0 5.8 1.29 6 2v1H6v-.99c.2-.72 3.3-2.01 6-2.01zm0-11C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  next: (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Path d="M2 11h16.17l-5.59-5.59L14 4l8 8-8 8-1.41-1.41L18.17 13H2v-2z" fill="#8587B7" />
    </Svg>
  ),
  back: (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Path d="M22 11H5.83l5.59-5.59L10 4l-8 8 8 8 1.41-1.41L5.83 13H22v-2z" fill="#8587B7" />
    </Svg>
  ),
  analytics: (
    <Svg width={24} height={24} fill="none">
      <Rect x={3} y={12} width={4} height={9} rx={1} fill="#B4B6C9" />
      <Rect x={10} y={3} width={4} height={18} rx={1} fill="#B4B6C9" />
      <Rect x={17} y={7} width={4} height={14} rx={1} fill="#B4B6C9" />
    </Svg>
  ),
  telegramm: (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none">
      <Path d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z" fill="#37AEE2" />
      <Path
        d="M9.2 18.1c1.6-.8 3.5-1.7 5.2-2.4l8.8-3.6c.5-.1 1.6-.4 1.7.5-.1 1.2-.2 2.4-.4 3.6-.4 2.6-.8 5.2-1.3 7.8-.1.6-.6 1-1.2 1h-.1c-.2 0-.5-.1-.7-.2-1.7-1.2-3.5-2.3-5.1-3.4-.3-.6-.2-1.4.4-1.8 1.3-1.3 2.9-2.5 4.3-3.9.4-.8-.7-.1-1.1.1-1.9 1.4-3.8 2.7-5.9 3.8-1.1.6-2.3.1-3.3-.2-.7-.4-2.1-.7-1.3-1.3z"
        fill="#fff"
      />
    </Svg>
  ),
  whatsapp: (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none">
      <G clipPath="url(#prefix__clip0)" fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M1.176 35.232c.367-1.1.702-2.112 1.04-3.12.36-1.068.715-2.136 1.085-3.2a.658.658 0 00-.084-.668 17.822 17.822 0 01-3.154-11.84 18 18 0 1116.776 19.56 17.4 17.4 0 01-8.304-2.668.962.962 0 00-.91-.124c-1.975.652-3.96 1.28-5.94 1.916-.134.04-.27.076-.509.144z"
          fill="#67C15E"
        />
        <Path
          d="M12.154 10.005c1.032.01 1.118.067 1.477.896.435 1 .867 2.002 1.308 3.002a.85.85 0 01-.087.95c-.3.362-.624.703-.939 1.056-.47.522-.487.679-.115 1.255 1.314 2.135 3.327 3.805 5.732 4.755.722.277 1.031.21 1.508-.374.32-.39.614-.8.924-1.198a.791.791 0 01.212-.25.866.866 0 01.955-.063c1.182.523 2.35 1.077 3.524 1.618a.58.58 0 01.275.243c.061.107.084.23.066.35-.096 1.768-.637 2.796-2.93 3.564-1.03.349-2.045.16-3.061-.096-4.263-1.07-7.436-3.556-9.933-6.942-.777-1.049-1.518-2.116-1.854-3.382a4.625 4.625 0 01-.121-2.33c.16-.774.515-1.5 1.035-2.115.222-.311.525-.561.88-.726.355-.165.75-.238 1.144-.213z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h36v36H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  ),
  viber: (
    <Svg width={36} height={36} viewBox="0 0 36 36">
      <Circle cx={18} cy={18} r={18} fill="#7C509A" />
      <Path
        d="M24.254 8.546a30.984 30.984 0 00-11.581 0c-1.686.364-3.812 2.402-4.178 4.003a22.87 22.87 0 000 9.463c.44 1.601 2.565 3.64 4.178 4.003.073 0 .146.073.146.146v4.585c0 .219.294.364.44.146l2.2-2.256s1.758-1.82 2.052-2.111c0 0 .073-.073.146-.073 2.199.073 4.471-.146 6.67-.51 1.686-.364 3.812-2.402 4.178-4.003.66-3.13.66-6.333 0-9.463-.44-1.528-2.565-3.566-4.251-3.93zm.073 13.684c-.366.728-.806 1.31-1.539 1.674l-.66.219c-.293-.073-.513-.146-.733-.219-2.345-.946-4.544-2.256-6.303-4.149a17.504 17.504 0 01-2.42-3.64c-.292-.654-.586-1.236-.806-1.892-.22-.582.147-1.164.514-1.6.366-.438.806-.729 1.319-.947.366-.219.733-.073 1.026.218.587.728 1.173 1.456 1.613 2.257.293.51.22 1.091-.294 1.455-.146.073-.22.146-.366.292-.073.072-.22.145-.293.29-.147.22-.147.437-.073.656.586 1.674 1.685 2.984 3.371 3.712.293.146.513.218.88.218.513-.072.733-.655 1.1-.946.366-.291.806-.291 1.245-.073.367.219.733.51 1.173.801.367.291.733.51 1.1.8.22.146.293.51.146.874zm-3.078-5.459c-.147 0-.074 0 0 0-.293 0-.367-.146-.44-.364 0-.145 0-.364-.073-.51a1.382 1.382 0 00-.514-.8l-.44-.218c-.22-.073-.366-.073-.586-.073-.22-.073-.293-.219-.293-.437 0-.146.22-.291.367-.291 1.172.073 2.052.728 2.199 2.11v.292c0 .145-.074.291-.22.291zm-.733-3.203c-.367-.145-.733-.29-1.173-.364-.147 0-.367-.072-.513-.072-.22 0-.367-.146-.293-.364 0-.219.146-.364.366-.292.733.073 1.393.219 2.053.51 1.319.655 2.052 1.747 2.272 3.203v.946c-.074.291-.587.364-.66 0 0-.073-.073-.218-.073-.291 0-.655-.147-1.31-.44-1.893-.44-.655-.953-1.091-1.54-1.383zm3.958 4.368c-.22 0-.367-.219-.367-.437 0-.437-.073-.874-.146-1.31-.293-2.33-2.2-4.222-4.471-4.586-.367-.073-.733-.073-1.027-.146-.22 0-.513 0-.586-.29-.073-.22.147-.437.367-.437h.146c3.005.072.147 0 0 0 3.079.072 5.644 2.11 6.157 5.168.073.51.147 1.019.147 1.6.146.22 0 .438-.22.438z"
        fill="#fff"
      />
    </Svg>
  ),
  forward: (
    <Svg width={24} height={24} fill="none">
      <Path d="M2 11h16.17l-5.59-5.59L14 4l8 8-8 8-1.41-1.41L18.17 13H2v-2z" fill="#B4B6C9" />
    </Svg>
  ),
  btnSmPagLeft: ({ fill = '#B4B6C9' }) => (
    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" style={{ transform: [{ rotate: '180deg' }] }}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.531 12L8.5 7.36703L9.98448 6L16.5 12L9.98448 18L8.5 16.633L13.531 12Z"
        fill={fill}
      />
    </Svg>
  ),
  btnSmPagRight: ({ fill = '#B4B6C9' }) => (
    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.531 12L8.5 7.36703L9.98448 6L16.5 12L9.98448 18L8.5 16.633L13.531 12Z"
        fill={fill}
      />
    </Svg>
  ),
  info: (
    <Svg width="18" height="21" viewBox="0 0 18 21" fill="none">
      <Path
        d="M4 14H14V16H4V14ZM4 10H14V12H4V10ZM12 0H2C0.9 0 0 0.9 0 2V19C0 20.1 0.89 21 1.99 21H16C17.1 21 18 20.1 18 19V6L12 0ZM16 19H2V2H11V7H16V19Z"
        fill="#5B8DEF"
      />
    </Svg>
  ),
  content: (
    <Svg width="18" height="20" viewBox="0 0 18 20" fill="none">
      <Path
        d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 1.75C9.22 1.75 9.41 1.85 9.55 2C9.67 2.13 9.75 2.31 9.75 2.5C9.75 2.91 9.41 3.25 9 3.25C8.59 3.25 8.25 2.91 8.25 2.5C8.25 2.31 8.33 2.13 8.45 2C8.59 1.85 8.78 1.75 9 1.75ZM16 18H2V4H16V18ZM9 5C7.35 5 6 6.35 6 8C6 9.65 7.35 11 9 11C10.65 11 12 9.65 12 8C12 6.35 10.65 5 9 5ZM9 9C8.45 9 8 8.55 8 8C8 7.45 8.45 7 9 7C9.55 7 10 7.45 10 8C10 8.55 9.55 9 9 9ZM3 15.47V17H15V15.47C15 12.97 11.03 11.89 9 11.89C6.97 11.89 3 12.96 3 15.47ZM5.31 15C6 14.44 7.69 13.88 9 13.88C10.31 13.88 12.01 14.44 12.69 15H5.31Z"
        fill="#5B8DEF"
      />
    </Svg>
  ),
  archive: ({ fill = '#555770' }) => (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M20.54 5.23L19.15 3.55C18.88 3.21 18.47 3 18 3H6C5.53 3 5.12 3.21 4.84 3.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V6.5C21 6.02 20.83 5.57 20.54 5.23ZM6.24 5H17.76L18.57 5.97H5.44L6.24 5ZM5 19V8H19V19H5ZM13.45 10H10.55V13H8L12 17L16 13H13.45V10Z"
        fill={fill}
      />
    </Svg>
  ),
  delete: (
    <Svg width="14" height="18" viewBox="0 0 14 18" fill="none">
      <Path
        d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6ZM10.5 1L9.5 0H4.5L3.5 1H0V3H14V1H10.5Z"
        fill="#FF5C5C"
      />
    </Svg>
  ),
  deleted: ({ fill = 'white' }) => {
    return (
      <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <Path
          d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
          fill={fill}
        />
      </Svg>
    );
  },
  warning: ({ fill = '#B4B6C9' }) => (
    <Svg width="64" height="64" viewBox="0 0 64 64" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2688 8.97959L4.27032 53.0612C3.50161 54.3714 4.46406 56 6.00147 56H57.9985C59.5359 56 60.4984 54.3714 59.7297 53.0612L33.7312 8.97959C33.3437 8.32449 32.675 8 32 8C31.325 8 30.6501 8.32449 30.2688 8.97959ZM32 9.96054L6.00421 54.0375C6.00405 54.0378 6.00389 54.0381 6.00374 54.0383C6.00323 54.0392 6.00276 54.04 6.00234 54.0408H57.9977C57.9973 54.0401 57.9969 54.0394 57.9964 54.0386C57.9962 54.0382 57.996 54.0379 57.9958 54.0375L32.0008 9.96184C32.0007 9.96178 32.0007 9.96171 32.0007 9.96165C32.0004 9.96127 32.0002 9.9609 32 9.96054ZM5.99986 54.0402C5.99985 54.0404 5.99984 54.0406 5.99983 54.0408C5.99503 54.0407 5.99235 54.0404 5.99151 54.0403L5.99102 54.0402C5.99102 54.0402 5.99157 54.0403 5.99257 54.0406C5.9948 54.0412 5.99725 54.0421 5.99962 54.0432C5.99942 54.0449 5.99912 54.0466 5.99877 54.048C5.99848 54.0491 5.9984 54.049 5.99895 54.0477C5.99924 54.047 5.99982 54.0457 6.00078 54.0438C6.00101 54.0439 6.00124 54.044 6.00147 54.0441C6.00221 54.0445 6.00294 54.0449 6.00363 54.0453C6.00611 54.0468 6.0072 54.0478 6.0072 54.0478L6.007 54.0476C6.00621 54.0468 6.00452 54.0448 6.00275 54.0418C6.00258 54.0415 6.00242 54.0413 6.00226 54.041"
        fill={fill}
      />
      <Path
        d="M31.9997 48C31.2146 47.984 30.4672 47.6609 29.9176 47.1C29.3681 46.5391 29.0603 45.7852 29.0603 45C29.0603 44.2148 29.3681 43.4609 29.9176 42.9C30.4672 42.3391 31.2146 42.016 31.9997 42C32.7847 42.016 33.5322 42.3391 34.0818 42.9C34.6313 43.4609 34.9391 44.2148 34.9391 45C34.9391 45.7852 34.6313 46.5391 34.0818 47.1C33.5322 47.6609 32.7847 47.984 31.9997 48Z"
        fill={fill}
      />
      <Path
        d="M30 25.52C30 25.234 30.225 25 30.5 25H33.5C33.775 25 34 25.234 34 25.52V37.48C34 37.766 33.775 38 33.5 38H30.5C30.225 38 30 37.766 30 37.48V25.52Z"
        fill={fill}
      />
    </Svg>
  ),
  warningSmall: ({ fill = '#FF5C5C' }) => (
    <Svg width="22" height="19" fill="none" viewBox="0 0 22 19">
      <Path fill={fill} d="M11 3.99L18.53 17H3.47L11 3.99zM11 0L0 19h22L11 0zm1 14h-2v2h2v-2zm0-6h-2v4h2V8z" />
    </Svg>
  ),
  empty: (
    <Svg width="64" height="64" viewBox="0 0 64 64" fill="none">
      <Path
        d="M32 51.7027C49.6731 51.7027 64 48.592 64 44.7547C64 40.9174 49.6731 37.8066 32 37.8066C14.3269 37.8066 0 40.9174 0 44.7547C0 48.592 14.3269 51.7027 32 51.7027Z"
        fill="#EFF2FA"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3465 13.6419C18.961 12.7036 19.9326 12 21.093 12H42.907C44.0675 12 45.0391 12.7037 45.6536 13.643L56 25.2851V35.8367H8V25.2862L18.3465 13.6419ZM21.093 14C20.786 14 20.3562 14.1997 19.9934 14.7787L19.949 14.8494L10 26.0464V33.8367H54V26.0454L44.0508 14.8503L44.0063 14.7792C43.6435 14.1995 43.2138 14 42.907 14H21.093Z"
        fill="#B4B6C9"
      />
      <Path
        d="M41.613 28.8135C41.613 27.2205 42.607 25.9053 43.84 25.9043H55V43.9067C55 46.0139 53.68 47.741 52.05 47.741H11.95C10.32 47.741 9 46.0129 9 43.9067V25.9043H20.16C21.393 25.9043 22.387 27.2175 22.387 28.8106V28.8324C22.387 30.4255 23.392 31.7119 24.624 31.7119H39.376C40.608 31.7119 41.613 30.4136 41.613 28.8205V28.8135Z"
        fill="#EFF2FA"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.84 25L55 25C55.5523 25 56 25.4508 56 26.0069V44.1326C56 45.397 55.6079 46.5819 54.9294 47.4716C54.2504 48.3622 53.2415 49 52.05 49H11.95C10.7583 49 9.74947 48.3617 9.07052 47.4712C8.39215 46.5814 8 45.3965 8 44.1326V26.0069C8 25.4508 8.44772 25 9 25H20.16C21.1554 25 21.9838 25.5361 22.5307 26.2575C23.0771 26.9781 23.387 27.9286 23.387 28.933V28.955C23.387 30.2525 24.1631 30.8474 24.624 30.8474H39.376C39.8323 30.8474 40.613 30.2464 40.613 28.943V28.936C40.613 27.9316 40.9229 26.9809 41.4689 26.2598C42.0155 25.5381 42.8443 25.0008 43.84 25ZM10 27.0137V44.1326C10 44.9893 10.2679 45.7346 10.657 46.245C11.0455 46.7546 11.5117 46.9863 11.95 46.9863H52.05C52.4885 46.9863 52.9546 46.7547 53.3431 46.2453C53.7321 45.7352 54 44.9898 54 44.1326V27.0137H43.8405C43.6032 27.014 43.3179 27.1393 43.0593 27.4808C42.8001 27.8231 42.613 28.3364 42.613 28.936V28.943C42.613 30.8477 41.3837 32.8611 39.376 32.8611H24.624C22.6209 32.8611 21.387 30.8655 21.387 28.955V28.933C21.387 28.3334 21.1999 27.8209 20.941 27.4794C20.6827 27.1387 20.3976 27.0137 20.16 27.0137H10Z"
        fill="#B4B6C9"
      />
    </Svg>
  ),
  cancel: ({ fill = '#8587B7', stretch = false }) => (
    <Svg width={stretch ? '100%' : 24} height={stretch ? '100%' : 24} viewBox="0 0 24 24" fill="none">
      <Path
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
        fill={fill}
      />
    </Svg>
  ),
  addSign: (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <Path
        d="M22 10.99L19.56 8.2L19.9 4.51L16.29 3.69L14.4 0.5L11 1.96L7.6 0.5L5.71 3.69L2.1 4.5L2.44 8.2L0 10.99L2.44 13.78L2.1 17.48L5.71 18.3L7.6 21.5L11 20.03L14.4 21.49L16.29 18.3L19.9 17.48L19.56 13.79L22 10.99ZM18.05 12.47L17.49 13.12L17.57 13.97L17.75 15.92L15.85 16.35L15.01 16.54L14.57 17.28L13.58 18.96L11.8 18.19L11 17.85L10.21 18.19L8.43 18.96L7.44 17.29L7 16.55L6.16 16.36L4.26 15.93L4.44 13.97L4.52 13.12L3.96 12.47L2.67 11L3.96 9.52L4.52 8.87L4.43 8.01L4.25 6.07L6.15 5.64L6.99 5.45L7.43 4.71L8.42 3.03L10.2 3.8L11 4.14L11.79 3.8L13.57 3.03L14.56 4.71L15 5.45L15.84 5.64L17.74 6.07L17.56 8.02L17.48 8.87L18.04 9.52L19.33 10.99L18.05 12.47Z"
        fill="#06C270"
      />
      <Path
        d="M9.09004 12.75L6.77004 10.42L5.29004 11.91L9.09004 15.72L16.43 8.35999L14.95 6.87L9.09004 12.75Z"
        fill="#06C270"
      />
    </Svg>
  ),
  removeSign: (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <Path d="M10.6 14.2004L6.79999 10.4004L5.29999 11.9004L9.09999 15.7004L10.6 14.2004Z" fill="#8587B7" />
      <Path
        d="M13.6 19.0004L11.8 18.2004L11 17.8004L10.2 18.2004L8.4 19.0004L7.4 17.3004L7 16.5004L6.2 16.4004L4.3 15.9004L4.4 14.0004L4.5 13.1004L4 12.5004L2.7 11.0004L4 9.50039L4.5 8.90039L4.4 8.10039L2.2 5.90039L2.4 8.20039L0 11.0004L2.4 13.8004L2.1 17.5004L5.7 18.3004L7.6 21.5004L11 20.0004L14.4 21.5004L15.7 19.3004L14.2 17.9004L13.6 19.0004Z"
        fill="#8587B7"
      />
      <Path
        d="M19.9 17.5L19.6 13.8L22 11L19.6 8.2L19.9 4.5L16.3 3.7L14.4 0.5L11 2L7.6 0.5L5.7 3.7L5.2 3.8L2.4 1L1 2.4L19.6 21L21 19.6L19 17.6L19.9 17.5ZM7 5.5L7.4 4.8L8.4 3.1L10.2 3.9L11 4.1L11.8 3.8L13.6 3L14.6 4.7L15 5.5L15.8 5.7L17.7 6.1L17.5 8.1L17.4 8.9L18 9.5L19.3 11L18 12.5L17.4 13.2L17.5 14L17.7 16L17.3 16.1L13 11.8L16.4 8.4L15 6.9L11.6 10.3L6.8 5.5H7Z"
        fill="#8587B7"
      />
    </Svg>
  ),
  allBoreholesMapIcon: ({ stretch = false }) => (
    <Svg width={stretch ? '100%' : 120} height={stretch ? '100%' : 120} viewBox="0 0 120 120" fill="none">
      <Path
        d="M12 96.5C11.7 96.5 11.5 96.3 11.5 96V24C11.5 23.7 11.7 23.5 12 23.5H108C108.3 23.5 108.5 23.7 108.5 24V96C108.5 96.3 108.3 96.5 108 96.5H12Z"
        fill="white"
      />
      <Path
        d="M107 25V95H13V25H107ZM108 22H12C10.9 22 10 22.9 10 24V96C10 97.1 10.9 98 12 98H108C109.1 98 110 97.1 110 96V24C110 22.9 109.1 22 108 22Z"
        fill="#CED2DF"
      />
      <G opacity="0.6">
        <Path opacity="0.6" d="M17 47H13V49H17V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M23 47H19V49H23V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M71 47H67V49H71V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M29 47H25V49H29V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M47 47H43V49H47V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M53 47H49V49H53V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M107 47H103V49H107V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M95 47H91V49H95V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M101 47H97V49H101V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M77 47H73V49H77V47Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M29 71H25V73H29V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M23 71H19V73H23V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M17 71H13V73H17V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M107 71H103V73H107V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M71 71H67V73H71V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M101 71H97V73H101V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M95 71H91V73H95V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M53 71H49V73H53V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M47 71H43V73H47V71Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M61 25H59V45C59.7 45 60.4 45.2 61 45.4V25Z" fill="#CED2DF" />
        <Path
          opacity="0.6"
          d="M61 54.5996C60.4 54.8996 59.7 54.9996 59 54.9996V70.9996H55V72.9996H59V94.9996H61V72.9996H65V70.9996H61V54.5996Z"
          fill="#CED2DF"
        />
        <Path opacity="0.6" d="M84 39C84.3 39 84.7 39 85 39.1V25H83V39.1C83.3 39 83.7 39 84 39Z" fill="#CED2DF" />
        <Path
          opacity="0.6"
          d="M89 49V47H88C87.1 48.2 85.7 49 84 49C82.3 49 80.9 48.2 80 47H79V49H83V71H80.6C80.3 71.8 79.7 72.5 79 73H83V95H85V73H89V71H85V49H89Z"
          fill="#CED2DF"
        />
        <Path
          opacity="0.6"
          d="M36 77.0004C35.7 77.0004 35.3 77.0004 35 76.9004V95.0004H37V76.9004C36.7 77.0004 36.3 77.0004 36 77.0004Z"
          fill="#CED2DF"
        />
        <Path
          opacity="0.6"
          d="M37 47V25H35V47H31V49H35V67.1C35.3 67 35.7 67 36 67C36.3 67 36.7 67 37 67.1V49H41V47H37Z"
          fill="#CED2DF"
        />
      </G>
      <Path
        d="M84 37C80.1 37 77 40.1 77 44C77 47.9 80.1 51 84 51C87.9 51 91 47.9 91 44C91 40.1 87.9 37 84 37ZM84 49C81.2 49 79 46.8 79 44C79 41.2 81.2 39 84 39C86.8 39 89 41.2 89 44C89 46.8 86.8 49 84 49Z"
        fill="#CED2DF"
      />
      <Path
        d="M84 47C85.6569 47 87 45.6569 87 44C87 42.3431 85.6569 41 84 41C82.3431 41 81 42.3431 81 44C81 45.6569 82.3431 47 84 47Z"
        fill="#CED2DF"
      />
      <Path
        d="M76 62C72.1 62 69 65.1 69 69C69 72.9 72.1 76 76 76C79.9 76 83 72.9 83 69C83 65.1 79.9 62 76 62ZM76 74C73.2 74 71 71.8 71 69C71 66.2 73.2 64 76 64C78.8 64 81 66.2 81 69C81 71.8 78.8 74 76 74Z"
        fill="#CED2DF"
      />
      <Path
        d="M76 72C77.6569 72 79 70.6569 79 69C79 67.3431 77.6569 66 76 66C74.3431 66 73 67.3431 73 69C73 70.6569 74.3431 72 76 72Z"
        fill="#CED2DF"
      />
      <Path
        d="M59 43C55.1 43 52 46.1 52 50C52 53.9 55.1 57 59 57C62.9 57 66 53.9 66 50C66 46.1 62.9 43 59 43ZM59 55C56.2 55 54 52.8 54 50C54 47.2 56.2 45 59 45C61.8 45 64 47.2 64 50C64 52.8 61.8 55 59 55Z"
        fill="#CED2DF"
      />
      <Path
        d="M59 53C60.6569 53 62 51.6569 62 50C62 48.3431 60.6569 47 59 47C57.3431 47 56 48.3431 56 50C56 51.6569 57.3431 53 59 53Z"
        fill="#CED2DF"
      />
      <Path
        d="M36 65C32.1 65 29 68.1 29 72C29 75.9 32.1 79 36 79C39.9 79 43 75.9 43 72C43 68.1 39.9 65 36 65ZM36 77C33.2 77 31 74.8 31 72C31 69.2 33.2 67 36 67C38.8 67 41 69.2 41 72C41 74.8 38.8 77 36 77Z"
        fill="#CED2DF"
      />
      <Path
        d="M36 75C37.6569 75 39 73.6569 39 72C39 70.3431 37.6569 69 36 69C34.3431 69 33 70.3431 33 72C33 73.6569 34.3431 75 36 75Z"
        fill="#CED2DF"
      />
    </Svg>
  ),
  boreholeMapIcon: ({ stretch = false }) => (
    <Svg width={stretch ? '100%' : 120} height={stretch ? '100%' : 120} viewBox="0 0 120 120" fill="none">
      <Path
        d="M98 30H84.8C83.9 24.3 79 20 73 20C72.9 20 72.7 20 72.6 20C66.8 20.2 62.1 24.5 61.2 30H22C20.9 30 20 30.9 20 32V88C20 89.1 20.9 90 22 90H98C99.1 90 100 89.1 100 88V32C100 30.9 99.1 30 98 30Z"
        fill="white"
      />
      <G opacity="0.6">
        <Path opacity="0.6" d="M48 33H46V87H48V33Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M53 50H49V52H53V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M71 50H67V52H71V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M65 50H61V52H65V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M59 50H55V52H59V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M27 50H23V52H27V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M39 50H35V52H39V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M33 50H29V52H33V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M45 50H41V52H45V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M79 50H75V52H79V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M97 50H93V52H97V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M91 50H87V52H91V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M85 50H81V52H85V50Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M45 68H41V70H45V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M33 68H29V70H33V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M97 68H93V70H97V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M27 68H23V70H27V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M91 68H87V70H91V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M39 68H35V70H39V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M79 68H75V70H79V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M65 68H61V70H65V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M71 68H67V70H71V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M59 68H55V70H59V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M53 68H49V70H53V68Z" fill="#CED2DF" />
        <Path opacity="0.6" d="M85 68H81V70H85V68Z" fill="#CED2DF" />
        <Path
          opacity="0.6"
          d="M74 49.0996C73.7 49.3996 73.3 49.6996 73 49.9996C72.7 49.6996 72.3 49.3996 72 49.0996V86.9996H74V49.0996Z"
          fill="#CED2DF"
        />
      </G>
      <Path
        d="M98 30H84.8C83.9 24.3 79 20 73.1 20H73C73 20 73 20 72.9 20C72.8 20 72.7 20 72.5 20C66.7 20.2 62 24.5 61.1 30H22C20.9 30 20 30.9 20 32V88C20 89.1 20.9 90 22 90H98C99.1 90 100 89.1 100 88V32C100 30.9 99.1 30 98 30ZM66.4 25.8C68 24.1 70.2 23.1 72.6 23C72.7 23 72.8 23 72.9 23H73H73.1C78 23 82 27 82 31.9C82 32 82 32.1 82 32.2C82 34.8 80.4 39.2 73 45.9C65.6 39.2 64 34.8 64 32.2C63.9 29.8 64.8 27.6 66.4 25.8ZM97 87H23V33H61C61 32.8 61 32.5 61 32.3C61 37.3 65 43.2 73 50C81 43.2 85 37.3 85 32.3C85 32.5 85 32.8 85 33H97V87Z"
        fill="#CED2DF"
      />
      <Path
        d="M73 35C74.6569 35 76 33.6569 76 32C76 30.3431 74.6569 29 73 29C71.3431 29 70 30.3431 70 32C70 33.6569 71.3431 35 73 35Z"
        fill="#CED2DF"
      />
    </Svg>
  ),
  documentIcon: ({ stretch = false }) => (
    <Svg width={stretch ? '100%' : 64} height={stretch ? '100%' : 76} viewBox="0 0 64 76" fill="none">
      <Path d="M2 0H45L64 19V74C64 75.1 63.1 76 62 76H2C0.9 76 0 75.1 0 74V2C0 0.9 0.9 0 2 0Z" fill="white" />
      <Path
        d="M45 0H2C0.9 0 0 0.9 0 2V74C0 75.1 0.9 76 2 76H62C63.1 76 64 75.1 64 74V19L45 0ZM61 20.2V73H3V3H43.8L44 3.2V17C44 18.7 45.3 20 47 20H60.8L61 20.2Z"
        fill="#CED2DF"
      />
      <Path
        opacity="0.6"
        d="M10 20H37C37.6 20 38 19.6 38 19V13C38 12.4 37.6 12 37 12H10C9.4 12 9 12.4 9 13V19C9 19.6 9.4 20 10 20Z"
        fill="#CED2DF"
      />
      <Path
        opacity="0.6"
        d="M10 34H54C54.6 34 55 33.6 55 33C55 32.4 54.6 32 54 32H10C9.4 32 9 32.4 9 33C9 33.6 9.4 34 10 34Z"
        fill="#CED2DF"
      />
      <Path
        opacity="0.6"
        d="M54 38H10C9.4 38 9 38.4 9 39C9 39.6 9.4 40 10 40H54C54.6 40 55 39.6 55 39C55 38.4 54.6 38 54 38Z"
        fill="#CED2DF"
      />
      <Path
        opacity="0.6"
        d="M54 44H10C9.4 44 9 44.4 9 45C9 45.6 9.4 46 10 46H54C54.6 46 55 45.6 55 45C55 44.4 54.6 44 54 44Z"
        fill="#CED2DF"
      />
      <Path
        opacity="0.6"
        d="M54 50H10C9.4 50 9 50.4 9 51C9 51.6 9.4 52 10 52H54C54.6 52 55 51.6 55 51C55 50.4 54.6 50 54 50Z"
        fill="#CED2DF"
      />
      <Path
        opacity="0.6"
        d="M54 56H10C9.4 56 9 56.4 9 57C9 57.6 9.4 58 10 58H54C54.6 58 55 57.6 55 57C55 56.4 54.6 56 54 56Z"
        fill="#CED2DF"
      />
      <Path
        opacity="0.6"
        d="M33 62H10C9.4 62 9 62.4 9 63C9 63.6 9.4 64 10 64H33C33.6 64 34 63.6 34 63C34 62.4 33.6 62 33 62Z"
        fill="#CED2DF"
      />
    </Svg>
  ),
  check: (
    <Svg width="18" height="14" viewBox="0 0 18 14" fill="none">
      <Path
        d="M6.00039 11.2001L1.80039 7.0001L0.400391 8.4001L6.00039 14.0001L18.0004 2.0001L16.6004 0.600098L6.00039 11.2001Z"
        fill="white"
      />
    </Svg>
  ),
  download: (
    <Svg width={24} height={24} fill="none">
      <Path d="M17 12h-4V3h-2v9H7l5 5 5-5z" fill="#5B8DEF" />
      <Path fillRule="evenodd" clipRule="evenodd" d="M2 21h20v-4h-2v2H4v-2H2v4z" fill="#5B8DEF" />
    </Svg>
  ),
  upload: ({ fill = '#5B8DEF' }) => (
    <Svg width="36" height="37" fill="none" viewBox="0 0 36 37">
      <Path
        fill={fill}
        d="M35.494 15.41l-.01-.037L30.225 2.01A1.81 1.81 0 0028.5.734H7.181c-.796 0-1.504.53-1.73 1.294L.536 15.256l-.015.033-.009.038c-.06.23-.08.464-.047.693a3.61 3.61 0 00-.01.225v17.152a2.855 2.855 0 002.85 2.85h29.4c1.571 0 2.85-1.28 2.855-2.85V16.245c0-.06 0-.122-.004-.173.019-.23 0-.45-.061-.661zm-13.866-2.015l-.014.736c-.037 2.105-1.49 3.52-3.614 3.52-1.036 0-1.926-.332-2.569-.965-.642-.633-.993-1.514-1.012-2.555l-.014-.736H4.758l3.727-9.06h18.712l3.83 9.06h-9.399zm-17.578 3.6h7.374c1.139 2.677 3.562 4.257 6.58 4.257 1.58 0 3.048-.441 4.234-1.275 1.04-.732 1.851-1.754 2.376-2.982h7.336v15.652H4.05V16.995z"
      />
    </Svg>
  ),
  image: ({ fill = '#5B8DEF' }) => (
    <Svg width="20" height="18" fill="none" viewBox="0 0 20 18">
      <Path
        fill={fill}
        d="M18 2h-3.17L13 0H7L5.17 2H2C.9 2 0 2.9 0 4v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H2V4h4.05l1.83-2h4.24l1.83 2H18v12zM10 5c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"
      />
    </Svg>
  ),
  print: ({ fill = '#8587B7' }) => (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Path
        d="M19 8H18V3H6V8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM8 5H16V8H8V5ZM16 17V19H8V15H16V17ZM18 15V13H6V15H4V11C4 10.45 4.45 10 5 10H19C19.55 10 20 10.45 20 11V15H18Z"
        fill={fill}
      />
      <Path
        d="M18 12.5C18.5523 12.5 19 12.0523 19 11.5C19 10.9477 18.5523 10.5 18 10.5C17.4477 10.5 17 10.9477 17 11.5C17 12.0523 17.4477 12.5 18 12.5Z"
        fill={fill}
      />
    </Svg>
  ),
  arrowLeft: ({ fill = '#B4B6C9' }) => (
    <Svg width="12" height="20" fill="none" viewBox="0 0 12 20">
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M9.707 0l1.414 1.414-8.293 8.293L11.121 18l-1.414 1.414L0 9.707 9.707 0z"
        clipRule="evenodd"
      />
    </Svg>
  ),
  arrowRight: ({ fill = '#B4B6C9' }) => (
    <Svg width="12" height="20" fill="none" viewBox="0 0 12 20">
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M2.293 0L.879 1.414l8.293 8.293L.879 18l1.414 1.414L12 9.707 2.293 0z"
        clipRule="evenodd"
      />
    </Svg>
  ),
  mapCircleMarker: ({ fill = '#ff5c5c' }) => (
    <Svg width={20} height={20} fill="none">
      <Circle cx={10} cy={10} r={8} strokeWidth={2} stroke="white" fill={fill} />
    </Svg>
  ),
  accepted: (
    <Svg width={20} height={20} fill="none">
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="#06C270"
      />
      <path d="M8.6 11.6L5.4 8.4L4 9.8L8.6 14.4L16.6 6.4L15.2 5L8.6 11.6Z" fill="white" />
    </Svg>
  ),
  rejected: (
    <Svg width={20} height={20} fill="none">
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="#FF5C5C"
      />
      <path
        d="M6.41 15L10 11.41L13.59 15L15 13.59L11.41 10L15 6.41L13.59 5L10 8.59L6.41 5L5 6.41L8.59 10L5 13.59L6.41 15Z"
        fill="white"
      />
    </Svg>
  ),
  unselect: (
    <Svg width={20} height={20} fill="none">
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
        fill="#FFB800"
      />
    </Svg>
  ),
  play: ({ fill = 'white' }): ReactElement => (
    <Svg width={24} height={24} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
      <Path
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8s-3.589 8-8 8z"
        fill={fill}
      />
      <Path d="M9 17l8-5l-8-5z" fill={fill} />
    </Svg>
  ),
  stop: ({ fill = 'white' }): ReactElement => (
    <Svg width={24} height={24} preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
      <Path
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8s8 3.589 8 8s-3.589 8-8 8z"
        fill={fill}
      />
      <Path d="M9 9h6v6H9z" fill={fill} />
    </Svg>
  )
};

export default svgs;
