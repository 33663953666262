import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { HStack, VStack, Pressable, ScrollView, Box, Input, Text, ChevronRightIcon, Divider } from 'native-base';
import { OverlayContainer } from '@react-native-aria/overlays';

import { codificatorStore } from '../../stores';
import { CodificatorSelection, CodificatorSelectionItem, translationKeyRegExp } from '../../stores/codificator';
import { Form } from '../../utils/form';

import { GeoDescriptionItem } from './geo-description-item';
import Icon from '../icon/icon';

type GeoDescriptionListProps<T extends string = 'geoDescription'> = {
  selection?: CodificatorSelection;
  form?: Form<{ [key in T]: CodificatorSelection }>;
  error?: boolean;
  onAdd?: (selectionItem: CodificatorSelectionItem) => void;
  onRemove?: (index: number) => void;
};

export const GeoDescriptionList: FC<GeoDescriptionListProps> = observer((props): ReactElement => {
  const { selection = [], form, error, onAdd, onRemove } = props;
  const { control } = form || {};

  const { t } = useTranslation();
  const [editable, setEditable] = useState(false);
  const [selectionText, setSelectionText] = useState('');

  const validSelection = codificatorStore.validSelection(selection);
  const selectOptions = codificatorStore.selectOptions(validSelection);
  const suggestOptions = codificatorStore.suggestOptions(validSelection, selectionText);

  if (editable && selectOptions.length === 0) {
    setEditable(false);
  }

  const startSelection = () => setEditable(true);
  const endSelection = () => {
    setEditable(false);
    setSelectionText('');
  };
  const cancel = () => {
    if (selectionText.trim() === '') {
      setEditable(false);
    } else {
      setSelectionText('');
    }
  };

  if (!codificatorStore.items) {
    return <></>;
  }

  const renderedSelection = form ? validSelection : selection;
  return (
    <>
      <VStack space={2}>
        {renderedSelection.map((item, index) => {
          const { levelId, key: itemKey } = item;
          const valid = index < validSelection.length;
          const key = `${levelId}-${itemKey}`;
          return (
            <GeoDescriptionItem
              key={key}
              index={index}
              levelId={levelId}
              itemKey={itemKey}
              valid={valid}
              onRemove={control && onRemove ? onRemove : undefined}
            />
          );
        })}
        {!!control && onAdd && selectOptions.length > 0 && (
          <Pressable
            paddingX={4}
            paddingY={2}
            fontSize={14}
            fontWeight={400}
            backgroundColor="white"
            borderRadius="lg"
            borderWidth={1}
            borderColor={error ? 'error' : 'light.light-5'}
            _hover={{ borderColor: 'primary.default' }}
            onPress={startSelection}
          >
            {({ isHovered }) => {
              const color = isHovered ? 'primary.default' : 'light.light-5';
              return (
                <HStack justifyContent="space-between" alignItems="center">
                  <Text color={color}>{t('borehole-layer-geo-description.add')}</Text>
                  <Box width={5} height={5}>
                    <ChevronRightIcon color={color} size={5} />
                  </Box>
                </HStack>
              );
            }}
          </Pressable>
        )}
      </VStack>
      {editable && (
        <OverlayContainer>
          <Pressable
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            backgroundColor="black"
            opacity={0.56}
            onPress={endSelection}
          />
          <ScrollView
            minHeight={300}
            position="absolute"
            top={0}
            bottom={0}
            right={0}
            width={400}
            backgroundColor="white"
            borderLeftWidth={1}
            borderLeftColor="light.light-5"
            keyboardShouldPersistTaps="handled"
          >
            <Box padding={2}>
              <Input
                variant="ghost"
                size="lg"
                backgroundColor="white"
                placeholder={t('borehole-layer-geo-description.suggest-placeholder')}
                autoFocus
                InputRightElement={
                  <Pressable onPress={cancel}>
                    <Icon name="cancel" marginRight={2} />
                  </Pressable>
                }
                value={selectionText}
                onChangeText={setSelectionText}
              />
            </Box>
            <Divider />
            <Box paddingY={2}>
              {suggestOptions.map((item) => {
                const { levelId, key: itemKey } = item;
                const key = `${levelId}-${itemKey}`;
                return (
                  <Pressable
                    key={key}
                    onPress={() => {
                      setSelectionText('');
                      if (onAdd) {
                        onAdd({ levelId, key: itemKey });
                      }
                    }}
                  >
                    {({ isHovered }) => {
                      const backgroundColor = isHovered ? '#e9eeff' : 'white';
                      return (
                        <Box
                          key={key}
                          paddingX={4}
                          height={14}
                          justifyContent="center"
                          backgroundColor={backgroundColor}
                        >
                          <Text>
                            {t(`codificator.${itemKey.replace(translationKeyRegExp, '-')}`, { defaultValue: itemKey })}
                          </Text>
                        </Box>
                      );
                    }}
                  </Pressable>
                );
              })}
            </Box>
          </ScrollView>
        </OverlayContainer>
      )}
    </>
  );
});
