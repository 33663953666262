import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, VStack, View, Pressable, Text } from 'native-base';

import Icon from '../../components/icon';
import { DeleteButton } from '../../components/delete-button';
import { SUBJECT } from '../../utils/permissions';
import { ActionView } from '../../components/action';

type DocumentProps = {
  id: number;
  fileName: string;
  title?: string;
  width?: string | number;
  height?: string | number;
  titleHeight?: string | number;
  isEditable: boolean;
  onClick: (id: number) => void;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
};

export const Document: React.FC<DocumentProps> = (props) => {
  const { id, fileName, title, width, height, titleHeight, isEditable, onClick, onEdit, onDelete } = props;
  const { t } = useTranslation();

  const handlePress = () => onClick(id);
  const handleEdit = () => onEdit(id);

  return (
    <Pressable onPress={handlePress}>
      <View width={width} height={height}>
        <Icon name="documentIcon" stretch />
      </View>
      {title && (
        <View flexDirection="row" justifyContent="center" alignItems="center" maxWidth={width} height={titleHeight}>
          <Text fontSize={14} lineHeight={18} noOfLines={2} isTruncated textAlign="center">
            {title}
          </Text>
        </View>
      )}
      {isEditable && (
        <HStack position="absolute" space={1} top={0} right={0}>
          <ActionView id="edit" actionCallback={handleEdit} item={props} iconName="edit" />
          <DeleteButton
            item={props}
            subject={SUBJECT.DOCUMENTS}
            markToDeleteConfirmationText=""
            deleteConfirmationText={t('document.delete-confirmation', { fileName })}
            onMark={() => console.log()}
            onDelete={onDelete}
          />
        </HStack>
      )}
    </Pressable>
  );
};

Document.displayName = Document.name;
