import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation from './ru/translation.json';

const resources = {
  ru: {
    translation
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    nsSeparator: ':',
    resources,
    lng: 'ru',

    interpolation: {
      escapeValue: false, // react already safes from xss
      prefix: '{',
      suffix: '}'
    },

    react: {
      useSuspense: true
    },

    returnEmptyString: false
  });

export { i18n };
