import { number, undefined, union, string, boolean, type } from 'io-ts';

export type Worker = {
	id: number;
	createdAt: number;
	lastUpdatedAt: number | undefined;
	name: string;
	phoneNumber: string | undefined;
	active: boolean;
};
export const WorkerIO = type(
	{
		id: number,
		createdAt: number,
		lastUpdatedAt: union([number, undefined]),
		name: string,
		phoneNumber: union([string, undefined]),
		active: boolean,
	},
	'Worker',
);
