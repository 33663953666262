import React, { FC, useEffect, useRef } from 'react';
import { Input } from 'native-base';
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native';
import { AcceptedStatus, AcceptedType } from './types';

import { boreholeSampleTableStore } from '../../../stores';

type BoreholeSampleCommentProps = {
  accepted: AcceptedType;
  id: number;
  comment: string | undefined;
};

export const BoreholeSampleCommentTotal: FC<BoreholeSampleCommentProps> = ({ accepted, id, comment }) => {
  const input = useRef();

  useEffect(() => {
    /* eslint-disable */
    if (accepted === AcceptedStatus.Rejected) {
      // @ts-ignore
      input.current.value = 'Образец не получен';
    } else if (accepted === AcceptedStatus.Accepted) {
      // @ts-ignore
      input.current.value = '';
    } else {
      // @ts-ignore
      input.current.value = comment || '';
    }
    /* eslint-enable */
  }, [accepted, input.current]);

  const addComment = (event: NativeSyntheticEvent<TextInputFocusEventData>) => {
    /* eslint-disable */
    // @ts-ignore
    boreholeSampleTableStore.addComment(id, { comment: event.target.value }).catch();
    /* eslint-enable */
  };

  return (
    <Input
      ref={input}
      size="sm"
      editable={accepted === AcceptedStatus.Undefined}
      width={208}
      isDisabled={accepted !== AcceptedStatus.Undefined}
      onBlur={addComment}
      placeholder=""
    />
  );
};
