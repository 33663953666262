import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { FacilityDeletedTableContainer } from '../../../features/facility/facility-deleted/facility-deleted-table.container';
import { PageProps } from '../../types';

type FacilityDeletedListPageProps = PageProps<'Facilities'>;

export const FacilityDeletedListPage: FC<FacilityDeletedListPageProps> = () => {
  return (
    <Page>
      <FacilityDeletedTableContainer />
    </Page>
  );
};

FacilityDeletedListPage.displayName = FacilityDeletedListPage.name;
