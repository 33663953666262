import React from 'react';
import { useTranslation } from 'react-i18next';
import { Facility } from '@geo/commons/models/Facility';
import { User } from '@geo/commons/models/User';
import { dateFormatLocale } from '@geo/commons/utils/date';
import { translationKeyRegExp } from '@geo/commons/stores/codificator';
import { centimetersToMeters } from '@geo/commons/utils/units';
import { ConsolidatedStatementRow } from '@geo/api/generated/swagger.json/components/schemas/ConsolidatedStatementRow';

import { PrintTemplate } from 'components/print-template/print-template';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './styles.module.css';

type PrintBoreholeProps = {
  rows: ConsolidatedStatementRow[];
  facility: Facility;
  user: User;
};

export const BoreholeLayersPrint = React.forwardRef<HTMLDivElement, PrintBoreholeProps>((props, ref) => {
  const { user, rows, facility } = props;
  const { t } = useTranslation();
  const countOfApproved = rows.filter((e) => e.accepted === 'ACCEPTED').length;

  return (
    <PrintTemplate ref={ref}>
      <>
        <table className={styles.table}>
          <thead>
            <tr>
              <th rowSpan={2}>{t('borehole.sample.print.number')}</th>
              <th colSpan={2}>{t('borehole.sample.print.place')}</th>
              <th colSpan={3}>{t('borehole.sample.print.description')}</th>
              <th rowSpan={2}>{t('borehole.sample.print.status')}</th>
              <th rowSpan={2}>{t('borehole.sample.print.comments')}</th>
            </tr>
            <tr>
              <th>{t('borehole.sample.print.boreholeId')}</th>
              <th>{t('borehole.sample.print.depth')}</th>
              <th>{t('borehole.sample.print.name')}</th>
              <th>{t('borehole.sample.print.structure')}</th>
              <th>{t('borehole.sample.print.type')}</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              const {
                index,
                boreholeId,
                sampleType,
                accepted,
                depthFrom,
                depthTo,
                comment,
                soilName,
                layerTypes = []
              } = row;
              let layerType = '';
              let acceptedLabel = '';
              switch (accepted) {
                case 'ACCEPTED':
                  acceptedLabel = 'Принят';
                  break;
                case 'REJECTED':
                  acceptedLabel = 'Не принят';
                  break;
                default:
                  acceptedLabel = '';
              }
              let descriptionStyle;
              if (layerTypes.includes('thawed') && layerTypes.includes('frozen')) {
                layerType = t('borehole.sample.print.descriptionError');
                descriptionStyle = styles.descriptionError;
              } else if (layerTypes.includes('thawed')) {
                layerType = t('borehole.sample.print.thawed');
              } else if (layerTypes.includes('frozen')) {
                layerType = t('borehole.sample.print.frozen');
              }
              return (
                <tr key={index}>
                  <td className={styles.bold}>{index}</td>
                  <td>{boreholeId}</td>
                  <td>{`${centimetersToMeters(depthFrom)} — ${centimetersToMeters(depthTo)}`}</td>
                  <td>{soilName && t(`codificator.${soilName.replace(translationKeyRegExp, '-')}`)}</td>
                  <td>{t(`sample-type.${sampleType.toLowerCase()}`)}</td>
                  <td className={descriptionStyle}>{layerType}</td>
                  <td>{acceptedLabel}</td>
                  <td>{comment}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p className={styles.boreholeName}>Объект: {facility?.name}</p>

        <table className={styles.withoutBorders}>
          <tbody>
            <tr>
              <td>
                <p className={styles.info}>
                  {t('borehole.sample.print.dateOfSampling')}: {dateFormatLocale(new Date().valueOf())}
                </p>
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td className={styles.column}>
                <p className={styles.info}>
                  {t('borehole.sample.print.countOfSamples')}: {rows.length}
                </p>
              </td>
              <td className={styles.autoColumn} />
              <td className={styles.column}>
                <p className={styles.info}>{t('borehole.sample.print.author')}:</p>
              </td>
            </tr>
            <tr>
              <td className={styles.column}>
                <p className={styles.info}>
                  {t('borehole.sample.print.countOfApproved')} : {countOfApproved}
                </p>
              </td>
              <td className={styles.autoColumn} />
              <td className={styles.column}>
                <p className={styles.info}>
                  {t('borehole.sample.print.approval')}: {user.name}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    </PrintTemplate>
  );
});

BoreholeLayersPrint.displayName = BoreholeLayersPrint.name;
