import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { FacilityUpdateContainer } from '../../../features/facility/facility-update/facility-update.container';
import { PageProps } from '../../types';

type FacilityUpdatingProps = PageProps<'FacilityUpdate'>;

export const FacilityUpdatePage: FC<FacilityUpdatingProps> = ({ route }) => {
  const { params } = route;
  const facilityId = params?.facilityId;
  if (!facilityId) {
    return null;
  }
  return (
    <Page>
      <FacilityUpdateContainer facilityId={facilityId} />
    </Page>
  );
};
