import { Ability, AbilityBuilder } from '@casl/ability';

import { User } from '@geo/commons/models/User';

export const ROLE = {
  Manager: 'ROLE_MANAGER',
  Geologist: 'ROLE_GEOLOGIST',
  OfficeWorker: 'ROLE_OFFICE_WORKER',
  Assistant: 'ROLE_ASSISTANT'
} as const;

export const SUBJECT = {
  AUDIT: 'audit',
  FACILITY: 'facility',
  COMMENT: 'comment',
  BOREHOLE: 'borehole',
  BOREHOLE_LAYER: 'boreholeLayer',
  ROUTE: 'route',
  OFFLINE: 'offline',
  PHOTO: 'photo',
  DOCUMENTS: 'documents',
  SHIFT: 'shift'
} as const;

export const ACTION = {
  CREATE: 'create',
  DELETE: 'delete',
  EDIT: 'edit',
  MARK_TO_ARCHIVE: 'markToArchive',
  MARK_TO_DELETE: 'markToDelete',
  CANCEL_MARK_TO_DELETE: 'cancelMarkToDelete',
  SEE_MARKED_FACILITIES: 'seeMarkedFacilities',
  SEE_ARCHIVED_FACILITIES: 'seeArchivedFacilities',
  ADD_SIGN: 'addSign',
  REMOVE_SIGN: 'removeSign',
  SET_RESPONSIBLE_FOR_FACILITY: 'setResponsibleForFacility',
  PRINT: 'print',
  ENABLE: 'enable'
} as const;

export function defineAbilitiesFor(user?: User): Ability {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { can, rules } = new AbilityBuilder(Ability);

  if (user) {
    const role = user.roles[0];

    can(ACTION.EDIT, SUBJECT.COMMENT, { creatorId: user.id });
    can(ACTION.DELETE, SUBJECT.COMMENT, { creatorId: user.id });

    if (role !== ROLE.Assistant) {
      can(ACTION.EDIT, SUBJECT.BOREHOLE, {
        status: { $in: ['NEW', 'IN_PROGRESS', 'PROBLEM'] },
        facilityStatus: { $nin: ['ARCHIVED'] },
        markToDelete: false
      });
    }

    if (role === ROLE.Manager || role === ROLE.OfficeWorker) {
      can(ACTION.CREATE, SUBJECT.PHOTO);
      can(ACTION.DELETE, SUBJECT.PHOTO);
      can(ACTION.DELETE, SUBJECT.BOREHOLE, {
        status: { $in: ['NEW', 'IN_PROGRESS', 'PROBLEM'] },
        facilityStatus: { $nin: ['ARCHIVED'] },
        markToDelete: false
      });

      can(ACTION.REMOVE_SIGN, SUBJECT.BOREHOLE, {
        status: { $in: [true] },
        facilityStatus: { $nin: ['ARCHIVED'] },
        markToDelete: false
      });
    }

    if (role === ROLE.Manager) {
      can(ACTION.CREATE, SUBJECT.AUDIT);
      can(ACTION.DELETE, SUBJECT.COMMENT);
      can(ACTION.CREATE, SUBJECT.FACILITY);
      can(ACTION.EDIT, SUBJECT.FACILITY, {
        status: { $nin: ['ARCHIVED'] }
      });
      can(ACTION.DELETE, SUBJECT.FACILITY);
      can(ACTION.MARK_TO_ARCHIVE, SUBJECT.FACILITY);
      can(ACTION.CANCEL_MARK_TO_DELETE, SUBJECT.FACILITY);
      can(ACTION.SET_RESPONSIBLE_FOR_FACILITY, SUBJECT.FACILITY);

      can(ACTION.CREATE, SUBJECT.BOREHOLE);
      can(ACTION.PRINT, SUBJECT.BOREHOLE);

      can(ACTION.SEE_MARKED_FACILITIES, SUBJECT.ROUTE);
      can(ACTION.SEE_ARCHIVED_FACILITIES, SUBJECT.ROUTE);

      can(ACTION.EDIT, SUBJECT.BOREHOLE_LAYER);
      can(ACTION.DELETE, SUBJECT.BOREHOLE_LAYER);

      can(ACTION.EDIT, SUBJECT.DOCUMENTS);
      can(ACTION.CREATE, SUBJECT.DOCUMENTS);
      can(ACTION.DELETE, SUBJECT.DOCUMENTS);
    }

    if (role === ROLE.Geologist) {
      can(ACTION.CREATE, SUBJECT.PHOTO);
      can(ACTION.DELETE, SUBJECT.PHOTO);
      can(ACTION.ADD_SIGN, SUBJECT.BOREHOLE, { status: { $in: [false] } });

      const boreholeLayerManageConditions = {
        status: { $nin: ['CLOSED'] },
        facilityStatus: { $nin: ['ARCHIVED'] },
        markToDelete: false,
        isActiveShift: true
      };
      can(ACTION.CREATE, SUBJECT.BOREHOLE_LAYER, boreholeLayerManageConditions);
      can(ACTION.EDIT, SUBJECT.BOREHOLE_LAYER, boreholeLayerManageConditions);
      can(ACTION.DELETE, SUBJECT.BOREHOLE_LAYER, boreholeLayerManageConditions);

      can(ACTION.ENABLE, SUBJECT.OFFLINE);
      can(ACTION.ENABLE, SUBJECT.SHIFT);
    }

    if (role === ROLE.OfficeWorker) {
      can(ACTION.CREATE, SUBJECT.FACILITY);
      can(ACTION.EDIT, SUBJECT.FACILITY, {
        status: { $nin: ['ARCHIVED'] },
        markToDelete: false
      });
      can(ACTION.MARK_TO_ARCHIVE, SUBJECT.FACILITY);
      can(ACTION.MARK_TO_DELETE, SUBJECT.FACILITY);
      can(ACTION.SET_RESPONSIBLE_FOR_FACILITY, SUBJECT.FACILITY);

      can(ACTION.CREATE, SUBJECT.BOREHOLE);

      can(ACTION.PRINT, SUBJECT.BOREHOLE);

      can(ACTION.SEE_MARKED_FACILITIES, SUBJECT.ROUTE);
      can(ACTION.SEE_ARCHIVED_FACILITIES, SUBJECT.ROUTE);

      can(ACTION.EDIT, SUBJECT.BOREHOLE_LAYER);
      can(ACTION.DELETE, SUBJECT.BOREHOLE_LAYER);

      can(ACTION.EDIT, SUBJECT.DOCUMENTS);
      can(ACTION.CREATE, SUBJECT.DOCUMENTS);
      can(ACTION.DELETE, SUBJECT.DOCUMENTS);
    }

    if (role === ROLE.Assistant) {
      can(ACTION.SEE_ARCHIVED_FACILITIES, SUBJECT.ROUTE);
      can(ACTION.PRINT, SUBJECT.BOREHOLE);
    }
  }

  return new Ability(rules);
}
