import { Button, HStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';
import Icon from '../../../components/icon';
import { Uploader } from '../../../components/uploader';
import { UploadExtension } from '../../../components/uploader/types';
import { FileType, UploadValue } from '../../../stores/file';

interface FilesUploadFormProps {
  file: FacilityFile | null;
  fileType: FileType;
  extensions: UploadExtension[];
  maxSize?: number;
  validate: boolean;
  setValidate: (value: boolean) => void;
  uploadValue: UploadValue | null;
  setUploadValue: (value: UploadValue | null) => void;
  isLoading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const FilesUploadForm: FC<FilesUploadFormProps> = (props) => {
  const {
    file,
    fileType,
    extensions,
    maxSize,
    validate,
    setValidate,
    uploadValue,
    setUploadValue,
    isLoading,
    onSubmit,
    onCancel
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <Uploader
        fileType={fileType}
        previewUrl={file?.contentUrl}
        extensions={extensions}
        maxSize={maxSize}
        value={uploadValue}
        setValue={setUploadValue}
        setValidate={setValidate}
      />
      <HStack mx={10} mt={6} space={6} mb={10}>
        <Button
          isLoading={isLoading}
          disabled={!validate || !uploadValue || isLoading}
          onPress={onSubmit}
          startIcon={<Icon name="save" />}
        >
          {t('form.buttons.save')}
        </Button>
        <Button onPress={onCancel} _text={{ color: 'primary.default' }} variant="link">
          {t('form.buttons.cancel')}
        </Button>
      </HStack>
    </>
  );
};
