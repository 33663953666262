import { IReactionDisposer, reaction } from 'mobx';

import { EntityStore } from '../entity';

export function createEntityLoadingReaction<
  Entity,
  EntityRequestType extends string,
  Store extends EntityStore<Entity, unknown, EntityRequestType> = EntityStore<Entity, unknown, EntityRequestType>
>(
  store: Store,
  requestType: EntityRequestType,
  callback: (
    state: { loading: boolean; error: string | null; item: Entity | null },
    oldState: { loading: boolean; error: string | null; item: Entity | null }
  ) => void
): IReactionDisposer {
  return reaction(
    () => ({
      loading: store.loading(requestType),
      error: store.error(requestType),
      item: store.item
    }),
    (state, oldState) => {
      if (oldState.loading && !state.loading && !state.error) {
        callback(state, oldState);
      }
    }
  );
}
