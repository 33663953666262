import { Stores } from '../index';

import { initUserReactions } from './user';
import { initPhotoReactions } from './photo';
import { initFacilityReactions } from './facility';
import { initBoreholeReactions } from './borehole';

export function initReactions(stores: Stores): () => void {
  const disposeUser = initUserReactions(stores);
  const disposePhoto = initPhotoReactions(stores);
  const disposeFacility = initFacilityReactions(stores);
  const disposeBorehole = initBoreholeReactions(stores);
  return () => {
    disposeUser();
    disposePhoto();
    disposeFacility();
    disposeBorehole();
  };
}
