import { saveAs } from 'file-saver';
import { getApiClient } from '@geo/commons/api/api-client';

export const fileSave = async (facilityId: number, documentId: number): Promise<void> => {
  try {
    const document = await getApiClient().documentController.getDocument(facilityId, documentId);
    const content = (await getApiClient().documentController.getDocumentContent(facilityId, documentId)) as ArrayBuffer;
    const { filename } = document;
    const array = new Uint8Array(content);

    const blob = new Blob([array]);

    saveAs(blob, filename);
  } catch (e) {
    console.error(e);
  }
};
