import React, { FC } from 'react';
import { HStack } from 'native-base';

import { ActionView } from '../action';
import { Actions } from '../table/table.model';

export type NarrowFacility = {
  id: number;
};

type HeaderActionsProps = {
  id: number;
  actions?: Actions<NarrowFacility>;
};

export const HeaderActions: FC<HeaderActionsProps> = ({ id, actions }) => {
  if (!actions || actions.length === 0) {
    return null;
  }
  return (
    <HStack space={2} alignItems="center">
      {actions?.map((action, index) => {
        const key = `${action.id}-${index}`;
        return <ActionView key={key} item={{ id }} {...action} />;
      })}
    </HStack>
  );
};
