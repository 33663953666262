import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';
import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getApiClient } from '../api/api-client';
import { CollectionFetched, CollectionStore } from './collection';
import { defaultDocumentsPerPage } from '../constants';

export class DocumentsStore extends CollectionStore<FacilityFile> {
  @observable editModeEnabled = false;

  @observable facilityId?: number;

  perPage = defaultDocumentsPerPage;

  constructor() {
    super();
    makeObservable(this);
  }

  async init(): Promise<void> {
    await super.init();
    await makePersistable(this, {
      name: 'Documents',
      properties: ['page'],
      storage: AsyncStorage
    });
  }

  @action setFacilityId(facilityId: number | undefined): void {
    this.facilityId = facilityId;
  }

  @action async fetchItems(): Promise<CollectionFetched<FacilityFile>> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }
    const { result: items, size: total } = await getApiClient().documentController.listDocuments(this.facilityId, {
      query: {
        page: this.page,
        size: this.perPage,
        sortBy: 'createdAt',
        order: 'DESC'
      }
    });
    return { items, total };
  }

  @action.bound
  toggleEditMode() {
    this.editModeEnabled = !this.editModeEnabled;
  }

  @action.bound
  setEditModeEnabled(enabled: boolean) {
    this.editModeEnabled = enabled;
  }

  @action.bound
  async remove(facilityId: number, documentId: number): Promise<void> {
    await getApiClient().documentController.deleteDocument(facilityId, documentId);
    this.items = this.items.filter((item) => item.id !== documentId);
  }
}
