import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { ConsolidatedStatement, ConsolidatedStatementIO } from '../components/schemas/ConsolidatedStatement';
import { serializeArrayParameter, serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, array, undefined, union, type } from 'io-ts';

export interface ConsolidatedStatementController<F> {
	readonly ConsolidatedStatement: (
		facilityId: number,
		parameters: { query: { sampleIds: Array<number> | undefined; deliveredByUserId: number | undefined } },
	) => HKT<F, ConsolidatedStatement>;
}

export interface ConsolidatedStatementController1<F extends URIS> {
	readonly ConsolidatedStatement: (
		facilityId: number,
		parameters: { query: { sampleIds: Array<number> | undefined; deliveredByUserId: number | undefined } },
	) => Kind<F, ConsolidatedStatement>;
}

export interface ConsolidatedStatementController2<F extends URIS2> {
	readonly ConsolidatedStatement: (
		facilityId: number,
		parameters: { query: { sampleIds: Array<number> | undefined; deliveredByUserId: number | undefined } },
	) => Kind2<F, Error, ConsolidatedStatement>;
}

export function consolidatedStatementController<F extends URIS2>(e: {
	httpClient: HTTPClient2<F>;
}): ConsolidatedStatementController2<F>;
export function consolidatedStatementController<F extends URIS>(e: {
	httpClient: HTTPClient1<F>;
}): ConsolidatedStatementController1<F>;
export function consolidatedStatementController<F>(e: {
	httpClient: HTTPClient<F>;
}): ConsolidatedStatementController<F>;
export function consolidatedStatementController<F>(e: {
	httpClient: HTTPClient<F>;
}): ConsolidatedStatementController<F> {
	return {
		ConsolidatedStatement: (facilityId, parameters) => {
			const query = compact([
				pipe(
					union([array(number), undefined]).encode(parameters.query['sampleIds']),
					option.fromNullable,
					option.chain(value => fromEither(serializeArrayParameter('form', 'sampleIds', value, true))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['deliveredByUserId']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'deliveredByUserId', value))),
				),
			]).join('&');

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/consolidated-statement/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'consolidatedStatement',
					operation: 'ConsolidatedStatement',
					method: 'GET',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						ConsolidatedStatementIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
