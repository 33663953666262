import { EntityStore } from './entity';
import { Map } from '../models/Map';
import { getApiClient } from '../api/api-client';

export class MapStore extends EntityStore<Map, number> {
  protected async fetchItem(id: number): Promise<Map> {
    const borehole = await getApiClient().boreholeController.getBorehole(id);
    const { name, status, facility, coordinate, creator, createdAt, lastUpdatedAt } = borehole;
    return {
      id,
      name,
      facilityId: facility.id,
      coordinate,
      creatorId: creator.id,
      createdAt,
      status,
      lastUpdatedAt,
      generated: true
    };
  }
}
