import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { CloneClientDbRequest, CloneClientDbRequestIO } from '../components/schemas/CloneClientDbRequest';
import { StreamingResponseBody, StreamingResponseBodyIO } from '../components/schemas/StreamingResponseBody';
import { serializeArrayParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType, BinaryFromStringIO, Binary } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { void as tvoid, undefined, union, type, number, array } from 'io-ts';

export interface ClientDbController<F> {
	readonly createDocument_1: (parameters: { body: { file: Binary | undefined } }) => HKT<F, void>;

	readonly clientDbClone_1: (parameters: {
		query: { facilityIds: Array<number> | undefined };
	}) => HKT<F, StreamingResponseBody>;

	readonly clientDbClone: (parameters: { body: CloneClientDbRequest }) => HKT<F, StreamingResponseBody>;

	readonly getSchemaScript: () => HKT<F, StreamingResponseBody>;
}

export interface ClientDbController1<F extends URIS> {
	readonly createDocument_1: (parameters: { body: { file: Binary | undefined } }) => Kind<F, void>;

	readonly clientDbClone_1: (parameters: {
		query: { facilityIds: Array<number> | undefined };
	}) => Kind<F, StreamingResponseBody>;

	readonly clientDbClone: (parameters: { body: CloneClientDbRequest }) => Kind<F, StreamingResponseBody>;

	readonly getSchemaScript: () => Kind<F, StreamingResponseBody>;
}

export interface ClientDbController2<F extends URIS2> {
	readonly createDocument_1: (parameters: { body: { file: Binary | undefined } }) => Kind2<F, Error, void>;

	readonly clientDbClone_1: (parameters: {
		query: { facilityIds: Array<number> | undefined };
	}) => Kind2<F, Error, StreamingResponseBody>;

	readonly clientDbClone: (parameters: { body: CloneClientDbRequest }) => Kind2<F, Error, StreamingResponseBody>;

	readonly getSchemaScript: () => Kind2<F, Error, StreamingResponseBody>;
}

export function clientDbController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): ClientDbController2<F>;
export function clientDbController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): ClientDbController1<F>;
export function clientDbController<F>(e: { httpClient: HTTPClient<F> }): ClientDbController<F>;
export function clientDbController<F>(e: { httpClient: HTTPClient<F> }): ClientDbController<F> {
	return {
		createDocument_1: parameters => {
			const body = type({ file: union([BinaryFromStringIO, undefined]) }).encode(parameters.body);

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'multipart/form-data',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/client-db/merge`,
					controller: 'clientDb',
					operation: 'createDocument_1',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						tvoid.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		clientDbClone_1: parameters => {
			const query = compact([
				pipe(
					union([array(number), undefined]).encode(parameters.query['facilityIds']),
					option.fromNullable,
					option.chain(value => fromEither(serializeArrayParameter('form', 'facilityIds', value, true))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/client-db/clone`,
					controller: 'clientDb',
					operation: 'clientDbClone_1',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						StreamingResponseBodyIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		clientDbClone: parameters => {
			const body = CloneClientDbRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/client-db/clone`,
					controller: 'clientDb',
					operation: 'clientDbClone',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						StreamingResponseBodyIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		getSchemaScript: () => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/client-db/schema`,
					controller: 'clientDb',
					operation: 'getSchemaScript',
					method: 'GET',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						StreamingResponseBodyIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
