import React, { FC, ReactElement } from 'react';
import { Spinner, View, Center, Text } from 'native-base';
import { useTranslation } from 'react-i18next';

import { LoadingIndicatorProps, LoadingErrorProps, LoadableProps } from './types';

export const LoadingIndicator: FC<LoadingIndicatorProps> = (props): ReactElement => {
  const { color = 'dark.dark-2', size = 'lg', height, width, children } = props;
  return (
    <View flexDirection="column" height={height} width={width} flexGrow={1} justifyContent="center">
      <Center>
        <Spinner color={color} size={size} accessibilityLabel="Loading" />
        {children}
      </Center>
    </View>
  );
};

export const LoadingError: FC<LoadingErrorProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { errorMessage, children } = props;
  return (
    <View flexDirection="column" flexGrow={1} justifyContent="center">
      <Center>
        <Text fontSize="xl" color="error">
          {errorMessage || t('loading.error')}
        </Text>
        {children}
      </Center>
    </View>
  );
};

export const Loadable: FC<LoadableProps> = (props): ReactElement => {
  const { isLoading, IndicatorComponent, error, ErrorComponent, children, ...restProps } = props;
  if (isLoading) {
    if (IndicatorComponent) {
      return (
        <LoadingIndicator {...restProps}>
          <IndicatorComponent />
        </LoadingIndicator>
      );
    }
    return <LoadingIndicator {...restProps} />;
  }
  if (error) {
    if (ErrorComponent) {
      return (
        <LoadingError {...restProps}>
          <ErrorComponent />
        </LoadingError>
      );
    }
    return <LoadingError />;
  }
  return <>{children}</>;
};
