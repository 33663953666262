import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import {
	CollectionDtoFacilityFile,
	CollectionDtoFacilityFileIO,
} from '../components/schemas/CollectionDtoFacilityFile';
import { FacilityFile, FacilityFileIO } from '../components/schemas/FacilityFile';
import {
	FacilityFileUpdateRequest,
	FacilityFileUpdateRequestIO,
} from '../components/schemas/FacilityFileUpdateRequest';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType, BinaryFromStringIO, Binary } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, undefined, union, literal, type, string, void as tvoid, unknown } from 'io-ts';

export interface PhotoController<F> {
	readonly listPhotos: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'creator' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => HKT<F, CollectionDtoFacilityFile>;

	readonly createPhoto: (
		facilityId: number,
		parameters: { query: { description: string }; body: { file: Binary | undefined } },
	) => HKT<F, FacilityFile>;

	readonly createPhoto_1: (
		facilityId: number,
		fileId: number,
		parameters: { query: { description: string | undefined }; body: { file: Binary | undefined } | undefined },
	) => HKT<F, FacilityFile>;

	readonly updatePhotoDescription: (
		facilityId: number,
		fileId: number,
		parameters: { body: FacilityFileUpdateRequest },
	) => HKT<F, FacilityFile>;

	readonly getPhoto: (facilityId: number, photoId: number) => HKT<F, FacilityFile>;

	readonly deletePhoto: (facilityId: number, photoId: number) => HKT<F, void>;

	readonly getPhotoPreviewContent: (facilityId: number, photoId: number) => HKT<F, unknown>;

	readonly getPhotoContent: (facilityId: number, photoId: number) => HKT<F, unknown>;
}

export interface PhotoController1<F extends URIS> {
	readonly listPhotos: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'creator' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind<F, CollectionDtoFacilityFile>;

	readonly createPhoto: (
		facilityId: number,
		parameters: { query: { description: string }; body: { file: Binary | undefined } },
	) => Kind<F, FacilityFile>;

	readonly createPhoto_1: (
		facilityId: number,
		fileId: number,
		parameters: { query: { description: string | undefined }; body: { file: Binary | undefined } | undefined },
	) => Kind<F, FacilityFile>;

	readonly updatePhotoDescription: (
		facilityId: number,
		fileId: number,
		parameters: { body: FacilityFileUpdateRequest },
	) => Kind<F, FacilityFile>;

	readonly getPhoto: (facilityId: number, photoId: number) => Kind<F, FacilityFile>;

	readonly deletePhoto: (facilityId: number, photoId: number) => Kind<F, void>;

	readonly getPhotoPreviewContent: (facilityId: number, photoId: number) => Kind<F, unknown>;

	readonly getPhotoContent: (facilityId: number, photoId: number) => Kind<F, unknown>;
}

export interface PhotoController2<F extends URIS2> {
	readonly listPhotos: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'name' | 'creator' | 'createdAt' | 'lastUpdatedAt') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind2<F, Error, CollectionDtoFacilityFile>;

	readonly createPhoto: (
		facilityId: number,
		parameters: { query: { description: string }; body: { file: Binary | undefined } },
	) => Kind2<F, Error, FacilityFile>;

	readonly createPhoto_1: (
		facilityId: number,
		fileId: number,
		parameters: { query: { description: string | undefined }; body: { file: Binary | undefined } | undefined },
	) => Kind2<F, Error, FacilityFile>;

	readonly updatePhotoDescription: (
		facilityId: number,
		fileId: number,
		parameters: { body: FacilityFileUpdateRequest },
	) => Kind2<F, Error, FacilityFile>;

	readonly getPhoto: (facilityId: number, photoId: number) => Kind2<F, Error, FacilityFile>;

	readonly deletePhoto: (facilityId: number, photoId: number) => Kind2<F, Error, void>;

	readonly getPhotoPreviewContent: (facilityId: number, photoId: number) => Kind2<F, Error, unknown>;

	readonly getPhotoContent: (facilityId: number, photoId: number) => Kind2<F, Error, unknown>;
}

export function photoController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): PhotoController2<F>;
export function photoController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): PhotoController1<F>;
export function photoController<F>(e: { httpClient: HTTPClient<F> }): PhotoController<F>;
export function photoController<F>(e: { httpClient: HTTPClient<F> }): PhotoController<F> {
	return {
		listPhotos: (facilityId, parameters) => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('name'),
							literal('creator'),
							literal('createdAt'),
							literal('lastUpdatedAt'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(number.encode(facilityId).toString())}/photo`,
					controller: 'photo',
					operation: 'listPhotos',
					method: 'GET',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoFacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createPhoto: (facilityId, parameters) => {
			const body = type({ file: union([BinaryFromStringIO, undefined]) }).encode(parameters.body);
			const query = compact([
				pipe(string.encode(parameters.query['description']), value =>
					fromEither(serializePrimitiveParameter('form', 'description', value)),
				),
			]).join('&');

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'multipart/form-data',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(number.encode(facilityId).toString())}/photo`,
					controller: 'photo',
					operation: 'createPhoto',
					method: 'POST',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,
					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createPhoto_1: (facilityId, fileId, parameters) => {
			const body = union([type({ file: union([BinaryFromStringIO, undefined]) }), undefined]).encode(
				parameters.body,
			);
			const query = compact([
				pipe(
					union([string, undefined]).encode(parameters.query['description']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'description', value))),
				),
			]).join('&');

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'multipart/form-data',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/photo/${encodeURIComponent(number.encode(fileId).toString())}`,
					controller: 'photo',
					operation: 'createPhoto_1',
					method: 'PATCH',
					responseType,
					pathParameters: [facilityId, fileId],
					parameters,
					query,
					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		updatePhotoDescription: (facilityId, fileId, parameters) => {
			const body = FacilityFileUpdateRequestIO.encode(parameters.body);

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/photo/description/${encodeURIComponent(number.encode(fileId).toString())}`,
					controller: 'photo',
					operation: 'updatePhotoDescription',
					method: 'PATCH',
					responseType,
					pathParameters: [facilityId, fileId],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		getPhoto: (facilityId, photoId) => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/photo/${encodeURIComponent(number.encode(photoId).toString())}`,
					controller: 'photo',
					operation: 'getPhoto',
					method: 'GET',
					responseType,
					pathParameters: [facilityId, photoId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						FacilityFileIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		deletePhoto: (facilityId, photoId) => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/photo/${encodeURIComponent(number.encode(photoId).toString())}`,
					controller: 'photo',
					operation: 'deletePhoto',
					method: 'DELETE',
					responseType,
					pathParameters: [facilityId, photoId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						tvoid.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		getPhotoPreviewContent: (facilityId, photoId) => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/photo/${encodeURIComponent(number.encode(photoId).toString())}/preview-content`,
					controller: 'photo',
					operation: 'getPhotoPreviewContent',
					method: 'GET',
					responseType,
					pathParameters: [facilityId, photoId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						unknown.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		getPhotoContent: (facilityId, photoId) => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/facility/${encodeURIComponent(
						number.encode(facilityId).toString(),
					)}/photo/${encodeURIComponent(number.encode(photoId).toString())}/content`,
					controller: 'photo',
					operation: 'getPhotoContent',
					method: 'GET',
					responseType,
					pathParameters: [facilityId, photoId],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						unknown.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
