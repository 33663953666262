import { action, makeObservable, observable } from 'mobx';

import { getApiClient } from '../api/api-client';
import { BoreholeLayer, BoreholeLayerSortColumn } from '../models/BoreholeLayer';
import { BaseTableStore, FetchListWithIdFn } from './base-table';
import { CollectionFetched } from './collection';

export type BoreholeTableGetFetchList = FetchListWithIdFn<BoreholeLayer, BoreholeLayerSortColumn>;

export class BoreholeLayerTableStore extends BaseTableStore<BoreholeLayer, BoreholeLayerSortColumn> {
  @observable boreholeId?: number;

  perPage = 200;

  constructor(storageKey: string) {
    super(storageKey);
    makeObservable(this);
    this.sortBy = 'depthFrom';
    this.order = 'ASC';
  }

  @action setBoreholeId(boreholeId: number | undefined): void {
    this.boreholeId = boreholeId;
  }

  readonly getFetchList = (): BoreholeTableGetFetchList => {
    return getApiClient().boreholeLayerController.boreholeLayerList;
  };

  @action async fetchItems(): Promise<CollectionFetched<BoreholeLayer>> {
    if (!this.boreholeId) {
      throw new Error('Borehole identifier is not set');
    }
    const { result: items, size: total } = await this.getFetchList()(this.boreholeId, { query: this.storedQuery });
    return { items, total };
  }

  @action add(boreholeLayer: BoreholeLayer): void {
    this.items.push(boreholeLayer);
  }

  @action update(boreholeLayer: BoreholeLayer): void {
    const index = this.items.findIndex((bl) => bl.id === boreholeLayer.id);
    if (index >= 0) {
      this.items.splice(index, 1, boreholeLayer);
    }
  }

  @action remove(id: number): void {
    const index = this.items.findIndex((boreholeLayer) => boreholeLayer.id === id);
    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }
}
