import React, { FC, useCallback, useState } from 'react';
import { View, Pressable, HStack, Box, Popover, List, Text, ChevronDownIcon } from 'native-base';
import { FacilityStatus } from '../../models/Facility';
import { StatusBadge } from './status-badge';

export const statusList: FacilityStatus[] = ['NEW', 'IN_PROGRESS', 'PROBLEM', 'CLOSED'];

type StatusSelectProps = {
  statuses?: FacilityStatus[];
  placeholder?: string;
  isDisabled?: boolean;
  value?: FacilityStatus;
  onValueChange?: (value: FacilityStatus) => void;
};

export const StatusSelect: FC<StatusSelectProps> = (props) => {
  const { placeholder, value, onValueChange, isDisabled, statuses = statusList } = props;
  const [isOpen, updateOpen] = useState<boolean>(false);
  const onChange = useCallback(
    (status: FacilityStatus) => {
      return () => {
        onValueChange?.(status);
        handleClose();
      };
    },
    [onValueChange, value]
  );
  const handleOpen = () => {
    updateOpen(true);
  };
  const handleClose = () => {
    updateOpen(false);
  };

  const Anchor = (
    <Box
      px={3}
      justifyContent="center"
      borderWidth={1}
      bg={isDisabled ? '#f7f7f7' : undefined}
      borderColor="#dbdeea"
      rounded="lg"
      height={10}
      width={240}
    >
      <HStack>
        <View flex={1}>{value ? <StatusBadge status={value} /> : <Text opacity={0.6}>{placeholder}</Text>}</View>
        <ChevronDownIcon size={5} />
      </HStack>
    </Box>
  );

  return isDisabled ? (
    Anchor
  ) : (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="bottom left"
      trigger={(triggerProps) => <Pressable {...triggerProps}>{Anchor}</Pressable>}
    >
      <Popover.Content backgroundColor="#fff" style={{ width: 240 }}>
        <Popover.Body px={0} py={0}>
          <List borderWidth={0} px={0} py={0}>
            {statuses.map((status) => (
              <List.Item
                key={status}
                py={1}
                onPress={onChange(status)}
                borderBottomWidth={1}
                borderBottomColor="#dbdeea"
              >
                <StatusBadge status={status} />
              </List.Item>
            ))}
          </List>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

StatusSelect.displayName = StatusSelect.name;
