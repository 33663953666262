import {
	BoreholeNotificationParams,
	BoreholeNotificationParamsIO,
} from '../../components/schemas/BoreholeNotificationParams';
import {
	FacilityCommentNotificationParams,
	FacilityCommentNotificationParamsIO,
} from '../../components/schemas/FacilityCommentNotificationParams';
import {
	FacilityFileNotificationParams,
	FacilityFileNotificationParamsIO,
} from '../../components/schemas/FacilityFileNotificationParams';
import { NotificationParams, NotificationParamsIO } from '../../components/schemas/NotificationParams';
import { User, UserIO } from '../../components/schemas/User';
import { UserNotificationParams, UserNotificationParamsIO } from '../../components/schemas/UserNotificationParams';
import { number, literal, union, boolean, undefined, type } from 'io-ts';

export type Notification = {
	id: number;
	principal: User;
	actionType: 'CREATE' | 'UPDATE' | 'DELETE' | 'MARK_TO_DELETE' | 'SET_TEMPERATURE';
	entityType:
		| 'FACILITY'
		| 'BOREHOLE'
		| 'BOREHOLE_LAYER'
		| 'USER'
		| 'PHOTO'
		| 'DOCUMENT'
		| 'COMMENT'
		| 'MAP'
		| 'WORKER';
	eventTs: number;
	viewed: boolean | undefined;
	entityDeleted: boolean;
	params:
		| NotificationParams
		| BoreholeNotificationParams
		| FacilityCommentNotificationParams
		| FacilityFileNotificationParams
		| UserNotificationParams;
};
export const NotificationIO = type(
	{
		id: number,
		principal: UserIO,
		actionType: union([
			literal('CREATE'),
			literal('UPDATE'),
			literal('DELETE'),
			literal('MARK_TO_DELETE'),
			literal('SET_TEMPERATURE'),
		]),
		entityType: union([
			literal('FACILITY'),
			literal('BOREHOLE'),
			literal('BOREHOLE_LAYER'),
			literal('USER'),
			literal('PHOTO'),
			literal('DOCUMENT'),
			literal('COMMENT'),
			literal('MAP'),
			literal('WORKER'),
		]),
		eventTs: number,
		viewed: union([boolean, undefined]),
		entityDeleted: boolean,
		params: union([
			NotificationParamsIO,
			BoreholeNotificationParamsIO,
			FacilityCommentNotificationParamsIO,
			FacilityFileNotificationParamsIO,
			UserNotificationParamsIO,
		]),
	},
	'Notification',
);
