import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { HStack } from 'native-base';
import Icon from '../icon';
import { useAppNavigation } from '../../hooks/useAppNavigation';

import { appStore } from '../../stores';

export const HeaderLeft: FC = observer(() => {
  const navigation = useAppNavigation();
  const { navigationState } = appStore;

  const canGoBack = (navigationState?.index || 0) > 0;
  const onPress = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
  }, []);

  return (
    <HStack pl={8} space={6} pr={26} alignItems="center">
      <Icon disabled={!canGoBack} onPress={onPress} name="back" />
    </HStack>
  );
});
