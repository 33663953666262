import React, { CSSProperties, FC, useCallback, useMemo, useState } from 'react';
import { Box, Text } from 'native-base';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import Icon from '../icon';
import { UploaderEdit } from './edit';
import { FileType, UploadValue } from '../../stores/file';

import { UploadExtension } from './types';

const BYTES_IN_MB = 1048576;

const containerStyles = {
  borderWidth: 1,
  display: 'flex',
  padding: 6,
  backgroundColor: 'light.light-0',
  borderRadius: 16,
  borderColor: 'light.light-3',
  height: 72
};

const dashedDndContainerWebStyles: CSSProperties = {
  borderWidth: 2,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderStyle: 'dashed',
  borderRadius: 16,
  borderColor: '#B4B6C9'
};

interface UploaderProps {
  fileType: FileType;
  previewUrl?: string;
  /**
   * Допустимые расширения файлов
   */
  extensions: UploadExtension[];
  /**
   * Допустимый максимальный размер
   * файла. Число в mb
   */
  maxSize?: number;
  value: UploadValue | null;
  setValue: (value: UploadValue | null) => void;
  /**
   * Коллбэк для установки флага
   * валидации
   */
  setValidate: (value: boolean) => void;
}

/**
 * Компонент загрузки файлов
 */
export const Uploader: FC<UploaderProps> = (props) => {
  const { fileType, previewUrl, extensions, maxSize = 10, value, setValue, setValidate } = props;

  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const validateFile = (file: File) => {
    if (Platform.OS === 'web') {
      const extensionsRegexp = new RegExp(`.(${extensions.map((e) => e.toLowerCase()).join('|')})$`);
      const extensionResult = extensionsRegexp.test(file?.name?.toLowerCase());
      const sizeResult = file?.size < maxSize * BYTES_IN_MB;
      if (!extensionResult) {
        return t(`uploader.${fileType}.invalid-file-format`, { formats: extensions.join(', ') });
      }
      if (!sizeResult) {
        return t(`uploader.${fileType}.invalid-file-size`, { maxSize });
      }
      return null;
    }

    return false;
  };

  const getUploadValue = (file: File): UploadValue => {
    if (Platform.OS === 'web') {
      const { name, type: contentType } = file;
      return {
        description: name,
        file,
        contentType
      };
    }
    return {
      description: file.name,
      file,
      contentType: ''
    };
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const validateMessage = validateFile(file);
      if (!validateMessage) {
        setError(null);
        setValidate(true);
        setValue(getUploadValue(file));
      } else {
        setValidate(false);
        setValue(null);
        setError(validateMessage);
      }
    },
    [extensions]
  );

  if (Platform.OS === 'android') return <Text>Uploader unavailable on mobile</Text>;

  const { getRootProps, getInputProps, inputRef } = useDropzone({ onDrop });

  const component = useMemo(() => {
    if (Platform.OS === 'android') {
      return null;
    }
    if (Platform.OS === 'web') {
      return (
        <Box {...containerStyles}>
          <div style={dashedDndContainerWebStyles} {...getRootProps()}>
            <input style={{ visibility: 'hidden' }} {...getInputProps()} />
            <Icon marginBottom={3} name="upload" />
            <Text fontSize={32} marginBottom={3}>
              Перетащите файл сюда
            </Text>
            <Text fontSize={16} color="">
              или нажмите{' '}
              <Text cursor="pointer" fontSize={16} color="primary.default">
                здесь
              </Text>{' '}
              для загрузки
            </Text>
          </div>
        </Box>
      );
    }
    return null;
  }, [inputRef.current, getRootProps, getInputProps]);

  return value && Platform.OS === 'web' ? (
    <UploaderEdit fileType={fileType} previewUrl={previewUrl} setValue={setValue} value={value} />
  ) : (
    <>
      {component}
      {error && (
        <Box
          borderRadius={16}
          marginTop={6}
          display="flex"
          flexDirection="row"
          alignItems="center"
          borderWidth={2}
          borderColor="dark.dark-0"
          height={16}
          backgroundColor="light.light-0"
        >
          <Icon name="warningSmall" fill="#EF3B3B" style={{ marginLeft: 25, marginRight: 25 }} />
          <Text>{error}</Text>
        </Box>
      )}
    </>
  );
};
