import React, { FC } from 'react';
import { Center, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import Icon from './icon';

interface BlankProps {
  mode?: 'default' | 'warning';
  size?: 'm' | 'l';
  isNeedIcon?: boolean;
  text?: string;
}

export const BlankPage: FC<BlankProps> = ({ mode = 'default', size = 'l', text = '', children, isNeedIcon = true }) => {
  const { t } = useTranslation();
  const marginBottom = size === 'l' ? 16 : 8;
  const iconName = mode === 'default' ? 'empty' : 'warning';

  return (
    <Center flex={1}>
      {isNeedIcon && <Icon name={iconName} style={{ marginBottom }} />}
      {children || <Text fontSize={20}>{t('blank.main.text')}</Text>}
      {text && (
        <Text fontSize={14} mt={16}>
          {text}
        </Text>
      )}
    </Center>
  );
};

BlankPage.displayName = BlankPage.name;
