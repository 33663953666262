import { reaction } from 'mobx';

import { getRoute, getRouteByIndex, navigate, replace, goBack } from '../../navigation';
import { screens } from '../../navigation/config';
import { Stores } from '../index';
import { createEntityLoadingReaction } from './utils';

import { Facility } from '../../models/Facility';
import { FacilityRequestType, FacilityStore } from '../facility';

export function initFacilityReactions(stores: Stores): () => void {
  const {
    appStore,
    facilityStore,
    facilityTableStore,
    facilityArchivedTableStore,
    facilityDeletedTableStore,
    commentsStore
  } = stores;

  const updateComments = (facilityId: number): void => {
    commentsStore.setFacilityId(facilityId);
    commentsStore.loadItems().catch((e) => console.error(e));
  };

  if (appStore.currentUser && facilityStore.loadParams) {
    updateComments(facilityStore.loadParams);
  }

  const disposeFacilityId = reaction(
    () => facilityStore.loadParams,
    (facilityId) => {
      if (facilityId) {
        updateComments(facilityId);
      }
    }
  );

  const disposeFacilityCreate = createEntityLoadingReaction<Facility, FacilityRequestType, FacilityStore>(
    facilityStore,
    'create',
    (state) => {
      const { item: facility } = state;
      if (facility) {
        replace(screens.FacilityView, { facilityId: facility.id });
      }
    }
  );

  const disposeFacilityUpdate = createEntityLoadingReaction<Facility, FacilityRequestType, FacilityStore>(
    facilityStore,
    'update',
    (state) => {
      const { item: facility } = state;
      if (facility) {
        const route = getRouteByIndex(-2);
        if (route && route.name === screens.FacilityView) {
          goBack();
        } else {
          replace(screens.FacilityView, { facilityId: facility.id });
        }
      }
    }
  );

  const disposeFacilityArchive = createEntityLoadingReaction<Facility, FacilityRequestType, FacilityStore>(
    facilityStore,
    'archive',
    () => {
      const { name: screenName } = getRoute() || {};
      switch (screenName) {
        case screens.FacilityView: {
          // No need to navigate or refresh something because facility is already updated in the store
          break;
        }
        case screens.FacilitiesArchived: {
          facilityArchivedTableStore.refresh().catch((e) => console.error(e));
          break;
        }
        default: {
          navigate(screens.FacilitiesArchived);
          break;
        }
      }
    }
  );

  const disposeFacilityRemove = createEntityLoadingReaction<Facility, FacilityRequestType, FacilityStore>(
    facilityStore,
    'remove',
    (state, oldState) => {
      const { name: screenName } = getRoute() || {};
      const { item: removedFacility } = oldState;
      switch (screenName) {
        case screens.FacilityView: {
          navigate(removedFacility?.status === 'ARCHIVED' ? screens.FacilitiesArchived : screens.Facilities);
          break;
        }
        case screens.Facilities: {
          facilityTableStore.refresh().catch((e) => console.error(e));
          break;
        }
        case screens.FacilitiesArchived: {
          facilityArchivedTableStore.refresh().catch((e) => console.error(e));
          break;
        }
        case screens.FacilitiesDeleted: {
          facilityDeletedTableStore.refresh().catch((e) => console.error(e));
          break;
        }
        default: {
          navigate(screens.Facilities);
          break;
        }
      }
    }
  );

  const disposeFacilityMarkToDelete = createEntityLoadingReaction<Facility, FacilityRequestType, FacilityStore>(
    facilityStore,
    'markToDelete',
    (state) => {
      const { item: facility } = state;
      if (facility) {
        if (facility.markToDelete) {
          navigate(screens.FacilitiesDeleted);
        } else {
          facilityDeletedTableStore.refresh().catch((e) => console.error(e));
        }
      }
    }
  );

  return () => {
    disposeFacilityId();
    disposeFacilityCreate();
    disposeFacilityUpdate();
    disposeFacilityArchive();
    disposeFacilityRemove();
    disposeFacilityMarkToDelete();
  };
}
