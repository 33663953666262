import yup from '../../utils/yup';

export const maxLength = 50;

export interface LoginFormData {
  email: string;
  password: string;
}
export const schema = yup.object().shape({
  email: yup.string().max(maxLength).required(),
  password: yup.string().max(maxLength).required()
});
