import React, { FC } from 'react';
import { observer } from 'mobx-react';

import { Loadable } from '../../../components/loadable';
import { Sort } from '../../../components/table/table.model';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { BoreholeLayerSampleSortColumn } from '../../../models/BoreholeLayerSample';
import { boreholeSampleTableStore } from '../../../stores';

import { BoreholeSampleTable } from './borehole-sample-table';
import { BoreholeSampleTableTotal } from './borehole-sample-total';

interface BoreholeLayerTableContainerProps {
  facilityId: number;
}

export const BoreholeLayerSampleTableContainer: FC<BoreholeLayerTableContainerProps> = observer(({ facilityId }) => {
  const { isLoading, error, page, perPage, total, countByTypeMap, sortTableProp, updatePage, updateSort, items } =
    boreholeSampleTableStore;

  const ready = useComponentReady(() => {
    boreholeSampleTableStore.setFacilityId(facilityId);
    boreholeSampleTableStore.setPage(0);
    boreholeSampleTableStore.loadItems().catch();
  }, [facilityId]);

  if (!ready) {
    return null;
  }

  const onChangeSort = (sort: Sort<BoreholeLayerSampleSortColumn>) => {
    updateSort(sort?.column, sort?.sortOrder);
  };

  const onChangePage = (p: number) => {
    const newPage = p - 1;
    updatePage(newPage);
  };

  return (
    <Loadable isLoading={isLoading} error={!!error}>
      <BoreholeSampleTable
        page={page}
        perPage={perPage}
        footer={<BoreholeSampleTableTotal facilityId={facilityId} countByTypeMap={countByTypeMap} total={total} />}
        total={total}
        facilityId={facilityId}
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        sort={sortTableProp}
        items={items}
      />
    </Loadable>
  );
});
