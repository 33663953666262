import { Box, HStack, IBoxProps, Text, VStack } from 'native-base';
import React, { FC } from 'react';
import Icon, { IconName } from '../icon';

type PanelProps = IBoxProps & {
  headerIcon?: IconName;
  headerIconColor?: string;
  headerLeft?: string | React.ReactNode;
  headerRight?: string | React.ReactNode;
};

export const Panel: FC<PanelProps> = ({
  headerIcon,
  headerIconColor,
  headerLeft,
  headerRight,
  children,
  ...boxProps
}) => {
  const icon = headerIcon && <Icon name={headerIcon} fill={headerIconColor} />;
  const left = headerLeft && (typeof headerLeft === 'string' ? <Text>{headerLeft}</Text> : headerLeft);
  const right = headerRight && (typeof headerRight === 'string' ? <Text>{headerRight}</Text> : headerRight);

  return (
    <VStack mb={6} borderWidth={1} borderColor="#dbdeea" backgroundColor="white" rounded="lg" {...boxProps}>
      <HStack
        height={14}
        px={6}
        borderBottomWidth={1}
        space={6}
        borderBottomColor="#dbdeea"
        alignItems="center"
        justifyContent="space-between"
      >
        {(icon || left) && (
          <HStack space={4} flex={1}>
            {icon}
            {left}
          </HStack>
        )}
        {right && <Box>{right}</Box>}
      </HStack>
      <Box mx={6}>{children}</Box>
    </VStack>
  );
};

Panel.displayName = 'Panel';
