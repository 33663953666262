import React, { FC } from 'react';
import { useToast } from 'native-base';
import { VStack, HStack, Text, IconButton, CloseIcon, Alert, IAlertProps } from 'native-base';

interface ToastAlertProps {
  id: string;
  title?: string;
  description?: string;
  isClosable?: boolean;
  status?: IAlertProps['status'];
  variant?: IAlertProps['variant'];
}

export const ToastAlert: FC<ToastAlertProps> = ({ id, status, variant, title, description, isClosable, ...rest }) => {
  const toast = useToast();

  // eslint-disable-next-line no-nested-ternary
  const color = variant === 'solid' ? 'lightText' : variant !== 'outline' ? 'darkText' : null;

  return (
    <Alert maxWidth="100%" alignSelf="center" flexDirection="row" status={status || 'info'} variant={variant} {...rest}>
      <VStack space={1} flexShrink={1} w="100%">
        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon />
            <Text fontSize="md" fontWeight="medium" flexShrink={1} color={color}>
              {title}
            </Text>
          </HStack>
          {isClosable ? (
            <IconButton
              variant="unstyled"
              icon={<CloseIcon size="3" />}
              _icon={{
                color: variant === 'solid' ? 'lightText' : 'darkText'
              }}
              onPress={() => toast.close(id)}
            />
          ) : null}
        </HStack>
        {description && (
          <Text px="6" color={color}>
            {description}
          </Text>
        )}
      </VStack>
    </Alert>
  );
};
