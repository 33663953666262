import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Fab } from 'native-base';

import Icon from '../../../components/icon';
import { Page } from '../../../components/page';
import { Tabs } from '../../../components/tabs';
import { DocumentListContainer } from '../../../features/document/document-list.container';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';
import { facilityStore } from '../../../stores';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { PermissionGuard } from '../../../utils/permissions/guard';

import { screens } from '../../config';
import { PageProps } from '../../types';

type DocumentListPageProps = PageProps<'Documents'>;

export const DocumentListPage: FC<DocumentListPageProps> = observer(({ route, navigation }) => {
  const { params } = route;
  const facilityId = params?.facilityId;

  useNavigationEffect(() => {
    facilityStore.loadItem(facilityId).catch((e) => console.error(e));
  }, [facilityId]);

  if (!facilityId) {
    return null;
  }

  const { isArchived, isDeleted } = facilityStore;
  const showAddButton = !facilityStore.loading('get') && !facilityStore.error('get') && !isArchived && !isDeleted;

  const onAddClick = () => {
    navigation.navigate(screens.DocumentUpload, { facilityId });
  };

  return (
    <Page
      header={<Tabs facilityId={facilityId} />}
      siblings={
        showAddButton && (
          <PermissionGuard action={ACTION.CREATE} subject={SUBJECT.DOCUMENTS}>
            <Fab
              position="absolute"
              onPress={onAddClick}
              size="sm"
              icon={<Icon name="addplus32" />}
              _web={{ position: 'fixed' }}
              renderInPortal={false}
            />
          </PermissionGuard>
        )
      }
    >
      <DocumentListContainer facilityId={facilityId} />
    </Page>
  );
});

DocumentListPage.displayName = DocumentListPage.name;
