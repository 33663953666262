import { override, makeObservable, action } from 'mobx';

import type { BoreholeTemperature } from '@geo/api/generated/swagger.json/components/schemas/BoreholeTemperature';
import { BoreholeTemperatureRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeTemperatureRequest';
import { EntityStore } from './entity';

import { getApiClient } from '../api/api-client';

export type BoreholeTemperatureRequestType = 'update' | 'remove';

export class BoreholeTemperatureStore extends EntityStore<BoreholeTemperature, number, BoreholeTemperatureRequestType> {
  constructor(storageKey?: string) {
    super(storageKey);
    makeObservable(this);
  }

  protected async fetchItem(id: number): Promise<BoreholeTemperature> {
    return getApiClient().boreholeTemperatureController.getBoreholeTemperature(id);
  }

  @override setItem(item: BoreholeTemperature | null): void {
    if (!item) {
      super.setItem(null);
    } else {
      super.setItem(item, item.id);
    }
  }

  @action async update(id: number, params: BoreholeTemperatureRequest): Promise<BoreholeTemperature> {
    return this.request(
      'update',
      () => getApiClient().boreholeTemperatureController.updateBoreholeTemperature(id, { body: params }),
      (boreholeTemperature) => this.setItem(boreholeTemperature)
    );
  }
}
