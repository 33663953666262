import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { BoreholeLayerSample, BoreholeLayerSampleIO } from '../components/schemas/BoreholeLayerSample';
import {
	BoreholeLayerSampleAcceptRequest,
	BoreholeLayerSampleAcceptRequestIO,
} from '../components/schemas/BoreholeLayerSampleAcceptRequest';
import {
	BoreholeLayerSampleCollectionDto,
	BoreholeLayerSampleCollectionDtoIO,
} from '../components/schemas/BoreholeLayerSampleCollectionDto';
import {
	BoreholeLayerSampleCommentRequest,
	BoreholeLayerSampleCommentRequestIO,
} from '../components/schemas/BoreholeLayerSampleCommentRequest';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, undefined, union, literal, type } from 'io-ts';

export interface BoreholeLayerSampleController<F> {
	readonly commentBoreholeLayerSample: (
		boreholeLayerSampleId: number,
		parameters: { body: BoreholeLayerSampleCommentRequest },
	) => HKT<F, BoreholeLayerSample>;

	readonly acceptBoreholeLayerSample: (
		boreholeLayerSampleId: number,
		parameters: { body: BoreholeLayerSampleAcceptRequest },
	) => HKT<F, BoreholeLayerSample>;

	readonly listByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'type' | 'depthFrom' | 'depthTo' | 'layerBoreholeId') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => HKT<F, BoreholeLayerSampleCollectionDto>;
}

export interface BoreholeLayerSampleController1<F extends URIS> {
	readonly commentBoreholeLayerSample: (
		boreholeLayerSampleId: number,
		parameters: { body: BoreholeLayerSampleCommentRequest },
	) => Kind<F, BoreholeLayerSample>;

	readonly acceptBoreholeLayerSample: (
		boreholeLayerSampleId: number,
		parameters: { body: BoreholeLayerSampleAcceptRequest },
	) => Kind<F, BoreholeLayerSample>;

	readonly listByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'type' | 'depthFrom' | 'depthTo' | 'layerBoreholeId') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind<F, BoreholeLayerSampleCollectionDto>;
}

export interface BoreholeLayerSampleController2<F extends URIS2> {
	readonly commentBoreholeLayerSample: (
		boreholeLayerSampleId: number,
		parameters: { body: BoreholeLayerSampleCommentRequest },
	) => Kind2<F, Error, BoreholeLayerSample>;

	readonly acceptBoreholeLayerSample: (
		boreholeLayerSampleId: number,
		parameters: { body: BoreholeLayerSampleAcceptRequest },
	) => Kind2<F, Error, BoreholeLayerSample>;

	readonly listByFacilityId: (
		facilityId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy: ('id' | 'type' | 'depthFrom' | 'depthTo' | 'layerBoreholeId') | undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind2<F, Error, BoreholeLayerSampleCollectionDto>;
}

export function boreholeLayerSampleController<F extends URIS2>(e: {
	httpClient: HTTPClient2<F>;
}): BoreholeLayerSampleController2<F>;
export function boreholeLayerSampleController<F extends URIS>(e: {
	httpClient: HTTPClient1<F>;
}): BoreholeLayerSampleController1<F>;
export function boreholeLayerSampleController<F>(e: { httpClient: HTTPClient<F> }): BoreholeLayerSampleController<F>;
export function boreholeLayerSampleController<F>(e: { httpClient: HTTPClient<F> }): BoreholeLayerSampleController<F> {
	return {
		commentBoreholeLayerSample: (boreholeLayerSampleId, parameters) => {
			const body = BoreholeLayerSampleCommentRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/sample/comment/${encodeURIComponent(
						number.encode(boreholeLayerSampleId).toString(),
					)}`,
					controller: 'boreholeLayerSample',
					operation: 'commentBoreholeLayerSample',
					method: 'PUT',
					responseType,
					pathParameters: [boreholeLayerSampleId],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeLayerSampleIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		acceptBoreholeLayerSample: (boreholeLayerSampleId, parameters) => {
			const body = BoreholeLayerSampleAcceptRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/sample/accept/${encodeURIComponent(number.encode(boreholeLayerSampleId).toString())}`,
					controller: 'boreholeLayerSample',
					operation: 'acceptBoreholeLayerSample',
					method: 'PUT',
					responseType,
					pathParameters: [boreholeLayerSampleId],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeLayerSampleIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listByFacilityId: (facilityId, parameters) => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('type'),
							literal('depthFrom'),
							literal('depthTo'),
							literal('layerBoreholeId'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/sample/facility/${encodeURIComponent(number.encode(facilityId).toString())}`,
					controller: 'boreholeLayerSample',
					operation: 'listByFacilityId',
					method: 'GET',
					responseType,
					pathParameters: [facilityId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeLayerSampleCollectionDtoIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
