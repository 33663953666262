import React, { ReactElement, useCallback, useMemo } from 'react';
import { Box, Text } from 'native-base';
import { useRoute } from '@react-navigation/native';

import { useAppNavigation } from '../../hooks/useAppNavigation';

import { screens, screensConfig, invertedScreensConfig, breadcrumbsConfig } from '../../navigation/config';
import { ScreenName, BreadcrumbScreenItem, BreadcrumbParams } from '../../navigation/types';

import { generatePath, extractConfigItemParams } from './utils';

/**
 * Компонент хлебных крошек
 * Отрисовывает список в соответствии с
 * текущим экраном/путем на основе
 * конфигурации breadcrumbsConfig
 */
export const Breadcrumbs = (): ReactElement => {
  const { name: screenName, params: routeParams } = useRoute();
  const params = routeParams as BreadcrumbParams;
  const currentScreenPath = screensConfig[screenName as ScreenName];
  const routePath = typeof currentScreenPath === 'string' ? currentScreenPath : currentScreenPath.path;
  const navigation = useAppNavigation();

  const pathParts = useMemo<Array<BreadcrumbScreenItem>>(() => {
    let prevScreenPath = '';
    const items: Array<BreadcrumbScreenItem> = [];

    const splitPath = routePath.split('/').filter(Boolean);
    splitPath.forEach((path) => {
      const screenPath = `${prevScreenPath}/${path}`;
      const screen = invertedScreensConfig[screenPath];
      let options = breadcrumbsConfig[screen];
      if (!options) {
        return;
      }

      prevScreenPath = screenPath;

      if (typeof options === 'object') {
        const { prepend, ...restOptions } = options;
        if (prepend) {
          const prependBreadcrumbsConfig = breadcrumbsConfig[prepend];
          const prependScreenOptions = screensConfig[prepend];
          if (prependBreadcrumbsConfig && prependScreenOptions) {
            items.push({
              screen: prepend,
              ...extractConfigItemParams(prependBreadcrumbsConfig),
              ...generatePath(
                typeof prependScreenOptions === 'object' ? prependScreenOptions.path : prependScreenOptions,
                params
              )
            });
          }
          options = restOptions;
        }
      }
      items.push({
        screen,
        ...extractConfigItemParams(options),
        ...generatePath(screenPath, params)
      });
    });

    return items;
  }, [screenName]);

  const isDividerShow = (index: number) => {
    return index !== pathParts.length - 1;
  };

  const isCurrentScreen = useCallback(
    (screen: keyof typeof screens) => {
      return screenName === screen;
    },
    [screenName]
  );

  const preparedChunks = useMemo(() => {
    return pathParts.map((value, index) => {
      const key = index;
      const isCurrent = isCurrentScreen(value.screen);
      return (
        <React.Fragment key={key}>
          <Text
            onPress={() => {
              if (
                (value.screen === screenName && JSON.stringify(value.params) === JSON.stringify(params)) ||
                (value.before && value.before(screenName as ScreenName) === false)
              ) {
                return;
              }
              navigation.navigate(value.screen, value.params);
            }}
            color={isCurrent ? 'dark.dark-0' : 'dark.dark-2'}
            fontSize={14}
            fontWeight={500}
          >
            {typeof value.label === 'function' ? value.label(value.params) : value.label}
          </Text>

          {isDividerShow(index) && (
            <Text fontFamily="Roboto" fontSize={14} color="icons" mx="8px">
              /
            </Text>
          )}
        </React.Fragment>
      );
    });
  }, [pathParts]);

  return (
    <Box display="flex" alignItems="center" height={12} flexDirection="row" overflow="hidden">
      {preparedChunks}
    </Box>
  );
};
