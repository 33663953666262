import React from 'react';
import { VStack, HStack } from 'native-base';
import { Controller } from 'react-hook-form';

import { Panel } from '../../../../components/panel/panel';
import { StatusSelect, statusList } from '../../../../components/status/status-select';
import { LabeledFormField } from '../../../../components/labeled-form-field';
import { BoreholeFormRendererProps } from './borehole-form-renderer.model';
import { BoreholeFormCommonPart } from './borehole-form-common-part';

export const BoreholeFormRendererGeologist: React.FC<BoreholeFormRendererProps> = ({ control, name }) => {
  const availableStatuses = statusList.filter((status) => status !== 'CLOSED');
  return (
    <Panel headerIcon="info" headerLeft={name}>
      <VStack space={4} py={5}>
        <HStack justifyContent="space-between">
          <LabeledFormField label="Статус" labelWidth="56px">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <StatusSelect
                  statuses={availableStatuses}
                  placeholder="Выберите статус"
                  value={value}
                  onValueChange={onChange}
                />
              )}
              name="status"
            />
          </LabeledFormField>
        </HStack>
        <BoreholeFormCommonPart control={control} />
      </VStack>
    </Panel>
  );
};
