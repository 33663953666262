import { Coordinate, CoordinateIO } from '../../components/schemas/Coordinate';
import { number, undefined, union, string, boolean, literal, type } from 'io-ts';

export type Map = {
	id: number;
	createdAt: number;
	lastUpdatedAt: number | undefined;
	name: string;
	facilityId: number;
	coordinate: Coordinate;
	creatorId: number;
	generated: boolean | undefined;
	status: ('NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED') | undefined;
};
export const MapIO = type(
	{
		id: number,
		createdAt: number,
		lastUpdatedAt: union([number, undefined]),
		name: string,
		facilityId: number,
		coordinate: CoordinateIO,
		creatorId: number,
		generated: union([boolean, undefined]),
		status: union([
			union([literal('NEW'), literal('IN_PROGRESS'), literal('PROBLEM'), literal('CLOSED')]),
			undefined,
		]),
	},
	'Map',
);
