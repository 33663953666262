import { number, string, undefined, union, array, literal, type } from 'io-ts';

export type ConsolidatedStatementRow = {
	index: number;
	boreholeId: string | undefined;
	soilName: string | undefined;
	depthFrom: number;
	depthTo: number;
	layerTypes: Array<string> | undefined;
	sampleType: 'MONOLITH' | 'WATER' | 'DISTURBED';
	accepted: 'UNDEFINED' | 'ACCEPTED' | 'REJECTED';
	comment: string | undefined;
};
export const ConsolidatedStatementRowIO = type(
	{
		index: number,
		boreholeId: union([string, undefined]),
		soilName: union([string, undefined]),
		depthFrom: number,
		depthTo: number,
		layerTypes: union([array(string), undefined]),
		sampleType: union([literal('MONOLITH'), literal('WATER'), literal('DISTURBED')]),
		accepted: union([literal('UNDEFINED'), literal('ACCEPTED'), literal('REJECTED')]),
		comment: union([string, undefined]),
	},
	'ConsolidatedStatementRow',
);
