import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Center, Text } from 'native-base';

import { LoadingIndicator } from '../../components/loadable';
import { useComponent } from '../../hooks/useComponent';
import { useComponentReady } from '../../hooks/useComponentReady';
import { mapStore } from '../../stores';
import { getMapId } from './utils';

type MapViewContainerProps = {
  mapId: number;
};

export const MapViewContainer: FC<MapViewContainerProps> = observer((props) => {
  const { mapId } = props;
  const { item: map } = mapStore;

  const { MapView } = useComponent();

  const ready = useComponentReady(() => {
    mapStore.loadItem(mapId).catch((e) => console.error(e));
  }, [mapId]);

  if (!ready) {
    return null;
  }

  if (mapStore.loading('get')) {
    return <LoadingIndicator />;
  }

  if (!map || mapStore.error('get')) {
    const errorMessage = !map ? 'Map is not set' : mapStore.error('get');
    return (
      <Center flexGrow={1}>
        <Text>{errorMessage}</Text>
      </Center>
    );
  }

  return <MapView id={getMapId(mapId)} maps={[map]} caption={map.name} />;
});
