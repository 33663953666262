import React, { createContext, ReactElement, PropsWithChildren } from 'react';
import { defaultComponents } from './default';
import { Components } from './types';

type ComponentProviderProps = PropsWithChildren<{
  components: Components;
}>;

const Context = createContext(defaultComponents);

const Provider = (props: ComponentProviderProps): ReactElement => {
  const { components, children } = props;
  return <Context.Provider value={components}>{children}</Context.Provider>;
};

export { Context, Provider };
