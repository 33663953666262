import { makeObservable, observable, computed, action, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import { makePersistable } from 'mobx-persist-store';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { BaseStore } from './base';

type BaseEntityRequestType = 'get';

export abstract class EntityStore<
  Entity,
  EntityLoadParams,
  EntityRequestType extends string = never
> extends BaseStore {
  @observable item: Entity | null = null;

  @observable loadingMap: { [key in BaseEntityRequestType | EntityRequestType]?: boolean } = {};

  @observable errorMap: { [key in BaseEntityRequestType | EntityRequestType]?: string | null } = {};

  @observable loadParams: EntityLoadParams | null = null;

  storageKey?: string;

  constructor(storageKey?: string) {
    super();
    makeObservable(this);
    this.storageKey = storageKey;
  }

  async init(): Promise<void> {
    await super.init();
    if (this.storageKey) {
      await makePersistable(this, {
        name: `${this.storageKey}`,
        properties: ['loadParams'],
        storage: AsyncStorage
      });
    }
  }

  @computed get anyLoading(): boolean {
    return Object.values(this.loadingMap).findIndex((loading) => !!loading) >= 0;
  }

  @computed get anyError(): boolean {
    return Object.values(this.errorMap).filter((error) => error !== null).length > 0;
  }

  loading = computedFn((requestType: BaseEntityRequestType | EntityRequestType): boolean => {
    return !!this.loadingMap[requestType];
  });

  error = computedFn((requestType: BaseEntityRequestType | EntityRequestType): string | null => {
    return this.errorMap[requestType] || null;
  });

  @action setLoading(requestType: BaseEntityRequestType | EntityRequestType, loading: boolean): void {
    this.loadingMap[requestType] = loading;
  }

  @action setError(requestType: BaseEntityRequestType | EntityRequestType, error: any): void {
    if (error === null) {
      this.errorMap[requestType] = null;
    } else if (typeof error === 'string') {
      this.errorMap[requestType] = error;
    } else if (error instanceof Error) {
      this.errorMap[requestType] = error.toString();
    } else {
      this.errorMap[requestType] = 'unknown';
    }
  }

  @action.bound setItem(item: Entity | null, params?: EntityLoadParams): void {
    this.item = item;
    this.loadParams = params || null;
  }

  @action async loadItem(params?: EntityLoadParams): Promise<Entity> {
    return this.request(
      'get',
      async (): Promise<Entity> => {
        this.loadParams = params || null;
        const item = await this.fetchItem(params);
        this.setItem(item);
        return item;
      },
      undefined,
      () => {
        this.setItem(null);
      }
    );
  }

  @action protected async request<T>(
    requestType: BaseEntityRequestType | EntityRequestType,
    execute: () => Promise<T>,
    onSuccess?: (result: T) => void,
    onError?: (e: any) => void
  ): Promise<T> {
    try {
      this.setLoading(requestType, true);
      const result = await execute();
      runInAction(() => {
        if (onSuccess) {
          onSuccess(result);
        }
        this.setError(requestType, null);
        this.setLoading(requestType, false);
      });
      return result;
    } catch (e) {
      runInAction(() => {
        if (onError) {
          onError(e);
        }
        this.setError(requestType, e);
        this.setLoading(requestType, false);
      });
      return Promise.reject(e);
    }
  }

  @action async refresh(): Promise<void> {
    await this.loadItem(this.loadParams || undefined);
  }

  protected abstract fetchItem(params?: EntityLoadParams): Promise<Entity>;
}
