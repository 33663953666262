import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Text, View } from 'native-base';

import { BlankPage } from '../../components/blank';
import { Loadable } from '../../components/loadable';
import { Pagination } from '../../components/pagination/pagination';
import { useComponentReady } from '../../hooks/useComponentReady';
import { documentsStore } from '../../stores';
import { DocumentList } from './document-list';

interface DocumentListContainerProps {
  facilityId: number;
}

export const DocumentListContainer: React.FC<DocumentListContainerProps> = observer((props) => {
  const { facilityId } = props;
  const { isLoading, error, items, page, perPage, total } = documentsStore;
  const isBlank = items.length === 0;

  const { t } = useTranslation();

  const ready = useComponentReady(() => {
    documentsStore.setFacilityId(facilityId);
    documentsStore.setPage(0);
    documentsStore.loadItems().catch((e) => console.error(e));
  }, []);

  if (!ready) {
    return null;
  }

  const onChangePage = (p: number) => {
    const newPage = p - 1;
    documentsStore.paginate(newPage).catch((e) => console.error(e));
  };

  return (
    <Loadable isLoading={isLoading} error={!!error}>
      {isBlank ? (
        <BlankPage isNeedIcon={false}>
          <Text fontSize={20} fontWeight={500} color="#b4b6c9" opacity={0.56}>
            {t('document.noData')}
          </Text>
        </BlankPage>
      ) : (
        <>
          <View flex={1} flexDirection="column">
            <DocumentList facilityId={facilityId} data={items} />
          </View>
          <Pagination current={page} total={total} perPage={perPage} onChange={onChangePage} />
        </>
      )}
    </Loadable>
  );
});
