import { BoreholeTemperature } from '../../models/BoreholeTemperatures';
import { ColumnDefinition } from '../../components/table/table.model';
import { i18n } from '../../i18n/i18next';
/**
 * Если статус Скважины отличается от “Закрыта”, вкладка неактивна, перейти на неё нельзя (рис 2)
 * TODO Добавить проверку на статус скважины
 *
 */
export const columns: ColumnDefinition<BoreholeTemperature>[] = [
  {
    key: 'depth',
    header: i18n.t('borehole.temperatures.depth'),
    width: 120
  },
  {
    key: 'temperature',
    header: i18n.t('borehole.temperatures.temperature'),
    width: 240
  },
  {
    key: 'measuredAt',
    header: i18n.t('borehole.temperatures.measuredAt'),
    width: 120
  },
  {
    key: 'time',
    header: i18n.t('borehole.temperatures.time'),
    width: 120
  },
  {
    key: 'geologistName',
    header: i18n.t('borehole.temperatures.geologist')
  }
];
