import { Worker } from '@geo/api/generated/swagger.json/components/schemas/Worker';
import React, { FC, useState } from 'react';
import { Pressable, HStack, Popover, List, Text, ChevronDownIcon, ChevronUpIcon } from 'native-base';
import { shiftStore, workerListStore } from '../../../stores';

type BoreHoleShiftWorkerCellProps = {
  worker: Worker;
  id: number;
};

export const BoreHoleShiftWorkerCell: FC<BoreHoleShiftWorkerCellProps> = ({ id, worker }) => {
  const { items: workers } = workerListStore;

  const [isOpen, updateOpen] = useState<boolean>(false);
  const [value, setValue] = useState<Worker>(worker);

  const handleOpen = () => {
    updateOpen(true);
  };
  const handleClose = () => {
    updateOpen(false);
  };

  const onChange = (newValue: Worker) => {
    setValue(newValue);
    shiftStore.updateShiftWorker(id, { workerId: newValue.id }).then(() => {
      handleClose();
    });
  };

  const Anchor = (
    <HStack
      px={3}
      alignItems="center"
      justifyContent="center"
      borderWidth={1}
      bg="#fff"
      borderColor="light.light-5"
      rounded="lg"
      height={8}
    >
      <Text w={140} noOfLines={1}>
        {value.name}
      </Text>
      {isOpen ? <ChevronUpIcon size={5} /> : <ChevronDownIcon color="#B4B6C9" size={5} />}
    </HStack>
  );

  const size = workers.length;
  const lastIndex = size - 1;

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="bottom left"
      trigger={(triggerProps) => <Pressable {...triggerProps}>{Anchor}</Pressable>}
    >
      <Popover.Content borderColor="#dbdeea" backgroundColor="#fff">
        <Popover.Body m={0} p={0} borderTopWidth={0}>
          <List borderWidth={0} p={0} m={0}>
            {workers.map((item, index) => (
              <List.Item
                borderWidth={0}
                px={1}
                height={12}
                onPress={() => onChange(item)}
                borderBottomWidth={index === lastIndex ? undefined : 1}
                _hover={{ backgroundColor: '#e9eeff' }}
                borderBottomColor="#dbdeea"
                key={item.id}
              >
                <Text noOfLines={1}>{item.name}</Text>
              </List.Item>
            ))}
          </List>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

BoreHoleShiftWorkerCell.displayName = 'SampleSelect';
