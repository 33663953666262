import { BreadcrumbConfigItem, BreadcrumbScreenItem } from '../../navigation/types';

type GeneratedPathInfo = {
  path: string;
  params: Record<string, string | number | boolean>;
};

export function generatePath(path = '/', params: Record<string, string | number | boolean> = {}): GeneratedPathInfo {
  const usedParams: Record<string, string | number | boolean> = {};
  const generatedPath = path.replace(/(:([^/]+))/g, (match, token: string, name: string): string => {
    if (params[name]) {
      usedParams[name] = params[name];
      return String(params[name]);
    }
    return token;
  });
  return { path: generatedPath, params: usedParams };
}

export function extractConfigItemParams(
  item: BreadcrumbConfigItem
): Omit<BreadcrumbScreenItem, 'screen' | 'path' | 'params'> {
  const label = typeof item === 'object' ? item.label : item;
  const before = typeof item === 'object' ? item.before : undefined;
  return { label, before };
}
