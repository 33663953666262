import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType, BlobToBlobIO } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { string, undefined, union, type } from 'io-ts';

export interface AuditController<F> {
	readonly getAuditReport: (parameters: {
		query: { startedFrom: string; startedTo: string; geologist: string | undefined; worker: string | undefined };
	}) => HKT<F, Blob>;
}

export interface AuditController1<F extends URIS> {
	readonly getAuditReport: (parameters: {
		query: { startedFrom: string; startedTo: string; geologist: string | undefined; worker: string | undefined };
	}) => Kind<F, Blob>;
}

export interface AuditController2<F extends URIS2> {
	readonly getAuditReport: (parameters: {
		query: { startedFrom: string; startedTo: string; geologist: string | undefined; worker: string | undefined };
	}) => Kind2<F, Error, Blob>;
}

export function auditController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): AuditController2<F>;
export function auditController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): AuditController1<F>;
export function auditController<F>(e: { httpClient: HTTPClient<F> }): AuditController<F>;
export function auditController<F>(e: { httpClient: HTTPClient<F> }): AuditController<F> {
	return {
		getAuditReport: parameters => {
			const query = compact([
				pipe(string.encode(parameters.query['startedFrom']), value =>
					fromEither(serializePrimitiveParameter('form', 'startedFrom', value)),
				),
				pipe(string.encode(parameters.query['startedTo']), value =>
					fromEither(serializePrimitiveParameter('form', 'startedTo', value)),
				),
				pipe(
					union([string, undefined]).encode(parameters.query['geologist']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'geologist', value))),
				),
				pipe(
					union([string, undefined]).encode(parameters.query['worker']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'worker', value))),
				),
			]).join('&');

			const accept = 'application/octet-stream';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/audit/report`,
					controller: 'audit',
					operation: 'getAuditReport',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BlobToBlobIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
