import { observable, action, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import { Codificator } from '@geo/api/generated/swagger.json/components/schemas/Codificator';

import { getApiClient } from '../api/api-client';

import { BaseStore } from './base';

export const translationKeyRegExp = /\./g;

function getCodificatorItems(items: Array<Codificator>, selection: CodificatorSelection): Array<Codificator> {
  let codificatorItems = items;
  selection.forEach(({ levelId, key }) => {
    if (codificatorItems.length === 0) {
      throw new TypeError(`Level "${levelId}" is not found, a tree leaf is reached`);
    }

    const levels = codificatorItems.filter((codificatorItem) => codificatorItem.lvl_id === levelId);
    if (levels.length === 0) {
      const availableLevelIds = `"${codificatorItems.map((codificatorItem) => codificatorItem.lvl_id).join('", "')}"`;
      throw new TypeError(
        `Level "${levelId}" is not found, the following level ids are available: ${availableLevelIds}`
      );
    }

    let level: Codificator | null = null;
    const availableKeys: Array<string> = [];
    for (let i = 0; i < levels.length; i += 1) {
      if (levels[i].keys.includes(key)) {
        level = levels[i];
        break;
      } else {
        availableKeys.push(...levels[i].keys);
      }
    }
    if (!level) {
      throw new TypeError(
        `Key "${key}" is not found on level "${levelId}", the following keys are available: ${availableKeys.join(', ')}`
      );
    }

    codificatorItems = level.items || [];
  });
  return codificatorItems;
}

function validateSelection(items: Array<Codificator>, selection: CodificatorSelection): CodificatorSelection {
  let codificatorItems = items;
  const validSelection: CodificatorSelection = [];
  for (let i = 0; i < selection.length; i += 1) {
    const { levelId, key } = selection[i];
    const levels = codificatorItems.filter((codificatorItem) => codificatorItem.lvl_id === levelId);
    const level = levels.find((l) => l.keys.includes(key));
    if (!level) {
      return validSelection;
    }
    validSelection.push({ levelId, key });
    codificatorItems = level.items || [];
  }
  return validSelection;
}

function codificatorItemsToSelection(items: Array<Codificator>): CodificatorSelection {
  const selection: CodificatorSelection = [];
  items.forEach((codificatorItem) => {
    const { lvl_id: levelId, keys } = codificatorItem;
    keys.forEach((key) => {
      selection.push({ levelId, key });
    });
  });
  return selection;
}

function createSearchRegExp(text: string): RegExp {
  // https://stackoverflow.com/a/3561711/506695
  return new RegExp(text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
}

export type CodificatorSelectionItem = {
  levelId: string;
  key: string;
};

export type CodificatorSelection = Array<CodificatorSelectionItem>;

export class CodificatorStore extends BaseStore {
  @observable loading = false;

  @observable error = false;

  @observable.ref items: Array<Codificator> | null = null;

  keyTranslations: Record<string, string> = {};

  @action
  setKeyTranslations(keyTranslations: Record<string, string> = {}) {
    this.keyTranslations = keyTranslations;
  }

  @action async load(): Promise<void> {
    try {
      this.loading = true;
      const items = await getApiClient().codificatorController.getCodificator();
      runInAction(() => {
        this.items = items;
        this.loading = false;
        this.error = false;
      });
    } catch (e) {
      runInAction(() => {
        this.items = null;
        this.loading = false;
        this.error = true;
      });
    }
  }

  selectOptions = computedFn((selection: CodificatorSelection): CodificatorSelection => {
    return this.items ? codificatorItemsToSelection(getCodificatorItems(this.items, selection)) : [];
  });

  validSelection = computedFn((selection: CodificatorSelection): CodificatorSelection => {
    return this.items ? validateSelection(this.items, selection) : [];
  });

  suggestOptions = computedFn((selection: CodificatorSelection, text: string): CodificatorSelection => {
    const selectOptions = this.selectOptions(selection);
    const normalizedText = text.trim();
    if (normalizedText === '') {
      return selectOptions;
    }
    const keysByTranslation = this.getKeysByTranslation(text);
    const keyResults = selectOptions.filter(({ key }) => createSearchRegExp(text).test(key));
    const translationResults = selectOptions.filter(({ key }) => {
      return keysByTranslation.includes(key.replace(translationKeyRegExp, '-'));
    });
    return [...keyResults, ...translationResults];
  });

  getKeysByTranslation = computedFn((text: string): Array<string> => {
    const regExp = createSearchRegExp(text);
    return Object.keys(this.keyTranslations).filter((key) => {
      return regExp.test(this.keyTranslations[key]);
    });
  });
}
