import { HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { auditController, AuditController, AuditController1, AuditController2 } from './AuditController';
import {
	auditEventController,
	AuditEventController,
	AuditEventController1,
	AuditEventController2,
} from './AuditEventController';
import { authController, AuthController, AuthController1, AuthController2 } from './AuthController';
import { boreholeController, BoreholeController, BoreholeController1, BoreholeController2 } from './BoreholeController';
import {
	boreholeLayerController,
	BoreholeLayerController,
	BoreholeLayerController1,
	BoreholeLayerController2,
} from './BoreholeLayerController';
import {
	boreholeLayerSampleController,
	BoreholeLayerSampleController,
	BoreholeLayerSampleController1,
	BoreholeLayerSampleController2,
} from './BoreholeLayerSampleController';
import {
	boreholeTemperatureController,
	BoreholeTemperatureController,
	BoreholeTemperatureController1,
	BoreholeTemperatureController2,
} from './BoreholeTemperatureController';
import { clientDbController, ClientDbController, ClientDbController1, ClientDbController2 } from './ClientDbController';
import {
	codificatorController,
	CodificatorController,
	CodificatorController1,
	CodificatorController2,
} from './CodificatorController';
import { commentController, CommentController, CommentController1, CommentController2 } from './CommentController';
import {
	consolidatedStatementController,
	ConsolidatedStatementController,
	ConsolidatedStatementController1,
	ConsolidatedStatementController2,
} from './ConsolidatedStatementController';
import { documentController, DocumentController, DocumentController1, DocumentController2 } from './DocumentController';
import { facilityController, FacilityController, FacilityController1, FacilityController2 } from './FacilityController';
import { mapController, MapController, MapController1, MapController2 } from './MapController';
import {
	notificationController,
	NotificationController,
	NotificationController1,
	NotificationController2,
} from './NotificationController';
import { photoController, PhotoController, PhotoController1, PhotoController2 } from './PhotoController';
import { shiftController, ShiftController, ShiftController1, ShiftController2 } from './ShiftController';
import { usersController, UsersController, UsersController1, UsersController2 } from './UsersController';
import { workerController, WorkerController, WorkerController1, WorkerController2 } from './WorkerController';
import { URIS, URIS2 } from 'fp-ts/lib/HKT';

export interface Controllers<F> {
	auditController: AuditController<F>;
	auditEventController: AuditEventController<F>;
	authController: AuthController<F>;
	boreholeController: BoreholeController<F>;
	boreholeLayerController: BoreholeLayerController<F>;
	boreholeLayerSampleController: BoreholeLayerSampleController<F>;
	boreholeTemperatureController: BoreholeTemperatureController<F>;
	clientDbController: ClientDbController<F>;
	codificatorController: CodificatorController<F>;
	commentController: CommentController<F>;
	consolidatedStatementController: ConsolidatedStatementController<F>;
	documentController: DocumentController<F>;
	facilityController: FacilityController<F>;
	mapController: MapController<F>;
	notificationController: NotificationController<F>;
	photoController: PhotoController<F>;
	shiftController: ShiftController<F>;
	usersController: UsersController<F>;
	workerController: WorkerController<F>;
}
export interface Controllers1<F extends URIS> {
	auditController: AuditController1<F>;
	auditEventController: AuditEventController1<F>;
	authController: AuthController1<F>;
	boreholeController: BoreholeController1<F>;
	boreholeLayerController: BoreholeLayerController1<F>;
	boreholeLayerSampleController: BoreholeLayerSampleController1<F>;
	boreholeTemperatureController: BoreholeTemperatureController1<F>;
	clientDbController: ClientDbController1<F>;
	codificatorController: CodificatorController1<F>;
	commentController: CommentController1<F>;
	consolidatedStatementController: ConsolidatedStatementController1<F>;
	documentController: DocumentController1<F>;
	facilityController: FacilityController1<F>;
	mapController: MapController1<F>;
	notificationController: NotificationController1<F>;
	photoController: PhotoController1<F>;
	shiftController: ShiftController1<F>;
	usersController: UsersController1<F>;
	workerController: WorkerController1<F>;
}
export interface Controllers2<F extends URIS2> {
	auditController: AuditController2<F>;
	auditEventController: AuditEventController2<F>;
	authController: AuthController2<F>;
	boreholeController: BoreholeController2<F>;
	boreholeLayerController: BoreholeLayerController2<F>;
	boreholeLayerSampleController: BoreholeLayerSampleController2<F>;
	boreholeTemperatureController: BoreholeTemperatureController2<F>;
	clientDbController: ClientDbController2<F>;
	codificatorController: CodificatorController2<F>;
	commentController: CommentController2<F>;
	consolidatedStatementController: ConsolidatedStatementController2<F>;
	documentController: DocumentController2<F>;
	facilityController: FacilityController2<F>;
	mapController: MapController2<F>;
	notificationController: NotificationController2<F>;
	photoController: PhotoController2<F>;
	shiftController: ShiftController2<F>;
	usersController: UsersController2<F>;
	workerController: WorkerController2<F>;
}

export function controllers<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): Controllers2<F>;
export function controllers<F extends URIS>(e: { httpClient: HTTPClient1<F> }): Controllers1<F>;
export function controllers<F>(e: { httpClient: HTTPClient<F> }): Controllers<F>;
export function controllers<F>(e: { httpClient: HTTPClient<F> }): Controllers<F> {
	return {
		auditController: auditController(e),
		auditEventController: auditEventController(e),
		authController: authController(e),
		boreholeController: boreholeController(e),
		boreholeLayerController: boreholeLayerController(e),
		boreholeLayerSampleController: boreholeLayerSampleController(e),
		boreholeTemperatureController: boreholeTemperatureController(e),
		clientDbController: clientDbController(e),
		codificatorController: codificatorController(e),
		commentController: commentController(e),
		consolidatedStatementController: consolidatedStatementController(e),
		documentController: documentController(e),
		facilityController: facilityController(e),
		mapController: mapController(e),
		notificationController: notificationController(e),
		photoController: photoController(e),
		shiftController: shiftController(e),
		usersController: usersController(e),
		workerController: workerController(e),
	};
}
