import { i18n } from '../../../i18n/i18next';
import { ColumnDefinition } from '../../../components/table/table.model';
import { BoreholeLayer, BoreholeLayerSortColumn } from '../../../models/BoreholeLayer';

export const columns: ColumnDefinition<BoreholeLayer, BoreholeLayerSortColumn>[] = [
  {
    key: 'id',
    header: i18n.t('borehole-layer.name'),
    width: 60,
    height: 10
  },
  {
    key: 'depthFrom',
    header: i18n.t('borehole-layer.depthFrom'),
    width: 180,
    height: 10
  },
  {
    key: 'gwlEmerged',
    header: i18n.t('borehole-layer.gwlEmerged'),
    width: 70,
    height: 10
  },
  {
    key: 'gwlSteady',
    header: i18n.t('borehole-layer.gwlSteady'),
    width: 70,
    height: 10
  },
  {
    key: 'geoDescription',
    header: i18n.t('borehole-layer.geoDescription'),
    width: 300
  },
  {
    key: 'samples',
    header: i18n.t('borehole-layer.samples')
  }
];
