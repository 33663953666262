import React, { FC } from 'react';

import { ACTION, SUBJECT } from './index';
import { usePermission } from './context';

type Action = typeof ACTION[keyof typeof ACTION];
type Subject = typeof SUBJECT[keyof typeof SUBJECT];

type PermissionGuardProps = {
  action: Action;
  subject: Subject;
  children: React.ReactNode | React.ReactNode[];
};

export const PermissionGuard: FC<PermissionGuardProps> = ({ action, subject, children }) => {
  const permission = usePermission();
  if (permission.can(action, subject)) {
    return <>{children}</>;
  }
  return null;
};
