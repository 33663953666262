import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Text, View } from 'native-base';
import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';

import { GridItems } from '../../components/grid-items';
import Icon from '../../components/icon/icon';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { useFunction } from '../../hooks/useFunction';
import { useGridItemSize } from '../../hooks/useGridItemSize';
import { useNavigationEffect } from '../../hooks/useNavigationEffect';
import { screens } from '../../navigation/config';
import { documentsStore, facilityStore } from '../../stores';
import { ACTION, SUBJECT } from '../../utils/permissions';
import { usePermission } from '../../utils/permissions/context';

import { Document } from './document';

const DOCUMENT_ROWS = 3;
const DOCUMENT_COLUMNS = 7;
const DOCUMENT_ROW_GAP = 10;
const DOCUMENT_COLUMN_GAP = 10;
const DOCUMENT_TITLE_HEIGHT = 50;

type DocumentListProps = {
  facilityId: number;
  data: FacilityFile[];
};

export const DocumentList: React.FC<DocumentListProps> = observer((props) => {
  const { facilityId, data } = props;
  const { editModeEnabled } = documentsStore;

  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const permission = usePermission();
  const { fileSave } = useFunction();

  const { width, height, onLayout } = useGridItemSize({
    rows: DOCUMENT_ROWS,
    columns: DOCUMENT_COLUMNS,
    rowGap: DOCUMENT_ROW_GAP,
    columnGap: DOCUMENT_COLUMN_GAP,
    verticalExtra: DOCUMENT_TITLE_HEIGHT
  });

  useNavigationEffect(() => {
    if (!facilityStore.item || facilityStore.item?.id !== facilityId) {
      facilityStore.loadItem(facilityId).catch((e) => console.error(e));
    }
    return () => documentsStore.setEditModeEnabled(false);
  }, [facilityId]);

  const toggleEditMode = () => documentsStore.toggleEditMode();

  const onDelete = (id: number) => {
    documentsStore.remove(facilityId, id).catch((e) => console.error(e));
  };

  const onClick = (id: number) => {
    fileSave(facilityId, id);
  };

  const onEdit = (documentId: number) => {
    navigation.navigate(screens.DocumentEdit, { facilityId, documentId });
  };

  const canEditDocuments =
    permission.can(ACTION.EDIT, SUBJECT.DOCUMENTS) &&
    facilityStore.item?.id === facilityId &&
    !facilityStore.isArchived &&
    !facilityStore.isDeleted;

  let documents;
  if (width > 0 && height > 0) {
    documents = (
      <GridItems
        items={data.map((document) => {
          const { id, filename: fileName, description } = document;
          return (
            <Document
              id={id}
              fileName={fileName}
              title={description}
              width={`${width}px`}
              height={`${height}px`}
              titleHeight={`${DOCUMENT_TITLE_HEIGHT}px`}
              isEditable={editModeEnabled}
              onClick={onClick}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          );
        })}
        columns={DOCUMENT_COLUMNS}
        rowGap={DOCUMENT_ROW_GAP}
        columnGap={DOCUMENT_COLUMN_GAP}
      />
    );
  }

  return (
    <>
      {canEditDocuments && (
        <Flex mb={4} alignItems="flex-end" h={12}>
          <Button
            minWidth={170}
            startIcon={<Icon fill="#fff" name={editModeEnabled ? 'cancel' : 'edit'} />}
            onPress={toggleEditMode}
          >
            {editModeEnabled ? t('document.buttons.edit-cancel') : t('document.buttons.edit')}
          </Button>
        </Flex>
      )}
      <View flex={1} onLayout={onLayout}>
        <View position="absolute" top={0} bottom={0} left={0} right={0} overflow="hidden">
          {documents}
        </View>
      </View>
    </>
  );
});

DocumentList.displayName = Document.name;
