import React from 'react';
import ReactDOM from 'react-dom';
import { NativeBaseProvider } from 'native-base';

import '@geo/commons/i18n/i18next';
import { theme } from '@geo/commons';
import { AppLoaderContainer } from '@geo/commons/features/app-loader/app-loader.container';
import Navigator, { makeScreen } from '@geo/commons/navigation/Navigator';
import { Provider as ComponentProvider } from '@geo/commons/utils/components';
import { Provider as FunctionProvider } from '@geo/commons/utils/functions';
import { PermissionProvider } from '@geo/commons/utils/permissions/context';
import { screens } from '@geo/commons/navigation/config';
import { AuditPage } from '@geo/commons/navigation/pages/audit/audit';
import { CodificatorTranslationsUploadPage } from '@geo/commons/navigation/pages/codificator/codificator-translation-upload';
import { CodificatorUploadPage } from '@geo/commons/navigation/pages/codificator/codificator-upload';
import { CodificatorPage } from '@geo/commons/navigation/pages/codificator/codificator';
import { webComponents } from './components';
import { webFunctions } from './functions';
import reportWebVitals from './reportWebVitals';

import './logs';
import './index.css';

ReactDOM.render(
  <PermissionProvider>
    <NativeBaseProvider theme={theme}>
      <FunctionProvider functions={webFunctions}>
        <ComponentProvider components={webComponents}>
          <AppLoaderContainer>
            <Navigator
              platformScreensBuilder={(initialRoute) => {
                return [
                  makeScreen(screens.Audit, 'Аудит', AuditPage, initialRoute),
                  makeScreen(screens.Codificator, 'Кодификатор', CodificatorPage, initialRoute),
                  makeScreen(
                    screens.CodificatorTranslationsUpload,
                    'Обновление',
                    CodificatorTranslationsUploadPage,
                    initialRoute
                  ),
                  makeScreen(screens.CodificatorUpload, 'Обновление переводов', CodificatorUploadPage, initialRoute)
                ];
              }}
            />
          </AppLoaderContainer>
        </ComponentProvider>
      </FunctionProvider>
    </NativeBaseProvider>
  </PermissionProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
