import yup from '../../utils/yup';

export const maxLength = 50;

export interface AuditFormModel {
  range: {
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    isDefault: boolean;
    key?: string | undefined;
  };
  worker?: string;
  geologist?: string;
}

export const schema = yup.object().shape({
  geologist: yup.string(),
  range: yup.object().shape({
    startDate: yup.date().required(),
    endDate: yup.date().required(),
    key: yup.string().required(),
    isDefault: yup.boolean().isFalse()
  }),
  worker: yup.string()
});
