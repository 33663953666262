import React, { FC } from 'react';
import i18n from 'i18next';

import { SampleType } from '@geo/commons/components/sample/sample-type';
import { statusText } from '@geo/commons/components/status/status-badge';
import { Borehole } from '@geo/commons/models/Borehole';
import { geoDescriptionToSelection } from '@geo/commons/features/borehole-layer/borehole-layer-table/utils';
import { BoreholeLayer } from '@geo/commons/models/BoreholeLayer';
import { translationKeyRegExp } from '@geo/commons/stores/codificator';
import { centimetersToMeters } from '@geo/commons/utils/units';

import { useTranslation } from 'react-i18next';
import { PrintTemplate } from 'components/print-template/print-template';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './index.module.css';

type ValueProps = {
  className?: string;
};
const Value: FC<ValueProps> = ({ children, className }) => {
  const preparedClassName = `${styles.value}${` ${className}` || ''}`;
  return <span className={preparedClassName}>{children}</span>;
};
const MDASH = '\u2014';

export interface BoreholePrintItem {
  borehole: Borehole;
  layers: BoreholeLayer[];
}

export interface BoreholePrintProps {
  items: BoreholePrintItem[];
}

export const BoreholePrint = React.forwardRef<HTMLDivElement, BoreholePrintProps>(({ items }, ref) => {
  const { t } = useTranslation();
  return (
    <PrintTemplate ref={ref}>
      {items.map(({ borehole, layers }) => {
        const { name, depthTarget, depthActual, coordinate, status, pipeCase = {}, technologicalExpansion } = borehole;
        const { latitude, longitude } = coordinate;
        return (
          <>
            <div className={styles.boreholeName}>
              Объект: <Value>{borehole.facility.name}</Value>
            </div>
            <div className={styles.title}>
              Журнал <br />
              описания горной выработки
            </div>
            <table className={styles.tableInfo}>
              <tbody>
                <tr>
                  <td>
                    № <Value>{name}</Value>
                  </td>
                  <td>
                    План (м) <Value>{centimetersToMeters(depthTarget)}</Value>
                  </td>
                  <td>
                    Факт (м) <Value>{depthActual ? centimetersToMeters(depthActual) : ''}</Value>
                  </td>
                </tr>
                <tr>
                  <td>Система координат:</td>
                  <td>
                    N <Value>{latitude}</Value>
                  </td>
                  <td>
                    E <Value>{longitude}</Value>
                  </td>
                </tr>
                <tr>
                  <td>Статус скважины:</td>
                  <td>{statusText[status]}</td>
                </tr>
              </tbody>
            </table>
            <div className={styles.casing}>
              Обсадка:
              <div className={styles.pipeWrapper}>
                <div className={styles.pipe}>
                  {t(`borehole.card.pipeCaseType_1`)}, м{' '}
                  <Value>{typeof pipeCase.TYPE_1 === 'number' ? centimetersToMeters(pipeCase.TYPE_1) : MDASH}</Value>
                </div>
                <div className={styles.pipe}>
                  {t(`borehole.card.pipeCaseType_1`)}, м{' '}
                  <Value>{typeof pipeCase.TYPE_2 === 'number' ? centimetersToMeters(pipeCase.TYPE_2) : MDASH}</Value>
                </div>
                <div className={styles.pipe}>
                  {t(`borehole.card.pipeCaseType_1`)}, м{' '}
                  <Value>{typeof pipeCase.TYPE_3 === 'number' ? centimetersToMeters(pipeCase.TYPE_3) : MDASH}</Value>
                </div>
              </div>
              Технологическое расширение, м{' '}
              <Value>
                {typeof technologicalExpansion === 'number' ? centimetersToMeters(technologicalExpansion) : MDASH}
              </Value>
            </div>
            <div className={styles.employers}>
              <div className={`${styles.employersColumn} ${styles.geologist}`}>
                Список геологов:
                <Value className={styles.employeGap}>Барандин А.Б.</Value>
                <Value className={styles.employeGap}>Иванов Г.В</Value>
              </div>
              <div className={styles.employersColumn}>
                Список бригадиров:
                <Value className={styles.employeGap}>Барандин А.Б.</Value>
                <Value className={styles.employeGap}>Иванов Г.В</Value>
              </div>
            </div>
            <div className={styles.pageBreaker}>
              <div className={styles.boreholesValue}>
                №: <Value>{name}</Value>
              </div>
              <table className={styles.tableBoreholeInfo}>
                <colgroup>
                  <col className={styles.colNarrow} />
                  <col className={styles.colNarrow} />
                  <col className={styles.colNarrow} />
                  <col />
                  <col className={styles.colNarrow} />
                  <col className={styles.colNarrow} />
                  <col className={styles.colWide} />
                </colgroup>
                <thead>
                  <tr>
                    <th rowSpan={2}>№ слоя</th>
                    <th colSpan={2}>Глубина, м</th>
                    <th rowSpan={2}>Послойное описание</th>
                    <th colSpan={2}>УГВ</th>
                    <th rowSpan={2}>Отбор проб.</th>
                  </tr>
                  <tr>
                    <th>От</th>
                    <th>До</th>
                    <th>Появ</th>
                    <th>Устан</th>
                  </tr>
                </thead>
                <tbody>
                  {layers.map((layer, i) => (
                    <tr key={layer.id}>
                      <td className={styles.alignRight}>{i + 1}</td>
                      <td className={styles.alignRight}>{centimetersToMeters(layer.depthFrom)}</td>
                      <td className={styles.alignRight}>{centimetersToMeters(layer.depthTo)}</td>
                      <td>
                        {geoDescriptionToSelection(layer.geoDescription)
                          ?.map(({ key }) => i18n.t(`codificator.${key.replace(translationKeyRegExp, '-')}`))
                          .join(', ')}
                      </td>
                      <td className={styles.alignRight}>
                        {layer.gwlEmerged ? centimetersToMeters(layer.gwlEmerged) : ''}
                      </td>
                      <td className={styles.alignRight}>
                        {layer.gwlSteady ? centimetersToMeters(layer.gwlSteady) : ''}
                      </td>
                      <td>
                        {layer.samples?.map(({ id: sampleId, depthFrom, depthTo, type }) => {
                          return (
                            <div key={sampleId} className={styles.sampleType}>
                              <SampleType type={type} isVisibleLabel={false} />
                              <div className={styles.sampleTypeDepth}>
                                {centimetersToMeters(depthFrom)} {MDASH} {centimetersToMeters(depthTo)}
                              </div>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.pageBreakAfter} />
          </>
        );
      })}
    </PrintTemplate>
  );
});

BoreholePrint.displayName = 'BoreholePrint';
