import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { Tabs } from '../../../components/tabs';
import { MapGeneralViewContainer } from '../../../features/map/map-general-view.container';
import { PageProps } from '../../types';

type MapGeneralViewPageProps = PageProps<'MapGeneralView'>;

export const MapGeneralViewPage: FC<MapGeneralViewPageProps> = ({ route }) => {
  const { params } = route;
  const { facilityId } = params;
  if (!facilityId) {
    return null;
  }
  return (
    <Page header={<Tabs facilityId={facilityId} />} scrollable={false}>
      <MapGeneralViewContainer facilityId={facilityId} />
    </Page>
  );
};
