import objectHash from 'object-hash';

import yup from '../../../utils/yup';
import { BoreholeLayer } from '../../../models/BoreholeLayer';
import { BoreholeLayerSampleType } from '../../../models/BoreholeLayerSample';
import { boreholeLayerTableStore } from '../../../stores';
import { centimetersToMeters } from '../../../utils/units';

type BoreholeLayerSampleFormData = {
  type: BoreholeLayerSampleType;
  depthFrom: number;
  depthTo: number;
};

export type BoreholeLayerFormData = {
  id: number;
  boreholeId: number;
  depthFrom: number;
  depthTo: number;
  gwlEmerged?: number;
  gwlSteady?: number;
  geoDescription: Array<{ levelId: string; key: string }>;
  samples: Array<BoreholeLayerSampleFormData>;
};

type DepthRange = { from: number; to: number };

type SampleTestContextFrom = [
  { schema: typeof schema.fields.samples.innerType; value: BoreholeLayerSampleFormData },
  { schema: typeof schema; value: BoreholeLayerFormData }
];
type SampleTestContext = yup.TestContext & {
  from: SampleTestContextFrom;
};

// eslint-disable-next-line no-template-curly-in-string
const depthRangeErrorMessage = '${path} makes up an invalid depth range or a depth range overlaping with existing one';
const numberRegExp = /^\d+(\.\d+)?$/;

function getSampleTestContext(context: yup.TestContext): SampleTestContext {
  return context as SampleTestContext;
}

function getSiblingBoreholeLayers(id: number): Array<BoreholeLayer> {
  const { items: boreholeLayers } = boreholeLayerTableStore;
  return id > 0 ? boreholeLayers.filter((boreholeLayer) => boreholeLayer.id !== id) : boreholeLayers;
}

function parseNumber(value: number | string): number | null {
  if (typeof value === 'string') {
    return numberRegExp.test(value) ? parseFloat(value) : null;
  }
  return value;
}

function getSampleBoreholeLayerDepth(context: yup.TestContext): DepthRange | null {
  const { from } = getSampleTestContext(context);
  const depthFrom = parseNumber(from[1].value.depthFrom);
  const depthTo = parseNumber(from[1].value.depthTo);
  return typeof depthFrom === 'number' && typeof depthTo === 'number' ? { from: depthFrom, to: depthTo } : null;
}

function getSiblingSamples(
  context: yup.TestContext,
  sample: BoreholeLayerSampleFormData
): {
  samples: Array<BoreholeLayerSampleFormData>;
  copies: number;
} {
  const { from } = getSampleTestContext(context);

  const parsedSamples = from[1].value.samples.map((s) => {
    // depthFrom and depthTo could be string here (if they are edited)
    // so he have to ensure that they are numbers before filtering
    const { type, depthFrom, depthTo } = s;
    return {
      type,
      depthFrom: parseNumber(depthFrom),
      depthTo: parseNumber(depthTo)
    };
  });

  const sampleHash = objectHash(sample);

  let copies = 0;
  const samples: Array<BoreholeLayerSampleFormData> = [];
  parsedSamples.forEach((s) => {
    const { type, depthFrom, depthTo } = s;
    const hash = objectHash(s);
    if (depthFrom !== null && depthTo !== null && hash !== sampleHash) {
      samples.push({ type, depthFrom, depthTo });
    } else {
      copies += 1;
    }
  });

  return { samples, copies };
}

function getBoreholeLayerDepths(boreholeLayer: BoreholeLayer): DepthRange {
  return { from: centimetersToMeters(boreholeLayer.depthFrom), to: centimetersToMeters(boreholeLayer.depthTo) };
}

function depthRangesOverlap(range1: DepthRange, range2: DepthRange): boolean {
  return range1.from < range2.to && range2.from < range1.to;
}

function depthRangesNested(inner: DepthRange, outer: DepthRange): boolean {
  return inner.from >= outer.from && inner.from < outer.to && inner.to <= outer.to && inner.to > outer.from;
}

function validateSampleDepthRange(context: yup.TestContext, sample: BoreholeLayerSampleFormData): boolean {
  const sampleDepth =
    typeof sample.depthFrom === 'number' && typeof sample.depthTo === 'number'
      ? { from: sample.depthFrom, to: sample.depthTo }
      : null;
  if (!sampleDepth) {
    return true;
  }

  const depth = getSampleBoreholeLayerDepth(context);
  if (depth && !depthRangesNested(sampleDepth, depth)) {
    return false;
  }

  const { samples, copies } = getSiblingSamples(context, sample);
  if (copies >= 2) {
    return false;
  }
  for (let i = 0; i < samples.length; i += 1) {
    const { depthFrom: from, depthTo: to } = samples[i];
    if (depthRangesOverlap(sampleDepth, { from, to })) {
      return false;
    }
  }

  return true;
}

const formatUndefined = (_: number, val: number | string): number | undefined | null =>
{
  return (val === Number(val) || !isNaN(Number(val))) ? Number(val) : null;
}

export const schema = yup.object().shape({
  depthFrom: yup
    .number()
    .min(0)
    .max(10000)
    .fractionalDigits(2)
    .required()
    .test({
      name: 'depth-from-range',
      message: depthRangeErrorMessage,
      test: function depthFromRange(depthFrom) {
        if (depthFrom === undefined) {
          return true;
        }
        const { id, depthTo } = this.parent as BoreholeLayerFormData;
        const depth = typeof depthTo === 'number' ? { from: depthFrom, to: depthTo } : null;
        const boreholeLayers = getSiblingBoreholeLayers(id);
        for (let i = 0; i < boreholeLayers.length; i += 1) {
          const siblingDepth = getBoreholeLayerDepths(boreholeLayers[i]);
          if (depthFrom >= siblingDepth.from && depthFrom < siblingDepth.to) {
            return false;
          }
          if (depth && depthRangesOverlap(depth, siblingDepth)) {
            return false;
          }
        }
        return true;
      }
    }),
  depthTo: yup
    .number()
    .moreThan(yup.ref('depthFrom'))
    .max(10000)
    .fractionalDigits(2)
    .required()
    .test({
      name: 'depth-to-range',
      message: depthRangeErrorMessage,
      test: function depthToRange(depthTo) {
        if (depthTo === undefined) {
          return true;
        }
        const { id, depthFrom } = this.parent as BoreholeLayerFormData;
        const depth = typeof depthFrom === 'number' ? { from: depthFrom, to: depthTo } : null;
        const boreholeLayers = getSiblingBoreholeLayers(id);
        for (let i = 0; i < boreholeLayers.length; i += 1) {
          const siblingDepth = getBoreholeLayerDepths(boreholeLayers[i]);
          if (depthTo > siblingDepth.from && depthTo <= siblingDepth.to) {
            return false;
          }
          if (depth && depthRangesOverlap(depth, siblingDepth)) {
            return false;
          }
        }
        return true;
      }
    }),
  gwlEmerged: yup.number().min(0).max(100000).fractionalDigits(2).nullable(true).transform(formatUndefined),
  gwlSteady: yup.number().min(0).max(100000).fractionalDigits(2).nullable(true).transform(formatUndefined),
  geoDescription: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        levelId: yup.string(),
        key: yup.string()
      })
    )
    // eslint-disable-next-line no-template-curly-in-string
    .test('geo-description-is-defined', '${path} is not defined', (value) => {
      if (!value || value.length === 0) {
        return false;
      }
      return (
        value[0].levelId !== 'not.defined' &&
        value[0].levelId !== 'not-defined' &&
        value[0].key !== 'not.defined' &&
        value[0].key !== 'not-defined'
      );
    }),
  samples: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string(),
        depthFrom: yup
          .number()
          .min(0)
          .max(10000)
          .fractionalDigits(2)
          .required()
          .test({
            name: 'sample-depth-from-range',
            message: depthRangeErrorMessage,
            test: function sampleDepthFromRange(depthFrom) {
              if (depthFrom === undefined) {
                return true;
              }
              const depth = getSampleBoreholeLayerDepth(this);
              if (depth && (depthFrom < depth.from || depthFrom >= depth.to)) {
                return false;
              }
              const { type, depthTo } = this.parent as BoreholeLayerFormData['samples'][0];
              return validateSampleDepthRange(this, { type, depthFrom, depthTo });
            }
          }),
        depthTo: yup
          .number()
          .moreThan(yup.ref('depthFrom'))
          .max(10000)
          .fractionalDigits(2)
          .required()
          .test({
            name: 'sample-depth-to-range',
            message: depthRangeErrorMessage,
            test: function sampleDepthToRange(depthTo) {
              if (depthTo === undefined) {
                return true;
              }
              const depth = getSampleBoreholeLayerDepth(this);
              if (depth && (depthTo <= depth.from || depthTo > depth.to)) {
                return false;
              }
              const { type, depthFrom } = this.parent as BoreholeLayerFormData['samples'][0];
              return validateSampleDepthRange(this, { type, depthFrom, depthTo });
            }
          })
      })
    )
});
