import React, { FC } from 'react';
import { subject } from '@casl/ability';
import { VStack, Box, HStack, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../components/table/table.model';
import { ActionView } from '../../components/action';
import { dateTimeFormatLocale } from '../../utils/date';
import { ACTION, SUBJECT } from '../../utils/permissions';
import { usePermission } from '../../utils/permissions/context';
import { DeleteButton } from '../../components/delete-button';
import { stringifyRole } from '../../utils/stringifyRole';

export interface Comment {
  id: number;
  text: string;
  facilityId: number;
  creatorId: number;
  creatorName: string;
  markToDelete?: boolean;
  role: string;
  createdAt: number;
}

export interface CommentProps extends Comment {
  currentUserId?: number;
  onEdit: (comment: Comment) => void;
  onDelete: (commentId: number) => void;
}

export const Comment: FC<CommentProps> = (props) => {
  const { t } = useTranslation();
  const { text, creatorName, markToDelete, creatorId, role, createdAt, onEdit, onDelete, currentUserId } = props;
  const permission = usePermission();
  const canDelete = permission.can(ACTION.DELETE, subject(SUBJECT.COMMENT, { creatorId }));
  const canEdit = permission.can(ACTION.EDIT, subject(SUBJECT.COMMENT, { creatorId }));
  const actionsList: Actions<Comment> = [];
  if (canDelete) {
    actionsList.push({
      id: 'delete',
      customRenderer: (item: Comment) => {
        return (
          <DeleteButton
            item={item}
            onMark={() => ''}
            subject={SUBJECT.COMMENT}
            onDelete={onDelete}
            markToDeleteConfirmationText=""
            deleteConfirmationText={t('comment.delete.confirmation')}
          />
        );
      }
    });
  }

  if (canEdit) {
    actionsList.push({
      id: 'edit',
      iconName: 'edit',
      actionCallback: (item) => {
        onEdit(item);
      }
    });
  }

  return (
    <VStack>
      {markToDelete ? (
        <Text>{t('comment.removedPlaceholder')}</Text>
      ) : (
        <>
          <HStack space={11} height={14} alignItems="center">
            <HStack alignItems="center" space={4}>
              <Text
                color="dark.dark-0"
                fontWeight={500}
                bg={currentUserId === creatorId ? '#e4f5d7' : '#e9eeff'}
                borderRadius="lg"
                px={2}
                py={1}
              >
                {creatorName}
              </Text>
              <Text color="dark.dark-2" fontSize={13}>
                {stringifyRole(role)}
              </Text>
            </HStack>
            <HStack flexShrink={1} width="100%" alignItems="center" justifyContent="flex-end">
              <HStack space={6} alignItems="center">
                <Text fontSize={12} opacity={0.56} fontWeight={500}>
                  {dateTimeFormatLocale(createdAt)}
                </Text>
                {actionsList?.map((action, i) => (
                  <ActionView key={`${action.id}-${i}`} item={props} {...action} />
                ))}
              </HStack>
            </HStack>
          </HStack>
          <Box>
            <Text color="dark.dark-2">{text}</Text>
          </Box>
        </>
      )}
    </VStack>
  );
};
