import React, { FC } from 'react';
import { PageProps } from '../../types';
import { BoreholeTemperaturesContainer } from '../../../features/borehole-temperatures/borehole-temperatures.container';
import { BoreholeLoader } from './borehole-loader';

type BoreholeTemperaturesPageProps = PageProps<'BoreholeTemperatures'>;

export const BoreholeTemperaturesPage: FC<BoreholeTemperaturesPageProps> = ({ route }) => {
  return (
    <BoreholeLoader {...route.params}>
      <BoreholeTemperaturesContainer boreholeId={route.params.boreholeId} />
    </BoreholeLoader>
  );
};

BoreholeTemperaturesPage.displayName = BoreholeTemperaturesPage.name;
