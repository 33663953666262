import { action, override } from 'mobx';

import { BoreholeCreateRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeCreateRequest';
import { BoreholeUpdateRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeUpdateRequest';

import { EntityStore } from './entity';
import { getApiClient } from '../api/api-client';
import { Borehole } from '../models/Borehole';

export type BoreholeRequestType = 'get' | 'create' | 'update' | 'remove' | 'sign' | 'unsign';

export class BoreholeStore extends EntityStore<Borehole, number, BoreholeRequestType> {
  protected async fetchItem(id: number): Promise<Borehole> {
    return getApiClient().boreholeController.getBorehole(id);
  }

  @override setItem(item: Borehole | null): void {
    if (!item) {
      super.setItem(null);
    } else {
      super.setItem(item, item.id);
    }
  }

  @action create(params: BoreholeCreateRequest): Promise<Borehole> {
    return this.request(
      'create',
      () => getApiClient().boreholeController.createBorehole({ body: params }),
      (borehole) => this.setItem(borehole)
    );
  }

  @action update(id: number, params: BoreholeUpdateRequest): Promise<Borehole> {
    return this.request(
      'update',
      () => getApiClient().boreholeController.updateBorehole(id, { body: params }),
      (borehole) => this.setItem(borehole)
    );
  }

  @action async remove(id: number): Promise<void> {
    await this.request(
      'remove',
      () => getApiClient().boreholeController.deleteBorehole(id),
      () => this.setItem(null)
    );
  }

  @action sign(id: number): Promise<Borehole> {
    if (this.item && this.item?.status === 'CLOSED') {
      return Promise.resolve(this.item);
    }
    return this.request(
      'sign',
      () => getApiClient().boreholeController.sign(id),
      (borehole) => this.setItem(borehole)
    );
  }

  @action unsign(id: number): Promise<Borehole> {
    if (this.item && this.item?.status !== 'CLOSED') {
      return Promise.resolve(this.item);
    }
    return this.request(
      'unsign',
      () => getApiClient().boreholeController.unsign(id),
      (borehole) => this.setItem(borehole)
    );
  }
}
