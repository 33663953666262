import { i18n } from '../../../i18n/i18next';
import { ColumnDefinition } from '../../../components/table/table.model';
import { BoreholeShiftTableEntity } from '../../../stores/shifts';

export const columns: ColumnDefinition<BoreholeShiftTableEntity>[] = [
  {
    key: 'worker',
    header: i18n.t('borehole.shift.name'),
    height: 6
  },
  {
    key: 'date',
    header: i18n.t('borehole.shift.date'),
    width: 70,
    height: 6
  },
  {
    key: 'time',
    header: i18n.t('borehole.shift.time'),
    width: 95,
    height: 6
  },
  {
    key: 'depth',
    header: i18n.t('borehole.shift.depth'),
    width: 85,
    height: 6
  }
];
