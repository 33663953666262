import React, { memo, useState } from 'react';
import { Button, Popover } from 'native-base';
import { dateFormat } from '@geo/commons/utils/date'; // theme css file
import { Range, DateRange as DateRangeOrigin, DateRangeProps as DateRangeOriginProps } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

interface DateRangeProps extends DateRangeOriginProps {
  placeholder?: string;
  manualRangeSelected: boolean;
}

const rangeFormat = ({ startDate, endDate }: Range, placeholder: string) => {
  const start = startDate ? dateFormat(startDate) : undefined;
  const end = endDate ? dateFormat(endDate) : undefined;
  return start && end ? `${start} - ${end}` : placeholder;
};

export const DateRange = memo(
  ({ placeholder = 'Календарь', manualRangeSelected, ranges, ...dateRangeProps }: DateRangeProps) => {
    const [isOpen, updateOpen] = useState<boolean>(false);
    const handleOpen = () => {
      updateOpen(true);
    };
    const handleClose = () => {
      updateOpen(false);
    };

    const first = ranges?.[0];

    return (
      <Popover
        isOpen={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        placement="bottom left"
        trigger={(triggerProps) => (
          <Button minWidth={100} {...triggerProps} variant={manualRangeSelected ? 'tabActive' : 'tab'}>
            {manualRangeSelected && first ? rangeFormat(first, placeholder) : placeholder}
          </Button>
        )}
      >
        <Popover.Content borderColor="#dbdeea" backgroundColor="#fff">
          <Popover.Body m={0} p={0} borderTopWidth={0}>
            <DateRangeOrigin
              months={1}
              direction="vertical"
              scroll={{ enabled: true }}
              ranges={ranges}
              {...dateRangeProps}
            />
          </Popover.Body>
        </Popover.Content>
      </Popover>
    );
  }
);
