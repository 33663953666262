export const pageStyle = `
@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }
@media print {
  td {
    font-weight: normal;
  }
  @page {
    margin-bottom: 40px;
    counter-increment: page;

    @bottom-left {
      content: '';
    }

    @bottom-left-corner {
      content: '';
    }
  
    @bottom-right {
      content: counter(page);
    }
  }
}

@page:first {
  @bottom-right {
    content: '';
  }
}
`;
