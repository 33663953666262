import React, { FC } from 'react';
import { Text, HStack } from 'native-base';

interface SummaryItem {
  label: string;
  value: number;
  postfix?: string;
}

export interface SummaryProps {
  items: SummaryItem[];
}

export const Summary: FC<SummaryProps> = (props) => {
  const { items } = props;
  const isSingle = items.length === 1;
  const content = items.map(({ label, value, postfix }) => (
    <HStack space={2} key={label}>
      <Text>{label}:</Text>
      <Text fontWeight={600} color="dark.dark-0">
        {value}
        {postfix}
      </Text>
    </HStack>
  ));
  return isSingle ? <>{content}</> : <HStack space={4}>{content}</HStack>;
};

Summary.displayName = Summary.name;
