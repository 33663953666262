import React, { FC } from 'react';
import { useFieldArray, useFormState } from 'react-hook-form';
import { observer } from 'mobx-react';

import { SampleList } from '../../../components/sample/sample-list/sample-list';
import { BoreholeLayerSampleItem } from '../../../models/BoreholeLayerSample';
import { Form } from '../../../utils/form';

import { BoreholeLayerFormData } from './borehole-layer.model';

type EditableSampleListProps = {
  form: Form<BoreholeLayerFormData>;
};

type BoreholeLayerTableSampleCellProps = {
  form?: Form<BoreholeLayerFormData>;
  samples?: Array<BoreholeLayerSampleItem>;
};

const fieldName = 'samples';

const EditableSampleList: FC<EditableSampleListProps> = (props) => {
  const { form } = props;
  const { control } = form;
  const { fields: samples, append, remove } = useFieldArray({ control, name: fieldName });
  const onAdd = () => append({ type: 'MONOLITH', depthFrom: NaN, depthTo: NaN });
  const onRemove = (index: number) => remove(index);
  return <SampleList name={fieldName} samples={samples} form={form} onAdd={onAdd} onRemove={onRemove} />;
};

export const BoreholeLayerTableSampleCell: FC<BoreholeLayerTableSampleCellProps> = observer((props) => {
  const { form, samples } = props;
  return form ? <EditableSampleList form={form} /> : <SampleList name={fieldName} samples={samples} />;
});

BoreholeLayerTableSampleCell.displayName = BoreholeLayerTableSampleCell.name;
