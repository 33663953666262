import { AppStore } from './app';
import { AppModeStore } from './app-mode';
import { BoreholeStore } from './borehole';
import { BoreholeTableStore } from './borehole-table';
import { BoreholeLayerStore } from './borehole-layer';
import { BoreholeLayerTableStore } from './borehole-layer-table';
import { BoreholeSampleTableStore } from './borehole-sample-table';
import { CodificatorStore } from './codificator';
import { CommentsStore } from './comments';
import { DocumentsStore } from './documents';
import { FacilityStore } from './facility';
import { FacilityTableStore } from './facility-table';
import { FacilityArchivedTableStore } from './facility-archived-table';
import { FacilityDeletedTableStore } from './facility-deleted-table';
import { FileStore } from './file';
import { MapListStore } from './map-list';
import { MapStore } from './map';
import { NotificationStore } from './notification';
import { PhotoStore } from './photo';
import { PhotosStore } from './photos';
import { WorkerListStore } from './worker-list';
import { BoreholeTemperaturesTableStore } from './borehole-temperatures-table';
import { BoreholeTemperatureStore } from './borehole-temperature';
import { ShiftStore } from './shift';

import { ShiftsStore } from './shifts';
import { initReactions } from './reactions';
import { BaseStore } from './base';

import { MapGeneralListStore } from './map-general-list';

export type Stores = {
  appStore: AppStore;
  photoStore: PhotoStore;
  fileStore: FileStore;
  appModeStore: AppModeStore;
  facilityStore: FacilityStore;
  facilityTableStore: FacilityTableStore;
  facilityArchivedTableStore: FacilityArchivedTableStore;
  facilityDeletedTableStore: FacilityDeletedTableStore;
  boreholeStore: BoreholeStore;
  boreholeLayerStore: BoreholeLayerStore;
  boreholeLayerTableStore: BoreholeLayerTableStore;
  boreholeTableStore: BoreholeTableStore;
  boreholeSampleTableStore: BoreholeSampleTableStore;
  mapListStore: MapListStore;
  mapGeneralListStore: MapGeneralListStore;
  mapStore: MapStore;
  photosStore: PhotosStore;
  commentsStore: CommentsStore;
  notificationStore: NotificationStore;
  documentsStore: DocumentsStore;
  codificatorStore: CodificatorStore;
  workerListStore: WorkerListStore;
  shiftStore: ShiftStore;
  shiftsStore: ShiftsStore;
  boreholeTemperaturesTableStore: BoreholeTemperaturesTableStore;
  boreholeTemperatureStore: BoreholeTemperatureStore;
};

export const appStore = new AppStore();
export const shiftStore = new ShiftStore();
export const fileStore = new FileStore();
export const shiftsStore = new ShiftsStore();
export const photoStore = new PhotoStore();
export const appModeStore = new AppModeStore();
export const notificationStore = new NotificationStore();
export const facilityStore = new FacilityStore('Facility');
export const facilityTableStore = new FacilityTableStore('Facility');
export const facilityArchivedTableStore = new FacilityArchivedTableStore('FacilityArchived');
export const facilityDeletedTableStore = new FacilityDeletedTableStore('FacilityDeleted');
export const boreholeStore = new BoreholeStore('Borehole');
export const boreholeTableStore = new BoreholeTableStore('Borehole');
export const boreholeLayerStore = new BoreholeLayerStore('BoreholeLayer');
export const boreholeLayerTableStore = new BoreholeLayerTableStore('BoreholeLayer');
export const boreholeSampleTableStore = new BoreholeSampleTableStore('BoreholeSample');
export const mapListStore = new MapListStore();
export const mapGeneralListStore = new MapGeneralListStore();
export const mapStore = new MapStore();
export const photosStore = new PhotosStore();
export const commentsStore = new CommentsStore();
export const documentsStore = new DocumentsStore();
export const codificatorStore = new CodificatorStore();
export const workerListStore = new WorkerListStore();
export const boreholeTemperaturesTableStore = new BoreholeTemperaturesTableStore('BoreholeTemperaturesTable');
export const boreholeTemperatureStore = new BoreholeTemperatureStore('BoreholeTemperature');

export const stores: Stores = {
  shiftStore,
  appStore,
  workerListStore,
  appModeStore,
  notificationStore,
  facilityStore,
  facilityTableStore,
  facilityArchivedTableStore,
  facilityDeletedTableStore,
  boreholeStore,
  boreholeTableStore,
  boreholeLayerStore,
  boreholeLayerTableStore,
  boreholeSampleTableStore,
  mapListStore,
  mapGeneralListStore,
  mapStore,
  fileStore,
  photoStore,
  photosStore,
  commentsStore,
  documentsStore,
  codificatorStore,
  shiftsStore,
  boreholeTemperaturesTableStore,
  boreholeTemperatureStore
};

const storeInitOrder: Array<BaseStore> = [appModeStore, appStore, facilityTableStore, codificatorStore];

export type InitStoreOptions = {
  appModeStoreInitializer?: (appModeStore: AppModeStore) => Promise<void>;
};

export async function initStores(options: InitStoreOptions = {}): Promise<() => void> {
  const { appModeStoreInitializer } = options;

  for (let i = 0; i < storeInitOrder.length; i += 1) {
    const store = storeInitOrder[i];
    if (store instanceof BaseStore) {
      // eslint-disable-next-line no-await-in-loop
      await store.init();
      if (store === appModeStore && appModeStoreInitializer) {
        // eslint-disable-next-line no-await-in-loop
        await appModeStoreInitializer(appModeStore);
      }
    }
  }

  const names = Object.keys(stores);
  await Promise.all(
    names.map((name) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const store = (stores as { [index: string]: any })[name];
      if (!(store instanceof BaseStore) || storeInitOrder.includes(store)) {
        return Promise.resolve();
      }
      return store instanceof BaseStore ? store.init() : Promise.resolve();
    })
  );
  const disposeReactions = initReactions(stores);
  return () => {
    disposeReactions();
  };
}
