import { makePersistable } from 'mobx-persist-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { defaultPhotosPerPage } from '../constants';
import { CollectionFetched, CollectionStore } from './collection';
import { getApiClient } from '../api/api-client';

export class PhotosStore extends CollectionStore<FacilityFile> {
  @observable facilityId?: number;

  @observable.ref file: FacilityFile | null = null;

  @observable.ref fileIds: number[] = [];

  @observable.ref prevPageIds: number[] = [];

  @observable.ref nextPageIds: number[] = [];

  perPage = defaultPhotosPerPage;

  constructor() {
    super();
    makeObservable(this);
  }

  async init(): Promise<void> {
    await super.init();
    await makePersistable(this, {
      name: 'Photos',
      properties: ['page', 'facilityId'],
      storage: AsyncStorage
    });
  }

  @action
  async fetchItems(): Promise<CollectionFetched<FacilityFile>> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }

    const { page } = this;
    const { items, total } = await this.fetchItemsByPage(page);
    this.setItems(items, total);
    const nextPage = page === this.lastPage ? 0 : page + 1;
    let prevPage = page === 0 ? this.lastPage : page - 1;

    console.log('fetchItems Photos', `prevPage: ${prevPage}`, `currentPage: ${page}`, `nextPage: ${nextPage}`);

    if (prevPage < 0) {
      prevPage = 0;
    }

    let nextPageItems: CollectionFetched<FacilityFile>;
    let prevPageItems: CollectionFetched<FacilityFile>;

    if (nextPage !== page) {
      nextPageItems = await this.fetchItemsByPage(nextPage);
    }
    if (prevPage !== page) {
      prevPageItems = await this.fetchItemsByPage(prevPage);
    }

    runInAction(() => {
      this.fileIds = this.items.map((file) => file.id);
      this.prevPageIds = prevPageItems?.items.map((i) => i.id) || [];
      this.nextPageIds = nextPageItems?.items.map((i) => i.id) || [];
    });
    return { items, total };
  }

  async fetchItemsByPage(page: number): Promise<CollectionFetched<FacilityFile>> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }
    const { result: items, size: total } = await getApiClient().photoController.listPhotos(this.facilityId, {
      query: {
        page,
        size: this.perPage,
        sortBy: 'createdAt',
        order: 'DESC'
      }
    });
    return { items, total };
  }

  @action setFacilityId(facilityId: number | undefined): void {
    this.facilityId = facilityId;
  }
}
