import React from 'react';
import { observer } from 'mobx-react';
import { Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import { notificationStore } from '../../../stores';
import { Loadable } from '../../../components/loadable';
import { BlankPage } from '../../../components/blank';
import { NotificationList } from '../notification-list';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';

export const NotificationsContainer = observer(() => {
  const { t } = useTranslation();
  const { isLoading, removeAll, readAll, items, countOfNew } = notificationStore;
  const isBlank = items.length === 0;

  useNavigationEffect(() => {
    notificationStore.loadItems();
  }, []);

  return (
    <Loadable isLoading={isLoading}>
      {isBlank ? (
        <BlankPage>
          <Text fontSize={20} fontWeight={600}>
            {t('notification.list.blankText')}
          </Text>
        </BlankPage>
      ) : (
        <NotificationList countOfNew={countOfNew} readAll={readAll} removeAll={removeAll} data={items} />
      )}
    </Loadable>
  );
});
