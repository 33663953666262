import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from 'native-base';

import { DeleteButton } from '../../../components/delete-button';
import { Loadable } from '../../../components/loadable';
import { Actions, Sort } from '../../../components/table/table.model';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { Facility, FacilitySortColumn } from '../../../models/Facility';
import { facilityStore, facilityArchivedTableStore } from '../../../stores';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { usePermission } from '../../../utils/permissions/context';

import { FacilityTable } from '../facility-table/facility-table';

export const FacilityArchivedTableContainer = observer(() => {
  const { sortTableProp, updateSelection, isLoading, selectedId, page, items, perPage, total } =
    facilityArchivedTableStore;

  const { t } = useTranslation();
  const permission = usePermission();

  const ready = useComponentReady(() => {
    facilityArchivedTableStore.setPage(page);
    facilityArchivedTableStore.loadItems().catch((e) => console.error(e));
  }, []);

  if (!ready) {
    return null;
  }

  const onMark = (id: number) => {
    facilityStore.setMarkToDelete(id, true).catch((e) => console.error(e));
  };

  const onDelete = (id: number) => {
    facilityStore.remove(id).catch((e) => console.error(e));
  };

  const onChangePage = (p: number) => {
    const newPage = p - 1;
    facilityArchivedTableStore.updatePage(newPage);
  };

  const onChangeSort = (sort: Sort<FacilitySortColumn>) => {
    facilityArchivedTableStore.updateSort(sort?.column, sort?.sortOrder);
  };

  const actions: Actions<Facility> = [];

  if (permission.can(ACTION.MARK_TO_ARCHIVE, SUBJECT.FACILITY)) {
    actions.push({
      id: 'archive',
      customRenderer: ({ id }: Facility) => {
        return (
          <Button variant="outline" onPress={() => facilityStore.archive(id, false).catch((e) => console.error(e))}>
            <Text>Восстановить</Text>
          </Button>
        );
      }
    });
  }

  actions.push({
    id: 'delete',
    customRenderer: (item: Facility) => {
      return (
        <DeleteButton
          item={item}
          subject={SUBJECT.FACILITY}
          onMark={onMark}
          onDelete={onDelete}
          markToDeleteConfirmationText="Объект будет отмечен «под удаление», но сохранён в системе. Полное удаление или отмену удаления сможет сделать
        только Руководитель. При подтверждении действия вы не сможете редактировать этот Объект. Вы уверены?"
          deleteConfirmationText={t('deleteConfirmation.text')}
        />
      );
    }
  });

  return (
    <Loadable isLoading={isLoading || facilityStore.anyLoading}>
      <FacilityTable
        selectedId={selectedId}
        sort={sortTableProp}
        page={page}
        total={total}
        perPage={perPage}
        onRowClick={updateSelection}
        actions={actions}
        onChangeSort={onChangeSort}
        onChangePage={onChangePage}
        items={items}
        actionsWidth={224}
      />
    </Loadable>
  );
});
