import { makeObservable, observable, action } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { CollectionFetched, CollectionStore } from './collection';
import { Map } from '../models/Map';

import { getApiClient } from '../api/api-client';

// Hot fix to show all boreholes on general map view. It can be optimized to be used with map-list.ts to avoid duplicate requests
export class MapGeneralListStore extends CollectionStore<Map> {
  @observable facilityId?: number;

  constructor() {
    super();
    makeObservable(this);
  }

  async init(): Promise<void> {
    await super.init();
    await makePersistable(this, {
      name: 'GeneralMap',
      properties: ['facilityId'],
      storage: AsyncStorage
    });
  }

  @action setFacilityId(facilityId?: number): void {
    this.facilityId = facilityId;
  }

  @action async fetchItems(): Promise<CollectionFetched<Map>> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }
    const { result: items, size: total } = await getApiClient().mapController.listOfMapsByFacilityId(this.facilityId, {
      query: {
        page: undefined,
        size: undefined,
        sortBy: undefined,
        order: undefined
      }
    });
    return { items: items.map((item) => ({ ...item, closed: Math.random() > 0.5 })), total };
  }
}
