import React from 'react';
import { NavigationState, ParamListBase, PartialState } from '@react-navigation/core';
import { NavigationContainerRef, Route, StackActions } from '@react-navigation/native';

export const navigationRef = React.createRef<NavigationContainerRef<{}>>();

export function getRoute(): Route<string> | null {
  return navigationRef.current?.getCurrentRoute() || null;
}

export function getRoutes(): Array<Route<string>> {
  return navigationRef.current?.getState()?.routes || [];
}

export function getRouteByIndex(index: number): Route<string> | null {
  const routes = getRoutes();
  if (index >= 0) {
    return index >= routes.length ? null : routes[index];
  }
  return Math.abs(index) > routes.length ? null : routes[routes.length + index];
}

export function navigate<RouteName extends keyof ParamListBase>(
  ...args: undefined extends ParamListBase[RouteName]
    ? [RouteName] | [RouteName, ParamListBase[RouteName]]
    : [RouteName, ParamListBase[RouteName]]
): void {
  // @ts-ignore
  navigationRef.current?.navigate(...args);
}

export function replace(
  name: string,
  params: Record<string, unknown>,
  key: string | number | undefined = undefined
): void {
  const routes = getRoutes();
  if (routes.length === 0) {
    return;
  }
  let source: string | undefined;
  if (typeof key === 'number') {
    if (key < 0) {
      source = routes[routes.length + key].key;
    }
    source = routes[key].key;
  }
  navigationRef.current?.dispatch({
    ...StackActions.replace(name, params),
    source,
    target: navigationRef.current?.getState().key
  });
}

export function reset(name: PartialState<NavigationState> | NavigationState): void {
  navigationRef.current?.reset(name);
}

export function goBack(): void {
  navigationRef.current?.goBack();
}
