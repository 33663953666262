import { TFunction } from 'i18next';
import { HStack, Text } from 'native-base';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Icon, { IconName } from '../../../components/icon';
import { AcceptedStatus, AcceptedType } from './types';

type AcceptSampleTypeIconProps = {
  type: AcceptedType;
  isVisibleLabel?: boolean;
};

const typeToIconName = (type: AcceptedType): IconName => {
  switch (type) {
    case AcceptedStatus.Accepted:
      return 'accepted';
    case AcceptedStatus.Rejected:
      return 'rejected';
    default:
      return 'unselect';
  }
};

export const typeToLabel = (t: TFunction, type: AcceptedType): string => {
  switch (type) {
    case AcceptedStatus.Accepted:
      return t('borehole.sample.select.accepted');
    case AcceptedStatus.Rejected:
      return t('borehole.sample.select.rejected');
    default:
      return t('borehole.sample.select.unselect');
  }
};

export const BoreholeSampleAcceptType: FC<AcceptSampleTypeIconProps> = ({ type, isVisibleLabel = true }) => {
  const { t } = useTranslation();

  if (!isVisibleLabel) {
    return <Icon name={typeToIconName(type)} />;
  }

  return (
    <HStack space={3} alignItems="center">
      <Icon name={typeToIconName(type)} />
      <Text>{typeToLabel(t, type)}</Text>
    </HStack>
  );
};

BoreholeSampleAcceptType.displayName = BoreholeSampleAcceptType.name;
