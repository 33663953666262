import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { View, Pressable, HStack, Box, Popover, List, Text, ChevronDownIcon, IBoxProps } from 'native-base';

export interface SelectOption<T> {
  key: string;
  label?: string;
  value: T;
}

interface CustomSelectProps<T> extends IBoxProps {
  options: SelectOption<T>[];
  isInvalid?: boolean;
  selectedBuilder?: (item: SelectOption<T>) => ReactNode;
  dropdownItemBuilder?: (option: SelectOption<T>) => ReactNode;
  placeholder?: string;
  isDisabled?: boolean;
  value?: T;
  onValueChange?: (value: SelectOption<T>['value']) => void;
}

export const CustomSelect = <T extends any>(props: CustomSelectProps<T>) => {
  const {
    placeholder,
    value,
    isInvalid = false,
    selectedBuilder: _selectedBuilder,
    dropdownItemBuilder,
    onValueChange,
    isDisabled,
    options,
    ...boxProps
  } = props;
  const [isOpen, updateOpen] = useState<boolean>(false);
  const onChange = useCallback(
    (newValue: SelectOption<T>['value']) => {
      return () => {
        onValueChange?.(newValue);
        handleClose();
      };
    },
    [onValueChange, options, value]
  );
  const handleOpen = () => {
    updateOpen(true);
  };
  const handleClose = () => {
    updateOpen(false);
  };

  const optionByValue = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [options, value]);

  const defaultSelectedBuilder = (option: SelectOption<T>) => option.label || option.key;

  const selectedBuilder = _selectedBuilder || defaultSelectedBuilder;
  const selectedContent = optionByValue ? selectedBuilder(optionByValue) : placeholder;

  const bg = (isDisabled ? 'light.light-3' : undefined) || boxProps.bg;

  const Anchor = (
    <Box
      px={3}
      justifyContent="center"
      borderWidth={1}
      borderColor={isInvalid ? 'error' : 'light.light-3'}
      rounded="lg"
      height={10}
      {...boxProps}
      bg={bg}
    >
      <HStack>
        <View flex={1}>
          {value ? (
            <Text noOfLines={1}>{selectedContent}</Text>
          ) : (
            <Text noOfLines={1} opacity={0.6}>
              {placeholder}
            </Text>
          )}
        </View>
        <ChevronDownIcon size={5} />
      </HStack>
    </Box>
  );

  return isDisabled ? (
    Anchor
  ) : (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="bottom left"
      trigger={(triggerProps) => <Pressable {...triggerProps}>{Anchor}</Pressable>}
    >
      <Popover.Content backgroundColor="#fff" style={{ width: 240 }}>
        <Popover.Body px={0} py={0}>
          <List borderWidth={0} px={0} py={0}>
            {options.map((option) => (
              <List.Item
                key={option.key}
                py={1}
                onPress={onChange(option.value)}
                borderBottomWidth={1}
                borderBottomColor="#dbdeea"
              >
                {dropdownItemBuilder ? dropdownItemBuilder(option) : option.label}
              </List.Item>
            ))}
          </List>
        </Popover.Body>
      </Popover.Content>
    </Popover>
  );
};

CustomSelect.displayName = CustomSelect.name;
