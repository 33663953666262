import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'native-base';
import { subject } from '@casl/ability';

import { ConfirmationPopup } from '../../../components/confirmation-popup';
import Icon from '../../../components/icon';
import { NarrowFacility } from '../../../components/panel/header-actions';
import { Loadable } from '../../../components/loadable';
import { Actions } from '../../../components/table/table.model';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useComponent } from '../../../hooks/useComponent';
import { screens } from '../../../navigation/config';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { boreholeStore } from '../../../stores';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { usePermission } from '../../../utils/permissions/context';

import { BoreholeCard } from './borehole-card';

interface BoreholeCardContainerProps {
  boreholeId: number;
}

const signText =
  'Вы уверены, что хотите подписать эту Скважину, и она точно готова? Отметить действие смогут только в Кабинете Офиса';
const unsignText = 'Вы уверены, что хотите снять подпись со Скважины и отправить её в работу?';

type SignAction = 'sign' | 'unsign';

export const BoreholeCardContainer: React.FC<BoreholeCardContainerProps> = observer((props) => {
  const { boreholeId } = props;

  const { item: borehole } = boreholeStore;

  const permission = usePermission();
  const navigation = useAppNavigation();

  const [isOpen, updateOpen] = useState<boolean>(false);
  const [signAction, updateSignAction] = useState<SignAction | undefined>(undefined);
  const { BoreholePrint } = useComponent();

  const handleOpen = () => {
    updateOpen(true);
  };
  const handleClose = () => {
    updateOpen(false);
  };

  const handleSignActionClick = (actionType: SignAction) => () => {
    updateSignAction(actionType);
  };

  const handleSignClose = () => {
    updateSignAction(undefined);
  };

  const popUpText = signAction === 'sign' ? signText : unsignText;

  const ready = useComponentReady(() => {
    if (borehole?.id !== boreholeId) {
      boreholeStore.loadItem(boreholeId).catch((e) => console.error(e));
    }
  }, [boreholeId]);

  if (!ready) {
    return null;
  }

  const handleRemoveButton = () => {
    if (borehole) {
      boreholeStore.remove(borehole.id).catch((e) => console.error(e));
      handleClose();
    }
  };

  const handleConfirmSignAction = () => {
    if (borehole) {
      if (signAction === 'sign') {
        boreholeStore.sign(borehole.id).catch((e) => console.error(e));
      } else if (signAction === 'unsign') {
        boreholeStore.unsign(borehole.id).catch((e) => console.error(e));
      }
      handleSignClose();
    }
  };

  const actions: Actions<NarrowFacility> = [
    {
      id: 'print',
      customRenderer: () => <BoreholePrint boreholeId={borehole?.id} />,
      isVisible: () => permission.can(ACTION.PRINT, SUBJECT.BOREHOLE)
    },
    {
      id: 'unsign',
      customRenderer: () => (
        <Button
          variant="outline"
          py={2}
          startIcon={<Icon name="removeSign" />}
          onPress={handleSignActionClick('unsign')}
        >
          Снять подпись
        </Button>
      ),
      isVisible: () =>
        !!borehole &&
        permission.can(
          ACTION.REMOVE_SIGN,
          subject(SUBJECT.BOREHOLE, {
            status: borehole.sign,
            facilityStatus: borehole.facility.status,
            markToDelete: borehole.facility.markToDelete || false
          })
        )
    },
    {
      id: 'sign',
      customRenderer: () => (
        <Button variant="outline" py={2} startIcon={<Icon name="addSign" />} onPress={handleSignActionClick('sign')}>
          Подписать
        </Button>
      ),
      isVisible: () => permission.can(ACTION.ADD_SIGN, subject(SUBJECT.BOREHOLE, { status: borehole?.sign }))
    },
    {
      id: 'edit',
      iconName: 'edit',
      actionCallback: () => {
        if (borehole) {
          navigation.navigate(screens.BoreholeUpdate, { boreholeId, facilityId: borehole.facility.id });
        }
      },
      isVisible: () =>
        !!borehole &&
        permission.can(
          ACTION.EDIT,
          subject(SUBJECT.BOREHOLE, {
            status: borehole.status,
            facilityStatus: borehole.facility.status,
            markToDelete: borehole.facility.markToDelete || false
          })
        )
    },
    {
      id: 'delete',
      iconName: 'delete',
      actionCallback: () => {
        handleOpen();
      },
      isVisible: () =>
        !!borehole &&
        permission.can(
          ACTION.DELETE,
          subject(SUBJECT.BOREHOLE, {
            status: borehole.status,
            facilityStatus: borehole.facility.status,
            markToDelete: borehole.facility.markToDelete || false
          })
        )
    }
  ];

  return (
    <Loadable isLoading={boreholeStore.anyLoading} error={!!boreholeStore.anyError}>
      <ConfirmationPopup isOpen={isOpen} handleConfirmButton={handleRemoveButton} handleCloseButton={handleClose}>
        Вы уверены, что хотите удалить Скважину без возможности восстановления?
      </ConfirmationPopup>
      <ConfirmationPopup
        isOpen={typeof signAction !== 'undefined'}
        handleConfirmButton={handleConfirmSignAction}
        handleCloseButton={handleSignClose}
      >
        {popUpText}
      </ConfirmationPopup>
      {borehole && <BoreholeCard data={borehole} actions={actions} />}
    </Loadable>
  );
});
