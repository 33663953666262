import React, { FC } from 'react';
import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { CodificatorUploader } from '../../../features/codificator/codificator-uploader';

type CodificatorTranslationsUploadPageProps = PageProps<'CodificatorTranslationsUpload'>;

export const CodificatorTranslationsUploadPage: FC<CodificatorTranslationsUploadPageProps> = () => {
  return (
    <Page>
      <CodificatorUploader
        successText="Обновление переводов прошло успешно"
        requestUrl="/api/v1/codificator/translations"
      />
    </Page>
  );
};

CodificatorTranslationsUploadPage.displayName = 'CodificatorTranslationsUploadPage';
