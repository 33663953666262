import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { CollectionDtoUser, CollectionDtoUserIO } from '../components/schemas/CollectionDtoUser';
import { User, UserIO } from '../components/schemas/User';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either } from 'fp-ts';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { pipe } from 'fp-ts/lib/pipeable';

export interface UsersController<F> {
	readonly listGeologists: () => HKT<F, CollectionDtoUser>;

	readonly current: () => HKT<F, User>;
}

export interface UsersController1<F extends URIS> {
	readonly listGeologists: () => Kind<F, CollectionDtoUser>;

	readonly current: () => Kind<F, User>;
}

export interface UsersController2<F extends URIS2> {
	readonly listGeologists: () => Kind2<F, Error, CollectionDtoUser>;

	readonly current: () => Kind2<F, Error, User>;
}

export function usersController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): UsersController2<F>;
export function usersController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): UsersController1<F>;
export function usersController<F>(e: { httpClient: HTTPClient<F> }): UsersController<F>;
export function usersController<F>(e: { httpClient: HTTPClient<F> }): UsersController<F> {
	return {
		listGeologists: () => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/user/geologist`,
					controller: 'users',
					operation: 'listGeologists',
					method: 'GET',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoUserIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		current: () => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/user/current`,
					controller: 'users',
					operation: 'current',
					method: 'GET',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						UserIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
