import { BoreholeTemperatureFormData } from './borehole-temperature.model';
import { BoreholeTemperature } from '../../models/BoreholeTemperatures';

export function getFormData(boreholeLayer: BoreholeTemperature): BoreholeTemperatureFormData {
  const { id, temperature, depth } = boreholeLayer;
  return {
    id,
    temperature,
    depth
  };
}
