import React, { FC } from 'react';
import { Text, Checkbox } from 'native-base';

import { NavigationLink } from '../../../components/link';
import { StatusBadge } from '../../../components/status/status-badge';
import { Table, TableWithPaginationProps } from '../../../components/table/table';
import { ColumnDefinition, CustomHeaderRenderers, CustomRenderers } from '../../../components/table/table.model';
import { FacilitySortColumn } from '../../../models/Facility';
import { screens } from '../../../navigation/config';
import { FacilityTableEntity } from '../../../stores/facility-table';
import { dateFormat } from '../../../utils/date';
import { centimetersToMeters } from '../../../utils/units';

import { columns } from './facility-table.columns';

export interface FacilityTableProps extends TableWithPaginationProps<FacilityTableEntity, FacilitySortColumn> {
  customColumn?: Array<ColumnDefinition<FacilityTableEntity, FacilitySortColumn>>;
  onMarkAllToOffline?: () => void;
  onMarkToOffline?: (id: number) => void;
  markedToOffline?: number[];
  markedAllToOffline?: boolean;
}

export const FacilityTable: FC<FacilityTableProps> = ({
  items,
  total,
  page,
  perPage,
  sort,
  customColumn,
  selectedId,
  onRowClick,
  onChangeSort,
  onChangePage,
  actions,
  onMarkAllToOffline,
  markedAllToOffline = false,
  onMarkToOffline,
  markedToOffline = [],
  actionsWidth = 170
}) => {
  const preparedColumn = customColumn || columns;

  const handleMarkAllToOffline = () => {
    onMarkAllToOffline?.();
  };

  const handleMarkToOffline = (id: number) => {
    onMarkToOffline?.(id);
  };

  const customHeaderRenderers: CustomHeaderRenderers<FacilityTableEntity> = {
    markToOffline: () => <Checkbox value="markedAll" isChecked={markedAllToOffline} onChange={handleMarkAllToOffline} />
  };

  const customRenderers: CustomRenderers<FacilityTableEntity> = {
    name: ({ id, name }) => {
      return <NavigationLink title={name} route={screens.FacilityView} routeParams={{ facilityId: id }} />;
    },
    boreholesTarget: ({ boreholesTarget, boreholeDepthTarget }) => (
      <Text>
        {centimetersToMeters(boreholeDepthTarget || 0)} / {boreholesTarget || 0}
      </Text>
    ),
    boreholesActual: ({ boreholesActual, boreholeDepthActual }) => (
      <Text>
        {centimetersToMeters(boreholeDepthActual || 0)} / {boreholesActual || 0}
      </Text>
    ),
    status: ({ status }) => <StatusBadge status={status} />,
    lastUpdatedAt: ({ lastUpdatedAt }) => <Text>{dateFormat(lastUpdatedAt)}</Text>,
    markToOffline: ({ id }) => (
      <Checkbox value="marked" isChecked={markedToOffline.includes(id)} onChange={() => handleMarkToOffline(id)} />
    )
  };

  return (
    <Table
      onRowClick={onRowClick}
      onChangePage={onChangePage}
      onChangeSort={onChangeSort}
      sort={sort}
      selectedId={selectedId}
      page={page}
      perPage={perPage}
      total={total}
      items={items}
      columns={preparedColumn}
      actions={actions}
      actionsWidth={actionsWidth}
      customHeaderRenderers={customHeaderRenderers}
      customRenderers={customRenderers}
    />
  );
};
