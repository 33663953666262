import { BoreholeLayerSampleCommentRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeLayerSampleCommentRequest';
import { observable, action } from 'mobx';

import { BoreholeLayerSampleAcceptRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeLayerSampleAcceptRequest';
import { getApiClient } from '../api/api-client';
import { BoreholeLayerSample, BoreholeLayerSampleSortColumn } from '../models/BoreholeLayerSample';
import { BaseTableStore } from './base-table';
import { CollectionFetched } from './collection';

export class BoreholeSampleTableStore extends BaseTableStore<BoreholeLayerSample, BoreholeLayerSampleSortColumn> {
  @observable facilityId?: number = undefined;

  @observable countByTypeMap?: Record<string, number> = undefined;

  constructor(storageKey: string) {
    super(storageKey);
    this.sortBy = 'layerBoreholeId';
    this.order = 'ASC';
  }

  @action.bound setFacilityId(facilityId: number): void {
    this.facilityId = facilityId;
  }

  @action async accept(boreholeLayerSampleId: number, parameters: BoreholeLayerSampleAcceptRequest): Promise<void> {
    const boreholeLayerSample = await getApiClient().boreholeLayerSampleController.acceptBoreholeLayerSample(
      boreholeLayerSampleId,
      {
        body: parameters
      }
    );

    const updated = this.items.map((item) => (item.id === boreholeLayerSampleId ? boreholeLayerSample : item));
    this.setItems(updated, updated.length);
  }

  @action async addComment(
    boreholeLayerSampleId: number,
    parameters: BoreholeLayerSampleCommentRequest
  ): Promise<void> {
    const boreholeLayerSample = await getApiClient().boreholeLayerSampleController.commentBoreholeLayerSample(
      boreholeLayerSampleId,
      {
        body: parameters
      }
    );

    const updated = this.items.map((item) => (item.id === boreholeLayerSampleId ? boreholeLayerSample : item));
    this.setItems(updated, updated.length);
  }

  @action
  async fetchItems(): Promise<CollectionFetched<BoreholeLayerSample>> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }

    const {
      result: items,
      size: total,
      countByTypeMap
    } = await getApiClient().boreholeLayerSampleController.listByFacilityId(this.facilityId, {
      query: this.storedQuery
    });

    return {
      items,
      total,
      update: () => {
        this.countByTypeMap = countByTypeMap;
      }
    };
  }
}
