import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Input, View, Button, List, Text, FormControl, VStack, HStack, IconButton } from 'native-base';
import { useTranslation } from 'react-i18next';
import { Panel } from '../../../components/panel/panel';
import { SelectBox } from '../../../components/select-box';
import Icon from '../../../components/icon';
import { StatusSelect } from '../../../components/status/status-select';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';
import { User } from '../../../models/User';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { usePermission } from '../../../utils/permissions/context';
import { FacilityFormData, schema } from './facility-form.model';

type UserOption = Pick<User, 'name' | 'id'>;

export interface FacilityFormProps {
  isCreation?: boolean;
  responsibleList: UserOption[];
  creationList: UserOption[];
  geologistList?: UserOption[];
  value: FacilityFormData;
  onSubmit: (data: FacilityFormData) => void;
  onCancel: () => void;
}

const userToOption = (user: UserOption): { label: string; value: string } => ({
  label: user.name,
  value: user.id.toString()
});

export const FacilityForm: FC<FacilityFormProps> = (props) => {
  const { isCreation, responsibleList, creationList, geologistList, value, onSubmit, onCancel } = props;

  const permission = usePermission();
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<FacilityFormData>({
    resolver: yupResolver<any>(schema),
    mode: 'onSubmit'
  });
  const geologist = geologistList || [];
  const [geologId, selectGeologId] = useState<number | null>(null);

  const onChangeGeolog = (id: string) => {
    if (id) {
      selectGeologId(Number(id));
    }
  };
  const requiredErrorText = t('form.error.required');

  const getUpdatedList = (values: number[] = []): number[] => {
    if (!geologId) {
      return values;
    }

    return Array.from(new Set(values).add(geologId));
  };
  const onRemoveHandler = (index: number, values: number[] = []) => {
    return [...values.slice(0, index), ...values.slice(index + 1)];
  };
  const getGeologNameById = (searchId: number) => {
    const geolog = geologist.find(({ id }) => id === searchId);
    return geolog?.name || '';
  };

  useNavigationEffect(() => {
    reset(value);
  }, []);

  const headerLeft = (
    <Controller
      control={control}
      render={({ field: { onChange, value: fieldValue }, fieldState: { invalid } }) => (
        <HStack flex={1}>
          <Input
            size="sm"
            isInvalid={invalid}
            value={fieldValue}
            onChangeText={onChange}
            placeholder={t('facility.form.fields.name.placeholder')}
            isFullWidth
          />
        </HStack>
      )}
      name="name"
      rules={{ required: requiredErrorText, maxLength: 100 }}
      defaultValue={value.name}
    />
  );

  return (
    <View mt={2}>
      <Panel headerIcon="description" headerIconColor="#5B8DEF" headerLeft={headerLeft}>
        <HStack pt={6} pb={6}>
          <VStack space={3} flex={1}>
            <FormControl>
              <HStack alignItems="center">
                <FormControl.Label width="56px">Шифр</FormControl.Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value: fieldValue }, fieldState: { invalid } }) => (
                    <Input
                      size="sm"
                      isInvalid={invalid}
                      value={fieldValue}
                      onChangeText={onChange}
                      placeholder={t('facility.form.fields.cypher.placeholder')}
                    />
                  )}
                  name="cypher"
                  rules={{ required: requiredErrorText, maxLength: 10 }}
                  defaultValue={value.cypher}
                />
              </HStack>
            </FormControl>
            <FormControl>
              <HStack alignItems="center">
                <FormControl.Label width="56px">{t('facility.form.fields.status.label')}</FormControl.Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value: fieldValue } }) => (
                    <StatusSelect
                      isDisabled={isCreation}
                      placeholder={t('facility.form.fields.status.placeholder')}
                      value={fieldValue}
                      onValueChange={onChange}
                    />
                  )}
                  name="status"
                  rules={{ required: requiredErrorText }}
                  defaultValue={value.status}
                />
              </HStack>
            </FormControl>
          </VStack>
          <VStack space={3}>
            <FormControl>
              <HStack alignItems="center">
                <FormControl.Label width="120px">{t('facility.form.fields.creatorName')}</FormControl.Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value: fieldValue } }) => (
                    <SelectBox
                      isDisabled={creationList.length === 1 || isCreation}
                      onValueChange={onChange}
                      value={String(fieldValue)}
                      options={creationList.map(userToOption)}
                    />
                  )}
                  name="creationId"
                  defaultValue={value.creationId}
                />
              </HStack>
            </FormControl>
            <FormControl>
              <HStack alignItems="center">
                <FormControl.Label width="120px">{t('facility.form.fields.responsibleUserName')}</FormControl.Label>
                <Controller
                  control={control}
                  render={({ field: { onChange, value: fieldValue } }) => (
                    <SelectBox
                      isDisabled={
                        permission.cannot(ACTION.SET_RESPONSIBLE_FOR_FACILITY, SUBJECT.FACILITY) ||
                        responsibleList.length === 1
                      }
                      onValueChange={onChange}
                      value={String(fieldValue)}
                      options={responsibleList.map(userToOption)}
                    />
                  )}
                  name="responsibleUserId"
                  defaultValue={Number(value.responsibleUserId)}
                />
              </HStack>
            </FormControl>
          </VStack>
        </HStack>
      </Panel>
      <Panel headerIcon="geologist" headerRight={<Text fontWeight={500}>{t('facility.form.geologist.header')}</Text>}>
        <List mb={4} borderWidth={0} _hover={{ backgroundColor: 'transparent' }}>
          <Controller
            control={control}
            name="geologistsUsersIds"
            defaultValue={value.geologistsUsersIds || []}
            render={({ field: { onChange, value: fieldValue } }) => (
              <>
                {(fieldValue || []).map((geologId, index) => {
                  return (
                    <List.Item key={index}>
                      <Text flex={1}>{getGeologNameById(geologId)}</Text>
                      <IconButton
                        onPress={() => onChange(onRemoveHandler(index, fieldValue))}
                        borderColor="#dbdeea"
                        variant="outline"
                        icon={<Icon name="del" />}
                      />
                    </List.Item>
                  );
                })}
                <List.Item>
                  <HStack alignItems="center" space={6}>
                    <SelectBox
                      height={40}
                      width={400}
                      placeholder={t('facility.form.geologist.fields.name')}
                      value={String(geologId)}
                      onValueChange={onChangeGeolog}
                      options={geologist.map(userToOption)}
                    />
                    <Button onPress={() => onChange(getUpdatedList(fieldValue))} startIcon={<Icon name="addplus" />}>
                      Добавить
                    </Button>
                  </HStack>
                </List.Item>
              </>
            )}
          />
        </List>
      </Panel>
      <HStack mx={10} space={6} mb={10}>
        <Button onPress={handleSubmit(onSubmit)} startIcon={<Icon name="save" />}>
          {t('form.buttons.save')}
        </Button>
        <Button onPress={onCancel} _text={{ color: 'primary.default' }} variant="link">
          {t('form.buttons.cancel')}
        </Button>
      </HStack>
    </View>
  );
};
