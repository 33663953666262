import { BoreholeLayerCreateRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeLayerCreateRequest';
import { BoreholeLayerUpdateRequest } from '@geo/api/generated/swagger.json/components/schemas/BoreholeLayerUpdateRequest';
import { GeoDescription } from '@geo/api/generated/swagger.json/components/schemas/GeoDescription';

import { Borehole } from '../../../models/Borehole';
import { BoreholeLayer } from '../../../models/BoreholeLayer';
import { CodificatorSelection } from '../../../stores/codificator';
import { centimetersToMeters, metersToCentimeters } from '../../../utils/units';

import { BoreholeLayerFormData } from './borehole-layer.model';

export function geoDescriptionToSelection(geoDescription: GeoDescription): CodificatorSelection {
  const selection: CodificatorSelection = [];
  let currentLevel: GeoDescription | undefined = geoDescription;
  while (currentLevel) {
    const { lvl_id: levelId, key } = currentLevel;
    selection.push({ levelId, key });
    currentLevel = currentLevel.nextLevel;
  }
  return selection;
}

export function selectionToGeoDescription(selection: CodificatorSelection): GeoDescription {
  let geoDescription: GeoDescription | null = null;
  let currentLevel: GeoDescription;
  selection.forEach((selectionItem) => {
    const { levelId, key } = selectionItem;
    const nextLevel = { lvl_id: levelId, key, nextLevel: undefined };
    if (!geoDescription) {
      geoDescription = nextLevel;
    } else if (currentLevel) {
      currentLevel.nextLevel = nextLevel;
    }
    currentLevel = nextLevel;
  });
  if (!geoDescription) {
    throw new TypeError('Codificator selection cannot be empty');
  }
  return geoDescription;
}

export function getFormData(boreholeLayer: BoreholeLayer): BoreholeLayerFormData {
  const { id, borehole, depthFrom, depthTo, gwlEmerged, gwlSteady, geoDescription, samples = [] } = boreholeLayer;
  return {
    id,
    boreholeId: borehole.id,
    depthFrom: centimetersToMeters(depthFrom),
    depthTo: centimetersToMeters(depthTo),
    gwlEmerged: gwlEmerged ? centimetersToMeters(gwlEmerged) : undefined,
    gwlSteady: gwlSteady ? centimetersToMeters(gwlSteady) : undefined,
    geoDescription: geoDescriptionToSelection(geoDescription),
    samples: samples.map((sample) => ({
      ...sample,
      depthFrom: centimetersToMeters(sample.depthFrom),
      depthTo: centimetersToMeters(sample.depthTo)
    }))
  };
}

type BoreholeLayerRequestBasic = Pick<
  BoreholeLayerUpdateRequest,
  'depthFrom' | 'depthTo' | 'gwlEmerged' | 'gwlSteady' | 'geoDescription' | 'samples'
>;

export function formDataToRequest(formData: BoreholeLayerFormData): BoreholeLayerRequestBasic {
  const { depthFrom, depthTo, gwlEmerged, gwlSteady, geoDescription, samples } = formData;
  return {
    depthFrom: metersToCentimeters(depthFrom),
    depthTo: metersToCentimeters(depthTo),
    gwlEmerged: gwlEmerged ? metersToCentimeters(gwlEmerged) : undefined,
    gwlSteady: gwlSteady ? metersToCentimeters(gwlSteady) : undefined,
    geoDescription: selectionToGeoDescription(geoDescription),
    samples: samples.map((sample) => ({
      type: sample.type,
      depthFrom: metersToCentimeters(sample.depthFrom),
      depthTo: metersToCentimeters(sample.depthTo)
    }))
  };
}

export function formDataToCreateRequest(formData: BoreholeLayerFormData): BoreholeLayerCreateRequest {
  const { boreholeId } = formData;
  const request = formDataToRequest(formData);
  return { ...request, boreholeId };
}

export function formDataToUpdateRequest(formData: BoreholeLayerFormData): BoreholeLayerUpdateRequest {
  return { ...formDataToRequest(formData) };
}

export function createEmptyBoreholeLayer(borehole: Borehole): BoreholeLayer {
  const now = new Date().getTime();
  return {
    id: 0,
    borehole,
    depthFrom: NaN,
    depthTo: NaN,
    gwlEmerged: NaN,
    gwlSteady: NaN,
    canEdit: true,
    geoDescription: {
      lvl_id: 'not.defined',
      key: 'not.defined',
      nextLevel: undefined
    },
    samples: [],
    createdAt: now,
    lastUpdatedAt: now
  };
}
