import { DependencyList, useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';

export type NavigationEffectCallback = (...args: any[]) => any;

export const useNavigationEffect = <T extends NavigationEffectCallback>(
  callback: T,
  deps: DependencyList = []
): void => {
  useFocusEffect(useCallback(callback, deps));
};
