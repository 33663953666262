import { getRoute, getRouteByIndex, navigate, replace, goBack } from '../../navigation';
import { screens } from '../../navigation/config';
import { shiftStore, appStore, Stores, appModeStore } from '../index';
import { createEntityLoadingReaction } from './utils';
import { ACTION, SUBJECT } from '../../utils/permissions';

import { Borehole } from '../../models/Borehole';
import { BoreholeStore, BoreholeRequestType } from '../borehole';

export function initBoreholeReactions(stores: Stores): () => void {
  const { boreholeStore, boreholeTableStore } = stores;

  const disposeBoreholeGet = createEntityLoadingReaction<Borehole, BoreholeRequestType, BoreholeStore>(
    boreholeStore,
    'get',
    (state) => {
      const { item: borehole } = state;
      if (borehole) {
        if (appStore.permission.can(ACTION.ENABLE, SUBJECT.SHIFT)) {
          shiftStore.updateItem(borehole.id).catch();
        }
        if (appModeStore.isOnline) {
          shiftStore.setGlobalShift().catch((e) => console.error(e));
        }
      }
    }
  );

  const disposeBoreholeCreate = createEntityLoadingReaction<Borehole, BoreholeRequestType, BoreholeStore>(
    boreholeStore,
    'create',
    (state) => {
      const { item: borehole } = state;
      if (borehole) {
        replace(screens.BoreholeView, { boreholeId: borehole.id, facilityId: borehole.facility.id });
      }
    }
  );

  const disposeBoreholeUpdate = createEntityLoadingReaction<Borehole, BoreholeRequestType, BoreholeStore>(
    boreholeStore,
    'update',
    (state) => {
      const { item: borehole } = state;
      if (borehole) {
        const route = getRouteByIndex(-2);
        if (route && route.name === screens.BoreholeView) {
          goBack();
        } else {
          replace(screens.BoreholeView, { boreholeId: borehole.id, facilityId: borehole.facility.id });
        }
      }
    }
  );

  const disposeBoreholeRemove = createEntityLoadingReaction<Borehole, BoreholeRequestType, BoreholeStore>(
    boreholeStore,
    'remove',
    (state, oldState) => {
      const { name: screenName } = getRoute() || {};
      const { item: removedBorehole } = oldState;
      if (!removedBorehole) {
        return;
      }
      if (screenName === screens.Boreholes) {
        boreholeTableStore.refresh().catch((e) => console.error(e));
      } else {
        navigate(screens.Boreholes, { facilityId: removedBorehole.facility.id });
      }
    }
  );

  const signUnsignReactionCallback = (state: { item: Borehole | null }) => {
    const { name: screenName } = getRoute() || {};
    const { item: borehole } = state;
    if (!borehole) {
      return;
    }
    if (appModeStore.isOffline) {
        shiftStore.close({ boreholeId: borehole.id })
    }
    switch (screenName) {
      case screens.BoreholeView: {
        // No need to navigate or refresh something because borehole is already update in the store
        break;
      }
      case screens.Boreholes: {
        boreholeTableStore.refresh().catch((e) => console.error(e));
        break;
      }
      default: {
        navigate(screens.Boreholes, { facilityId: borehole.facility.id });
        break;
      }
    }
  };

  const disposeBoreholeSign = createEntityLoadingReaction<Borehole, BoreholeRequestType, BoreholeStore>(
    boreholeStore,
    'sign',
    signUnsignReactionCallback
  );

  const disposeBoreholeUnsign = createEntityLoadingReaction<Borehole, BoreholeRequestType, BoreholeStore>(
    boreholeStore,
    'unsign',
    signUnsignReactionCallback
  );

  return () => {
    disposeBoreholeGet();
    disposeBoreholeCreate();
    disposeBoreholeUpdate();
    disposeBoreholeRemove();
    disposeBoreholeSign();
    disposeBoreholeUnsign();
  };
}
