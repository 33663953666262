import React, { FC, memo } from 'react';
import { HStack, Text } from 'native-base';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import { BoreholeLayerSampleType } from '../../models/BoreholeLayerSample';
import Icon, { IconName } from '../icon';

type SampleTypeIconProps = {
  type: BoreholeLayerSampleType;
  isVisibleLabel?: boolean;
};

const typeToIconName = (type: BoreholeLayerSampleType): IconName => {
  switch (type) {
    case 'DISTURBED':
      return 'disturbed';
    case 'MONOLITH':
      return 'monolith';
    default:
      return 'water';
  }
};

export const typeToLabel = (t: TFunction, type: BoreholeLayerSampleType): string => {
  switch (type) {
    case 'DISTURBED':
      return t('sample-type.disturbed');
    case 'MONOLITH':
      return t('sample-type.monolith');
    default:
      return t('sample-type.water');
  }
};

export const SampleType: FC<SampleTypeIconProps> = memo(({ type, isVisibleLabel = true }) => {
  const { t } = useTranslation();
  if (!isVisibleLabel) {
    return <Icon name={typeToIconName(type)} />;
  }
  return (
    <HStack space={2} alignItems="center">
      <Icon name={typeToIconName(type)} />
      <Text>{typeToLabel(t, type)}</Text>
    </HStack>
  );
});

SampleType.displayName = SampleType.name;
