import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, HStack } from 'native-base';

import { Panel } from '../../../components/panel/panel';
import { StatusBadge } from '../../../components/status/status-badge';
import { Actions } from '../../../components/table/table.model';

import { Facility } from '../../../models/Facility';
import { dateFormatLocale } from '../../../utils/date';
import { HeaderActions } from '../../../components/panel/header-actions';
import { centimetersToMeters } from '../../../utils/units';

export type NarrowFacility = {
  id: number;
};

export interface FacilityCardProps {
  id: number;
  data: Facility;
  actions?: Actions<NarrowFacility>;
}

type DetailBadgeProps = {
  title: string;
  value: React.ReactNode;
  mr?: number;
  titleWidth: number;
  width: number;
};

export const DetailBadge: FC<DetailBadgeProps> = ({ title, mr, value, titleWidth, width }) => {
  const renderedValue = useMemo(() => {
    if (typeof value === 'string') {
      return (
        <Text
          color="dark.dark-0"
          style={{
            fontSize: 14
          }}
        >
          {value}
        </Text>
      );
    }
    return value;
  }, [value]);

  return (
    <Box
      mr={mr}
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width
      }}
    >
      <Text
        color="dark.dark-2"
        opacity={0.56}
        fontWeight={500}
        style={{
          width: titleWidth,
          fontSize: 14
        }}
      >
        {title}
      </Text>
      {renderedValue}
    </Box>
  );
};

export const FacilityCard: FC<FacilityCardProps> = ({ data, actions }) => {
  const { t } = useTranslation();
  const {
    id,
    cypher,
    status,
    createdAt,
    lastUpdatedAt,
    responsibleUserName,
    creatorName,
    name,
    boreholesTarget,
    boreholeDepthTarget,
    boreholesActual,
    boreholeDepthActual,
    geologists = []
  } = data || {};

  const target = t('facility.card.depthValue', {
    depth: centimetersToMeters(boreholeDepthTarget || 0),
    count: boreholesTarget
  });
  const actual = t('facility.card.depthValue', {
    depth: centimetersToMeters(boreholeDepthActual || 0),
    count: boreholesActual
  });

  return (
    <>
      <Panel
        headerIcon="info"
        headerLeft={name}
        headerRight={
          <HStack alignItems="center">
            <HeaderActions id={id} actions={actions} />
          </HStack>
        }
      >
        <Box my={6}>
          <HStack space={4} height={12}>
            <DetailBadge title={t('facility.card.id')} value={String(id)} titleWidth={56} width={220} mr={6} />
            <DetailBadge title={t('facility.card.cypher')} value={cypher} titleWidth={56} width={220} mr={124} />
            <DetailBadge title={t('facility.card.creatorName')} value={creatorName} titleWidth={120} width={360} />
            <DetailBadge
              title={t('facility.card.createdAt')}
              value={dateFormatLocale(createdAt)}
              titleWidth={80}
              width={200}
            />
          </HStack>
          <HStack space={4} height={12}>
            <DetailBadge title={t('facility.card.target')} value={target} titleWidth={56} width={220} mr={6} />
            <DetailBadge title={t('facility.card.actual')} value={actual} titleWidth={56} width={220} mr={124} />
            <DetailBadge
              title={t('facility.card.responsibleUserName')}
              value={responsibleUserName}
              titleWidth={120}
              width={360}
            />
            <DetailBadge
              title={t('facility.card.lastUpdatedAt')}
              value={dateFormatLocale(lastUpdatedAt)}
              titleWidth={80}
              width={200}
            />
          </HStack>
          <HStack height={12}>
            <DetailBadge
              title={t('facility.card.status')}
              value={<StatusBadge status={status} />}
              titleWidth={56}
              width={176}
              mr={6}
            />
          </HStack>
        </Box>
      </Panel>
      <Panel headerIcon="content" headerLeft={t('facility.card.geologist')}>
        <Box my={6}>
          {geologists.map((geologist) => (
            <Text key={geologist.id}>{geologist.name}</Text>
          ))}
        </Box>
      </Panel>
    </>
  );
};
