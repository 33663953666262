import { observable, makeObservable, computed, action, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Platform } from 'react-native';
import { getStateFromPath, NavigationState } from '@react-navigation/core';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ability } from '@casl/ability';

import { BaseStore } from './base';

import { getApiClient } from '../api/api-client';
import { User } from '../models/User';

import { screensConfig } from '../navigation/config';
import { defaultRoute, defaultAuthedRoute } from '../navigation/constants';
import { ScreenName, Route } from '../navigation/types';

import { ROLE, defineAbilitiesFor } from '../utils/permissions';

type Role = typeof ROLE[keyof typeof ROLE];

export class AppStore extends BaseStore {
  @observable currentUser?: User;

  @observable role?: Role;

  @observable loginWrongCredentials = false;

  @observable isLoggingIn = false;

  @observable token?: string;

  @observable.ref permission: Ability = defineAbilitiesFor();

  @observable users: User[] = [];

  @observable.ref navigationState?: NavigationState;

  @observable isNavigationStateReady = false;

  @observable isLoading = true;

  @observable error = false;

  constructor() {
    super();
    makeObservable(this);
  }

  @computed get route(): Route | undefined {
    const routes = this.navigationState?.routes || [];
    const route = routes.length === 0 ? undefined : routes[routes.length - 1];
    return route ? { name: route.name as ScreenName, params: route.params } : undefined;
  }

  @computed get initialRoute(): Route {
    if (!this.currentUser) {
      return defaultRoute;
    }
    return this.route ? this.route : defaultAuthedRoute;
  }

  async init(): Promise<void> {
    await super.init();
    await makePersistable(this, {
      name: 'App',
      properties: ['currentUser', 'token', 'navigationState'],
      storage: AsyncStorage
    });

    this.initNavigationState();
    if (this.currentUser) {
      await this.updateUserData();
    } else {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action async updateUserData(): Promise<void> {
    try {
      const user = await getApiClient().usersController.current();

      const role = user.roles[0] as Role;
      let users: User[] = [];
      if (role !== 'ROLE_GEOLOGIST') {
        const { result } = await getApiClient().usersController.listGeologists();
        users = result;
      }
      const permission = defineAbilitiesFor(user);
      runInAction(() => {
        this.currentUser = user;
        this.role = role;
        this.permission = permission;
        this.users = users;
        this.error = false;
      });
    } catch (e) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action setToken(token: string): void {
    this.token = token;
  }

  @action async login(email: string, password: string): Promise<void> {
    this.isLoggingIn = true;
    try {
      const response = await getApiClient().authController.signin({ body: { email, password } });
      const { token } = response;
      runInAction(() => {
        this.token = token;
        this.loginWrongCredentials = false;
        this.isLoggingIn = false;
      });
      await this.updateUserData();
    } catch (e: unknown) {
      runInAction(() => {
        this.token = undefined;
        this.loginWrongCredentials = true;
        this.isLoggingIn = false;
      });
    }
  }

  @action logout(): void {
    runInAction(() => {
      this.currentUser = undefined;
      this.role = undefined;
      this.token = undefined;
      this.permission = defineAbilitiesFor();
      this.role = undefined;
    });
  }

  @action initNavigationState(): void {
    let userRoute: Route | null = null;

    if (Platform.OS === 'web') {
      const userState = getStateFromPath(window.location.pathname, { screens: screensConfig });
      if (userState && userState.routes.length > 0) {
        userRoute = userState.routes[0] as Route;
      } else {
        userRoute = this.currentUser ? defaultAuthedRoute : defaultRoute;
      }
    }

    if (this.navigationState && userRoute) {
      const { routes } = this.navigationState;
      if (routes) {
        const route = routes.length > 0 ? routes[routes.length - 1] : undefined;
        if (
          route &&
          (route.name !== userRoute.name ||
            JSON.stringify(route.params || {}) !== JSON.stringify(userRoute.params || {}))
        ) {
          this.navigationState = Object.assign(this.navigationState, {
            stale: true,
            index: this.navigationState.index + 1,
            routes: [
              ...(routes || []),
              {
                key: `${userRoute.name}-${Math.floor(Math.random() * 10000000)}`,
                ...userRoute
              }
            ]
          });
        }
      }
    }

    this.isNavigationStateReady = true;
  }

  @action setNavigationState(state: NavigationState): void {
    this.navigationState = state;
  }
}
