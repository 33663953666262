import { Notification as NotificationGenerated } from '@geo/api/generated/swagger.json/components/schemas/Notification';
import { FacilityCommentNotificationParams } from '@geo/api/generated/swagger.json/components/schemas/FacilityCommentNotificationParams';
import { BoreholeNotificationParams } from '@geo/api/generated/swagger.json/components/schemas/BoreholeNotificationParams';
import { UserNotificationParams } from '@geo/api/generated/swagger.json/components/schemas/UserNotificationParams';
import { FacilityFileNotificationParams } from '@geo/api/generated/swagger.json/components/schemas/FacilityFileNotificationParams';
import { FacilityNotificationParams } from '@geo/api/generated/swagger.json/components/schemas/FacilityNotificationParams';

export type Notification = NotificationGenerated;
type BaseNotification = Omit<NotificationGenerated, 'entityType' | 'params'>;
type NotificationWithParams<E extends NotificationGenerated['entityType'], P> = BaseNotification & {
  entityType: E;
  params: P;
};

export const isFacilityNotification = (
  notification: Notification
): notification is NotificationWithParams<'FACILITY', FacilityNotificationParams> =>
  notification.entityType === 'FACILITY';

export const isPhotoNotification = (
  notification: Notification
): notification is NotificationWithParams<'PHOTO', FacilityFileNotificationParams> =>
  notification.entityType === 'PHOTO';

export const isDocumentNotification = (
  notification: Notification
): notification is NotificationWithParams<'DOCUMENT', FacilityFileNotificationParams> =>
  notification.entityType === 'DOCUMENT';

export const isUserNotification = (
  notification: Notification
): notification is NotificationWithParams<'USER', UserNotificationParams> => notification.entityType === 'USER';

export const isBoreholeNotification = (
  notification: Notification
): notification is NotificationWithParams<'BOREHOLE', BoreholeNotificationParams> =>
  notification.entityType === 'BOREHOLE';

export const isBoreholeSetTemperatureNotification = (
  notification: Notification
): notification is NotificationWithParams<'BOREHOLE', BoreholeNotificationParams> =>
  notification.entityType === 'BOREHOLE' && notification.actionType === 'SET_TEMPERATURE';

export const isCommentNotification = (
  notification: Notification
): notification is NotificationWithParams<'COMMENT', FacilityCommentNotificationParams> =>
  notification.entityType === 'COMMENT';
