import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { VStack, HStack, Checkbox } from 'native-base';

import { Level, LevelItem } from '../../../components/level';

import { HeaderActions, NarrowFacility } from '../../../components/panel/header-actions';
import { Panel } from '../../../components/panel/panel';
import { Actions } from '../../../components/table/table.model';
import { FacilityStatus } from '../../../models/Facility';
import { StatusBadge } from '../../../components/status/status-badge';
import { dateFormatLocale } from '../../../utils/date';
import { centimetersToMeters, centimetersToMetersOptional, stringifyFractional } from '../../../utils/units';
import { appStore } from '../../../stores';
import { BoreholeShifts } from '../borehole-shifts/borehole-shifts';

export interface BoreholeCardData {
  id: number;
  name: string;
  creator: {
    id: number;
    name: string;
  };
  status: FacilityStatus;
  coordinate: {
    id: number;
    latitude: number;
    longitude: number;
  };
  isPlastic: boolean;
  isMetal: boolean;
  createdAt: number;
  lastUpdatedAt?: number;
  depthTarget: number;
  depthActual?: number;
  technologicalExpansion?: number;
  pipeCase?: Record<string, number>;
  sign?: boolean;
  signerId?: number;
  signAt?: number;
}

export interface BoreholeCardProps {
  data: BoreholeCardData;
  actions?: Actions<NarrowFacility>;
}

const MDASH = '\u2014';

export const BoreholeCard: React.FC<BoreholeCardProps> = observer(({ data, actions }) => {
  const { t } = useTranslation();
  const {
    id,
    name,
    status,
    isMetal,
    isPlastic,
    coordinate,
    createdAt,
    creator,
    lastUpdatedAt,
    depthActual,
    depthTarget,
    technologicalExpansion,
    signerId,
    sign,
    signAt
  } = data;

  const { currentUser, users } = appStore;

  const pipeCase = data.pipeCase || {};

  const findSignerName = (): string => {
    if (users.length > 0) {
      return users.find((user) => user.id === signerId)?.name || '';
    }

    if (currentUser && currentUser?.id === signerId) {
      return currentUser.name;
    }

    return '';
  };

  const headerRight = (
    <HStack alignItems="center">
      {sign && (
        <>
          <HStack space={4}>
            <LevelItem label={t('borehole.card.signerName')}>{findSignerName()}</LevelItem>
            <LevelItem label={t('borehole.card.signAt')}>{dateFormatLocale(signAt)}</LevelItem>
          </HStack>
        </>
      )}
      <HeaderActions id={id} actions={actions} />
    </HStack>
  );

  const ratio1 = 1.75;
  const ratio2 = 0.7;
  const ratio3 = 3;

  return (
    <>
      <Panel headerIcon="info" headerLeft={name} headerRight={headerRight}>
        <HStack py={6} space={6} justifyContent="space-between">
          <VStack flex={1}>
            <Level
              left={<LevelItem label={t('borehole.card.id')}>{name}</LevelItem>}
              right={
                <LevelItem label={t('borehole.card.status')}>
                  <StatusBadge status={status} />
                </LevelItem>
              }
              ratio={ratio1}
            />
            <LevelItem label={t('borehole.card.creatorName')} ratio={ratio1}>
              {creator.name}
            </LevelItem>
            <LevelItem label={t('borehole.card.coordinates')} ratio={ratio1}>
              <Level
                left={
                  <LevelItem label="N">
                    {t('units.degrees', { value: stringifyFractional(coordinate.latitude, 6) })}
                  </LevelItem>
                }
                right={
                  <LevelItem label="E">
                    {t('units.degrees', { value: stringifyFractional(coordinate.longitude, 6) })}
                  </LevelItem>
                }
              />
            </LevelItem>
            <Level
              left={
                <LevelItem label={t('borehole.card.depthTarget')}>
                  {t('units.meters', { value: stringifyFractional(centimetersToMeters(depthTarget), 2) })}
                </LevelItem>
              }
              right={
                <LevelItem label={t('borehole.card.depthActual')}>
                  {t('units.meters', { value: stringifyFractional(centimetersToMetersOptional(depthActual), 2) })}
                </LevelItem>
              }
              ratio={ratio1}
            />
          </VStack>

          <VStack flex={1}>
            <Level
              align="top"
              ratio={Infinity}
              gap={6}
              left={<LevelItem label={t('borehole.card.pipeCase')} />}
              right={
                <>
                  {[1, 2, 3].map((type) => {
                    const value = pipeCase[`TYPE_${type}`];
                    return (
                      <LevelItem
                        key={`pipe-case-${type}`}
                        label={t(`borehole.card.pipeCaseType_${type}`)}
                        ratio={ratio2}
                      >
                        {typeof value === 'number'
                          ? t('units.meters', { value: stringifyFractional(centimetersToMeters(value), 2) })
                          : MDASH}
                      </LevelItem>
                    );
                  })}
                  <LevelItem label={t('borehole.card.technologicalExpansion')} ratio={ratio2}>
                    {typeof technologicalExpansion === 'number'
                      ? t('units.meters', {
                          value: stringifyFractional(centimetersToMeters(technologicalExpansion), 2)
                        })
                      : MDASH}
                  </LevelItem>
                </>
              }
            />
            <Level
              align="top"
              ratio={Infinity}
              gap={6}
              left={<LevelItem label={t('borehole.card.pipeType')} />}
              right={
                <>
                  <LevelItem ratio={ratio2} label={t('borehole.card.isMetal')}>
                    <Checkbox value="isMetal" isChecked={isMetal} isDisabled />
                  </LevelItem>
                  <LevelItem ratio={ratio2} label={t('borehole.card.isPlastic')}>
                    <Checkbox value="isPlastic" isChecked={isPlastic} isDisabled />
                  </LevelItem>
                </>
              }
            />
          </VStack>

          <VStack flex={1}>
            <LevelItem label={t('borehole.card.createdAt')} ratio={ratio3}>
              {dateFormatLocale(createdAt)}
            </LevelItem>
            <LevelItem label={t('borehole.card.lastUpdatedAt')} ratio={ratio3}>
              {dateFormatLocale(lastUpdatedAt)}
            </LevelItem>
          </VStack>
        </HStack>
      </Panel>

      <BoreholeShifts boreholeId={id} />
    </>
  );
});
