import { User, UserIO } from '../../components/schemas/User';
import { number, literal, union, undefined, string, type } from 'io-ts';

export type AuditEvent = {
	eventTs: number;
	eventType:
		| 'PHOTO_CREATE'
		| 'PHOTO_UPDATE'
		| 'PHOTO_DELETE'
		| 'DOCUMENT_CREATE'
		| 'DOCUMENT_UPDATE'
		| 'DOCUMENT_DELETE'
		| 'COMMENT_CREATE'
		| 'COMMENT_UPDATE'
		| 'COMMENT_DELETE'
		| 'TAB_LOCATION'
		| 'TAB_ONLINE'
		| 'TAB_OFFLINE';
	principal: User | undefined;
	text: string;
};
export const AuditEventIO = type(
	{
		eventTs: number,
		eventType: union([
			literal('PHOTO_CREATE'),
			literal('PHOTO_UPDATE'),
			literal('PHOTO_DELETE'),
			literal('DOCUMENT_CREATE'),
			literal('DOCUMENT_UPDATE'),
			literal('DOCUMENT_DELETE'),
			literal('COMMENT_CREATE'),
			literal('COMMENT_UPDATE'),
			literal('COMMENT_DELETE'),
			literal('TAB_LOCATION'),
			literal('TAB_ONLINE'),
			literal('TAB_OFFLINE'),
		]),
		principal: union([UserIO, undefined]),
		text: string,
	},
	'AuditEvent',
);
