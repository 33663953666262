import { action, observable, makeObservable } from 'mobx';

import { getApiClient } from '../api/api-client';
import { Borehole, BoreholeSortColumn } from '../models/Borehole';
import { BaseTableStore, FetchListWithIdFn } from './base-table';
import { CollectionFetched } from './collection';

export type BoreholeTableGetFetchList = FetchListWithIdFn<Borehole, BoreholeSortColumn>;

export class BoreholeTableStore extends BaseTableStore<Borehole, BoreholeSortColumn> {
  @observable facilityId?: number;

  constructor(storageKey: string) {
    super(storageKey);
    makeObservable(this);
    this.sortBy = 'lastUpdatedAt';
    this.order = 'DESC';
  }

  @action setFacilityId(facilityId: number | undefined): void {
    this.facilityId = facilityId;
  }

  readonly getFetchList = (): BoreholeTableGetFetchList => {
    return getApiClient().boreholeController.listOfBoreholesByFacilityId;
  };

  @action async fetchItems(): Promise<CollectionFetched<Borehole>> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }
    const { result: items, size: total } = await this.getFetchList()(this.facilityId, { query: this.storedQuery });
    return { items, total };
  }
}
