import React, { Fragment, FC, ReactElement } from 'react';
import { VStack, HStack } from 'native-base';

import { useGridItems } from '../hooks/useGridItems';

type GridItemsProps = {
  items: Array<ReactElement>;
  columns: number;
  rowGap?: number;
  columnGap?: number;
};

const defaultRowGap = 10;
const defaultColumnGap = 10;

export const GridItems: FC<GridItemsProps> = (props): ReactElement => {
  const { items, columns, rowGap = defaultRowGap, columnGap = defaultColumnGap } = props;
  const gridItems = useGridItems(items, columns);
  return (
    <VStack space={`${rowGap}px`}>
      {gridItems.map((rowColumns, rowIndex) => {
        const rowKey = `row-${rowIndex}`;
        return (
          <HStack key={rowKey} space={`${columnGap}px`}>
            {rowColumns.map((columnIndex) => {
              const index = rowIndex * columns + columnIndex;
              const key = `item-${index}`;
              return <Fragment key={key}>{items[index]}</Fragment>;
            })}
          </HStack>
        );
      })}
    </VStack>
  );
};
