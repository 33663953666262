import {
	ConsolidatedStatementRow,
	ConsolidatedStatementRowIO,
} from '../../components/schemas/ConsolidatedStatementRow';
import { User, UserIO } from '../../components/schemas/User';
import { array, number, undefined, union, type } from 'io-ts';

export type ConsolidatedStatement = {
	rows: Array<ConsolidatedStatementRow>;
	count: number;
	acceptedCount: number;
	deliveredBy: User | undefined;
	acceptedBy: User;
};
export const ConsolidatedStatementIO = type(
	{
		rows: array(ConsolidatedStatementRowIO),
		count: number,
		acceptedCount: number,
		deliveredBy: union([UserIO, undefined]),
		acceptedBy: UserIO,
	},
	'ConsolidatedStatement',
);
