import React, { useMemo } from 'react';
import { HStack, Menu, View, Pressable, Tooltip, Box, ZStack, Text } from 'native-base';
import { useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Platform } from 'react-native';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { useComponent } from '../../hooks/useComponent';
import { screens, screensConfig } from '../../navigation/config';
import { appStore, appModeStore, shiftStore, notificationStore } from '../../stores';
import { ACTION, SUBJECT } from '../../utils/permissions';
import { PermissionGuard } from '../../utils/permissions/guard';

import { openApp, openTelegramm, openViber, openWhatsapp } from './utils';
import Icon from '../icon';
import { NavButton } from './nav-button';
import { apiUrl } from '../../constants';

type MessengerType = 'viber' | 'telegramm' | 'whatsapp';
type MessengerOpenHandlerMap = Record<MessengerType, (args: { number: string }) => void>;

const messengerOpenHandlerMap: MessengerOpenHandlerMap = {
  whatsapp: openWhatsapp,
  viber: openViber,
  telegramm: openTelegramm
};

export const HeaderRight: React.FC = observer(() => {
  const { hasUnreadableNotification } = notificationStore;
  const { isStarted, globalShift } = shiftStore;
  const { t } = useTranslation();
  const handlePress = (messenger: MessengerType) => () => {
    messengerOpenHandlerMap[messenger]({ number: 't' });
  };
  const { AppModeSwitcher } = useComponent();
  const navigation = useAppNavigation();
  const route = useRoute();
  const routeName = route.name;
  const toPage = (name: string): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigation.navigate(name);
  };

  const handleAuditClick = () => {
    if (Platform.OS === 'web') {
      toPage(screens.Audit);
    } else {
      const auditUrl = screensConfig[screens.Audit];
      if (typeof auditUrl === 'string') {
        const url = `${apiUrl}${auditUrl.replace('/', '')}`;
        openApp(url).catch();
      }
    }
  };

  const { currentUser } = appStore;
  const { isOffline } = appModeStore;
  const menuUserComponent = currentUser && (
    <HStack ml={4} space={1} alignItems="center">
      <Icon name="user" />
      <Text color="dark.dark-2" fontSize={14} fontWeight={500}>
        {currentUser.name}
      </Text>
    </HStack>
  );

  const logoutAvailable = useMemo(() => {
    return !(isOffline || isStarted);
  }, [isStarted, isOffline]);

  return (
    <HStack pr="34px" alignItems="center">
      <HStack pr={34} alignItems="center">
        <PermissionGuard action={ACTION.ENABLE} subject={SUBJECT.OFFLINE}>
          <AppModeSwitcher />
        </PermissionGuard>
        <PermissionGuard action={ACTION.SEE_MARKED_FACILITIES} subject={SUBJECT.ROUTE}>
          <NavButton
            mr={4}
            isActive={routeName === screens.FacilitiesDeleted}
            onPress={() => toPage(screens.FacilitiesDeleted)}
            iconName="deleted"
          >
            {t('header.navButtons.deleted')}
          </NavButton>
        </PermissionGuard>
        <PermissionGuard action={ACTION.CREATE} subject={SUBJECT.AUDIT}>
          <NavButton mr={4} isActive={routeName === screens.Audit} onPress={handleAuditClick} iconName="analytics">
            {t('header.navButtons.audit')}
          </NavButton>
        </PermissionGuard>
        <PermissionGuard action={ACTION.SEE_ARCHIVED_FACILITIES} subject={SUBJECT.ROUTE}>
          <NavButton
            isActive={routeName === screens.FacilitiesArchived}
            onPress={() => toPage(screens.FacilitiesArchived)}
            iconName="archive"
          >
            {t('header.navButtons.archive')}
          </NavButton>
        </PermissionGuard>
      </HStack>
      <HStack space={4} pl={4} pr={4} alignItems="center">
        <Icon name="viber" onPress={handlePress('viber')} />
        <Icon name="telegramm" onPress={handlePress('telegramm')} />
        <Icon name="whatsapp" onPress={handlePress('whatsapp')} />
      </HStack>
      <HStack ml={5} mr={5} space={8} alignItems="center">
        <Pressable
          width={10}
          height={10}
          rounded="full"
          _pressed={{
            backgroundColor: 'white'
          }}
          onPress={() => toPage(screens.Notification)}
        >
          <ZStack flex={1} alignItems="center" justifyContent="center">
            <View>
              <Icon name="notifications" />
            </View>
            {hasUnreadableNotification && <Box size={2} mt={-5} ml={5} rounded="lg" bg="#fe5cad" />}
          </ZStack>
        </Pressable>
      </HStack>
      {logoutAvailable ? (
        <Menu
          placement="bottom right"
          width={50}
          trigger={(triggerProps) => <Pressable {...triggerProps}>{menuUserComponent}</Pressable>}
        >
          <Menu.Item onPress={() => appStore.logout()}>
            <HStack space={4}>
              <Icon name="logout" />
              <Text>{t('header.menuItems.logout')}</Text>
            </HStack>
          </Menu.Item>
        </Menu>
      ) : (
        <Tooltip
          placement="bottom right"
          label={t('header.notAvailableMenu', {
            facility: globalShift?.borehole?.facility?.name,
            borehole: globalShift?.borehole?.name
          })}
        >
          {menuUserComponent}
        </Tooltip>
      )}
    </HStack>
  );
});
