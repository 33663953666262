import { action, observable } from 'mobx';
import { http } from '@geo/api/http-client';
import { UploadValue } from './file';
import { EntityStore } from './entity';

export class UploaderStore<EntityLoadParams> extends EntityStore<UploadValue | null, EntityLoadParams, 'upload'> {
  @observable validate = false;

  @action.bound async upload(requestUrl: string) {
    const upload = this.item;
    const uploadedFile = upload?.file;
    if (upload && uploadedFile) {
      return this.request(
        'upload',
        () => {
          const { description } = upload;
          const form = new FormData();
          form.append('file', uploadedFile);
          return http.post(requestUrl, form, { params: { description } });
        },
        (response) => {
          console.log(response);
        }
      );
    }
    return null;
  }

  @action.bound
  setValidate(validate: boolean) {
    this.validate = validate;
  }

  protected fetchItem(): Promise<UploadValue | null> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Promise.resolve(null);
  }
}
