// eslint-disable-next-line import/no-duplicates
import format from 'date-fns/format';
// eslint-disable-next-line import/no-duplicates
import ru from 'date-fns/locale/ru';

export const dateFormat = (date?: number | Date): string => {
  return date === undefined ? '—' : format(new Date(date), 'dd.MM.yy');
};

export const timeFormat = (date?: number): string => {
  return date === undefined ? '—' : format(new Date(date), 'HH:mm');
};

export const dateFormatLocale = (date?: number): string => {
  return date === undefined ? '—' : format(new Date(date), 'dd LLL yyyy г.', { locale: ru });
};

export const dateTimeFormatLocale = (date?: number): string => {
  return date === undefined ? '—' : format(new Date(date), 'dd LLL yyyy г., H:mm:ss', { locale: ru });
};
