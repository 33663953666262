import React, { FC, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { NavigationState } from '@react-navigation/core';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { MainLayout, ModalLayout } from '../components/layouts';
import { appStore } from '../stores';

import { navigationRef } from './index';
import { navigatorTheme } from './constants';
import { screens, screensConfig } from './config';
import { RootStackParamList, ScreenName, Route } from './types';

import { Login } from './pages/auth/login';
import { Restore } from './pages/auth/restore';
import { FacilityListPage } from './pages/facility/facility-list';
import { FacilityArchivedListPage } from './pages/facility/facility-archived-list';
import { FacilityDeletedListPage } from './pages/facility/facility-deleted-list';
import { FacilityCardPage } from './pages/facility/facility-card';
import { FacilityCreatePage } from './pages/facility/facility-create';
import { FacilityUpdatePage } from './pages/facility/facility-update';
import { BoreholesPage } from './pages/boreholes/borehole-list';
import { BoreholeViewPage } from './pages/boreholes/borehole-view';
import { BoreholeCreatePage } from './pages/boreholes/borehole-create';
import { BoreholeUpdatePage } from './pages/boreholes/borehole-update';
import { BoreholeLayersPage } from './pages/boreholes/borehole-layers';
import { BoreholeTemperaturesPage } from './pages/boreholes/borehole-temperatures';
import { BoreholeSamplesPage } from './pages/boreholes/borehole-samples';
import { MapListPage } from './pages/map/map-list';
import { MapGeneralViewPage } from './pages/map/map-general-view';
import { MapViewPage } from './pages/map/map-view';
import { PhotoListPage } from './pages/photo/photo-list';
import { PhotoUploadPage } from './pages/photo/photo-upload';
import { PhotoCardPage } from './pages/photo/photo-card';
import { DocumentListPage } from './pages/document/document-list';
import { DocumentUploadPage } from './pages/document/document-upload';
import { DocumentEditPage } from './pages/document/document-edit';
import { CommentsPage } from './pages/comments';
import { NotificationsPage } from './pages/notifications/notifications';
import { PhotoEditPage } from './pages/photo/photo-edit';

const RootStack = createNativeStackNavigator<RootStackParamList>();

const linking = {
  prefixes: [],
  config: { screens: screensConfig }
};

type LayoutsConfig = Record<keyof typeof screens, React.ComponentType<React.PropsWithChildren<any>>>;

const layoutsConfig: Partial<LayoutsConfig> = {
  [screens.PhotoCard]: ModalLayout
};

export const makeScreen = (
  name: ScreenName,
  title: string,
  component: React.ComponentType<any>,
  initialRoute?: Route
) => {
  return (
    <RootStack.Screen
      key={name}
      name={name}
      getId={getScreenId(name)}
      options={{ title, header: () => null }}
      initialParams={initialRoute?.name === name ? initialRoute?.params : {}}
      component={React.memo((pageProps) => {
        const renderedPage = React.createElement(component, pageProps);
        return React.createElement(layoutsConfig[name] || MainLayout, null, renderedPage);
      })}
    />
  );
};

const getScreenId =
  (screenName: ScreenName) =>
  (options: { params: Record<string, any> | undefined }): string => {
    const string = options.params ? Object.values(options.params).join('_') : '';
    return `${screenName}${string}`;
  };

interface NavigatorProps {
  platformScreensBuilder?: (initialRoute: Route) => JSX.Element[];
}

const Navigator: FC<NavigatorProps> = observer(({ platformScreensBuilder }) => {
  const { currentUser, initialRoute, navigationState, isNavigationStateReady } = appStore;

  const navigatorScreens = useMemo(() => {
    return currentUser
      ? [
          makeScreen(screens.Facilities, 'Объекты', FacilityListPage, initialRoute),
          makeScreen(screens.FacilitiesArchived, 'Архив объектов', FacilityArchivedListPage, initialRoute),
          makeScreen(screens.FacilitiesDeleted, 'Объекты под удаление', FacilityDeletedListPage, initialRoute),
          makeScreen(screens.FacilityView, 'Объекты / Название объекта', FacilityCardPage, initialRoute),
          makeScreen(screens.FacilityCreate, 'Создание', FacilityCreatePage, initialRoute),
          makeScreen(screens.FacilityUpdate, 'Редактирование', FacilityUpdatePage, initialRoute),
          makeScreen(screens.Boreholes, 'Скважины', BoreholesPage, initialRoute),
          makeScreen(screens.BoreholeView, 'Скважины / Название скважины', BoreholeViewPage, initialRoute),
          makeScreen(screens.BoreholeCreate, '', BoreholeCreatePage, initialRoute),
          makeScreen(screens.BoreholeUpdate, '', BoreholeUpdatePage, initialRoute),
          makeScreen(screens.BoreholeLayers, 'Геолог. описание', BoreholeLayersPage, initialRoute),
          makeScreen(screens.BoreholeTemperatures, 'Температура', BoreholeTemperaturesPage, initialRoute),
          makeScreen(screens.BoreholeSamples, 'Список образцов', BoreholeSamplesPage, initialRoute),
          makeScreen(screens.MapList, 'Карты', MapListPage, initialRoute),
          makeScreen(screens.MapGeneralView, 'Общая карта объекта', MapGeneralViewPage, initialRoute),
          makeScreen(screens.MapView, 'Карта скважины объекта', MapViewPage, initialRoute),
          makeScreen(screens.Photos, 'Фотографии', PhotoListPage, initialRoute),
          makeScreen(screens.PhotosUpload, 'Добавление фотографии', PhotoUploadPage, initialRoute),
          makeScreen(screens.PhotoEdit, 'Редактирование фотографии', PhotoEditPage, initialRoute),
          makeScreen(screens.PhotoCard, 'Фотография', PhotoCardPage, initialRoute),
          makeScreen(screens.Documents, 'Документы', DocumentListPage, initialRoute),
          makeScreen(screens.DocumentUpload, 'Добавление документа', DocumentUploadPage, initialRoute),
          makeScreen(screens.DocumentEdit, 'Редактирование документа', DocumentEditPage, initialRoute),
          makeScreen(screens.Comments, 'Комментарии', CommentsPage, initialRoute),
          makeScreen(screens.Notification, 'Уведомления', NotificationsPage, initialRoute)
        ]
      : [];
  }, [currentUser]);

  const platformsScreens = useMemo(() => {
    return currentUser && platformScreensBuilder ? platformScreensBuilder(initialRoute) : [];
  }, [platformScreensBuilder, currentUser]);

  const onStateChange: (state: NavigationState | undefined) => void = useCallback(
    (state) => {
      if (state) {
        appStore.setNavigationState(state);
      }
    },
    [currentUser]
  );

  if (!isNavigationStateReady) {
    return null;
  }

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={navigatorTheme}
      linking={linking}
      initialState={navigationState}
      onStateChange={onStateChange}
    >
      <RootStack.Navigator initialRouteName={initialRoute.name} screenOptions={{ animation: 'none' }}>
        <RootStack.Screen name="Login" options={{ headerShown: false }} component={Login} />
        <RootStack.Screen name="Restore" component={Restore} />
        {navigatorScreens}
        {platformsScreens}
      </RootStack.Navigator>
    </NavigationContainer>
  );
});

export default Navigator;
