import { action, override } from 'mobx';
import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';
import { getApiClient } from '../api/api-client';
import { fileDownload } from '../utils/file';
import { FileStore } from './file';

export class PhotoStore extends FileStore {
  protected async fetchItem(id: number): Promise<FacilityFile> {
    if (!this.facilityId) {
      throw new Error('Facility identifier is not set');
    }
    return getApiClient().photoController.getPhoto(this.facilityId, id);
  }

  @override upload(facilityId: number): Promise<FacilityFile> | null {
    return super.upload(facilityId, 'photo');
  }

  @action.bound download(file: FacilityFile): Promise<Blob> {
    return this.request(
      'download',
      () => {
        return getApiClient().photoController.getPhotoContent(file.facilityId, file.id) as Promise<Blob>;
      },
      (blob) => {
        fileDownload(blob, file.filename, file.contentType);
      }
    );
  }

  @action.bound
  updateDescription(facilityId: number, fileId: number, description?: string): Promise<FacilityFile> {
    return getApiClient().photoController.updatePhotoDescription(facilityId, fileId, {
      body: {
        description: description || ''
      }
    });
  }
}
