import { Pressable, View } from 'native-base';
import React, { FC, useCallback } from 'react';
import { FacilityFile } from '@geo/api/generated/swagger.json/components/schemas/FacilityFile';

import { GridItems } from '../../components/grid-items';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { useGridItemSize } from '../../hooks/useGridItemSize';
import { screens } from '../../navigation/config';
import { Photo } from './photo';

const PHOTO_ROWS = 3;
const PHOTO_COLUMNS = 7;
const PHOTO_ROW_GAP = 10;
const PHOTO_COLUMN_GAP = 10;
const PHOTO_TITLE_HEIGHT = 50;

type PhotoListProps = {
  facilityId: number;
  data: FacilityFile[];
};

export const PhotoList: FC<PhotoListProps> = (props) => {
  const { facilityId, data } = props;

  const navigation = useAppNavigation();

  const { width, height, onLayout } = useGridItemSize({
    rows: PHOTO_ROWS,
    columns: PHOTO_COLUMNS,
    rowGap: PHOTO_ROW_GAP,
    columnGap: PHOTO_COLUMN_GAP,
    verticalExtra: PHOTO_TITLE_HEIGHT
  });

  const onPress = useCallback(
    (photoId: number) => () => {
      navigation.navigate(screens.PhotoCard, { facilityId, photoId });
    },
    []
  );

  let photos;
  if (width > 0 && height > 0) {
    photos = (
      <GridItems
        items={data.map((photo) => {
          const { id, filename, contentType } = photo;
          return (
            <Pressable onPress={onPress(id)}>
              <Photo
                facilityId={facilityId}
                contentType={contentType}
                photoId={id}
                title={filename}
                width={`${width}px`}
                height={`${height}px`}
                titleHeight={`${PHOTO_TITLE_HEIGHT}px`}
              />
            </Pressable>
          );
        })}
        columns={PHOTO_COLUMNS}
        rowGap={PHOTO_ROW_GAP}
        columnGap={PHOTO_COLUMN_GAP}
      />
    );
  }

  return (
    <View flex={1} onLayout={onLayout}>
      <View position="absolute" top={0} bottom={0} left={0} right={0} overflow="hidden">
        {photos}
      </View>
    </View>
  );
};

PhotoList.displayName = PhotoList.name;
