import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, HStack, VStack } from 'native-base';

import { NavigationLink } from '../../../components/link';
import { StatusBadge } from '../../../components/status/status-badge';
import { Table, TableWithPaginationProps } from '../../../components/table/table';
import { CustomHeaderRenderers, CustomRenderers } from '../../../components/table/table.model';
import { Borehole, BoreholeSortColumn } from '../../../models/Borehole';
import { screens } from '../../../navigation/config';
import { dateFormat } from '../../../utils/date';
import { stringifyFractional } from '../../../utils/units';

import { columns } from './borehole-table.columns';

export type BoreholeTableProps = TableWithPaginationProps<Borehole, BoreholeSortColumn>;

export const BoreholeTable: FC<BoreholeTableProps> = ({
  items,
  page,
  selectedId,
  onRowClick,
  total,
  sort,
  onChangeSort,
  perPage,
  onChangePage,
  actions,
  actionsWidth = 170
}) => {
  const { t } = useTranslation();

  const customHeaderRenderers: CustomHeaderRenderers<Borehole> = {
    coordinate: () => {
      return (
        <VStack>
          <Text color="dark.dark-2" opacity={0.56} fontSize={13} textAlign="center">
            {t('borehole.table.coordinate')}
          </Text>
          <HStack>
            <Text flex={1} color="dark.dark-2" opacity={0.56} fontSize={13} justifyContent="center" textAlign="center">
              N
            </Text>
            <Text flex={1} color="dark.dark-2" opacity={0.56} fontSize={13} justifyContent="center" textAlign="center">
              E
            </Text>
          </HStack>
        </VStack>
      );
    }
  };

  const customRenderers: CustomRenderers<Borehole> = {
    name: ({ id, name, facility }) => {
      return (
        <NavigationLink
          title={name}
          route={screens.BoreholeView}
          routeParams={{ boreholeId: id, facilityId: facility.id }}
        />
      );
    },
    coordinate: ({ coordinate }) => (
      <HStack>
        <Text flex={1} justifyContent="center" textAlign="center">
          {stringifyFractional(coordinate.latitude, 6, '—')}
        </Text>
        <Text flex={1} justifyContent="center" textAlign="center">
          {stringifyFractional(coordinate.longitude, 6, '—')}
        </Text>
      </HStack>
    ),
    status: ({ status }) => <StatusBadge status={status} />,
    lastUpdatedAt: ({ lastUpdatedAt }) => <Text>{dateFormat(lastUpdatedAt)}</Text>
  };

  return (
    <Table
      items={items}
      columns={columns}
      headerHeight={14}
      sort={sort}
      selectedId={selectedId}
      onRowClick={onRowClick}
      customHeaderRenderers={customHeaderRenderers}
      customRenderers={customRenderers}
      actions={actions}
      actionsWidth={actionsWidth}
      total={total}
      page={page}
      perPage={perPage}
      onChangePage={onChangePage}
      onChangeSort={onChangeSort}
    />
  );
};
