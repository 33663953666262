import { PhotoExtension, DocumentExtension } from './types';

export const photoMaxSize = 10;
export const documentMaxSize = 100;

export const photoExtensions: PhotoExtension[] = ['jpg', 'png', 'gif', 'jpeg'];
export const documentExtensions: DocumentExtension[] = [
  'xlsx',
  'pdf',
  'dxf',
  'dwg',
  'doc',
  'xls',
  'docx',
  ...photoExtensions
];
