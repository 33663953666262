import React, { PropsWithChildren } from 'react';
import { Box } from 'native-base';

interface ModalLayoutProps {
  onClose: () => void;
}

export const ModalLayout = ({ children }: PropsWithChildren<ModalLayoutProps>) => {
  return <Box>{children}</Box>;
};
