import { CoordinateRequest, CoordinateRequestIO } from '../../components/schemas/CoordinateRequest';
import { literal, union, string, number, undefined, record, boolean, type } from 'io-ts';

export type BoreholeUpdateRequest = {
	status: 'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED';
	name: string;
	coordinate: CoordinateRequest;
	depthTarget: number | undefined;
	depthActual: number | undefined;
	technologicalExpansion: number | undefined;
	pipeCase: { [key: string]: number } | undefined;
	isMetal: boolean | undefined;
	isPlastic: boolean | undefined;
};
export const BoreholeUpdateRequestIO = type(
	{
		status: union([literal('NEW'), literal('IN_PROGRESS'), literal('PROBLEM'), literal('CLOSED')]),
		name: string,
		coordinate: CoordinateRequestIO,
		depthTarget: union([number, undefined]),
		depthActual: union([number, undefined]),
		technologicalExpansion: union([number, undefined]),
		pipeCase: union([record(string, number), undefined]),
		isMetal: union([boolean, undefined]),
		isPlastic: union([boolean, undefined]),
	},
	'BoreholeUpdateRequest',
);
