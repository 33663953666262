import { observable, action, makeObservable } from 'mobx';
import { Codificator } from '@geo/api/generated/swagger.json/components/schemas/Codificator';
import { AuditController } from '@geo/api/generated/swagger.json/paths/AuditController';

import { getApiClient } from '../api/api-client';

import { BaseStore } from './base';
import { fileDownload } from '../utils/file';

type AuditReportRequestDto = Parameters<AuditController<'Promise'>['getAuditReport']>[0]['query'];

export class ReportStore extends BaseStore {
  @observable loading = false;

  @observable error = false;

  @observable.ref items: Array<Codificator> | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  @action async download(rawRequest: AuditReportRequestDto): Promise<void> {
    const keys = Object.keys(rawRequest) as Array<keyof AuditReportRequestDto>;
    const request = keys.reduce((acc: AuditReportRequestDto, key) => {
      const value = rawRequest[key];
      if (typeof value !== 'undefined') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[key] = value;
      }
      return acc;
    }, {} as AuditReportRequestDto);

    const blob = await getApiClient().auditController.getAuditReport({
      query: request
    });
    fileDownload(blob, 'report.xlsx');
  }
}
