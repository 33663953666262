import { Components } from '@geo/commons/utils/components/types';
import { FC } from 'react';
import { MapView } from './features/map/map-view';
import { BoreholePrintContainer } from './features/borehole-print/borehole-print.container';
import { BoreholeLayersPrintContainer } from './features/borehole-layers-print/borehole-layers-print.container';
import { FabImagePicker } from './components/fab-image-picker';
import { BoreholesPrintContainer } from './features/boreholes-print/boreholes-print.container';

const empty: FC = () => null;

export const webComponents: Components = {
  AppInfoComponent: empty,
  AppModeSwitcher: empty,
  AppModeLoading: empty,
  ShiftSwitcher: empty,
  MapView,
  FabImagePicker,
  BoreholesPrint: BoreholesPrintContainer,
  BoreholePrint: BoreholePrintContainer,
  BoreholeLayersPrint: BoreholeLayersPrintContainer
};
