import React, { ReactElement, useCallback } from 'react';
import { HStack, Box, Text, Pressable, ChevronDownIcon, ChevronUpIcon } from 'native-base';

import { TableIdentifiedItem, ColumnDefinition, CustomHeaderRenderers, Sort } from './table.model';

export type TableHeaderProps<T extends TableIdentifiedItem, S extends string = never> = {
  columns: Array<ColumnDefinition<T, S>>;
  height?: number;
  sort?: Sort<S>;
  customHeaderRenderers?: CustomHeaderRenderers<T>;
  actionsWidth?: number | string;
  onChangeSort: (sort: Sort<S>) => void;
};

export const TableHeader = <T extends TableIdentifiedItem, S extends string = never>(
  props: TableHeaderProps<T, S>
): ReactElement => {
  const { columns, height = 14, sort, customHeaderRenderers, actionsWidth = 0, onChangeSort } = props;

  const onSort = useCallback(
    (s: Sort<S>) => () => {
      onChangeSort(s);
    },
    [onChangeSort]
  );

  const headers = columns.map((column, i) => {
    const key = `${column.key}-${i}`;
    const paddingLeft = i === 0 ? 4 : 0;
    const paddingRight = i - 1 === columns.length ? 4 : 0;
    const { width, sortBy } = column;

    const Component = sortBy ? Pressable : Box;
    const SortIcon = sort?.sortOrder === 'ASC' || sort?.column !== sortBy ? ChevronUpIcon : ChevronDownIcon;
    const customHeaderRenderer = customHeaderRenderers?.[column.key];

    const header = customHeaderRenderer ? (
      customHeaderRenderer()
    ) : (
      <HStack alignItems="center">
        <Text color="dark.dark-2" opacity={0.56} fontSize={14}>
          {column.header}
        </Text>
        {!!sortBy && (
          <SortIcon
            opacity={sort?.column === sortBy ? 1 : 0.56}
            color={sort?.column === sortBy ? '#5b8def' : '#555770'}
            marginLeft={1}
            size={5}
          />
        )}
      </HStack>
    );

    const onPress = sortBy
      ? onSort({
          column: sortBy,
          sortOrder: sort?.sortOrder === 'ASC' && sort?.column === sortBy ? 'DESC' : 'ASC'
        })
      : undefined;

    return (
      <Component
        key={key}
        onPress={onPress}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        flex={width ? undefined : 1}
        width={width}
      >
        {header}
      </Component>
    );
  });

  return (
    <HStack space={4} py={2} minHeight={height} borderBottomWidth={1} borderBottomColor="#dbdeea" alignItems="center">
      {headers}
      <Box width={actionsWidth} paddingRight={4} flex={actionsWidth ? undefined : 1} />
    </HStack>
  );
};
