import React, { FC, memo } from 'react';
import { Badge, Center, Text } from 'native-base';

import { i18n } from '../../i18n/i18next';
import { FacilityStatus } from '../../models/Facility';
import { BoreholeStatus } from '../../models/Borehole';

export const statusColor: Record<FacilityStatus | BoreholeStatus, string> = {
  ARCHIVED: '#8587b7',
  CLOSED: '#8587b7',
  IN_PROGRESS: '#5b8def',
  NEW: '#06c270',
  PROBLEM: '#ff5c5c'
};

export const statusText: Record<FacilityStatus, string> = {
  ARCHIVED: i18n.t('facility.status.archived'),
  CLOSED: i18n.t('facility.status.closed'),
  IN_PROGRESS: i18n.t('facility.status.in_progress'),
  NEW: i18n.t('facility.status.new'),
  PROBLEM: i18n.t('facility.status.problem')
};

export interface StatusBadgeProps {
  width?: number;
  status: FacilityStatus | BoreholeStatus;
}

export const StatusBadge: FC<StatusBadgeProps> = memo(({ status, width = 88 }) => (
  <Badge width={width} rounded={13} backgroundColor={statusColor[status]}>
    <Center>
      <Text color="white" lineHeight="md" fontSize="xs">
        {statusText[status]}
      </Text>
    </Center>
  </Badge>
));

StatusBadge.displayName = StatusBadge.name;
