import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { Codificator, CodificatorIO } from '../components/schemas/Codificator';
import { getResponseTypeFromMediaType, BinaryFromStringIO, Binary } from '../utils/utils';
import { either } from 'fp-ts';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { pipe } from 'fp-ts/lib/pipeable';
import { array, undefined, union, type, string, record } from 'io-ts';

export interface CodificatorController<F> {
	readonly getCodificator: () => HKT<F, Array<Codificator>>;

	readonly uploadCodificator: (parameters: { body: { file: Binary | undefined } }) => HKT<F, Array<Codificator>>;

	readonly getTranslations: () => HKT<F, { [key: string]: string }>;

	readonly uploadTranslations: (parameters: {
		body: { file: Binary | undefined };
	}) => HKT<F, { [key: string]: string }>;
}

export interface CodificatorController1<F extends URIS> {
	readonly getCodificator: () => Kind<F, Array<Codificator>>;

	readonly uploadCodificator: (parameters: { body: { file: Binary | undefined } }) => Kind<F, Array<Codificator>>;

	readonly getTranslations: () => Kind<F, { [key: string]: string }>;

	readonly uploadTranslations: (parameters: {
		body: { file: Binary | undefined };
	}) => Kind<F, { [key: string]: string }>;
}

export interface CodificatorController2<F extends URIS2> {
	readonly getCodificator: () => Kind2<F, Error, Array<Codificator>>;

	readonly uploadCodificator: (parameters: {
		body: { file: Binary | undefined };
	}) => Kind2<F, Error, Array<Codificator>>;

	readonly getTranslations: () => Kind2<F, Error, { [key: string]: string }>;

	readonly uploadTranslations: (parameters: {
		body: { file: Binary | undefined };
	}) => Kind2<F, Error, { [key: string]: string }>;
}

export function codificatorController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): CodificatorController2<F>;
export function codificatorController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): CodificatorController1<F>;
export function codificatorController<F>(e: { httpClient: HTTPClient<F> }): CodificatorController<F>;
export function codificatorController<F>(e: { httpClient: HTTPClient<F> }): CodificatorController<F> {
	return {
		getCodificator: () => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/codificator`,
					controller: 'codificator',
					operation: 'getCodificator',
					method: 'GET',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						array(CodificatorIO).decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		uploadCodificator: parameters => {
			const body = type({ file: union([BinaryFromStringIO, undefined]) }).encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'multipart/form-data',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/codificator`,
					controller: 'codificator',
					operation: 'uploadCodificator',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						array(CodificatorIO).decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		getTranslations: () => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/codificator/translations`,
					controller: 'codificator',
					operation: 'getTranslations',
					method: 'GET',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						record(string, string).decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		uploadTranslations: parameters => {
			const body = type({ file: union([BinaryFromStringIO, undefined]) }).encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'multipart/form-data',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/codificator/translations`,
					controller: 'codificator',
					operation: 'uploadTranslations',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						record(string, string).decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
