import React, { FC } from 'react';
import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { FilesUploadContainer } from '../../../features/files-upload/files-upload.container';
import { screens } from '../../config';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { fileStore } from '../../../stores';

type DocumentEditPageProps = PageProps<'DocumentEdit'>;

export const DocumentEditPage: FC<DocumentEditPageProps> = ({ route }) => {
  const navigation = useAppNavigation();
  const { params } = route;
  const facilityId = params?.facilityId;
  const documentId = params?.documentId;

  if (!facilityId || !documentId) {
    return null;
  }

  const back = () => {
    navigation.navigate(screens.Documents, {
      facilityId
    });
  };

  return (
    <Page>
      <FilesUploadContainer
        store={fileStore}
        mode="edit"
        navigate={back}
        fileId={documentId}
        fileType="document"
        facilityId={facilityId}
      />
    </Page>
  );
};

DocumentEditPage.displayName = 'DocumentEditPage';
