import React, { FC } from 'react';
import { Text, View, VStack } from 'native-base';
import { FacilityImage } from '../../components/facility-image';

type Orientation = 'landscape' | 'portrait';

type PhotoProps = {
  facilityId: number;
  photoId: number;
  title?: string;
  contentType: string;
  orientation?: Orientation;
  width?: string | number;
  height?: string | number;
  titleHeight?: string | number;
};

export const Photo: FC<PhotoProps> = (props) => {
  const { facilityId, contentType, photoId, title, width = 100, height = 100, titleHeight } = props;
  return (
    <VStack alignItems="center">
      <FacilityImage
        contentType={contentType}
        resizeMode="contain"
        resizeMethod="scale"
        facilityId={facilityId}
        photoId={photoId}
        width={width}
        height={height}
        alt={title}
      />
      {title && (
        <View flexDirection="row" justifyContent="center" alignItems="center" maxWidth={width} height={titleHeight}>
          <Text fontSize={14} lineHeight={18} noOfLines={2} isTruncated textAlign="center">
            {title}
          </Text>
        </View>
      )}
    </VStack>
  );
};

Photo.displayName = Photo.name;
