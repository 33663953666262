import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { BoreholeLayer, BoreholeLayerIO } from '../components/schemas/BoreholeLayer';
import {
	BoreholeLayerCollectionDto,
	BoreholeLayerCollectionDtoIO,
} from '../components/schemas/BoreholeLayerCollectionDto';
import {
	BoreholeLayerCreateRequest,
	BoreholeLayerCreateRequestIO,
} from '../components/schemas/BoreholeLayerCreateRequest';
import {
	BoreholeLayerUpdateRequest,
	BoreholeLayerUpdateRequestIO,
} from '../components/schemas/BoreholeLayerUpdateRequest';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { number, unknown, undefined, union, literal, type } from 'io-ts';

export interface BoreholeLayerController<F> {
	readonly getBoreholeLayer: (id: number) => HKT<F, BoreholeLayer>;

	readonly updateBoreholeLayer: (
		id: number,
		parameters: { body: BoreholeLayerUpdateRequest },
	) => HKT<F, BoreholeLayer>;

	readonly deleteBoreholeLayer: (id: number) => HKT<F, unknown>;

	readonly createBoreholeLayer: (parameters: { body: BoreholeLayerCreateRequest }) => HKT<F, BoreholeLayer>;

	readonly boreholeLayerList: (
		boreholeId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy:
					| ('id' | 'depthFrom' | 'depthTo' | 'gwlEmerged' | 'gwlSteady' | 'createdAt' | 'editedAt')
					| undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => HKT<F, BoreholeLayerCollectionDto>;
}

export interface BoreholeLayerController1<F extends URIS> {
	readonly getBoreholeLayer: (id: number) => Kind<F, BoreholeLayer>;

	readonly updateBoreholeLayer: (
		id: number,
		parameters: { body: BoreholeLayerUpdateRequest },
	) => Kind<F, BoreholeLayer>;

	readonly deleteBoreholeLayer: (id: number) => Kind<F, unknown>;

	readonly createBoreholeLayer: (parameters: { body: BoreholeLayerCreateRequest }) => Kind<F, BoreholeLayer>;

	readonly boreholeLayerList: (
		boreholeId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy:
					| ('id' | 'depthFrom' | 'depthTo' | 'gwlEmerged' | 'gwlSteady' | 'createdAt' | 'editedAt')
					| undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind<F, BoreholeLayerCollectionDto>;
}

export interface BoreholeLayerController2<F extends URIS2> {
	readonly getBoreholeLayer: (id: number) => Kind2<F, Error, BoreholeLayer>;

	readonly updateBoreholeLayer: (
		id: number,
		parameters: { body: BoreholeLayerUpdateRequest },
	) => Kind2<F, Error, BoreholeLayer>;

	readonly deleteBoreholeLayer: (id: number) => Kind2<F, Error, unknown>;

	readonly createBoreholeLayer: (parameters: { body: BoreholeLayerCreateRequest }) => Kind2<F, Error, BoreholeLayer>;

	readonly boreholeLayerList: (
		boreholeId: number,
		parameters: {
			query: {
				page: number | undefined;
				size: number | undefined;
				sortBy:
					| ('id' | 'depthFrom' | 'depthTo' | 'gwlEmerged' | 'gwlSteady' | 'createdAt' | 'editedAt')
					| undefined;
				order: ('ASC' | 'DESC') | undefined;
			};
		},
	) => Kind2<F, Error, BoreholeLayerCollectionDto>;
}

export function boreholeLayerController<F extends URIS2>(e: {
	httpClient: HTTPClient2<F>;
}): BoreholeLayerController2<F>;
export function boreholeLayerController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): BoreholeLayerController1<F>;
export function boreholeLayerController<F>(e: { httpClient: HTTPClient<F> }): BoreholeLayerController<F>;
export function boreholeLayerController<F>(e: { httpClient: HTTPClient<F> }): BoreholeLayerController<F> {
	return {
		getBoreholeLayer: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/layer/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'boreholeLayer',
					operation: 'getBoreholeLayer',
					method: 'GET',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeLayerIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		updateBoreholeLayer: (id, parameters) => {
			const body = BoreholeLayerUpdateRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/layer/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'boreholeLayer',
					operation: 'updateBoreholeLayer',
					method: 'PUT',
					responseType,
					pathParameters: [id],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeLayerIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		deleteBoreholeLayer: id => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/layer/${encodeURIComponent(number.encode(id).toString())}`,
					controller: 'boreholeLayer',
					operation: 'deleteBoreholeLayer',
					method: 'DELETE',
					responseType,
					pathParameters: [id],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						unknown.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createBoreholeLayer: parameters => {
			const body = BoreholeLayerCreateRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/layer/create`,
					controller: 'boreholeLayer',
					operation: 'createBoreholeLayer',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeLayerIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		boreholeLayerList: (boreholeId, parameters) => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('depthFrom'),
							literal('depthTo'),
							literal('gwlEmerged'),
							literal('gwlSteady'),
							literal('createdAt'),
							literal('editedAt'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/borehole/layer/borehole/${encodeURIComponent(number.encode(boreholeId).toString())}`,
					controller: 'boreholeLayer',
					operation: 'boreholeLayerList',
					method: 'GET',
					responseType,
					pathParameters: [boreholeId],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						BoreholeLayerCollectionDtoIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
