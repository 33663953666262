import {
	FacilityNotificationParams,
	FacilityNotificationParamsIO,
} from '../../components/schemas/FacilityNotificationParams';
import { NotificationParams, NotificationParamsIO } from '../../components/schemas/NotificationParams';
import { string, undefined, union, type, intersection } from 'io-ts';

export type FacilityCommentNotificationParams = NotificationParams & {
	text: string | undefined;
	facility: FacilityNotificationParams | undefined;
};
export const FacilityCommentNotificationParamsIO = intersection([
	NotificationParamsIO,
	type({ text: union([string, undefined]), facility: union([FacilityNotificationParamsIO, undefined]) }),
]);
