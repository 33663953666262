export const arcgisAccessToken =
  'AAPK81c4a769541741e6a369b03af8ccc5b4Jkd9fbumD9oE3tTISWME-D21KDwqJRsK-OTs1HAqqTw3MsYQgyG7FEOj2kNgF1Xq';

export const tileOnlineUrlTemplate = `https://ibasemaps-api.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=${arcgisAccessToken}`;
export const tileOfflineUrlTemplate = `{z}/{x}/{y}.jpg`;
export const tileSize = 256;

export const defaultMaxZoomLevel = 17;

export const mapLatitudePaddingMeters = 5000;
export const mapLongitudePaddingMeters = 5000;
