import React, { FC } from 'react';
import { VStack } from 'native-base';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { NavigationLink } from '../../../components/link';
import { screens } from '../../config';

type CodificatorUploadPageProps = PageProps<'Codificator'>;

export const CodificatorPage: FC<CodificatorUploadPageProps> = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <VStack>
        <NavigationLink title={t('codificator-page.upload')} route={screens.CodificatorUpload} />
        <NavigationLink
          title={t('codificator-page.upload-translations')}
          route={screens.CodificatorTranslationsUpload}
        />
      </VStack>
    </Page>
  );
};

CodificatorPage.displayName = 'CodificatorPage';
