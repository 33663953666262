import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import {
	CollectionDtoNotification,
	CollectionDtoNotificationIO,
} from '../components/schemas/CollectionDtoNotification';
import { Count, CountIO } from '../components/schemas/Count';
import { IdSetRequest, IdSetRequestIO } from '../components/schemas/IdSetRequest';
import { Notification, NotificationIO } from '../components/schemas/Notification';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { array, number, undefined, union, literal, type, void as tvoid } from 'io-ts';

export interface NotificationController<F> {
	readonly setNotificationsViewed: (parameters: { body: IdSetRequest }) => HKT<F, Array<Notification>>;

	readonly listOfNotifications: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'viewed' | 'eventTs' | 'actionType' | 'entityType') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => HKT<F, CollectionDtoNotification>;

	readonly deleteAllNotifications: () => HKT<F, void>;

	readonly countOfNotificationsForCurrentUser: () => HKT<F, Count>;
}

export interface NotificationController1<F extends URIS> {
	readonly setNotificationsViewed: (parameters: { body: IdSetRequest }) => Kind<F, Array<Notification>>;

	readonly listOfNotifications: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'viewed' | 'eventTs' | 'actionType' | 'entityType') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind<F, CollectionDtoNotification>;

	readonly deleteAllNotifications: () => Kind<F, void>;

	readonly countOfNotificationsForCurrentUser: () => Kind<F, Count>;
}

export interface NotificationController2<F extends URIS2> {
	readonly setNotificationsViewed: (parameters: { body: IdSetRequest }) => Kind2<F, Error, Array<Notification>>;

	readonly listOfNotifications: (parameters: {
		query: {
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'viewed' | 'eventTs' | 'actionType' | 'entityType') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind2<F, Error, CollectionDtoNotification>;

	readonly deleteAllNotifications: () => Kind2<F, Error, void>;

	readonly countOfNotificationsForCurrentUser: () => Kind2<F, Error, Count>;
}

export function notificationController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): NotificationController2<F>;
export function notificationController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): NotificationController1<F>;
export function notificationController<F>(e: { httpClient: HTTPClient<F> }): NotificationController<F>;
export function notificationController<F>(e: { httpClient: HTTPClient<F> }): NotificationController<F> {
	return {
		setNotificationsViewed: parameters => {
			const body = IdSetRequestIO.encode(parameters.body);

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/notification/set-viewed`,
					controller: 'notification',
					operation: 'setNotificationsViewed',
					method: 'PATCH',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						array(NotificationIO).decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		listOfNotifications: parameters => {
			const query = compact([
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([
						union([
							literal('id'),
							literal('viewed'),
							literal('eventTs'),
							literal('actionType'),
							literal('entityType'),
						]),
						undefined,
					]).encode(parameters.query['sortBy']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/notification`,
					controller: 'notification',
					operation: 'listOfNotifications',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoNotificationIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		deleteAllNotifications: () => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/notification`,
					controller: 'notification',
					operation: 'deleteAllNotifications',
					method: 'DELETE',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						tvoid.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		countOfNotificationsForCurrentUser: () => {
			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/notification/count`,
					controller: 'notification',
					operation: 'countOfNotificationsForCurrentUser',
					method: 'GET',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CountIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
