import React, { FC } from 'react';
import { Text } from 'native-base';

import { NavigationLink } from '../../../components/link';
import { StatusBadge } from '../../../components/status/status-badge';
import { Table, TableWithPaginationProps } from '../../../components/table/table';
import { Actions, ColumnDefinition, Sort } from '../../../components/table/table.model';
import { FacilitySortColumn } from '../../../models/Facility';
import { screens } from '../../../navigation/config';
import { FacilityTableEntity } from '../../../stores/facility-table';
import { dateFormat } from '../../../utils/date';

import { columns } from './facility-deleted-table.columns';

export type FacilityDeletedTableProps = TableWithPaginationProps<FacilityTableEntity, FacilitySortColumn>;

export const FacilityDeletedTable: FC<FacilityDeletedTableProps> = ({
  items,
  total,
  page,
  perPage,
  sort,
  selectedId,
  onRowClick,
  onChangeSort,
  onChangePage,
  actions,
  actionsWidth = 170
}) => {
  return (
    <Table
      onRowClick={onRowClick}
      onChangePage={onChangePage}
      onChangeSort={onChangeSort}
      sort={sort}
      selectedId={selectedId}
      page={page}
      total={total}
      items={items}
      perPage={perPage}
      columns={columns}
      actions={actions}
      actionsWidth={actionsWidth}
      customRenderers={{
        name: ({ id, name }) => {
          return <NavigationLink title={name} route={screens.FacilityView} routeParams={{ facilityId: id }} />;
        },
        status: ({ status }) => <StatusBadge status={status} />,
        lastUpdatedAt: ({ lastUpdatedAt }) => <Text>{dateFormat(lastUpdatedAt)}</Text>
      }}
    />
  );
};
