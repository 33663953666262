import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { ConfirmationPopup } from '../../../components/confirmation-popup';
import { Loadable, LoadingError, LoadingIndicator } from '../../../components/loadable';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { screens } from '../../../navigation/config';
import { appStore, facilityStore } from '../../../stores';

import { FacilityForm } from '../facility-form/facility-form';
import { FacilityFormData } from '../facility-form/facility-form.model';

interface FacilityUpdateContainerProps {
  facilityId: number;
}

export const FacilityUpdateContainer = observer(({ facilityId }: FacilityUpdateContainerProps) => {
  const { users: responsibleList } = appStore;
  const { item: facility } = facilityStore;
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const [isOpen, setOpen] = useState<boolean>(false);

  const ready = useComponentReady(() => {
    facilityStore.loadItem(facilityId).catch((e) => console.error(e));
  }, [facilityId]);

  if (!ready) {
    return null;
  }

  const onSubmit = (formData: FacilityFormData) => {
    const { name, cypher, status, responsibleUserId, geologistsUsersIds } = formData;
    facilityStore
      .update(facilityId, { name, cypher, status, responsibleUserId, geologistsUsersIds })
      .catch((e) => console.error(e));
  };

  const onCancel = () => {
    setOpen(true);
  };

  const onCancelConfirm = () => {
    setOpen(false);
    navigation.navigate(screens.FacilityView, { facilityId });
  };

  if (facilityStore.loading('get')) {
    return <LoadingIndicator />;
  }

  if (facilityStore.error('get')) {
    return <LoadingError />;
  }

  if (!facility || responsibleList.length === 0) {
    return null;
  }

  const formValue = {
    responsibleUserId: facility.responsibleUserId,
    name: facility.name,
    cypher: facility.cypher,
    status: facility.status,
    creationId: facility.creatorId,
    geologistsUsersIds: facility.geologists?.filter((user) => user.id !== undefined)?.map(({ id = 0 }) => id) || []
  };

  const creationList = [
    {
      id: facility.creatorId,
      name: facility.creatorName
    }
  ];

  return (
    <Loadable isLoading={facilityStore.loading('update')}>
      <FacilityForm
        isCreation={false}
        responsibleList={responsibleList}
        creationList={creationList}
        geologistList={responsibleList}
        value={formValue}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
      <ConfirmationPopup
        isOpen={isOpen}
        handleConfirmButton={onCancelConfirm}
        handleCloseButton={() => {
          setOpen(false);
        }}
      >
        {t('facility.form.update.cancel.confirmation')}
      </ConfirmationPopup>
    </Loadable>
  );
});
