import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, View } from 'native-base';
import { subject } from '@casl/ability';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ConfirmationPopup } from '../../../components/confirmation-popup';
import Icon from '../../../components/icon';
import { Loadable } from '../../../components/loadable';
import { Actions, Sort } from '../../../components/table/table.model';
import { useComponentReady } from '../../../hooks/useComponentReady';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';
import { BoreholeLayer, BoreholeLayerSortColumn } from '../../../models/BoreholeLayer';
import { boreholeLayerStore, boreholeLayerTableStore, boreholeStore, shiftStore } from '../../../stores';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { usePermission } from '../../../utils/permissions/context';

import { BoreholeLayerTable } from './borehole-layer-table';
import { BoreholeLayerFormData, schema } from './borehole-layer.model';
import { getFormData, formDataToCreateRequest, formDataToUpdateRequest, createEmptyBoreholeLayer } from './utils';
import { Form } from '../../../utils/form';

interface BoreholeLayerTableContainerProps {
  boreholeId: number;
  facilityId: number;
  layerId?: number;
}

export const BoreholeLayerTableContainer: FC<BoreholeLayerTableContainerProps> = observer((props) => {
  const { boreholeId, layerId } = props;
  const { item: borehole } = boreholeStore;
  const { isCurrentStarted } = shiftStore;

  const { items, page, perPage, total, sortTableProp, selectedId, updateSelection, updatePage, updateSort } =
    boreholeLayerTableStore;

  const { control, trigger, formState, handleSubmit, reset } = useForm<BoreholeLayerFormData>({
    mode: 'onSubmit',
    resolver: yupResolver<any>(schema),
    reValidateMode: 'onChange'
  });
  const form: Form<BoreholeLayerFormData> = { control, validate: trigger, state: formState };

  const { t } = useTranslation();
  const [editableBoreholeLayerId, setEditableBoreholeLayerId] = useState<number | null>(null);
  const [deleteBoreholeLayerId, setDeleteBoreholeLayerId] = useState<number | null>(null);
  const permission = usePermission();

  const ready = useComponentReady(() => {
    boreholeStore.loadItem(boreholeId).catch((e) => console.error(e));
    boreholeLayerTableStore.setBoreholeId(boreholeId);
    boreholeLayerTableStore.loadItems().catch((e) => console.error(e));
  }, [boreholeId]);

  useNavigationEffect(() => {
    return () => {
      boreholeLayerStore.setItem(null);
    };
  });

  useNavigationEffect(() => {
    if (layerId) {
      updateSelection(layerId);
    }
    return () => updateSelection(undefined);
  }, [layerId]);

  if (!ready) {
    return null;
  }

  const onSubmit = (formData: BoreholeLayerFormData) => {
    if (editableBoreholeLayerId === null) {
      return;
    }

    (async () => {
      try {
        let boreholeLayer;
        if (editableBoreholeLayerId === 0) {
          boreholeLayer = await boreholeLayerStore.create(formDataToCreateRequest(formData));
          boreholeLayerTableStore.remove(0);
          boreholeLayerTableStore.add(boreholeLayer);
        } else {
          boreholeLayer = await boreholeLayerStore.update(editableBoreholeLayerId, formDataToUpdateRequest(formData));
          boreholeLayerTableStore.update(boreholeLayer);
        }
        setEditableBoreholeLayerId(null);
        await boreholeLayerTableStore.refresh();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const onAdd = () => {
    if (!borehole) {
      return;
    }
    const boreholeLayer: BoreholeLayer = createEmptyBoreholeLayer(borehole);
    boreholeLayerTableStore.add(boreholeLayer);
    setEditableBoreholeLayerId(boreholeLayer.id);
    reset(getFormData(boreholeLayer));
  };

  const onChangePage = (p: number) => {
    const newPage = p - 1;
    updatePage(newPage);
  };

  const onChangeSort = (sort: Sort<BoreholeLayerSortColumn>) => {
    updateSort(sort?.column, sort?.sortOrder);
  };

  const confirmDelete = () => {
    if (typeof deleteBoreholeLayerId === 'number') {
      (async () => {
        try {
          if (deleteBoreholeLayerId > 0) {
            await boreholeLayerStore.remove(deleteBoreholeLayerId);
          }
          boreholeLayerTableStore.remove(deleteBoreholeLayerId);
          setDeleteBoreholeLayerId(null);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  };

  const cancelDelete = () => {
    setDeleteBoreholeLayerId(null);
  };

  const canCreate =
    !!borehole &&
    isCurrentStarted &&
    permission.can(
      ACTION.CREATE,
      subject(SUBJECT.BOREHOLE_LAYER, {
        status: borehole.status,
        facilityStatus: borehole.facility.status,
        markToDelete: borehole.facility.markToDelete,
        isActiveShift: isCurrentStarted
      })
    );

  const actions: Actions<BoreholeLayer> = [
    {
      id: 'edit',
      iconName: 'edit',
      actionCallback: (boreholeLayer) => {
        setEditableBoreholeLayerId(boreholeLayer.id);
        reset(getFormData(boreholeLayer));
      },
      isVisible: () =>
        !!borehole &&
        permission.can(
          ACTION.EDIT,
          subject(SUBJECT.BOREHOLE_LAYER, {
            status: borehole.status,
            facilityStatus: borehole.facility.status,
            markToDelete: borehole.facility.markToDelete,
            isActiveShift: isCurrentStarted
          })
        )
    },
    {
      id: 'delete',
      iconName: 'delete',
      actionCallback: (boreholeLayer) => {
        setDeleteBoreholeLayerId(boreholeLayer.id);
      },
      isVisible: (boreholeLayer) =>
        !!borehole &&
        boreholeLayer.canEdit &&
        permission.can(
          ACTION.DELETE,
          subject(SUBJECT.BOREHOLE_LAYER, {
            status: borehole.status,
            facilityStatus: borehole.facility.status,
            markToDelete: borehole.facility.markToDelete,
            isActiveShift: isCurrentStarted
          })
        )
    }
  ];

  const actionsEditable: Actions<BoreholeLayer> = [
    {
      id: 'submit',
      iconName: 'check',
      iconVariant: 'solid',
      actionCallback: () => handleSubmit(onSubmit)()
    },
    {
      id: 'cancel',
      iconName: 'cancel',
      actionCallback: () => {
        if (editableBoreholeLayerId === 0) {
          boreholeLayerTableStore.remove(editableBoreholeLayerId);
        }
        setEditableBoreholeLayerId(null);
      }
    }
  ];

  return (
    <Loadable
      isLoading={boreholeStore.loading('get') || boreholeLayerStore.loading('get')}
      error={!!boreholeStore.error('get') || !!boreholeLayerStore.error('get')}
    >
      <ConfirmationPopup
        isOpen={typeof deleteBoreholeLayerId === 'number'}
        handleConfirmButton={confirmDelete}
        handleCloseButton={cancelDelete}
      >
        Вы уверены, что хотите удалить этот слой без возможности восстановления?
      </ConfirmationPopup>
      <BoreholeLayerTable
        form={form}
        editableBoreholeLayerId={typeof editableBoreholeLayerId === 'number' ? editableBoreholeLayerId : undefined}
        items={items.slice()}
        total={total}
        page={page}
        perPage={perPage}
        sort={sortTableProp}
        onRowClick={updateSelection}
        selectedId={selectedId}
        actions={actions}
        actionsEditable={actionsEditable}
        onChangePage={onChangePage}
        onChangeSort={onChangeSort}
      />
      {canCreate ? (
        <Button
          disabled={typeof editableBoreholeLayerId === 'number'}
          width={200}
          marginY={6}
          startIcon={<Icon name="addplus" />}
          onPress={onAdd}
        >
          {t('borehole-layer.add')}
        </Button>
      ) : (
        <View marginY={6} height={2} />
      )}
    </Loadable>
  );
});
