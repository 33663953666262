import { User, UserIO } from '../../components/schemas/User';
import { number, undefined, union, type } from 'io-ts';

export type BoreholeTemperature = {
	id: number;
	depth: number | undefined;
	temperature: number | undefined;
	measuredAt: number | undefined;
	geologist: User;
};
export const BoreholeTemperatureIO = type(
	{
		id: number,
		depth: union([number, undefined]),
		temperature: union([number, undefined]),
		measuredAt: union([number, undefined]),
		geologist: UserIO,
	},
	'BoreholeTemperature',
);
