import {
	FacilityNotificationParams,
	FacilityNotificationParamsIO,
} from '../../components/schemas/FacilityNotificationParams';
import { NotificationParams, NotificationParamsIO } from '../../components/schemas/NotificationParams';
import { string, undefined, union, type, intersection } from 'io-ts';

export type BoreholeNotificationParams = NotificationParams & {
	name: string | undefined;
	facility: FacilityNotificationParams | undefined;
};
export const BoreholeNotificationParamsIO = intersection([
	NotificationParamsIO,
	type({ name: union([string, undefined]), facility: union([FacilityNotificationParamsIO, undefined]) }),
]);
