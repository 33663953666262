import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Platform } from 'react-native';
import { Page } from '../../../components/page';
import { Tabs } from '../../../components/tabs';
import { PhotoListContainer } from '../../../features/photo/photo-list.container';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';
import { facilityStore } from '../../../stores';
import { ACTION, SUBJECT } from '../../../utils/permissions';
import { PermissionGuard } from '../../../utils/permissions/guard';

import { PageProps } from '../../types';
import { useComponent } from '../../../hooks/useComponent';

type PhotoListPageProps = PageProps<'Photos'>;

export const PhotoListPage: FC<PhotoListPageProps> = observer(({ route }) => {
  const { params } = route;
  const facilityId = params?.facilityId;

  const { FabImagePicker } = useComponent();

  useNavigationEffect(() => {
    facilityStore.loadItem(facilityId).catch((e) => console.error(e));
  }, [facilityId]);

  if (!facilityId) {
    return null;
  }

  const { isArchived, isDeleted } = facilityStore;
  const showAddButton = !facilityStore.loading('get') && !facilityStore.error('get') && !isArchived && !isDeleted;

  return (
    <Page
      header={<Tabs facilityId={facilityId} />}
      siblings={
        showAddButton && (
          <PermissionGuard action={ACTION.CREATE} subject={SUBJECT.PHOTO}>
            {Platform.OS === 'web' ? (
              <FabImagePicker type="gallery" facilityId={facilityId} />
            ) : (
              <>
                <FabImagePicker right={90} bg="dark.dark-2" type="camera" facilityId={facilityId} />
                <FabImagePicker type="gallery" facilityId={facilityId} />
              </>
            )}
          </PermissionGuard>
        )
      }
    >
      <PhotoListContainer facilityId={facilityId} />
    </Page>
  );
});

PhotoListPage.displayName = PhotoListPage.name;
