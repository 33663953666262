import React, { createContext, ReactElement, PropsWithChildren } from 'react';
import { defaultFunctions } from './default';
import { Functions } from './types';

type ComponentProviderProps = PropsWithChildren<{
  functions: Functions;
}>;

const Context = createContext(defaultFunctions);

const Provider = (props: ComponentProviderProps): ReactElement => {
  const { functions, children } = props;
  return <Context.Provider value={functions}>{children}</Context.Provider>;
};

export { Context, Provider };
