import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import memoize from 'fast-memoize';

import { Table, TableWithPaginationProps } from '../../../components/table/table';
import { Actions } from '../../../components/table/table.model';
import { TableRow, TableRowProps } from '../../../components/table/table-row';
import { BoreholeLayer, BoreholeLayerSortColumn } from '../../../models/BoreholeLayer';
import { Form } from '../../../utils/form';

import { getRenderers } from './renderer';
import { getFormRenderers } from './form-renderer';
import { BoreholeLayerFormData } from './borehole-layer.model';
import { columns } from './borehole-layer-table.columns';

type BoreholeLayerTableProps = TableWithPaginationProps<BoreholeLayer, BoreholeLayerSortColumn> & {
  form: Form<BoreholeLayerFormData>;
  editableBoreholeLayerId?: number;
  actionsEditable?: Actions<BoreholeLayer>;
};

export const BoreholeLayerTable: FC<BoreholeLayerTableProps> = observer((props) => {
  const { form, editableBoreholeLayerId, perPage, page, onRowClick, actions, actionsEditable } = props;

  const isEditableBoreholeLayer = useMemo(() => {
    return memoize((boreholeLayer: BoreholeLayer): boolean => {
      return typeof editableBoreholeLayerId === 'number' && boreholeLayer.id === editableBoreholeLayerId;
    });
  }, [editableBoreholeLayerId]);

  const renderItem =
    (rowProps: TableRowProps<BoreholeLayer, BoreholeLayerSortColumn>) =>
    ({ item }: { item: BoreholeLayer; index: number }) => {
      const isEditable = isEditableBoreholeLayer(item);
      return (
        <TableRow
          key={`table-row-${item.id}`}
          {...rowProps}
          customRenderers={isEditable ? getFormRenderers(form, perPage, page) : getRenderers(perPage, page)}
          actions={isEditable ? actionsEditable : actions}
        />
      );
    };

  return (
    <Table
      {...props}
      columns={columns}
      onRowClick={onRowClick}
      customRowRenderer={renderItem}
      rowAlignment="flex-start"
      actionsWidth={110}
      page={page}
      hidePagination
      perPage={perPage}
    />
  );
});
