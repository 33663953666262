import React, { FC } from 'react';
import { PageProps } from '../../types';
import { BoreholeLayerSampleTableContainer } from '../../../features/borehole-sample/borehole-sample-table/borehole-sample-table.container';
import { Page } from '../../../components/page';
import { Tabs } from '../../../components/tabs';

type BoreholeSamplesPageProps = PageProps<'BoreholeSamples'>;

export const BoreholeSamplesPage: FC<BoreholeSamplesPageProps> = ({ route }) => {
  const { params } = route;
  const facilityId = params?.facilityId;
  if (!facilityId) {
    return null;
  }
  return (
    <Page header={<Tabs facilityId={facilityId} />}>
      <BoreholeLayerSampleTableContainer facilityId={facilityId} />
    </Page>
  );
};

BoreholeSamplesPage.displayName = BoreholeSamplesPage.name;
