import { ResponseValidationError, HTTPClient, HTTPClient1, HTTPClient2 } from '../client/client';
import { CollectionDtoAuditEvent, CollectionDtoAuditEventIO } from '../components/schemas/CollectionDtoAuditEvent';
import { LocationDto, LocationDtoIO } from '../components/schemas/LocationDto';
import { serializePrimitiveParameter } from '../utils/openapi-3-utils';
import { getResponseTypeFromMediaType } from '../utils/utils';
import { either, option } from 'fp-ts';
import { compact } from 'fp-ts/lib/Array';
import { HKT, Kind, Kind2, URIS, URIS2 } from 'fp-ts/lib/HKT';
import { fromEither } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { void as tvoid, number, undefined, union, literal, type } from 'io-ts';

export interface AuditEventController<F> {
	readonly tabLocationOnline: () => HKT<F, void>;

	readonly createOffline: () => HKT<F, void>;

	readonly tabLocationEvent: (parameters: { body: LocationDto }) => HKT<F, void>;

	readonly list: (parameters: {
		query: {
			from: number;
			to: number;
			principalId: number | undefined;
			eventType:
				| (
						| 'PHOTO_CREATE'
						| 'PHOTO_UPDATE'
						| 'PHOTO_DELETE'
						| 'DOCUMENT_CREATE'
						| 'DOCUMENT_UPDATE'
						| 'DOCUMENT_DELETE'
						| 'COMMENT_CREATE'
						| 'COMMENT_UPDATE'
						| 'COMMENT_DELETE'
						| 'TAB_LOCATION'
						| 'TAB_ONLINE'
						| 'TAB_OFFLINE'
				  )
				| undefined;
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'eventTs' | 'eventType') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => HKT<F, CollectionDtoAuditEvent>;
}

export interface AuditEventController1<F extends URIS> {
	readonly tabLocationOnline: () => Kind<F, void>;

	readonly createOffline: () => Kind<F, void>;

	readonly tabLocationEvent: (parameters: { body: LocationDto }) => Kind<F, void>;

	readonly list: (parameters: {
		query: {
			from: number;
			to: number;
			principalId: number | undefined;
			eventType:
				| (
						| 'PHOTO_CREATE'
						| 'PHOTO_UPDATE'
						| 'PHOTO_DELETE'
						| 'DOCUMENT_CREATE'
						| 'DOCUMENT_UPDATE'
						| 'DOCUMENT_DELETE'
						| 'COMMENT_CREATE'
						| 'COMMENT_UPDATE'
						| 'COMMENT_DELETE'
						| 'TAB_LOCATION'
						| 'TAB_ONLINE'
						| 'TAB_OFFLINE'
				  )
				| undefined;
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'eventTs' | 'eventType') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind<F, CollectionDtoAuditEvent>;
}

export interface AuditEventController2<F extends URIS2> {
	readonly tabLocationOnline: () => Kind2<F, Error, void>;

	readonly createOffline: () => Kind2<F, Error, void>;

	readonly tabLocationEvent: (parameters: { body: LocationDto }) => Kind2<F, Error, void>;

	readonly list: (parameters: {
		query: {
			from: number;
			to: number;
			principalId: number | undefined;
			eventType:
				| (
						| 'PHOTO_CREATE'
						| 'PHOTO_UPDATE'
						| 'PHOTO_DELETE'
						| 'DOCUMENT_CREATE'
						| 'DOCUMENT_UPDATE'
						| 'DOCUMENT_DELETE'
						| 'COMMENT_CREATE'
						| 'COMMENT_UPDATE'
						| 'COMMENT_DELETE'
						| 'TAB_LOCATION'
						| 'TAB_ONLINE'
						| 'TAB_OFFLINE'
				  )
				| undefined;
			page: number | undefined;
			size: number | undefined;
			sortBy: ('id' | 'eventTs' | 'eventType') | undefined;
			order: ('ASC' | 'DESC') | undefined;
		};
	}) => Kind2<F, Error, CollectionDtoAuditEvent>;
}

export function auditEventController<F extends URIS2>(e: { httpClient: HTTPClient2<F> }): AuditEventController2<F>;
export function auditEventController<F extends URIS>(e: { httpClient: HTTPClient1<F> }): AuditEventController1<F>;
export function auditEventController<F>(e: { httpClient: HTTPClient<F> }): AuditEventController<F>;
export function auditEventController<F>(e: { httpClient: HTTPClient<F> }): AuditEventController<F> {
	return {
		tabLocationOnline: () => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/audit-event/tab-online`,
					controller: 'auditEvent',
					operation: 'tabLocationOnline',
					method: 'POST',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						tvoid.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		createOffline: () => {
			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/audit-event/tab-offline`,
					controller: 'auditEvent',
					operation: 'createOffline',
					method: 'POST',
					responseType,
					pathParameters: [],

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						tvoid.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		tabLocationEvent: parameters => {
			const body = LocationDtoIO.encode(parameters.body);

			const accept = 'application/json';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
				'Content-type': 'application/json',
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/audit-event/tab-location`,
					controller: 'auditEvent',
					operation: 'tabLocationEvent',
					method: 'POST',
					responseType,
					pathParameters: [],

					body,
					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						tvoid.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},

		list: parameters => {
			const query = compact([
				pipe(number.encode(parameters.query['from']), value =>
					fromEither(serializePrimitiveParameter('form', 'from', value)),
				),
				pipe(number.encode(parameters.query['to']), value =>
					fromEither(serializePrimitiveParameter('form', 'to', value)),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['principalId']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'principalId', value))),
				),
				pipe(
					union([
						union([
							literal('PHOTO_CREATE'),
							literal('PHOTO_UPDATE'),
							literal('PHOTO_DELETE'),
							literal('DOCUMENT_CREATE'),
							literal('DOCUMENT_UPDATE'),
							literal('DOCUMENT_DELETE'),
							literal('COMMENT_CREATE'),
							literal('COMMENT_UPDATE'),
							literal('COMMENT_DELETE'),
							literal('TAB_LOCATION'),
							literal('TAB_ONLINE'),
							literal('TAB_OFFLINE'),
						]),
						undefined,
					]).encode(parameters.query['eventType']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'eventType', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['page']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'page', value))),
				),
				pipe(
					union([number, undefined]).encode(parameters.query['size']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'size', value))),
				),
				pipe(
					union([union([literal('id'), literal('eventTs'), literal('eventType')]), undefined]).encode(
						parameters.query['sortBy'],
					),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'sortBy', value))),
				),
				pipe(
					union([union([literal('ASC'), literal('DESC')]), undefined]).encode(parameters.query['order']),
					option.fromNullable,
					option.chain(value => fromEither(serializePrimitiveParameter('form', 'order', value))),
				),
			]).join('&');

			const accept = '*/*';

			const responseType = getResponseTypeFromMediaType(accept);
			const requestHeaders = {
				Accept: accept,
			};

			return e.httpClient.chain(
				e.httpClient.request({
					url: `/api/v1/audit-event`,
					controller: 'auditEvent',
					operation: 'list',
					method: 'GET',
					responseType,
					pathParameters: [],
					parameters,
					query,

					headers: { ...requestHeaders },
				}),
				value =>
					pipe(
						CollectionDtoAuditEventIO.decode(value),
						either.mapLeft(ResponseValidationError.create),
						either.fold(
							error => e.httpClient.throwError(error),
							decoded => e.httpClient.of(decoded),
						),
					),
			);
		},
	};
}
