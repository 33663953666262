import React, { FC } from 'react';
import { Box, Center, HStack, Text } from 'native-base';
import { PageView, PageViewProps } from './page-view';

const dots = (
  <Box width="44px" height="40px">
    <Center flex={1}>
      <Text>...</Text>
    </Center>
  </Box>
);

export const BreakView: FC<PageViewProps> = (props) => (
  <HStack space={1} alignItems="center">
    <PageView {...props} />
    {dots}
  </HStack>
);
