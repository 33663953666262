import { literal, union, type } from 'io-ts';

export type FacilityStatusRequest = { status: 'NEW' | 'IN_PROGRESS' | 'PROBLEM' | 'CLOSED' | 'ARCHIVED' };
export const FacilityStatusRequestIO = type(
	{
		status: union([
			literal('NEW'),
			literal('IN_PROGRESS'),
			literal('PROBLEM'),
			literal('CLOSED'),
			literal('ARCHIVED'),
		]),
	},
	'FacilityStatusRequest',
);
