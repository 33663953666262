import React, { FC } from 'react';
import { Page } from '../../../components/page';
import { PageProps } from '../../types';
import { CodificatorUploader } from '../../../features/codificator/codificator-uploader';

type CodificatorUploadPageProps = PageProps<'CodificatorUpload'>;

export const CodificatorUploadPage: FC<CodificatorUploadPageProps> = () => {
  return (
    <Page>
      <CodificatorUploader successText="Обновление прошло успешно" requestUrl="/api/v1/codificator" />
    </Page>
  );
};

CodificatorUploadPage.displayName = 'CodificatorUploadPage';
