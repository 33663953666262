import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { ScrollView, Flex, View } from 'native-base';
import { useNavigationEffect } from '../hooks/useNavigationEffect';

export interface PageProps {
  header?: ReactElement;
  siblings?: ReactNode;
  scrollable?: boolean;
}

export const Page: FC<PageProps> = (props): ReactElement => {
  const { children, header, siblings, scrollable = true } = props;
  const [visible, setVisible] = useState(false);

  useNavigationEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  const headerElement = header && (
    <View marginX={34} marginBottom={2}>
      {header}
    </View>
  );

  const contentElement = visible && (
    <Flex flexDirection="column" flexGrow={1} flexShrink={1} flexBasis="auto" mx={34}>
      {children}
    </Flex>
  );

  const siblingsElement = siblings;

  return scrollable ? (
    <>
      {headerElement}
      <ScrollView style={{ flexGrow: 1 }} contentContainerStyle={{ flexGrow: 1 }} keyboardShouldPersistTaps="handled">
        {contentElement}
      </ScrollView>
      {siblingsElement}
    </>
  ) : (
    <>
      {headerElement}
      {contentElement}
      {siblingsElement}
    </>
  );
};

Page.displayName = Page.name;
