import { useMemo } from 'react';

export function useGridItems(data: Array<any>, columns: number): Array<Array<number>> {
  return useMemo(() => {
    const items: Array<Array<number>> = [];
    data.forEach((item, i) => {
      const rowIndex = Math.floor(i / columns);
      const columnIndex = i % columns;
      if (items.length === rowIndex) {
        items.push([columnIndex]);
      } else {
        items[rowIndex].push(columnIndex);
      }
    });
    return items;
  }, [data.length, columns]);
}
