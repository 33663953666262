import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Text, ScrollView } from 'native-base';

import { Loadable } from '../../../components/loadable';
import { appStore, commentsStore, facilityStore } from '../../../stores';
import { Comment } from '../comment';
import { BlankPage } from '../../../components/blank';
import { Comments } from '../comments';
import { CommentForm } from '../comment-form';
import { useNavigationEffect } from '../../../hooks/useNavigationEffect';

type CommentsContainerProps = {
  facilityId: number;
};

interface FormValue {
  text: string;
}

export const CommentsContainer: React.FC<CommentsContainerProps> = observer(({ facilityId }) => {
  const { currentUser } = appStore;
  const [comment, setComment] = useState<Comment | undefined>();
  const { isLoading, error, items: comments } = commentsStore;
  const { t } = useTranslation();

  useNavigationEffect(() => {
    facilityStore.loadItem(facilityId).catch((e) => console.error(e));
  }, [facilityId]);

  const data = comments.map((commentItem) => {
    const { creator, ...rest } = commentItem;
    const {
      name,
      id,
      roles: [role]
    } = creator;
    const props: Comment = {
      ...rest,
      creatorId: id,
      creatorName: name,
      role
    };
    return props;
  });
  const isBlank = data.length === 0;

  const onSubmit = (value: FormValue) => {
    if (comment) {
      commentsStore
        .update(facilityId, comment.id, value)
        .then(() => setComment(undefined))
        .catch();
    } else {
      commentsStore.create(facilityId, value).catch((e) => console.error(e));
    }
  };

  const handleDelete = (commentId: number) => {
    commentsStore.delete(facilityId, commentId).catch((e) => console.error(e));
  };

  const handleEdit = setComment;

  const handleCancelEdit = () => {
    setComment(undefined);
  };

  const { isArchived, isDeleted } = facilityStore;
  const showCommentForm = !isArchived && !isDeleted;

  return (
    <Loadable isLoading={isLoading || facilityStore.loading('get')} error={!!error || !!facilityStore.error('get')}>
      {isBlank ? (
        <BlankPage isNeedIcon={false}>
          <Text fontSize={20} fontWeight={500} color="#b4b6c9" opacity={0.56}>
            {t('comment.list.emptyPlaceholder')}
          </Text>
        </BlankPage>
      ) : (
        <ScrollView flex={1} keyboardShouldPersistTaps="handled">
          <Comments currentUserId={currentUser?.id} comments={data} onEdit={handleEdit} onDelete={handleDelete} />
        </ScrollView>
      )}
      {showCommentForm && <CommentForm onCancel={handleCancelEdit} comment={comment} onSubmit={onSubmit} />}
    </Loadable>
  );
});
