import React, { useCallback, useState } from 'react';
import { useToast } from 'native-base';
import { observer } from 'mobx-react';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { UploaderStore } from '../../stores/uploader';
import { screens } from '../../navigation/config';
import { ToastAlert } from '../../components/toast-alert/toast-alert';
import { FilesUploadForm } from '../files-upload/files-upload-form/files-upload-form';
import { Loadable } from '../../components/loadable';

interface CodificatorUploaderProps {
  requestUrl: string;
  successText: string;
}

export const CodificatorUploader = observer(({ successText, requestUrl }: CodificatorUploaderProps) => {
  const navigation = useAppNavigation();
  const [{ anyLoading, anyError, item, setItem, upload, setValidate, validate }] = useState<UploaderStore<void>>(
    new UploaderStore<void>()
  );
  const toast = useToast();
  const toMain = useCallback(() => navigation.navigate(screens.Facilities, { page: undefined }), []);

  const onSubmit = useCallback(async () => {
    await upload(requestUrl);
    toast.show({
      render: ({ id }) => {
        const details = {
          title: successText,
          variant: 'solid',
          isClosable: true
        };
        return <ToastAlert id={id} {...details} />;
      }
    });
    toMain();
  }, [item]);
  return (
    <Loadable isLoading={anyLoading} error={anyError}>
      <FilesUploadForm
        file={null}
        fileType="codificator"
        extensions={['json']}
        isLoading={false}
        onCancel={toMain}
        onSubmit={onSubmit}
        setUploadValue={setItem}
        setValidate={setValidate}
        uploadValue={item}
        validate={validate}
      />
    </Loadable>
  );
});
