import React, { FC } from 'react';
import { PageProps } from '../../types';
import { BoreholeLayerTableContainer } from '../../../features/borehole-layer/borehole-layer-table/borehole-layer-table.container';
import { BoreholeLoader } from './borehole-loader';

type BoreholeLayersPageProps = PageProps<'BoreholeLayers'>;

export const BoreholeLayersPage: FC<BoreholeLayersPageProps> = ({ route }) => {
  const { params } = route;
  return (
    <BoreholeLoader {...route.params}>
      <BoreholeLayerTableContainer {...params} />
    </BoreholeLoader>
  );
};

BoreholeLayersPage.displayName = BoreholeLayersPage.name;
