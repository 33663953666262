import { Coordinate, CoordinateIO } from '../../components/schemas/Coordinate';
import { User, UserIO } from '../../components/schemas/User';
import { Worker, WorkerIO } from '../../components/schemas/Worker';
import { number, undefined, union, type } from 'io-ts';

export type Shift = {
	id: number;
	boreholeId: number;
	worker: Worker;
	geologist: User;
	coordinate: Coordinate | undefined;
	depthTo: number | undefined;
	startedAt: number;
	finishedAt: number | undefined;
	lastUpdatedAt: number;
};
export const ShiftIO = type(
	{
		id: number,
		boreholeId: number,
		worker: WorkerIO,
		geologist: UserIO,
		coordinate: union([CoordinateIO, undefined]),
		depthTo: union([number, undefined]),
		startedAt: number,
		finishedAt: union([number, undefined]),
		lastUpdatedAt: number,
	},
	'Shift',
);
