import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { differenceInWeeks } from 'date-fns';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useComponentReady } from '../../hooks/useComponentReady';
import { Loadable } from '../../components/loadable';
import { BoreholeTemperature } from '../../models/BoreholeTemperatures';
import { boreholeStore, boreholeTemperaturesTableStore, boreholeTemperatureStore } from '../../stores';

import { BoreholeTemperatures } from './borehole-temperatures';
import { Actions } from '../../components/table/table.model';
import { Form } from '../../utils/form';
import { BoreholeTemperatureFormData, prepareDataToSubmit, schema } from './borehole-temperature.model';
import { getFormData } from './utils';
import { useAppNavigation } from '../../hooks/useAppNavigation';
import { screens } from '../../navigation/config';

type BoreholeTemperaturesContainerProps = {
  boreholeId: number;
};

export const BoreholeTemperaturesContainer: FC<BoreholeTemperaturesContainerProps> = observer((props) => {
  const { boreholeId } = props;

  const navigation = useAppNavigation();
  const [editableBoreholeTemperatureId, setEditableBoreholeTemperatureId] = useState<number | null>(null);

  const { item: borehole } = boreholeStore;
  const { isLoading, items, updateSelection, selectedId } = boreholeTemperaturesTableStore;

  const { control, trigger, formState, reset, handleSubmit } = useForm<BoreholeTemperatureFormData>({
    mode: 'onSubmit',
    resolver: yupResolver<any>(schema),
    reValidateMode: 'onChange'
  });
  const form: Form<BoreholeTemperatureFormData> = { control, validate: trigger, state: formState };

  const ready = useComponentReady(() => {
    if (borehole?.id !== boreholeId) {
      boreholeStore.loadItem(boreholeId).catch((e) => console.error(e));
    }
    boreholeTemperaturesTableStore.setBoreholeId(boreholeId);
    boreholeTemperaturesTableStore.loadItems().catch();
  }, [boreholeId]);

  if (!ready) {
    return null;
  }

  if (borehole && borehole.status !== 'CLOSED') {
    navigation.navigate(screens.BoreholeView, { boreholeId, facilityId: borehole.facility.id });
  }

  const onSubmit = (formData: BoreholeTemperatureFormData) => {
    if (editableBoreholeTemperatureId === null) {
      return;
    }

    (async () => {
      try {
        const { id, ...requestData } = formData;
        const submitData = prepareDataToSubmit({ id, ...requestData });
        await boreholeTemperatureStore.update(id, submitData);
        setEditableBoreholeTemperatureId(null);
        await boreholeTemperaturesTableStore.refresh();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let canEdit = false;
  if (borehole?.signAt && borehole?.status === 'CLOSED') {
    canEdit = true; // differenceInWeeks(new Date(), borehole.signAt) >= 2;
  }

  const actions: Actions<BoreholeTemperature> = [];

  if (canEdit) {
    actions.push({
      id: 'edit',
      iconName: 'edit',
      actionCallback: (boreholeTemperature) => {
        setEditableBoreholeTemperatureId(boreholeTemperature.id);
        reset(getFormData(boreholeTemperature));
      },
      isVisible: () => canEdit
    });
  }

  const actionsEditable: Actions<BoreholeTemperature> = [
    {
      id: 'submit',
      iconName: 'check',
      iconVariant: 'solid',
      actionCallback: () => handleSubmit(onSubmit)()
    },
    {
      id: 'cancel',
      iconName: 'cancel',
      actionCallback: () => {
        setEditableBoreholeTemperatureId(null);
      }
    }
  ];

  return (
    <Loadable isLoading={isLoading}>
      <BoreholeTemperatures
        form={form}
        editableBoreholeTemperatureId={
          typeof editableBoreholeTemperatureId === 'number' ? editableBoreholeTemperatureId : undefined
        }
        onRowClick={updateSelection}
        items={items}
        actions={actions}
        actionsWidth={canEdit ? undefined : 0}
        selectedId={selectedId}
        actionsEditable={actionsEditable}
      />
    </Loadable>
  );
});
