import React, { FC } from 'react';
import { Box, Text } from 'native-base';

import { FacilityImage } from '../../components/facility-image';

interface PhotoCardComponentProps {
  facilityId: number;
  photoId: number;
  contentType?: string;
  title?: string;
}

export const PhotoCard: FC<PhotoCardComponentProps> = ({
  facilityId,
  contentType = 'images/jpeg',
  title = 'image title',
  photoId
}) => {
  return (
    <Box>
      <FacilityImage
        resizeMode="contain"
        contentType={contentType}
        facilityId={facilityId}
        isThumbnail={false}
        photoId={photoId}
        width={940}
        height={560}
        alt={title}
      />
      <Text fontWeight={500} my={10} textAlign="center">
        {title}
      </Text>
    </Box>
  );
};
