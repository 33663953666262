import React, { FC } from 'react';

import { Page } from '../../../components/page';
import { BoreholeCreate } from '../../../features/borehole/borehole-create/borehole-create';
import { PageProps } from '../../types';

type BoreholeCreatePageProps = PageProps<'BoreholeCreate'>;

export const BoreholeCreatePage: FC<BoreholeCreatePageProps> = ({ route }) => {
  const { params } = route;
  const currentId = params?.facilityId;
  if (!currentId) {
    return null;
  }
  return (
    <Page>
      <BoreholeCreate facilityId={currentId} />
    </Page>
  );
};

BoreholeCreatePage.displayName = BoreholeCreatePage.name;
