import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Pressable, Text } from 'native-base';

import Icon from '../icon/icon';
import { translationKeyRegExp } from '../../stores/codificator';

type GeoDescriptionItemProps = {
  index: number;
  levelId: string;
  itemKey: string;
  valid?: boolean;
  onRemove?: (index: number) => void;
};

const removeSize = 17;

export const GeoDescriptionItem: FC<GeoDescriptionItemProps> = (props) => {
  const { index, levelId, itemKey, valid = true, onRemove } = props;
  const { t } = useTranslation();

  const onPress = () => {
    if (onRemove) {
      onRemove(index);
    }
  };

  const backgroundColor = valid ? '#eff2ff' : '#feefef';
  return (
    <HStack backgroundColor={backgroundColor} borderRadius="lg" paddingX={4} paddingY={2} alignItems="center">
      <Text color="dark.dark-0" fontWeight={500} flex={1}>
        {t(`codificator.${itemKey.replace(translationKeyRegExp, '-')}`, { defaultValue: itemKey })}
      </Text>
      {onRemove && (
        <Pressable onPress={onPress} width={`${removeSize}px`} height={`${removeSize}px`}>
          <Icon name="cancel" backgroundColor="white" stretch={true} borderRadius={`${removeSize / 2}px`} />
        </Pressable>
      )}
    </HStack>
  );
};

GeoDescriptionItem.displayName = GeoDescriptionItem.name;
